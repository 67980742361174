var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M1360 4707 c-47 -14 -892 -848 -933 -921 -38 -67 -38 -145 1 -212 18\n-33 174 -194 462 -480 461 -456 458 -454 550 -454 124 0 240 116 240 240 0 86\n-13 104 -244 338 l-220 222 1330 0 c1505 0 1390 -6 1467 80 90 100 90 220 0\n320 -77 86 38 80 -1467 80 l-1330 0 220 223 c181 182 222 229 232 263 27 95 8\n169 -63 239 -70 71 -151 91 -245 62z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3600 2467 c-19 -6 -58 -34 -85 -62 -71 -70 -90 -144 -63 -239 10\n-34 51 -81 232 -263 l220 -223 -1328 0 c-1014 0 -1338 -3 -1370 -12 -86 -26\n-166 -136 -166 -228 0 -92 80 -202 166 -228 32 -9 356 -12 1370 -12 l1328 0\n-220 -222 c-231 -234 -244 -252 -244 -338 0 -124 116 -240 240 -240 91 0 90\n-2 550 454 290 287 444 447 463 479 21 38 27 63 27 107 0 44 -6 69 -27 107\n-19 32 -173 192 -463 479 -368 364 -441 432 -476 442 -50 14 -106 14 -154 -1z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }