var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "import-area" }, [
    _c("div", { staticClass: "tokens-area" }, [
      _c("div", { staticClass: "token-item" }, [
        _c(
          "div",
          {
            ref: "fromListButton",
            staticClass: "token-select",
            on: { click: _vm.toggleFromList },
          },
          [
            _vm.fromToken === null
              ? _c("div", { staticClass: "token-data" }, [
                  _c("div", { staticClass: "token-area" }, [
                    _c("div", { staticClass: "empty-area" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "normal" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("liquidity.select_token")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chevron-area" },
                    [_c("ChevronDown")],
                    1
                  ),
                ])
              : _c("div", { staticClass: "token-data" }, [
                  _c(
                    "div",
                    { staticClass: "token-area" },
                    [
                      _c("nuxt-img", {
                        attrs: {
                          preset: "tokenBigLogo",
                          src:
                            _vm.fromToken.address !== _vm.$config.ZERO
                              ? _vm.$getImageByAddress(_vm.fromToken.address)
                              : _vm.$getImage(_vm.fromToken.logoURI),
                          alt: _vm.fromToken.name,
                          title: _vm.fromToken.symbol,
                        },
                        on: {
                          error: function ($event) {
                            $event.target.src = require("~/static/img/default_token.svg")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.fromToken.symbol) +
                            "\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chevron-area" },
                    [_c("ChevronDown")],
                    1
                  ),
                ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { ref: "fromList", staticClass: "token-select-list" },
          [
            _c("div", { staticClass: "h4" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("swap.select_token")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              { staticClass: "search-area", attrs: { method: "POST" } },
              [
                _c(
                  "button",
                  {
                    staticClass: "search-button",
                    attrs: {
                      type: "button",
                      "arira-label": _vm.$t("common.search"),
                    },
                  },
                  [_c("Search")],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("common.search") + "...",
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("TokensList", {
              attrs: {
                "current-token": _vm.pair.from_token,
                "pair-token": _vm.pair.to_token,
                list: _vm.computedList,
              },
              on: { pick: _vm.selectFromToken },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "manageFromButton",
                staticClass: "open-manage",
                on: { click: _vm.toggleManageFrom },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("swap.manage")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("ManageModal", {
              ref: "manageFromArea",
              attrs: { active: _vm.activeManageFrom },
              on: { toggle: _vm.toggleManageFrom },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swap-area" }, [_c("Plus")], 1),
      _vm._v(" "),
      _c("div", { staticClass: "token-item" }, [
        _c(
          "div",
          {
            ref: "toListButton",
            staticClass: "token-select",
            on: { click: _vm.toggleToList },
          },
          [
            _vm.toToken === null
              ? _c("div", { staticClass: "token-data" }, [
                  _c("div", { staticClass: "token-area" }, [
                    _c("div", { staticClass: "empty-area" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "normal" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("liquidity.select_token")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chevron-area" },
                    [_c("ChevronDown")],
                    1
                  ),
                ])
              : _c("div", { staticClass: "token-data" }, [
                  _c(
                    "div",
                    { staticClass: "token-area" },
                    [
                      _c("nuxt-img", {
                        attrs: {
                          preset: "tokenBigLogo",
                          src:
                            _vm.toToken.address !== _vm.$config.ZERO
                              ? _vm.$getImageByAddress(_vm.toToken.address)
                              : _vm.$getImage(_vm.toToken.logoURI),
                          alt: _vm.toToken.name,
                          title: _vm.toToken.symbol,
                        },
                        on: {
                          error: function ($event) {
                            $event.target.src = require("~/static/img/default_token.svg")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.toToken.symbol) +
                            "\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chevron-area" },
                    [_c("ChevronDown")],
                    1
                  ),
                ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { ref: "toList", staticClass: "token-select-list" },
          [
            _c("div", { staticClass: "h4" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("swap.select_token")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              { staticClass: "search-area", attrs: { method: "POST" } },
              [
                _c(
                  "button",
                  {
                    staticClass: "search-button",
                    attrs: {
                      type: "button",
                      "arira-label": _vm.$t("common.search"),
                    },
                  },
                  [_c("Search")],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("common.search") + "...",
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("TokensList", {
              attrs: {
                "current-token": _vm.pair.to_token,
                "pair-token": _vm.pair.from_token,
                list: _vm.computedList,
              },
              on: { pick: _vm.selectToToken },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "manageToButton",
                staticClass: "open-manage",
                on: { click: _vm.toggleManageTo },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("swap.manage")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("ManageModal", {
              ref: "manageToArea",
              attrs: { active: _vm.activeManageTo },
              on: { toggle: _vm.toggleManageTo },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "hint-area" }, [
      !_vm.tokensPicked &&
      _vm.errorText.length === 0 &&
      _vm.infoText.length === 0
        ? _c("div", { staticClass: "loading-area" }, [
            _c("div", { staticClass: "icon-area" }),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("pair.pick_tokens")) + "\n      "
              ),
            ]),
          ])
        : _vm.tokensPicked && _vm.loading
        ? _c("div", { staticClass: "loading-area" }, [
            _c("div", { staticClass: "icon-area" }, [_c("Loading")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("common.loading")) + "...\n      "
              ),
            ]),
          ])
        : _vm.errorText.length > 0
        ? _c("div", { staticClass: "loading-area horizontal warning" }, [
            _c("div", { staticClass: "icon-area" }, [_c("WarnTriangle")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _vm._v("\n        " + _vm._s(_vm.errorText) + "\n      "),
            ]),
          ])
        : _vm.infoText.length > 0
        ? _c("div", { staticClass: "loading-area horizontal info" }, [
            _c("div", { staticClass: "icon-area" }, [_c("CoinHold")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _vm._v("\n        " + _vm._s(_vm.infoText) + "\n      "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.infoText.length > 0
        ? _c("div", { staticClass: "buttons-area" }, [
            _c(
              "button",
              {
                staticClass: "btn cyan",
                attrs: { type: "button" },
                on: { click: _vm.goToLiquidity },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("pools.add_liquidity")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.tokensPicked
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline cyan",
                    attrs: { type: "button" },
                    on: { click: _vm.clearForm },
                  },
                  [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("pools.clear_form")) +
                          "\n        "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }