<template>
  <div v-if="!loadingScreen && !successScreen && !errorScreen" class="confirm-area">
    <div v-if="method === 'buy'" class="buy-area">
      <div class="inputs-area">
        <div class="input">
          <input v-model="form.from" type="number" placeholder="100..." @input="changeFrom">
          <div class="token-info">
            <picture>
              <source srcset="/img/new/floatie-token.webp" type="image/webp">
              <nuxt-img
                fit="inside"
                src="/img/new/floatie-token.png"
                alt="Buy DFP to become DEX platform investor"
                title="Buy $DFP tokens"
                loading="lazy"
                placeholder
                :quality="80"
              />
            </picture>
            <div class="token-symbol">
              DFP
            </div>
          </div>
        </div>
        <div class="hr"></div>
        <div class="input usdt">
          <input v-model="form.to" type="number" placeholder="10..." @input="changeTo">
          <div class="token-info">
            <picture>
              <source :srcset="`/icons/${form.currency.toLowerCase()}.webp`" type="image/webp">
              <nuxt-img
                fit="inside"
                :src="`/icons/${form.currency.toLowerCase()}.png`"
                :alt="`Sell ${form.currency.toUpperCase()} for purchasing Defi Pool investment tokens`"
                :title="`${form.currency.toUpperCase()} for sell`"
                loading="lazy"
                placeholder
                :quality="80"
              />
            </picture>
            <div class="token-symbol">
              {{ currentCurrency }}
            </div>
          </div>
        </div>
      </div>
      <div class="inputs-area">
        <div class="input column">
          <div class="label-area">
            <p class="label">
              {{ $t('invest.currency') }}
            </p>
            <p class="description">
              {{ $t('invest.currency_desc') }}
            </p>
          </div>
          <div class="field-area">
            <Select v-model="form.currency" :options="currenciesList" @select="changeCurrency" />
          </div>
        </div>
      </div>
      <div class="desc-table">
        <div class="desc-row">
          <div class="title">
            {{ $t('invest.price') }}
          </div>
          <div class="value">
            1 DFP = {{ price }} {{ currentCurrency }}
          </div>
        </div>
        <div v-if="form.currency === 'inner'" class="desc-row">
          <div class="title">
            {{ $t('common.account_balance') }}
            <div class="hint-icon wide-tooltip" :tooltip="$t('invest.topup_account_tip')">
              <Question />
            </div>
            <div class="hint-icon cta wide-tooltip" :tooltip="$t('invest.topup_account_cta')" @click="openTopup">
              <PlusIcon />
            </div>
          </div>
          <div v-if="user !== null && user !== undefined" class="value">
            {{ $priceFormat(user.usdt_balance, 2) }} USDT
          </div>
          <div v-else class="value">
            0 USDT
          </div>
        </div>
      </div>
      <div :class="{'error-info': true, 'active': error !== null}">
        <div class="icon-item">
          <WarnTriangle />
        </div>
        <div class="text-area">
          {{ error }}
        </div>
      </div>
    </div>
    <div v-if="method === 'transfer'" class="buy-area">
      <div class="modal-description">
        {{ $t('invest.transfer_description') }}
      </div>
      <div class="inputs-area">
        <div class="input">
          <input v-model="form.to" type="number" placeholder="10..." @input="checkTo">
          <div class="token-info">
            <picture>
              <source srcset="/img/new/floatie-token.webp" type="image/webp">
              <nuxt-img
                fit="inside"
                src="/img/new/floatie-token.png"
                alt="Buy DFP to become DEX platform investor"
                title="Buy $DFP tokens"
                loading="lazy"
                placeholder
                :quality="80"
              />
            </picture>
            <div class="token-symbol">
              DFP
            </div>
          </div>
        </div>
      </div>
      <div class="inputs-area">
        <div class="input column">
          <div class="label-area">
            <p class="label">
              {{ $t('invest.wallet_for_transfer') }}
            </p>
            <p class="description">
              {{ $t('invest.wallet_for_transfer_desc') }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="form.address" class="smaller" type="text" placeholder="0xA11ce..." @input="checkAddress">
          </div>
        </div>
      </div>
      <div class="desc-table">
        <div class="desc-row">
          <div class="title">
            {{ $t('invest.dfp_balance') }}
          </div>
          <div class="value">
            {{ balance !== null ? $priceFormat(balance, 0) : '...' }} DFP
          </div>
        </div>
      </div>
      <div :class="{'error-info': true, 'active': error !== null}">
        <div class="icon-item">
          <WarnTriangle />
        </div>
        <div class="text-area">
          {{ error }}
        </div>
      </div>
    </div>
    <div v-if="method === 'buy' && error === null" class="buttons-area">
      <button :class="{'btn pink': true, 'disabled': loading || form.from === null }" type="button" :disabled="loading || form.from === null" @click="buyTokens">
        <span v-if="!loading">
          {{ $t('invest.buy_now') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
    <div v-if="method === 'transfer' && error === null" class="buttons-area">
      <button :class="{'btn pink': true, 'disabled': loading || form.to === null || form.address === null }" type="button" :disabled="loading || form.to === null || form.address === null" @click="transferTokens">
        <span v-if="!loading">
          {{ $t('invest.send_dfp') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
  </div>
  <div v-else-if="loadingScreen" class="loading-area">
    <ModalLoader />
    <div class="desc-area">
      <div class="h5">
        {{
          method === 'buy' ?
            $t('invest.buying', {
              'amount': form.from,
              'tokens': $numberToString(form.from, [
                $t('invest.token'),
                $t('invest.tokens'),
                $t('invest.many_tokens')
              ])
            }) :
            $t('invest.transfering', {
              'amount': form.to,
              'tokens': $numberToString(form.to, [
                $t('invest.token'),
                $t('invest.tokens'),
                $t('invest.many_tokens')
              ])
            })
        }}
      </div>
      <p>
        {{ $t('invest.confirm_transaction') }}
      </p>
    </div>
  </div>
  <div v-else-if="successScreen" class="success-area">
    <div class="icon-area">
      <CheckIcon />
    </div>
    <div v-if="method === 'buy'" class="desc-area">
      <div class="h4">
        {{ $t('invest.bought_title') }}
      </div>
      <p class="desc">
        {{ $t('invest.bought_text') }}
      </p>
    </div>
    <div v-else class="desc-area">
      <div class="h4">
        {{ $t('invest.transfered_title') }}
      </div>
      <p class="desc">
        {{ $t('invest.transfered_text') }}
      </p>
    </div>
    <hr>
    <div class="buttons-area">
      <a v-if="form.hash !== null" class="btn btn-outline pink" :href="form.hash" rel="nofollow noopener noreferrer" target="_blank">
        <span class="text">
          {{ $t('swap.view_explorer') }}
          <span class="icon icon-right">
            <NewTabIcon />
          </span>
        </span>
      </a>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.close') }}
      </button>
    </div>
  </div>
  <div v-else-if="errorScreen" class="error-area">
    <WarnTriangle />
    <div class="desc-area">
      <div class="h4">
        {{ $t('invest.error') }}
      </div>
      <p class="desc">
        {{ errorText }}
      </p>
      <hr>
      <button type="button" class="btn pink" @click="returnInitState()">
        {{ $t('common.dismiss') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import {
  hydrateWhenIdle,
  hydrateWhenVisible
} from 'vue-lazy-hydration'

import 'nouislider/distribute/nouislider.min.css'

export default {
  components: {
    Question: hydrateWhenVisible(() => import('~/components/svg/new/Question')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    ModalLoader: hydrateWhenVisible(() => import('~/components/app/interface/ModalLoader')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    PlusIcon: hydrateWhenVisible(() => import('~/components/svg/PlusIcon')),
    Select: hydrateWhenIdle(() => import('~/components/app/interface/Select')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  props: {
    method: {
      type: String,
      default: () => {
        return 'buy'
      }
    },
    balance: {
      type: Number,
      default: () => {
        return 0
      }
    },
    price: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },
  data() {
    return {
      form: {
        from: null,
        to: null,
        address: null,
        hash: null,
        currency: 'inner'
      },
      error: null,
      percent: 0,
      errorText: this.$t('invest.error_text'),
      loading: false,
      loadingScreen: false,
      successScreen: false,
      errorScreen: false,
      currenciesList: [
        {
          name: this.$t('common.inner_usdt_balance'),
          value: 'inner'
        },
        {
          name: this.$t('common.usd'),
          value: 'usd'
        },
        {
          name: this.$t('common.eur'),
          value: 'eur'
        },
        {
          name: 'USDT (TRC20)',
          value: 'ust'
        },
        {
          name: 'USDC',
          value: 'us1'
        },
        {
          name: 'BNB',
          value: 'bnb'
        },
        {
          name: 'Polygon Matic',
          value: 'ma1'
        },
        {
          name: 'Dai',
          value: 'dai'
        },
        {
          name: 'Bitcoin',
          value: 'btc'
        },
        {
          name: 'Ethereum',
          value: 'eth'
        },
        {
          name: 'Bitcoin Cash',
          value: 'bch'
        },
        {
          name: 'Litecoin',
          value: 'ltc'
        },
        {
          name: 'Dash',
          value: 'daa'
        },
        {
          name: 'Ripple',
          value: 'xrp'
        },
        {
          name: 'Tron',
          value: 'trx'
        },
        {
          name: 'Cake tokens',
          value: 'ca1'
        },
        {
          name: 'Uniswap tokens',
          value: 'uni'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('currencies', [
      'currencies'
    ]),
    isModalOpened() {
      return this.$store.getters['modal/modalGlobalOpen'] !== undefined ? this.$store.getters['modal/modalGlobalOpen'] : false
    },
    currentCurrency() {
      const currItem = this.currenciesList.find(itm => itm.value === this.form.currency)
      if (currItem !== undefined) {
        let findCurrency = currItem.value
        if (findCurrency === 'ust') {
          findCurrency = 'usdt'
        }
        if (findCurrency === 'us1') {
          findCurrency = 'usdc'
        }
        if (findCurrency === 'ma1') {
          findCurrency = 'matic'
        }
        if (findCurrency === 'daa') {
          findCurrency = 'dash'
        }
        if (findCurrency === 'ca1') {
          findCurrency = 'cake'
        }
        if (findCurrency === 'inner') {
          findCurrency = 'acc'
        }
        return findCurrency.toUpperCase()
      } else {
        return this.form.currency !== null ? this.form.currency.toUpperCase() : this.form.currency
      }
    }
  },
  watch: {
    isModalOpened() {
      if (!this.loading) {
        this.returnInitState()
      }
    },
    method() {
      this.checkAddressField()
    }
  },
  mounted() {
    this.fetchCurrencies()
    this.form.address = this.user !== null && this.method === 'buy' ? this.user.address : ''
  },
  methods: {
    ...mapActions('investments', [
      'addInvestment'
    ]),
    ...mapActions('auth', [
      'generatePaymentLink',
      'investInDfp'
    ]),
    ...mapActions('currencies', [
      'fetchCurrencies'
    ]),
    ...mapActions('modal', [
      'changeModalWalletState',
      'changeModalProfileState'
    ]),
    ...mapMutations('modal', [
      'setInnerState'
    ]),
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    },
    clearForm() {
      this.form.from = null
      this.form.to = null
      this.form.currency = 'inner'
      this.error = null
      this.percent = 0
    },
    closeModal() {
      this.$emit('closeGlobalModal')
      this.returnInitState()
    },
    returnInitState() {
      this.$emit('initConfirmState')
      this.loading = false
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = false
      this.form.address = this.user !== null && this.form.address === null && this.method === 'buy' ? this.user.address : this.form.address
    },
    changeFrom() {
      this.error = null
      if (this.form.from !== null) {
        if (parseFloat(this.form.from) < 0) {
          this.form.from = 0
        }
        this.form.from = Math.floor(parseFloat(this.form.from))
        let tokenPrice = this.price
        let findCurrency = this.form.currency.toLowerCase()
        if (findCurrency === 'ust') {
          findCurrency = 'usdt'
        }
        if (findCurrency === 'us1') {
          findCurrency = 'usdc'
        }
        if (findCurrency === 'ma1') {
          findCurrency = 'matic'
        }
        if (findCurrency === 'daa') {
          findCurrency = 'dash'
        }
        if (findCurrency === 'ca1') {
          findCurrency = 'cake'
        }
        const dbCurrItem = this.currencies !== undefined ? this.currencies.find(itm => itm.code.toLowerCase() === findCurrency) : undefined
        if (dbCurrItem !== undefined) {
          tokenPrice = dbCurrItem.price * tokenPrice
        }
        let afterComma = 2
        if (!['usd', 'usdt', 'usdc', 'eur', 'inner'].includes(findCurrency)) {
          afterComma = 6
        }
        this.form.to = parseFloat(this.form.from) * tokenPrice
        this.form.to = Number(this.form.to.toFixed(afterComma))
        if (this.form.currency === 'inner' && parseFloat(this.form.to) > this.user.usdt_balance) {
          this.error = this.$t('invest.low_usdt_balance')
        }
        // if (this.form.address === null || (this.form.address !== null && this.form.address.length === 0)) {
        //   this.form.address = this.user !== null ? this.user.address : ''
        // }
        // if (this.usdtBalance !== null && this.form.to > this.usdtBalance) {
        //   this.percent = 100
        //   this.error = this.$t('invest.low_usdt_balance')
        // } else {
        //   this.percent = this.form.to / this.usdtBalance * 100
        // }
      }
    },
    changeTo() {
      this.error = null
      if (this.form.to !== null) {
        if (parseFloat(this.form.to) < 0) {
          this.form.to = 0
        }
        let tokenPrice = this.price
        let findCurrency = this.form.currency.toLowerCase()
        if (findCurrency === 'ust') {
          findCurrency = 'usdt'
        }
        if (findCurrency === 'us1') {
          findCurrency = 'usdc'
        }
        if (findCurrency === 'ma1') {
          findCurrency = 'matic'
        }
        if (findCurrency === 'daa') {
          findCurrency = 'dash'
        }
        if (findCurrency === 'ca1') {
          findCurrency = 'cake'
        }
        const dbCurrItem = this.currencies !== undefined ? this.currencies.find(itm => itm.code.toLowerCase() === findCurrency) : undefined
        if (dbCurrItem !== undefined) {
          tokenPrice = dbCurrItem.price * tokenPrice
        }
        this.form.from = Math.floor(parseFloat(this.form.to) / tokenPrice)
        if (this.form.currency === 'inner' && parseFloat(this.form.to) > this.user.usdt_balance) {
          this.error = this.$t('invest.low_usdt_balance')
        }
        // if (this.form.address === null || (this.form.address !== null && this.form.address.length === 0)) {
        //   this.form.address = this.user !== null ? this.user.address : ''
        // }
        // if (this.usdtBalance !== null && parseFloat(this.form.to) > this.usdtBalance) {
        //   this.percent = 100
        //   this.error = this.$t('invest.low_usdt_balance')
        // } else {
        //   this.percent = parseFloat(this.form.to) / this.usdtBalance * 100
        // }
      }
    },
    checkTo() {
      this.error = null
      if (this.form.to !== null && this.form.to.length > 0) {
        if (parseFloat(this.form.to) < 1) {
          this.form.to = 1
        }
        this.form.to = Math.floor(parseFloat(this.form.to))
        // if (this.balance !== null && this.form.to > this.balance) {
        //   this.error = this.$t('invest.low_dfp_balance')
        // }
      }
    },
    changeCurrency(newCurrency) {
      if (this.form.currency !== newCurrency && newCurrency !== undefined && newCurrency !== null) {
        this.form.currency = newCurrency
        this.changeFrom()
      }
    },
    checkAddress() {
      this.error = null
      if (this.form.address !== null && this.form.address.length > 0 && !this.$isAddress(this.form.address)) {
        this.error = this.$t('invest.input_address')
      }
    },
    async buyTokens() {
      this.initLoadingScreen()
      this.loading = true
      if (this.form.currency !== 'inner') {
        const newLink = await this.generatePaymentLink({
          ...this.form,
          amount: this.form.to,
          type: 'invest',
          description: this.$t('invest.payment_description', { address: this.user.address })
        })
        this.loading = false
        window.open(newLink, '_blank')
        this.closeModal()
      } else {
        this.initLoadingScreen()
        await this.investInDfp({
          ...this.form,
          amount: this.form.to,
          tokens: this.form.from,
          type: 'invest',
          description: this.$t('invest.payment_description', { address: this.user.address })
        })
        this.loading = false
        this.initSuccessScreen()
      }
    },
    async openTopup() {
      this.closeModal()
      if (this.user !== undefined && this.user !== null && this.user.address.length > 0) {
        this.setInnerState('topup')
        await this.changeModalProfileState({
          state: true
        })
      } else {
        await this.changeModalWalletState({
          state: true
        })
      }
    },
    transferTokens() {
      this.initLoadingScreen()
      // ...
    },
    checkAddressField() {
      this.form.address = this.user !== null && this.method === 'buy' ? this.user.address : ''
    },
    rejectTransaction(error) {
      if (error === undefined || error === null || typeof error !== 'object') {
        this.initErrorScreen()
      } else {
        switch (error.type.toLowerCase()) {
          case 'denied':
            this.errorText = this.$t('common.deined_operation')
            break
          case 'error':
            this.errorText = error.message !== undefined && error.message.length > 0 ? this.$t('invest.error_text_with_error', { error: error.message }) : this.$t('invest.error_text')
            break
          default:
            this.errorText = this.$t('invest.error_text')
        }
        this.initErrorScreen()
      }
    },
    initLoadingScreen() {
      this.$emit('initLoading')
      this.loading = false
      this.loadingScreen = true
      this.errorScreen = false
      this.successScreen = false
    },
    initSuccessScreen() {
      this.$emit('initSuccess', this.form)
      this.loading = false
      this.loadingScreen = false
      this.errorScreen = false
      this.successScreen = true
      this.clearForm()
    },
    initErrorScreen() {
      this.$emit('initError')
      this.loading = false
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = true
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modalContent.css');
</style>
