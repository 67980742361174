export const state = () => ({
  orders: [],
  total: 0,
  order: null,
  ordersLoading: false,
  orderLoading: false
})

export const mutations = {
  setOrders(state, orders) {
    state.orders = orders
  },
  setOrder(state, order) {
    state.order = order
  },
  setOrdersTotal(state, total) {
    state.total = total
  },
  addOrders(state, orders) {
    state.orders = state.orders.concat(orders)
  },
  unshiftOrder(state, order) {
    state.orders = state.orders.unshift(order)
  },
  removeOrder(state, hash) {
    const itemIndex = state.orders.findIndex(order => order.hash === hash)
    if (itemIndex !== -1) {
      state.orders.splice(itemIndex, 1)
    }
  },
  setLoadingOrders(state, status) {
    state.ordersLoading = status
  },
  setLoadingOrder(state, status) {
    state.orderLoading = status
  }
}

export const actions = {
  async fetchOrders({
    commit
  }, payload) {
    commit('setLoadingOrders', true)
    const request = await this.$generateApiRequest()
    const orders = await request.$get('limits', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        status: payload.status !== undefined && payload.status !== null ? payload.status : null,
        search: payload.search !== undefined && payload.search !== null ? payload.search : null
      }
    })
    commit('setOrders', orders.answer.orders)
    commit('setOrdersTotal', orders.answer.total)
    commit('setLoadingOrders', false)
    this.$refreshCookieExpires()
  },
  async addOrder({
    commit
  }, payload) {
    commit('setLoadingOrder', true)
    const request = await this.$generateApiRequest()
    const order = await request.$post('limits', payload)
    commit('unshiftOrder', order.answer.order)
    commit('setLoadingOrder', false)
    this.$refreshCookieExpires()
  },
  async cancelOrder({
    commit
  }, payload) {
    commit('setLoadingOrder', true)
    const request = await this.$generateApiRequest()
    await request.$put('limits/' + payload.hash + '/cancel')
    commit('removeOrder', payload.hash)
    commit('setLoadingOrder', false)
    this.$refreshCookieExpires()
  },
  async fetchPair({
    commit
  }, payload) {
    commit('setLoadingOrder', true)
    const request = await this.$generateApiRequest()
    const order = await request.$get('limits/' + payload.hash)
    commit('setOrder', order.answer.order)
    commit('setLoadingOrder', false)
  }
}

export const getters = {
  orders: state => state.orders,
  order: state => state.order,
  total: state => state.total,
  ordersLoading: state => state.ordersLoading,
  orderLoading: state => state.orderLoading
}
