var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 30 30",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M28.3962 13.1868C27.2021 9.08414 25.8555 6.96434 25.8338 6.92631C25.757 6.83262 23.8542 4.55543 19.2678 2.87109L18.6618 4.52109C20.8437 5.32242 22.3916 6.3 23.2429 6.93164C20.7013 6.17268 17.6778 5.70785 15 5.70785H14.9999L14.4139 15.1365L14.9999 24.7665H15L19.3923 24.6506L21.1117 27.1284C27.5391 27.1284 29.8975 23.1019 30 22.9416C29.9841 22.7179 29.5469 17.1404 28.3962 13.1868Z",
          fill: "#FF7DCD",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M6.75709 6.93164C7.60834 6.30006 9.15627 5.32242 11.3381 4.52109L10.7321 2.87109C6.1459 4.55543 4.24301 6.83262 4.16625 6.92631C4.14451 6.96434 2.79785 9.08414 1.60383 13.1868C0.453047 17.1404 0.0158789 22.7179 0 22.9416C0.102539 23.1019 2.46094 27.1284 8.88826 27.1284L10.6165 24.6529L15 24.7665V5.70785C12.3221 5.70785 9.29865 6.17268 6.75709 6.93164Z",
          fill: "#FD93EF",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M9.17824 14.8826H10.9361V17.8123H9.17824V14.8826Z",
          fill: "#FFFBF5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M19.0639 14.8826H20.8218V17.8123H19.0639V14.8826Z",
          fill: "#F7F0EB",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M15 25.2101L14.414 24.3312L15 23.4523C18.1001 23.4523 21.0972 22.548 23.6674 20.8373L24.6414 22.3005C21.7818 24.204 18.4478 25.2101 15 25.2101Z",
          fill: "#FD56A2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M15 25.2101C11.5522 25.2101 8.21824 24.204 5.35863 22.3005L6.33263 20.8373C8.90279 22.548 11.8999 23.4523 15 23.4523V25.2101Z",
          fill: "#FD6ADB",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }