var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.action === "deposit"
    ? _c("div", { staticClass: "confirm-area" }, [
        _c("div", { staticClass: "settings-area" }, [
          _c("div", { staticClass: "inputs-area" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", { staticClass: "label-area" }, [
                _c("p", { staticClass: "label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("farm.deposit_action")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "balance" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.balance")) +
                      ": " +
                      _vm._s(
                        _vm.farm !== null
                          ? _vm.$formatCryptoAmount(_vm.farm.balance, 6)
                          : "..."
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field-area" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.farmAmount,
                      expression: "farmAmount",
                    },
                  ],
                  attrs: { type: "number", step: "0.0000001", min: "0" },
                  domProps: { value: _vm.farmAmount },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.farmAmount = $event.target.value
                      },
                      _vm.checkDepositAmount,
                    ],
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "max-button active",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setMax()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "text" }, [
                      _vm._v("\n              MAX\n            "),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "stake-percent percent-area" }, [
            _c(
              "div",
              { staticClass: "handler" },
              [
                _c("veeno", {
                  ref: "percentSlider",
                  attrs: {
                    connect: "",
                    handles: [0, 0],
                    range: {
                      min: 0,
                      max: 100,
                    },
                    set: [0, parseFloat(_vm.percent)],
                  },
                  on: { change: _vm.updateVeeno, slide: _vm.setSlide },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "range-switch-area" }, [
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 25 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(25)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            25%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 50 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(50)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            50%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 75 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(75)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            75%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 100 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(100)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            100%\n          ")])]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "button",
            {
              class: {
                "btn cyan": true,
                disabled:
                  _vm.loading ||
                  _vm.transaction.pending ||
                  _vm.farmAmount === 0,
              },
              attrs: {
                type: "button",
                disabled: _vm.loading || _vm.transaction.pending,
              },
              on: { click: _vm.confirmDeposit },
            },
            [
              !_vm.loading && !_vm.transaction.pending
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("farm.confirm_deposit")) +
                        "\n      "
                    ),
                  ])
                : _c("span", [_c("Loading")], 1),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "links-area" }, [
          _c(
            "p",
            { staticClass: "link", on: { click: _vm.createLPLink } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("farm.get_lp_token", {
                      from: _vm.farm.token_from.symbol,
                      to: _vm.farm.token_to.symbol,
                    })
                  ) +
                  "\n      "
              ),
              _c("NewTabIcon"),
            ],
            1
          ),
        ]),
      ])
    : _vm.action === "withdraw"
    ? _c("div", { staticClass: "confirm-area" }, [
        _c("div", { staticClass: "settings-area" }, [
          _c("div", { staticClass: "inputs-area" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", { staticClass: "label-area" }, [
                _c("p", { staticClass: "label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("farm.withdraw_action")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "balance" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.balance")) +
                      ": " +
                      _vm._s(
                        _vm.farm !== null
                          ? _vm.$formatCryptoAmount(_vm.farm.deposited, 6)
                          : "..."
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field-area" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.farmAmount,
                      expression: "farmAmount",
                    },
                  ],
                  attrs: { type: "number", step: "0.0000001", min: "0" },
                  domProps: { value: _vm.farmAmount },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.farmAmount = $event.target.value
                      },
                      _vm.checkWithdrawAmount,
                    ],
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "max-button active",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setMax()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "text" }, [
                      _vm._v("\n              MAX\n            "),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "stake-percent percent-area" }, [
            _c(
              "div",
              { staticClass: "handler" },
              [
                _c("veeno", {
                  ref: "percentSlider",
                  attrs: {
                    connect: "",
                    handles: [0, 0],
                    range: {
                      min: 0,
                      max: 100,
                    },
                    set: [0, parseFloat(_vm.percent)],
                  },
                  on: { change: _vm.updateVeeno, slide: _vm.setSlide },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "range-switch-area" }, [
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 25 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(25)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            25%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 50 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(50)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            50%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 75 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(75)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            75%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 100 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(100)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            100%\n          ")])]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "button",
            {
              class: {
                "btn cyan": true,
                disabled:
                  _vm.loading ||
                  _vm.transaction.pending ||
                  _vm.farmAmount === 0,
              },
              attrs: {
                type: "button",
                disabled: _vm.loading || _vm.transaction.pending,
              },
              on: { click: _vm.confirmWithdraw },
            },
            [
              !_vm.loading && !_vm.transaction.pending
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("farm.confirm_withdraw")) +
                        "\n      "
                    ),
                  ])
                : _c("span", [_c("Loading")], 1),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "links-area" }, [
          _c(
            "p",
            { staticClass: "link", on: { click: _vm.createLPLink } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("farm.get_lp_token", {
                      from: _vm.farm.token_from.symbol,
                      to: _vm.farm.token_to.symbol,
                    })
                  ) +
                  "\n      "
              ),
              _c("NewTabIcon"),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }