<template>
  <svg viewBox="0 0 568 182" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_102_28)">
      <path d="M10.6081 129.651H4V121.441H10.6081V129.651ZM4.76839 128.881H9.83974V122.211H4.76839V128.881Z" fill="url(#paint0_linear_102_28)" />
      <path d="M21.4616 128.602H14.8535V119.978H21.4616V128.602ZM15.6218 127.832H20.6932V120.748H15.6218V127.832Z" fill="url(#paint1_linear_102_28)" />
      <path d="M32.315 123.972H25.7069V96.9245H32.315V123.972ZM26.4657 123.202H31.5466V97.6945H26.4753V123.202H26.4657Z" fill="url(#paint2_linear_102_28)" />
      <path d="M43.1589 119.448H36.5508V86.3461H43.1589V119.448ZM37.3192 118.678H42.3905V87.1161H37.3192V118.678Z" fill="url(#paint3_linear_102_28)" />
      <path d="M54.0123 136.582H47.4042V114.164H54.0123V136.582ZM48.1726 135.811H53.244V114.934H48.1726V135.811Z" fill="url(#paint4_linear_102_28)" />
      <path d="M64.8658 140.162H58.2577V122.538H64.8658V140.162ZM59.0261 139.392H64.0974V123.308H59.0261V139.392Z" fill="url(#paint5_linear_102_28)" />
      <path d="M75.7193 167.191H69.1015V139.354H75.7096V167.191H75.7193ZM69.8699 166.421H74.9413V140.124H69.8699V166.421Z" fill="url(#paint6_linear_102_28)" />
      <path d="M86.5631 141.106H79.955V132.115H86.5631V141.106ZM80.7234 140.335H85.7947V132.885H80.7234V140.335Z" fill="url(#paint7_linear_102_28)" />
      <path d="M97.4166 172.947H90.8084V142.511H97.4166V172.947ZM91.5768 172.177H96.6482V143.281H91.5768V172.177Z" fill="url(#paint8_linear_102_28)" />
      <path d="M108.27 141.173H101.662V130.392H108.27V141.173ZM102.421 140.403H107.492V131.162H102.421V140.403Z" fill="url(#paint9_linear_102_28)" />
      <path d="M119.123 156.372H112.515V130.383H119.123V156.372ZM113.274 155.601H118.345V131.153H113.274V155.601Z" fill="url(#paint10_linear_102_28)" />
      <path d="M129.967 152.107H123.359V146.554H129.967V152.107ZM124.128 151.337H129.199V147.324H124.128V151.337Z" fill="url(#paint11_linear_102_28)" />
      <path d="M140.821 144.089H134.213V137.919H140.821V144.089ZM134.981 143.319H140.052V138.689H134.981V143.319Z" fill="url(#paint12_linear_102_28)" />
      <path d="M151.674 151.26H145.066V137.554H151.674V151.26ZM145.825 150.49H150.896V138.324H145.825V150.49Z" fill="url(#paint13_linear_102_28)" />
      <path d="M162.518 167.133H155.91V146.265H162.518V167.133ZM156.678 166.363H161.75V147.035H156.678V166.363Z" fill="url(#paint14_linear_102_28)" />
      <path d="M173.372 148.19H166.763V128.717H173.372V148.19ZM167.532 147.42H172.603V129.488H167.532V147.42Z" fill="url(#paint15_linear_102_28)" />
      <path d="M184.225 155.034H177.617V130.431H184.225V155.034ZM178.385 154.264H183.457V131.201H178.385V154.264Z" fill="url(#paint16_linear_102_28)" />
      <path d="M195.078 128.4H188.47V121.768H195.078V128.4ZM189.229 127.63H194.3V122.538H189.229V127.63Z" fill="url(#paint17_linear_102_28)" />
      <path d="M205.922 139.671H199.314V118.736H205.922V139.671ZM200.083 138.901H205.154V119.506H200.083V138.901Z" fill="url(#paint18_linear_102_28)" />
      <path d="M216.776 162.108H210.168V128.958H216.776V162.108ZM210.936 161.338H216.007V129.728H210.936V161.338Z" fill="url(#paint19_linear_102_28)" />
      <path d="M227.629 138.545H221.021V108.995H227.629V138.545ZM221.789 137.775H226.861V109.765H221.789V137.775Z" fill="url(#paint20_linear_102_28)" />
      <path d="M238.483 178H231.875V147.834H238.483V178ZM232.633 177.23H237.705V148.604H232.633V177.23Z" fill="url(#paint21_linear_102_28)" />
      <path d="M249.327 161.3H242.718V136.341H249.327V161.3ZM243.487 160.53H248.558V137.111H243.487V160.53Z" fill="url(#paint22_linear_102_28)" />
      <path d="M260.18 137.332H253.572V126.927H260.18V137.332ZM254.34 136.562H259.412V127.697H254.34V136.562Z" fill="url(#paint23_linear_102_28)" />
      <path d="M271.033 141.211H264.425V120.959H271.033V141.211ZM265.184 140.441H270.255V121.729H265.184V140.441Z" fill="url(#paint24_linear_102_28)" />
      <path d="M281.877 149.634H275.269V139.334H281.877V149.634ZM276.038 148.864H281.109V140.104H276.038V148.864Z" fill="url(#paint25_linear_102_28)" />
      <path d="M292.731 142.212H286.123V111.757H292.731V142.212ZM286.891 141.442H291.962V112.527H286.891V141.442Z" fill="url(#paint26_linear_102_28)" />
      <path d="M303.584 123.558H296.976V100.351H303.584V123.558ZM297.744 122.788H302.816V101.121H297.744V122.788Z" fill="url(#paint27_linear_102_28)" />
      <path d="M314.438 112.778H307.83V80.6863H314.438V112.778ZM308.588 112.008H313.66V81.4563H308.588V112.008Z" fill="url(#paint28_linear_102_28)" />
      <path d="M325.281 103.008H318.673V93.6133H325.281V103.008ZM319.442 102.238H324.513V94.3834H319.442V102.238Z" fill="url(#paint29_linear_102_28)" />
      <path d="M336.135 121.585H329.527V108.225H336.135V121.585ZM330.295 120.815H335.367V108.995H330.295V120.815Z" fill="url(#paint30_linear_102_28)" />
      <path d="M346.988 140.855H340.38V106.945H346.988V140.855ZM341.149 140.085H346.22V107.715H341.149V140.085Z" fill="url(#paint31_linear_102_28)" />
      <path d="M357.842 110.968H351.234V101.968H357.842V110.968ZM351.993 110.198H357.064V102.738H351.993V110.198Z" fill="url(#paint32_linear_102_28)" />
      <path d="M368.686 102.199H362.078V70.002H368.686V102.199ZM362.846 101.429H367.917V70.772H362.846V101.429Z" fill="url(#paint33_linear_102_28)" />
      <path d="M379.539 77.4906H372.931V56.7573H379.539V77.4906ZM373.699 76.7206H378.771V57.5274H373.699V76.7206Z" fill="url(#paint34_linear_102_28)" />
      <path d="M390.393 65.9304H383.785V43.0602H390.393V65.9304ZM384.543 65.1604H389.615V43.8303H384.543V65.1604Z" fill="url(#paint35_linear_102_28)" />
      <path d="M401.237 71.8116H394.628V65.4876H401.237V71.8116ZM395.397 71.0415H400.468V66.2577H395.397V71.0415Z" fill="url(#paint36_linear_102_28)" />
      <path d="M412.09 79.3868H405.482V50.982H412.09V79.3868ZM406.25 78.6168H411.322V51.7521H406.25V78.6168Z" fill="url(#paint37_linear_102_28)" />
      <path d="M422.943 60.0974H416.335V41.3662H422.943V60.0974ZM417.104 59.3273H422.175V42.1362H417.104V59.3273Z" fill="url(#paint38_linear_102_28)" />
      <path d="M433.797 39.1908H427.189V4H433.797V39.1908ZM427.948 38.4208H433.019V4.77004H427.948V38.4208Z" fill="url(#paint39_linear_102_28)" />
      <path d="M444.641 35.87H438.033V30.4894H444.641V35.87ZM438.801 35.1H443.872V31.2594H438.801V35.1Z" fill="url(#paint40_linear_102_28)" />
      <path d="M455.494 51.2804H448.886V21.6724H455.494V51.2804ZM449.654 50.5104H454.726V22.4424H449.654V50.5104Z" fill="url(#paint41_linear_102_28)" />
      <path d="M466.348 48.335H459.74V39.7491H466.348V48.335ZM460.508 47.565H465.579V40.5191H460.508V47.565Z" fill="url(#paint42_linear_102_28)" />
      <path d="M477.201 65.9497H470.583V44.0517H477.192V65.9497H477.201ZM471.352 65.1796H476.423V44.8217H471.352V65.1796Z" fill="url(#paint43_linear_102_28)" />
      <path d="M488.045 54.8515H481.437V43.9169H488.045V54.8515ZM482.205 54.0814H487.277V44.6773H482.205V54.0814Z" fill="url(#paint44_linear_102_28)" />
      <path d="M498.898 48.3543H492.29V35.4946H498.898V48.3543ZM493.059 47.5842H498.13V36.2646H493.059V47.5842Z" fill="url(#paint45_linear_102_28)" />
      <path d="M303.584 123.558H296.976V100.351H303.584V123.558ZM297.744 122.788H302.816V101.121H297.744V122.788Z" fill="url(#paint46_linear_102_28)" />
      <path d="M314.438 112.778H307.83V80.6863H314.438V112.778ZM308.588 112.008H313.66V81.4563H308.588V112.008Z" fill="url(#paint47_linear_102_28)" />
      <path d="M325.281 103.008H318.673V93.6133H325.281V103.008ZM319.442 102.238H324.513V94.3834H319.442V102.238Z" fill="url(#paint48_linear_102_28)" />
      <path d="M357.842 110.968H351.234V101.968H357.842V110.968ZM351.993 110.198H357.064V102.738H351.993V110.198Z" fill="url(#paint49_linear_102_28)" />
      <path d="M368.686 102.199H362.078V70.002H368.686V102.199ZM362.846 101.429H367.917V70.772H362.846V101.429Z" fill="url(#paint50_linear_102_28)" />
      <path d="M379.539 77.4906H372.931V56.7573H379.539V77.4906ZM373.699 76.7206H378.771V57.5274H373.699V76.7206Z" fill="url(#paint51_linear_102_28)" />
      <path d="M390.393 65.9304H383.785V43.0602H390.393V65.9304ZM384.543 65.1604H389.615V43.8303H384.543V65.1604Z" fill="url(#paint52_linear_102_28)" />
      <path d="M401.237 71.8116H394.628V65.4876H401.237V71.8116ZM395.397 71.0415H400.468V66.2577H395.397V71.0415Z" fill="url(#paint53_linear_102_28)" />
      <path d="M412.09 79.3868H405.482V50.982H412.09V79.3868ZM406.25 78.6168H411.322V51.7521H406.25V78.6168Z" fill="url(#paint54_linear_102_28)" />
      <path d="M422.943 60.0974H416.335V41.3662H422.943V60.0974ZM417.104 59.3273H422.175V42.1362H417.104V59.3273Z" fill="url(#paint55_linear_102_28)" />
      <path d="M433.797 39.1908H427.189V4H433.797V39.1908ZM427.948 38.4208H433.019V4.77004H427.948V38.4208Z" fill="url(#paint56_linear_102_28)" />
      <path d="M444.641 35.87H438.033V30.4894H444.641V35.87ZM438.801 35.1H443.872V31.2594H438.801V35.1Z" fill="url(#paint57_linear_102_28)" />
      <path d="M455.494 51.2804H448.886V21.6724H455.494V51.2804ZM449.654 50.5104H454.726V22.4424H449.654V50.5104Z" fill="url(#paint58_linear_102_28)" />
      <path d="M466.348 48.335H459.74V39.7491H466.348V48.335ZM460.508 47.565H465.579V40.5191H460.508V47.565Z" fill="url(#paint59_linear_102_28)" />
      <path d="M477.201 65.9497H470.583V44.0517H477.192V65.9497H477.201ZM471.352 65.1796H476.423V44.8217H471.352V65.1796Z" fill="url(#paint60_linear_102_28)" />
      <path d="M488.045 54.8515H481.437V43.9169H488.045V54.8515ZM482.205 54.0814H487.277V44.6773H482.205V54.0814Z" fill="url(#paint61_linear_102_28)" />
      <path d="M498.898 48.3543H492.29V35.4946H498.898V48.3543ZM493.059 47.5842H498.13V36.2646H493.059V47.5842Z" fill="url(#paint62_linear_102_28)" />
      <path d="M509.752 43.349H503.144V19.8051H509.752V43.349ZM503.903 42.579H508.974V20.5751H503.903V42.579Z" fill="url(#paint63_linear_102_28)" />
      <path d="M520.605 56.1798H513.997V30.9032H520.605V56.1798ZM514.756 55.4097H519.827V31.6733H514.756V55.4097Z" fill="url(#paint64_linear_102_28)" />
      <path d="M531.449 70.31H524.841V50.9146H531.449V70.31ZM525.609 69.54H530.681V51.6847H525.609V69.54Z" fill="url(#paint65_linear_102_28)" />
      <path d="M542.303 59.2599H535.695V42.348H542.303V59.2599ZM536.463 58.4899H541.534V43.118H536.463V58.4899Z" fill="url(#paint66_linear_102_28)" />
      <path d="M553.156 50.8858H546.548V34.8786H553.156V50.8858ZM547.307 50.1157H552.378V35.6486H547.307V50.1157Z" fill="url(#paint67_linear_102_28)" />
      <path d="M564 37.4582H557.392V10.2662H564V37.4582ZM558.16 36.6882H563.232V11.0362H558.16V36.6882Z" fill="url(#paint68_linear_102_28)" />
    </g>
    <defs>
      <filter
        id="filter0_f_102_28"
        x="0"
        y="0"
        width="568"
        height="182"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_102_28" />
      </filter>
      <linearGradient
        id="paint0_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint57_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint58_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint59_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint61_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint62_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint63_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint64_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint65_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint66_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint67_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint68_linear_102_28"
        x1="557.156"
        y1="3.99999"
        x2="15.9721"
        y2="170.275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FC7D3" stop-opacity="0" />
        <stop offset="0.455788" stop-color="#300FFF" stop-opacity="0.89" />
        <stop offset="0.725712" stop-color="#2FC7D3" />
        <stop offset="0.994792" stop-color="#2FC7D3" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CharLinear'
}
</script>
