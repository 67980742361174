var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 1086 872",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { filter: "url(#filter0_f_157_1317)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "543",
            cy: "405.5",
            rx: "337",
            ry: "220.5",
            transform: "rotate(-24.1067 543 405.5)",
            fill: "url(#paint0_linear_157_1317)",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "filter",
            {
              attrs: {
                id: "filter0_f_157_1317",
                x: "0.394531",
                y: "-60.3574",
                width: "1085.21",
                height: "931.715",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _vm._v(" "),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape",
                },
              }),
              _vm._v(" "),
              _c("feGaussianBlur", {
                attrs: {
                  stdDeviation: "111",
                  result: "effect1_foregroundBlur_157_1317",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_157_1317",
                x1: "681.659",
                y1: "622.017",
                x2: "114.684",
                y2: "99.3598",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#2523AA" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#2523AA",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }