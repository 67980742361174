var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "12",
        height: "16",
        viewbox: "0 0 12 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10.5 15.9999H1.5C0.67334 15.9999 0 15.3448 0 14.539V7.72161C0 6.9158 0.67334 6.26074 1.5 6.26074H10.5C11.3267 6.26074 12 6.9158 12 7.72161V14.539C12 15.3448 11.3267 15.9999 10.5 15.9999ZM1.5 7.23466C1.22461 7.23466 1 7.45281 1 7.72161V14.539C1 14.8078 1.22461 15.026 1.5 15.026H10.5C10.7754 15.026 11 14.8078 11 14.539V7.72161C11 7.45281 10.7754 7.23466 10.5 7.23466H1.5Z",
          fill: "#0C275C",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M9.5 6.95652C9.224 6.95652 9 6.73391 9 6.45963V3.97516C9 2.33138 7.65405 0.993789 6 0.993789C4.34595 0.993789 3 2.33138 3 3.97516V6.45963C3 6.73391 2.776 6.95652 2.5 6.95652C2.224 6.95652 2 6.73391 2 6.45963V3.97516C2 1.7828 3.79395 0 6 0C8.20605 0 10 1.7828 10 3.97516V6.45963C10 6.73391 9.776 6.95652 9.5 6.95652Z",
          fill: "#0C275C",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }