var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 511.806 511.806",
        width: "512",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", [
        _c("g", [
          _c("path", {
            attrs: {
              d: "m391.093 137.182-103.098 66.893-10.001 40.832 10.001 55.323z",
              fill: "#12b0ff",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "m189.19 456.487 98.805-156.257v-96.155l-147.116 95.453z",
              fill: "#36c6ff",
            },
          }),
        ]),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "m189.19 456.487 31.617-113.979 65.823 14.581z",
            fill: "#5698e0",
          },
        }),
        _c("g", [
          _c("path", {
            attrs: {
              d: "m511.806 24.628-223.811 95.25-10.001 47.781 10.001 43.823 81.075-48.522-81.075 98.183-10.001 65.357 10.001 65.358 129.774 95.32z",
              fill: "#aae3ff",
            },
          }),
          _vm._v(" "),
          _c("g", { attrs: { fill: "#cef" } }, [
            _c("path", {
              attrs: { d: "m220.807 342.508 67.188 49.35v-130.715z" },
            }),
            _c("path", {
              attrs: {
                d: "m140.879 299.528 147.116-88.046v-91.604l-287.995 122.566z",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }