<template>
  <div ref="modalArea" :class="{ 'modal-container': true, 'connect-wallet': true, 'active': modalOpen }">
    <div class="modal-wrap">
      <div ref="modalInner" class="modal-content-wrap">
        <div v-if="!errorScreen" class="modal-content">
          <div class="header">
            <div class="h2">
              {{ $t('common.connect_wallet') }}
            </div>
            <div class="close-area" @click="closeModal">
              <Plus />
            </div>
          </div>
          <hr>
          <div class="wallets-area">
            <div :class="{'wallet active': true, 'minified': isMinified}" @click="connectMetamask($event)">
              <div v-if="loadingWallet !== 'metamask'" class="image-area metamask">
                <picture>
                  <source srcset="/img/new/metamask.webp" type="image/webp">
                  <nuxt-img
                    fit="inside"
                    width="275"
                    sizes="sm:175px md:200px lg:225px xl:250px xxl:275px"
                    src="/img/new/metamask.png"
                    alt="Metamask wallet connect on Defi Pool Finance"
                    title="Metamask"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div v-else class="image-area">
                <Loading />
              </div>
              <div class="text-area">
                <p>
                  Metamask
                </p>
              </div>
            </div>
            <div :class="{'wallet active': true, 'minified': isMinified}">
              <div class="image-area binance">
                <Bnb />
              </div>
              <div class="text-area">
                <p>
                  Binance {{ $t('common.wallet') }}
                </p>
              </div>
            </div>
            <div :class="{'wallet active': true, 'minified': isMinified}">
              <div class="image-area coinbase">
                <picture>
                  <source srcset="/img/new/coinbase.webp" type="image/webp">
                  <nuxt-img
                    fit="inside"
                    width="275"
                    sizes="sm:175px md:200px lg:225px xl:250px xxl:275px"
                    src="/img/new/coinbase.png"
                    alt="Coinbase wallet connect on Defi Pool Finance"
                    title="Coinbase"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div class="text-area">
                <p>
                  Coinbase {{ $t('common.wallet') }}
                </p>
              </div>
            </div>
            <div :class="{'wallet': true, 'active': !isMinified}" @click="toggleMinified">
              <div class="image-area more">
                <DropsLine />
              </div>
              <div class="text-area">
                <p>
                  {{ $t('common.more') }}
                </p>
              </div>
            </div>
            <div :class="{'wallet minified': true, 'active': isMinified}">
              <div class="image-area walletconnect">
                <picture>
                  <source srcset="/img/new/walletconnect.webp" type="image/webp">
                  <nuxt-img
                    fit="inside"
                    width="275"
                    sizes="sm:175px md:200px lg:225px xl:250px xxl:275px"
                    src="/img/new/walletconnect.png"
                    alt="Wallet Connect on Defi Pool Finance"
                    title="Wallet Connect"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div class="text-area">
                <p>
                  Wallet Connect
                </p>
              </div>
            </div>
            <div :class="{'wallet minified': true, 'active': isMinified}">
              <div class="image-area formatic">
                <picture>
                  <source srcset="/img/new/formatic.webp" type="image/webp">
                  <nuxt-img
                    fit="inside"
                    width="275"
                    sizes="sm:175px md:200px lg:225px xl:250px xxl:275px"
                    src="/img/new/formatic.png"
                    alt="Fortmatic Wallet on Defi Pool Finance"
                    title="Fortmatic"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div class="text-area">
                <p>
                  Fortmatic
                </p>
              </div>
            </div>
            <div :class="{'wallet minified': true, 'active': isMinified}">
              <div class="image-area portis">
                <picture>
                  <source srcset="/img/new/portis.webp" type="image/webp">
                  <nuxt-img
                    fit="inside"
                    width="275"
                    sizes="sm:175px md:200px lg:225px xl:250px xxl:275px"
                    src="/img/new/portis.png"
                    alt="Portis wallet on Defi Pool Finance"
                    title="Portis"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div class="text-area">
                <p>
                  Portis
                </p>
              </div>
            </div>
          </div>
          <hr class="mt-0">
          <div v-if="!isMinified" class="footer">
            <p class="desc">
              {{ $t('common.havent_got_wallet') }}
            </p>
            <button type="button" class="btn pink" @click.prevent="gotoDocs">
              {{ $t('common.learn_to_connect') }}
            </button>
          </div>
          <div v-else class="footer">
            <p class="desc">
              {{ $t('common.new_to_eth') }}
            </p>
            <button type="button" class="btn pink" @click.prevent="gotoDocs">
              {{ $t('common.learn_more_wallets') }}
            </button>
          </div>
        </div>
        <div v-else class="modal-content error">
          <div class="header">
            <div class="h2">
              {{ $t('common.other_error') }}
            </div>
            <div class="close-area" @click="closeModal">
              <Plus />
            </div>
          </div>
          <hr>
          <div class="icon-area">
            <WarnTriangle />
          </div>
          <div class="text-area">
            <div class="h4">
              {{ $t('common.modal_error') }}
            </div>
            <p class="desc">
              {{ errorText }}
            </p>
            <div v-if="errorCta.length > 0" class="buttons-area">
              <a :href="errorCta" rel="nofollow noopener noreferrer" class="btn pink" target="_blank">
                {{ $t('common.install_wallet') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  name: 'ConnectWalletComponent',
  components: {
    Bnb: hydrateWhenVisible(() => import('~/components/svg/new/Bnb')),
    DropsLine: hydrateWhenVisible(() => import('~/components/svg/new/DropsLine')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    Plus: hydrateWhenVisible(() => import('~/components/svg/new/Plus')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  data() {
    return {
      loadingWallet: '',
      isMinified: false,
      errorScreen: false,
      errorText: this.$t('common.modal_error_desc'),
      errorCta: ''
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalWalletOpen'
    ]),
    modalOpen() {
      return this.modalWalletOpen !== undefined ? this.modalWalletOpen : false
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapActions('modal', [
      'changeModalWalletState'
    ]),
    toggleMinified() {
      this.isMinified = !this.isMinified
    },
    async closeModal() {
      await this.changeModalWalletState({
        state: false
      })
      setTimeout(() => {
        this.errorScreen = false
        this.errorText = this.$t('common.modal_error_desc')
        this.errorCta = ''
        this.isMinified = false
      }, 300)
    },
    handleClickEvents(evt) {
      if (
        this.$refs.modalArea !== undefined &&
        this.$refs.modalArea.contains(evt.target) &&
        !this.$refs.modalInner.contains(evt.target)
      ) {
        this.closeModal()
      }
    },
    async connectMetamask(evt = null) {
      if (evt !== null) {
        evt.preventDefault()
      }
      if (window.ethereum !== undefined) {
        if (window.ethereum.isMetaMask === true) {
          this.loadingWallet = 'metamask'
          await window.ethereum
            .request({
              method: 'eth_requestAccounts'
            })
            .then(async (result) => {
              if (result) {
                const currentChain = parseInt(window.ethereum.networkVersion)
                if (currentChain !== this.$config.chainId) {
                  const replaced = await this.$replaceChain()
                  if (replaced) {
                    const userData = this.$cookies.get(this.$config.userStorageName)
                    userData.chainId = currentChain
                    this.$cookies.set(this.$config.userStorageName, userData)
                    await this.$metamaskNonce()
                    this.loadingWallet = ''
                  } else {
                    this.loadingWallet = ''
                  }
                } else {
                  const userData = this.$cookies.get(this.$config.userStorageName)
                  userData.chainId = currentChain
                  this.$cookies.set(this.$config.userStorageName, userData)
                  await this.$metamaskNonce()
                  this.loadingWallet = ''
                }
                this.$nuxt.$emit('loginWallet')
              } else {
                this.loadingWallet = ''
                this.errorScreen = true
                this.errorText = this.$t('common.deined_operation')
              }
            })
            .catch((error) => {
              this.loadingWallet = ''
              this.errorScreen = true
              this.errorText = this.$t('common.deined_operation') + ' ' + error.message
            })
        } else {
          let accounts = []
          try {
            accounts = await window.eth.getAccounts()
            if (accounts.length === 0) {
              this.loadingWallet = ''
              this.errorScreen = true
              this.errorText = this.$t('common.wallet_locked')
            }
          } catch (err) {
            this.loadingWallet = ''
            this.errorScreen = true
            this.errorText = this.$t('common.dont_support_or_block')
          }
        }
      } else {
        this.loadingWallet = ''
        this.errorScreen = true
        this.errorText = this.$t('common.wallet_not_installed')
        this.errorCta = 'https://metamask.app.link/dapp/defipool.finance/'
      }
    },
    gotoDocs() {
      this.closeModal()
      this.$router.push({
        path: this.localePath('/docs')
      })
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modal.css');
</style>
