var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "26",
        viewbox: "0 0 32 26",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M32 3.078C30.81 3.6 29.542 3.946 28.22 4.114C29.58 3.302 30.618 2.026 31.106 0.488C29.838 1.244 28.438 1.778 26.946 2.076C25.742 0.794 24.026 0 22.154 0C18.522 0 15.598 2.948 15.598 6.562C15.598 7.082 15.642 7.582 15.75 8.058C10.296 7.792 5.47 5.178 2.228 1.196C1.662 2.178 1.33 3.302 1.33 4.512C1.33 6.784 2.5 8.798 4.244 9.964C3.19 9.944 2.156 9.638 1.28 9.156C1.28 9.176 1.28 9.202 1.28 9.228C1.28 12.416 3.554 15.064 6.536 15.674C6.002 15.82 5.42 15.89 4.816 15.89C4.396 15.89 3.972 15.866 3.574 15.778C4.424 18.376 6.836 20.286 9.704 20.348C7.472 22.094 4.638 23.146 1.57 23.146C1.032 23.146 0.516 23.122 0 23.056C2.906 24.93 6.35 26 10.064 26C22.136 26 28.736 16 28.736 7.332C28.736 7.042 28.726 6.762 28.712 6.484C30.014 5.56 31.108 4.406 32 3.078Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }