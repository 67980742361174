<template>
  <svg viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4 -0.000244141H4C3.12 -0.000244141 2.4 0.692064 2.4 1.53822V2.11514C2.4 2.32668 2.58 2.49976 2.8 2.49976C3.02 2.49976 3.2 2.32668 3.2 2.11514V1.53822C3.2 1.46129 3.22 1.38437 3.24 1.30745L7.72 4.99976L3.24 8.69206C3.22 8.61514 3.2 8.53822 3.2 8.4613V7.88437C3.2 7.67283 3.02 7.49976 2.8 7.49976C2.58 7.49976 2.4 7.67283 2.4 7.88437V8.4613C2.4 9.30745 3.12 9.99976 4 9.99976H16.4C17.28 9.99976 18 9.30745 18 8.4613V1.53822C18 0.692064 17.28 -0.000244141 16.4 -0.000244141ZM3.84 0.788217C3.88 0.768987 3.94 0.768987 4 0.768987H16.4C16.46 0.768987 16.52 0.768987 16.56 0.788217L10.46 5.80745C10.3 5.92283 10.1 5.92283 9.94 5.80745L3.84 0.788217ZM16.4 9.23053H4C3.94 9.23053 3.88 9.23053 3.84 9.2113L8.34 5.49976L9.42 6.4036C9.64 6.59591 9.92 6.69206 10.2 6.69206C10.48 6.69206 10.76 6.59591 10.98 6.4036L12.06 5.49976L16.56 9.2113C16.52 9.23053 16.46 9.23053 16.4 9.23053ZM17.2 8.4613C17.2 8.53822 17.18 8.61514 17.16 8.69206L12.68 4.99976L17.16 1.30745C17.18 1.38437 17.2 1.46129 17.2 1.53822V8.4613ZM1.2 4.03822C1.2 3.82668 1.38 3.6536 1.6 3.6536H4C4.22 3.6536 4.4 3.82668 4.4 4.03822C4.4 4.24976 4.22 4.42283 4 4.42283H1.6C1.38 4.42283 1.2 4.24976 1.2 4.03822ZM4 6.34591H0.4C0.18 6.34591 0 6.17283 0 5.96129C0 5.74976 0.18 5.57668 0.4 5.57668H4C4.22 5.57668 4.4 5.74976 4.4 5.96129C4.4 6.17283 4.22 6.34591 4 6.34591Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'EnvelopeIcon'
}
</script>
