<script>
import { Bar, mixins } from 'vue-chartjs'
import { mapGetters } from 'vuex'
const { reactiveProp } = mixins

export default {
  name: 'BarChartComponent',
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['darkTheme'])
  },
  mounted() {
    this.changeCartsColors()
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    changeCartsColors() {
      if (this.darkTheme) {
        this.chartData.datasets[0].borderColor = 'rgb(60, 222, 235)'
        this.chartData.datasets[0].borderColor = 'rgb(60, 222, 235)'
        this.chartData.datasets[0].backgroundColor = 'rgb(60, 222, 235)'
      } else {
        this.chartData.datasets[0].borderColor = 'rgb(255, 60, 159)'
        this.chartData.datasets[0].borderColor = 'rgb(255, 60, 159)'
        this.chartData.datasets[0].backgroundColor = 'rgb(255, 60, 159)'
      }
    }
  }
}
</script>
