var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 15 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "7.5",
          cy: "7.5",
          r: "7.25",
          fill: "#D9D9D9",
          "fill-opacity": "0.1",
          stroke: "#FF3B9F",
          "stroke-width": "0.5",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "7.5",
          cy: "7.5",
          r: "5.5",
          fill: "#FF3B9F",
          "fill-opacity": "0.3",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: { cx: "7.5", cy: "7.5", r: "3.5", fill: "#FF3B9F" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }