var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "menu-area" }, [
      _c(
        "div",
        { staticClass: "newsletter" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "logo-area",
              attrs: {
                to: _vm.localePath("/"),
                "aria-label": _vm.$t("common.go_to_home_page"),
              },
            },
            [
              !_vm.darkTheme
                ? _c(
                    "div",
                    { staticClass: "logo-picture" },
                    [
                      _c("nuxt-picture", {
                        attrs: {
                          fit: "inside",
                          src: "/img/df_wide_light.svg",
                          alt: "Defi Pool Finance eran and exchange cryptocurrency",
                          "img-attrs": {
                            class: "desktop",
                            title: "Defi Pool logo",
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "logo-picture" },
                    [
                      _c("nuxt-picture", {
                        attrs: {
                          fit: "inside",
                          src: "/img/df_wide_dark.svg",
                          alt: "Defi Pool Finance eran and exchange cryptocurrency",
                          "img-attrs": {
                            class: "desktop",
                            title: "Defi Pool logo",
                          },
                        },
                      }),
                    ],
                    1
                  ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-area" }, [
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("common.subscribe")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                attrs: { action: "POST" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.subscribeToNewsletter.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "input-area" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "email-input",
                    attrs: {
                      type: "email",
                      name: "email",
                      placeholder: _vm.emailPlaceholder,
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "subscribe",
                      attrs: {
                        type: "submit",
                        "aria-label": _vm.$t("common.subscribe_cta"),
                      },
                    },
                    [
                      !_vm.subscribeLoading
                        ? _c("span", [_c("Envelope")], 1)
                        : _c("span", [_c("Loading")], 1),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "menu-col" }, [
        _c("div", { staticClass: "h4" }, [
          _vm._v("\n        " + _vm._s(_vm.$t("common.about")) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "list" }, [
          _c(
            "div",
            { staticClass: "link-area" },
            [
              _c("ChevronRight"),
              _vm._v(" "),
              _c("nuxt-link", { attrs: { to: _vm.localePath("/terms") } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.terms")) +
                    "\n          "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "link-area" },
            [
              _c("ChevronRight"),
              _vm._v(" "),
              _c("nuxt-link", { attrs: { to: _vm.localePath("/policy") } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.policy")) +
                    "\n          "
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "menu-col" }, [
        _c("div", { staticClass: "h4" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("common.developers")) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "list" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contacts.github !== undefined &&
                    _vm.contacts.github !== null &&
                    _vm.contacts.github.length > 0,
                  expression:
                    "contacts.github !== undefined && contacts.github !== null && contacts.github.length > 0",
                },
              ],
              staticClass: "link-area",
            },
            [
              _c("ChevronRight"),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.contacts.github,
                    rel: "nofollow noreferrer noopener",
                    target: "_blank",
                  },
                },
                [_vm._v("\n            Github\n          ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "link-area" },
            [
              _c("ChevronRight"),
              _vm._v(" "),
              _c("nuxt-link", { attrs: { to: _vm.localePath("/docs") } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.documentation")) +
                    "\n          "
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "menu-col no-margin" }, [
        _c("div", { staticClass: "h4" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("common.customer_support")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "list socials" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contacts.discord !== undefined &&
                    _vm.contacts.discord !== null &&
                    _vm.contacts.discord.length > 0,
                  expression:
                    "contacts.discord !== undefined && contacts.discord !== null && contacts.discord.length > 0",
                },
              ],
              staticClass: "link-area",
            },
            [
              _c(
                "a",
                {
                  staticClass: "social",
                  attrs: {
                    href: _vm.contacts.discord,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-area" },
                    [_c("DiscordLogo")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h6" }, [
                    _vm._v("\n              Discord\n            "),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contacts.twitter !== undefined &&
                    _vm.contacts.twitter !== null &&
                    _vm.contacts.twitter.length > 0,
                  expression:
                    "contacts.twitter !== undefined && contacts.twitter !== null && contacts.twitter.length > 0",
                },
              ],
              staticClass: "link-area",
            },
            [
              _c(
                "a",
                {
                  staticClass: "social",
                  attrs: {
                    href: _vm.contacts.twitter,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-area" },
                    [_c("TwitterLogo")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h6" }, [
                    _vm._v("\n              Twitter\n            "),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contacts.reddit !== undefined &&
                    _vm.contacts.reddit !== null &&
                    _vm.contacts.reddit.length > 0,
                  expression:
                    "contacts.reddit !== undefined && contacts.reddit !== null && contacts.reddit.length > 0",
                },
              ],
              staticClass: "link-area",
            },
            [
              _c(
                "a",
                {
                  staticClass: "social",
                  attrs: {
                    href: _vm.contacts.reddit,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-area" },
                    [_c("RedditLogo")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h6" }, [
                    _vm._v("\n              Reddit\n            "),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contacts.telegram !== undefined &&
                    _vm.contacts.telegram !== null &&
                    _vm.contacts.telegram.length > 0,
                  expression:
                    "contacts.telegram !== undefined && contacts.telegram !== null && contacts.telegram.length > 0",
                },
              ],
              staticClass: "link-area",
            },
            [
              _c(
                "a",
                {
                  staticClass: "social",
                  attrs: {
                    href: _vm.contacts.telegram,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-area" },
                    [_c("TelegramLogo")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h6" }, [
                    _vm._v("\n              Telegram news\n            "),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contacts.telegram_chat !== undefined &&
                    _vm.contacts.telegram_chat !== null &&
                    _vm.contacts.telegram_chat.length > 0,
                  expression:
                    "contacts.telegram_chat !== undefined && contacts.telegram_chat !== null && contacts.telegram_chat.length > 0",
                },
              ],
              staticClass: "link-area",
            },
            [
              _c(
                "a",
                {
                  staticClass: "social",
                  attrs: {
                    href: _vm.contacts.telegram_chat,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-area" },
                    [_c("TelegramLogo")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h6" }, [
                    _vm._v("\n              Telegram chat\n            "),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contacts.instagram !== undefined &&
                    _vm.contacts.instagram !== null &&
                    _vm.contacts.instagram.length > 0,
                  expression:
                    "contacts.instagram !== undefined && contacts.instagram !== null && contacts.instagram.length > 0",
                },
              ],
              staticClass: "link-area",
            },
            [
              _c(
                "a",
                {
                  staticClass: "social",
                  attrs: {
                    href: _vm.contacts.instagram,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-area" },
                    [_c("InstagramLogo")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h6" }, [
                    _vm._v("\n              Instagram\n            "),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contacts.facebook !== undefined &&
                    _vm.contacts.facebook !== null &&
                    _vm.contacts.facebook.length > 0,
                  expression:
                    "contacts.facebook !== undefined && contacts.facebook !== null && contacts.facebook.length > 0",
                },
              ],
              staticClass: "link-area",
            },
            [
              _c(
                "a",
                {
                  staticClass: "social",
                  attrs: {
                    href: _vm.contacts.facebook,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-area" },
                    [_c("FacebookLogo")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h6" }, [
                    _vm._v("\n              Facebook\n            "),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "divider-horizontal" }),
    _vm._v(" "),
    _c("div", { staticClass: "subfooter-area" }, [
      _c("div", { staticClass: "dev-area" }, [
        _c("p", [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("common.rights")) +
              " © " +
              _vm._s(_vm.fullYear) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "settings" }, [
        _c("div", { staticClass: "lang-switcher-area dropdown" }, [
          _c(
            "div",
            { staticClass: "dropdown-header" },
            [_c("Planet"), _vm._v(" "), _c("ChevronDown")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dropdown-area" },
            _vm._l(_vm.availableLocales, function (locale) {
              return _c(
                "nuxt-link",
                {
                  key: locale.code,
                  attrs: { to: _vm.switchLocalePath(locale.code) },
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(locale.name) + "\n          "
                  ),
                ]
              )
            }),
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: `switcher-area ${_vm.darkTheme ? "dark" : "light"}`,
            on: { click: _vm.changeTheme },
          },
          [
            _c("div", { staticClass: "switcher" }, [
              _c("div", { staticClass: "light" }, [_c("Sun")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "dark" }, [_c("Moon")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "circle" }, [_c("SwitchCircle")], 1),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "settings-area" }, [
          _c(
            "button",
            {
              staticClass: "settings-button",
              attrs: {
                type: "button",
                "aria-label": _vm.$t("common.settings"),
              },
              on: { click: _vm.openModalSettings },
            },
            [_c("Cog")],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "connect-wallet",
            attrs: {
              type: "button",
              "aria-label": _vm.$t("common.connect_wallet"),
            },
            on: { click: _vm.openModalWallets },
          },
          [
            _c("Wallet"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n          " + _vm._s(_vm.currentAccount) + "\n        "
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }