var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tokens-list" },
    _vm._l(_vm.list, function (token, index) {
      return _c(
        "div",
        {
          key: index,
          class: { item: true, active: _vm.isTokenActive(token.symbol) },
          on: {
            click: function ($event) {
              return _vm.selectFromToken(token)
            },
          },
        },
        [
          _c("div", { staticClass: "info-area" }, [
            _c(
              "div",
              { staticClass: "icon-area" },
              [
                _c("nuxt-img", {
                  attrs: {
                    src:
                      token.address !== _vm.$config.ZERO
                        ? _vm.$getImageByAddress(token.address)
                        : _vm.$getImage(token.logoURI),
                    alt: token.name,
                    title: token.symbol,
                    quality: 80,
                    format: "webp",
                    lazy: "",
                  },
                  on: {
                    error: function ($event) {
                      $event.target.src = require("~/static/img/default_token.svg")
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _vm._v("\n        " + _vm._s(token.symbol) + "\n      "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tooltip-name" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  token.balance !== undefined &&
                    token.balance !== null &&
                    parseFloat(token.balance) !== 0
                    ? _vm.$formatCryptoAmount(parseFloat(token.balance), 4)
                    : ""
                ) +
                "\n      " +
                _vm._s(token.name) +
                "\n    "
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }