<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0,512) scale(0.1,-0.1)" fill="currentColor" stroke="none">
      <path
        d="M157 3616 c-66 -18 -104 -52 -137 -121 -25 -52 -25 -109 1 -165 27
            -60 45 -79 104 -106 l50 -24 2385 0 2385 0 50 24 c59 27 77 46 104 106 37 81
            20 169 -45 234 -66 66 147 61 -2474 63 -1906 1 -2384 -1 -2423 -11z"
      />
      <path
        d="M125 1896 c-27 -13 -58 -32 -67 -42 -29 -33 -58 -107 -58 -149 0 -79
            65 -171 139 -197 53 -19 4789 -19 4842 0 74 26 139 118 139 197 0 42 -29 116
            -58 149 -9 10 -39 29 -67 42 l-50 24 -2385 0 -2385 0 -50 -24z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EqualIcon'
}
</script>
