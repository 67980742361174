export { default as CommonFooter } from '../../components/common/Footer.vue'
export { default as CommonHeader } from '../../components/common/Header.vue'
export { default as CommonHeaderNav } from '../../components/common/HeaderNav.vue'
export { default as CommonHeaderNavMobile } from '../../components/common/HeaderNavMobile.vue'
export { default as ModalsConnectWallet } from '../../components/modals/ConnectWallet.vue'
export { default as ModalsManageModal } from '../../components/modals/ManageModal.vue'
export { default as ModalsProfileModal } from '../../components/modals/ProfileModal.vue'
export { default as ModalsRoiCalculator } from '../../components/modals/RoiCalculator.vue'
export { default as ModalsSettingsModal } from '../../components/modals/SettingsModal.vue'
export { default as ModalsVoteModal } from '../../components/modals/VoteModal.vue'
export { default as ModalsWindowModal } from '../../components/modals/WindowModal.vue'
export { default as SvgAccountIcon } from '../../components/svg/AccountIcon.vue'
export { default as SvgArrowBack } from '../../components/svg/ArrowBack.vue'
export { default as SvgArrowDown } from '../../components/svg/ArrowDown.vue'
export { default as SvgArrowRight } from '../../components/svg/ArrowRight.vue'
export { default as SvgBigLoaderSpinner } from '../../components/svg/BigLoaderSpinner.vue'
export { default as SvgBoxIcon } from '../../components/svg/BoxIcon.vue'
export { default as SvgChartIcon } from '../../components/svg/ChartIcon.vue'
export { default as SvgCheckIcon } from '../../components/svg/CheckIcon.vue'
export { default as SvgChevronRight } from '../../components/svg/ChevronRight.vue'
export { default as SvgClockIcon } from '../../components/svg/ClockIcon.vue'
export { default as SvgCloseIcon } from '../../components/svg/CloseIcon.vue'
export { default as SvgCloudIcon } from '../../components/svg/CloudIcon.vue'
export { default as SvgCogIcon } from '../../components/svg/CogIcon.vue'
export { default as SvgCopyIcon } from '../../components/svg/CopyIcon.vue'
export { default as SvgDarkTheme } from '../../components/svg/DarkTheme.vue'
export { default as SvgDashboardIcon } from '../../components/svg/DashboardIcon.vue'
export { default as SvgDiscordLogo } from '../../components/svg/DiscordLogo.vue'
export { default as SvgDiscourseLogo } from '../../components/svg/DiscourseLogo.vue'
export { default as SvgFlashIcon } from '../../components/svg/FlashIcon.vue'
export { default as SvgLightTheme } from '../../components/svg/LightTheme.vue'
export { default as SvgLoaderIcon } from '../../components/svg/LoaderIcon.vue'
export { default as SvgLoaderSpinner } from '../../components/svg/LoaderSpinner.vue'
export { default as SvgLockIcon } from '../../components/svg/LockIcon.vue'
export { default as SvgLogoSquare } from '../../components/svg/LogoSquare.vue'
export { default as SvgLogoWide } from '../../components/svg/LogoWide.vue'
export { default as SvgMainLogo } from '../../components/svg/MainLogo.vue'
export { default as SvgManageIcon } from '../../components/svg/ManageIcon.vue'
export { default as SvgMenuBar } from '../../components/svg/MenuBar.vue'
export { default as SvgMenuButton } from '../../components/svg/MenuButton.vue'
export { default as SvgMenuDots } from '../../components/svg/MenuDots.vue'
export { default as SvgMetamaskIcon } from '../../components/svg/MetamaskIcon.vue'
export { default as SvgNewTabIcon } from '../../components/svg/NewTabIcon.vue'
export { default as SvgOracleIcon } from '../../components/svg/OracleIcon.vue'
export { default as SvgPaginationLeft } from '../../components/svg/PaginationLeft.vue'
export { default as SvgPaginationRight } from '../../components/svg/PaginationRight.vue'
export { default as SvgPairsIcon } from '../../components/svg/PairsIcon.vue'
export { default as SvgPlusIcon } from '../../components/svg/PlusIcon.vue'
export { default as SvgRedditLogo } from '../../components/svg/RedditLogo.vue'
export { default as SvgRefreshIcon } from '../../components/svg/RefreshIcon.vue'
export { default as SvgSavedFlag } from '../../components/svg/SavedFlag.vue'
export { default as SvgSearchIcon } from '../../components/svg/SearchIcon.vue'
export { default as SvgShortMainLogo } from '../../components/svg/ShortMainLogo.vue'
export { default as SvgSwapIcon } from '../../components/svg/SwapIcon.vue'
export { default as SvgTelegramLogo } from '../../components/svg/TelegramLogo.vue'
export { default as SvgTokenListIcon } from '../../components/svg/TokenListIcon.vue'
export { default as SvgTokensIcon } from '../../components/svg/TokensIcon.vue'
export { default as SvgTwitterLogo } from '../../components/svg/TwitterLogo.vue'
export { default as SvgWarnCircleIcon } from '../../components/svg/WarnCircleIcon.vue'
export { default as SvgWarnSign } from '../../components/svg/WarnSign.vue'
export { default as AnalyticsPartsBarChart } from '../../components/analytics/parts/BarChart.vue'
export { default as AnalyticsPartsDoughnutChart } from '../../components/analytics/parts/DoughnutChart.vue'
export { default as AnalyticsPartsLineChart } from '../../components/analytics/parts/LineChart.vue'
export { default as AnalyticsPartsPagination } from '../../components/analytics/parts/Pagination.vue'
export { default as AnalyticsTablesTopPairs } from '../../components/analytics/tables/TopPairs.vue'
export { default as AnalyticsTablesTopTokens } from '../../components/analytics/tables/TopTokens.vue'
export { default as AnalyticsTablesTopTransactions } from '../../components/analytics/tables/TopTransactions.vue'
export { default as AppInterfaceBuyTickets } from '../../components/app/interface/BuyTickets.vue'
export { default as AppInterfaceCommonModal } from '../../components/app/interface/CommonModal.vue'
export { default as AppInterfaceConfirmOrder } from '../../components/app/interface/ConfirmOrder.vue'
export { default as AppInterfaceConfirmSwap } from '../../components/app/interface/ConfirmSwap.vue'
export { default as AppInterfaceFarmAsset } from '../../components/app/interface/FarmAsset.vue'
export { default as AppInterfaceImportPool } from '../../components/app/interface/ImportPool.vue'
export { default as AppInterfaceInvestToken } from '../../components/app/interface/InvestToken.vue'
export { default as AppInterfaceLimitOrdersTable } from '../../components/app/interface/LimitOrdersTable.vue'
export { default as AppInterfaceModalLoader } from '../../components/app/interface/ModalLoader.vue'
export { default as AppInterfaceRemoveLiquidity } from '../../components/app/interface/RemoveLiquidity.vue'
export { default as AppInterfaceSelect } from '../../components/app/interface/Select.vue'
export { default as AppInterfaceStakeAsset } from '../../components/app/interface/StakeAsset.vue'
export { default as AppInterfaceSupplyLiquidity } from '../../components/app/interface/SupplyLiquidity.vue'
export { default as AppInterfaceSwitcher } from '../../components/app/interface/Switcher.vue'
export { default as AppInterfaceTicketsList } from '../../components/app/interface/TicketsList.vue'
export { default as AppInterfaceTokensList } from '../../components/app/interface/TokensList.vue'
export { default as AppInterfaceTransactionsList } from '../../components/app/interface/TransactionsList.vue'
export { default as AppPredictionCard } from '../../components/app/prediction/PredictionCard.vue'
export { default as SvgNewArrowBack } from '../../components/svg/new/ArrowBack.vue'
export { default as SvgNewBan } from '../../components/svg/new/Ban.vue'
export { default as SvgNewBgLiquid } from '../../components/svg/new/BgLiquid.vue'
export { default as SvgNewBgLiquidSmall } from '../../components/svg/new/BgLiquidSmall.vue'
export { default as SvgNewBnb } from '../../components/svg/new/Bnb.vue'
export { default as SvgNewCalculator } from '../../components/svg/new/Calculator.vue'
export { default as SvgNewCardHat } from '../../components/svg/new/CardHat.vue'
export { default as SvgNewChart } from '../../components/svg/new/Chart.vue'
export { default as SvgNewChartApr } from '../../components/svg/new/ChartApr.vue'
export { default as SvgNewCheckFilled } from '../../components/svg/new/CheckFilled.vue'
export { default as SvgNewCheckOutline } from '../../components/svg/new/CheckOutline.vue'
export { default as SvgNewChevronDown } from '../../components/svg/new/ChevronDown.vue'
export { default as SvgNewChevronLeft } from '../../components/svg/new/ChevronLeft.vue'
export { default as SvgNewChevronRight } from '../../components/svg/new/ChevronRight.vue'
export { default as SvgNewCloudRefreshIcon } from '../../components/svg/new/CloudRefreshIcon.vue'
export { default as SvgNewCog } from '../../components/svg/new/Cog.vue'
export { default as SvgNewCoinHold } from '../../components/svg/new/CoinHold.vue'
export { default as SvgNewCoins } from '../../components/svg/new/Coins.vue'
export { default as SvgNewCommunity } from '../../components/svg/new/Community.vue'
export { default as SvgNewComputerAnalytics } from '../../components/svg/new/ComputerAnalytics.vue'
export { default as SvgNewCookie } from '../../components/svg/new/Cookie.vue'
export { default as SvgNewCopy } from '../../components/svg/new/Copy.vue'
export { default as SvgNewCrossFilled } from '../../components/svg/new/CrossFilled.vue'
export { default as SvgNewCryptoCoin } from '../../components/svg/new/CryptoCoin.vue'
export { default as SvgNewCube } from '../../components/svg/new/Cube.vue'
export { default as SvgNewCubes } from '../../components/svg/new/Cubes.vue'
export { default as SvgNewCyclePath } from '../../components/svg/new/CyclePath.vue'
export { default as SvgNewDecentralize } from '../../components/svg/new/Decentralize.vue'
export { default as SvgNewDiscordLogo } from '../../components/svg/new/DiscordLogo.vue'
export { default as SvgNewDiscourseLogo } from '../../components/svg/new/DiscourseLogo.vue'
export { default as SvgNewDiveInText } from '../../components/svg/new/DiveInText.vue'
export { default as SvgNewDropsLine } from '../../components/svg/new/DropsLine.vue'
export { default as SvgNewEnvelope } from '../../components/svg/new/Envelope.vue'
export { default as SvgNewEqual } from '../../components/svg/new/Equal.vue'
export { default as SvgNewErrorSign } from '../../components/svg/new/ErrorSign.vue'
export { default as SvgNewFacebookLogo } from '../../components/svg/new/FacebookLogo.vue'
export { default as SvgNewFigures } from '../../components/svg/new/Figures.vue'
export { default as SvgNewFlamingo } from '../../components/svg/new/Flamingo.vue'
export { default as SvgNewFloatieLogo } from '../../components/svg/new/FloatieLogo.vue'
export { default as SvgNewInfo } from '../../components/svg/new/Info.vue'
export { default as SvgNewInstagramLogo } from '../../components/svg/new/InstagramLogo.vue'
export { default as SvgNewLikeable } from '../../components/svg/new/Likeable.vue'
export { default as SvgNewList } from '../../components/svg/new/List.vue'
export { default as SvgNewLoading } from '../../components/svg/new/Loading.vue'
export { default as SvgNewLock } from '../../components/svg/new/Lock.vue'
export { default as SvgNewLogo } from '../../components/svg/new/Logo.vue'
export { default as SvgNewLogoMobile } from '../../components/svg/new/LogoMobile.vue'
export { default as SvgNewLowFee } from '../../components/svg/new/LowFee.vue'
export { default as SvgNewMoney } from '../../components/svg/new/Money.vue'
export { default as SvgNewMoon } from '../../components/svg/new/Moon.vue'
export { default as SvgNewPaginationDot } from '../../components/svg/new/PaginationDot.vue'
export { default as SvgNewPaginationDotActive } from '../../components/svg/new/PaginationDotActive.vue'
export { default as SvgNewPlanet } from '../../components/svg/new/Planet.vue'
export { default as SvgNewPlay } from '../../components/svg/new/Play.vue'
export { default as SvgNewPlus } from '../../components/svg/new/Plus.vue'
export { default as SvgNewPoolLadder } from '../../components/svg/new/PoolLadder.vue'
export { default as SvgNewPrize } from '../../components/svg/new/Prize.vue'
export { default as SvgNewPuzzle } from '../../components/svg/new/Puzzle.vue'
export { default as SvgNewQuestion } from '../../components/svg/new/Question.vue'
export { default as SvgNewQuestionCircle } from '../../components/svg/new/QuestionCircle.vue'
export { default as SvgNewRedditLogo } from '../../components/svg/new/RedditLogo.vue'
export { default as SvgNewRefresh } from '../../components/svg/new/Refresh.vue'
export { default as SvgNewRefreshTime } from '../../components/svg/new/RefreshTime.vue'
export { default as SvgNewRocket } from '../../components/svg/new/Rocket.vue'
export { default as SvgNewSearch } from '../../components/svg/new/Search.vue'
export { default as SvgNewSecurity } from '../../components/svg/new/Security.vue'
export { default as SvgNewSplashes } from '../../components/svg/new/Splashes.vue'
export { default as SvgNewStarFilledIcon } from '../../components/svg/new/StarFilledIcon.vue'
export { default as SvgNewStarIcon } from '../../components/svg/new/StarIcon.vue'
export { default as SvgNewSun } from '../../components/svg/new/Sun.vue'
export { default as SvgNewSwap } from '../../components/svg/new/Swap.vue'
export { default as SvgNewSwapArrows } from '../../components/svg/new/SwapArrows.vue'
export { default as SvgNewSwitchCircle } from '../../components/svg/new/SwitchCircle.vue'
export { default as SvgNewTelegramLogo } from '../../components/svg/new/TelegramLogo.vue'
export { default as SvgNewTrashCan } from '../../components/svg/new/TrashCan.vue'
export { default as SvgNewTwitterLogo } from '../../components/svg/new/TwitterLogo.vue'
export { default as SvgNewUpdate } from '../../components/svg/new/Update.vue'
export { default as SvgNewVerified } from '../../components/svg/new/Verified.vue'
export { default as SvgNewWallet } from '../../components/svg/new/Wallet.vue'
export { default as SvgNewWarn } from '../../components/svg/new/Warn.vue'
export { default as SvgNewWarnTriangle } from '../../components/svg/new/WarnTriangle.vue'
export { default as SvgNewCloudsCloudCyanDiveLeft } from '../../components/svg/new/clouds/CloudCyanDiveLeft.vue'
export { default as SvgNewCloudsCloudCyanDiveRight } from '../../components/svg/new/clouds/CloudCyanDiveRight.vue'
export { default as SvgNewCloudsCloudCyanError } from '../../components/svg/new/clouds/CloudCyanError.vue'
export { default as SvgNewCloudsCloudCyanFloatie } from '../../components/svg/new/clouds/CloudCyanFloatie.vue'
export { default as SvgNewCloudsCloudCyanLiquidityLeft } from '../../components/svg/new/clouds/CloudCyanLiquidityLeft.vue'
export { default as SvgNewCloudsCloudCyanLiquidityRight } from '../../components/svg/new/clouds/CloudCyanLiquidityRight.vue'
export { default as SvgNewCloudsCloudCyanSplashie } from '../../components/svg/new/clouds/CloudCyanSplashie.vue'
export { default as SvgNewCloudsCloudCyanStakeLeft } from '../../components/svg/new/clouds/CloudCyanStakeLeft.vue'
export { default as SvgNewCloudsCloudCyanStakeRight } from '../../components/svg/new/clouds/CloudCyanStakeRight.vue'
export { default as SvgNewCloudsCloudCyanVotingLeft } from '../../components/svg/new/clouds/CloudCyanVotingLeft.vue'
export { default as SvgNewCloudsCloudCyanVotingRight } from '../../components/svg/new/clouds/CloudCyanVotingRight.vue'
export { default as SvgNewCloudsCloudPinkFloatie } from '../../components/svg/new/clouds/CloudPinkFloatie.vue'
export { default as SvgNewCloudsCloudPinkSplashie } from '../../components/svg/new/clouds/CloudPinkSplashie.vue'
export { default as SvgNewCloudsCyanCloudCompany } from '../../components/svg/new/clouds/CyanCloudCompany.vue'
export { default as SvgNewCloudsCyanCloudIcons } from '../../components/svg/new/clouds/CyanCloudIcons.vue'
export { default as SvgNewCloudsCyanOval } from '../../components/svg/new/clouds/CyanOval.vue'
export { default as SvgNewCloudsCyanPoolShadow } from '../../components/svg/new/clouds/CyanPoolShadow.vue'
export { default as SvgNewCloudsPinkCircle } from '../../components/svg/new/clouds/PinkCircle.vue'
export { default as SvgNewCloudsPinkCloudCompany } from '../../components/svg/new/clouds/PinkCloudCompany.vue'
export { default as SvgNewCloudsPinkOval } from '../../components/svg/new/clouds/PinkOval.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
