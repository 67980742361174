<template>
  <div v-if="!loadingScreen && !successScreen && !errorScreen && action === 'stake'" class="confirm-area">
    <div class="settings-area">
      <div class="inputs-area">
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $config.floatieSymbol + ' ' + $t('stake.to_stake') }}
            </p>
            <p class="balance">
              {{ $t('common.balance') }}: {{ pool !== null ? $formatCryptoAmount(pool.balance, 6) : '...' }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="stakeAmount" type="number" step="0.0000001" min="0" @input="checkStakeAmount">
            <button type="button" class="max-button active" @click="setMax()">
              <p class="text">
                {{ $t('swap.max') }}
              </p>
            </button>
          </div>
        </div>
      </div>
      <div class="stake-percent percent-area">
        <div class="handler">
          <veeno
            ref="percentSlider"
            connect
            :handles="[0, 0]"
            :range="{
              'min': 0,
              'max': 100
            }"
            :set="[0, parseFloat(percent)]"
            @change="updateVeeno"
            @slide="setSlide"
          />
        </div>
        <div class="range-switch-area">
          <div :class="{'switch-item': true, 'active': percent === 25}" @click="setPercent(25)">
            <span>
              25%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 50}" @click="setPercent(50)">
            <span>
              50%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 75}" @click="setPercent(75)">
            <span>
              75%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 100}" @click="setPercent(100)">
            <span>
              100%
            </span>
          </div>
        </div>
      </div>
      <div v-if="pool.type !== undefined && pool.type === 'locked' && pool.ctaType === 'stake'" class="inputs-area">
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $t('stake.add_duration') }}
            </p>
            <p class="balance">
              {{ $t('stake.max_duration') }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="weeksExtend" type="number" step="0.0000001" min="0" @input="checkweeks">
            <button type="button" class="max-button active" @click="setWeeks(52)">
              <p class="text">
                {{ $t('swap.max') }}
              </p>
            </button>
          </div>
        </div>
      </div>
      <div v-if="pool.type !== undefined && pool.type === 'locked' && pool.ctaType === 'stake'" class="stake-percent percent-area m-0 p-0">
        <div class="range-switch-area">
          <div :class="{'switch-item': true, 'active': weeksExtend === 1}" @click="setWeeks(1)">
            <span>
              1 {{ $t('common.w') }}
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': weeksExtend === 5}" @click="setWeeks(5)">
            <span>
              5 {{ $t('common.w') }}
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': weeksExtend === 10}" @click="setWeeks(10)">
            <span>
              10 {{ $t('common.w') }}
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': weeksExtend === 25}" @click="setWeeks(25)">
            <span>
              25 {{ $t('common.w') }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="pool.type !== undefined && pool.type === 'locked' && pool.ctaType === 'add_floatie'" class="extending-area">
        <div :class="{'warning-area': true, 'active': !extendLock}">
          <div class="icon-area">
            <WarnCircleIcon />
          </div>
          <div class="text-area">
            <p class="desc">
              {{ $t('stake.stake_more_floatie') }}
            </p>
          </div>
        </div>
        <div class="checkbox-area">
          <div class="checkbox">
            <input id="extendAndRenewLock" v-model="extendLock" type="checkbox">
            <label for="extendAndRenewLock">
              {{ $t('stake.renew_and_receive') }}
            </label>
          </div>
        </div>
      </div>
      <hr v-if="pool.type !== undefined && pool.type === 'locked'">
      <div v-if="pool.type !== undefined && pool.type === 'locked'" class="modal-table">
        <div class="row">
          <div class="text-area">
            <p class="hint">
              {{ $t('stake.to_be_staked') }}
            </p>
          </div>
          <div class="value-area">
            <div v-if="extendLock" class="h6 highlighted">
              {{ (pool.info.lockedAmount + stakeAmount) > 1000 ? $priceFormat(pool.info.lockedAmount, 2) + ' -> ' + $priceFormat(pool.info.lockedAmount + stakeAmount, 2) : $formatCryptoAmount(pool.info.lockedAmount) + ' -> ' + $formatCryptoAmount(pool.info.lockedAmount + stakeAmount) }} {{ pool.token.symbol }}
            </div>
            <div v-else class="h6">
              {{ stakeAmount > 1000 ? $priceFormat(stakeAmount, 2) : $formatCryptoAmount(stakeAmount) }} {{ pool.token.symbol }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="text-area">
            <p class="hint">
              {{ $t('stake.duration') }}
            </p>
          </div>
          <div class="value-area">
            <div :class="{'h6': true, 'highlighted': extendLock}">
              {{ extendLock ? '-> ' + getDepositPeriod() : '-' }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="text-area">
            <p class="hint">
              {{ $t('stake.unlock_on') }}
            </p>
          </div>
          <div class="value-area">
            <div v-if="!extendLock" class="h6">
              {{ getUnlockDate() }}
            </div>
            <div v-else class="h6 highlighted">
              {{ getNewUnlockDate() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="buttons-area">
      <button :class="{ 'btn cyan': true, 'disabled': loading || stakeAmount < 0.00001}" type="button" :disabled="loading || stakeAmount < 0.00001" @click="confirmStake">
        <span v-if="!loading">
          {{ $t('stake.confirm_stake') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
  </div>
  <div v-else-if="!loadingScreen && !successScreen && !errorScreen && action === 'unstake'" class="confirm-area">
    <div class="staking-area">
      <div class="header">
        <div class="token-area">
          <img :src="pool.token.address !== $config.ZERO ? $getImageByAddress(pool.token.address) : $getImage(pool.token.logoURI)" alt="$FLOATIE stake pool for earning auto flexible rewards" title="$FLOATIE stake pool">
          <div class="name">
            <div class="h2">
              {{ $formatCryptoAmount(parseFloat(stakeAmount)) }}
            </div>
            <div class="h4">
              / {{ convertToUsd(stakeAmount) }} USD
            </div>
          </div>
        </div>
        <div class="symbol">
          {{ pool.token.symbol }}
        </div>
      </div>
    </div>
    <div class="settings-area">
      <div class="inputs-area">
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $config.floatieSymbol + ' ' + $t('stake.to_unstake') }}
            </p>
            <p class="balance">
              {{ $t('common.balance') }}: {{ pool !== null ? $formatCryptoAmount(pool.info.lockedAmount + pool.earned, 6) : '...' }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="stakeAmount" type="number" step="0.0000001" min="0" @input="checkStakeAmount">
            <button type="button" class="max-button active" @click="setMax()">
              <p class="text">
                MAX
              </p>
            </button>
          </div>
        </div>
      </div>
      <div class="stake-percent percent-area">
        <div class="handler">
          <veeno
            ref="percentSlider"
            connect
            :handles="[0, 0]"
            :range="{
              'min': 0,
              'max': 100
            }"
            :set="[0, parseFloat(percent)]"
            @change="updateVeeno"
            @slide="setSlide"
          />
        </div>
        <div class="range-switch-area">
          <div :class="{'switch-item': true, 'active': percent === 25}" @click="setPercent(25)">
            <span>
              25%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 50}" @click="setPercent(50)">
            <span>
              50%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 75}" @click="setPercent(75)">
            <span>
              75%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 100}" @click="setPercent(100)">
            <span>
              100%
            </span>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'warning-area': true, 'active': pool.info.unstakeFee !== 0}">
      <div class="icon-area">
        <WarnCircleIcon />
      </div>
      <div class="text-area">
        <p class="desc">
          {{ $t('stake.unstaking_fee_active', {fee: pool.info.unstakeFee}) }}
        </p>
      </div>
    </div>
    <div class="buttons-area">
      <button :class="{ 'btn cyan': true, 'disabled': loading }" type="button" :disabled="loading" @click="confirmUnStake">
        <span v-if="!loading">
          {{ $t('stake.confirm_unstake') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
  </div>
  <div v-else-if="!loadingScreen && !successScreen && !errorScreen && action === 'extend'" class="confirm-area">
    <div class="settings-area">
      <div class="header">
        <div class="token-area">
          <img src="/img/new/floatie_item.svg" alt="$FLOATIE stake pool for earning auto flexible rewards" title="$FLOATIE stake pool">
          <div class="name">
            <div class="h2">
              {{ $formatCryptoAmount(parseFloat(pool.info.lockedAmount), 6) }}
            </div>
            <div class="h4">
              / {{ $priceFormat(convertToUsd(pool.info.lockedAmount), 2) }} USD
            </div>
          </div>
        </div>
        <div class="symbol">
          {{ pool.token.symbol }}
        </div>
      </div>
      <div class="inputs-area">
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $t('stake.add_duration') }}
            </p>
            <p class="balance">
              {{ $t('stake.max_duration') }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="weeksExtend" type="number" step="0.0000001" min="0" @input="checkweeks">
            <button type="button" class="max-button active" @click="setWeeks(52)">
              <p class="text">
                MAX
              </p>
            </button>
          </div>
        </div>
      </div>
      <div class="stake-percent percent-area m-0 p-0">
        <div class="range-switch-area">
          <div :class="{'switch-item': true, 'active': weeksExtend === 1}" @click="setWeeks(1)">
            <span>
              1 {{ $t('common.w') }}
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': weeksExtend === 5}" @click="setWeeks(5)">
            <span>
              5 {{ $t('common.w') }}
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': weeksExtend === 10}" @click="setWeeks(10)">
            <span>
              10 {{ $t('common.w') }}
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': weeksExtend === 25}" @click="setWeeks(25)">
            <span>
              25 {{ $t('common.w') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="modal-table">
      <div class="row">
        <div class="text-area">
          <p class="hint">
            {{ $t('stake.to_be_staked') }}
          </p>
        </div>
        <div class="value-area">
          <div class="h6">
            {{ pool.info.lockedAmount > 1000 ? $priceFormat(pool.info.lockedAmount, 2) : $formatCryptoAmount(pool.info.lockedAmount) }} {{ pool.token.symbol }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-area">
          <p class="hint">
            {{ $t('stake.duration') }}
          </p>
        </div>
        <div class="value-area">
          <div class="h6 highlighted">
            {{ extendedWeeksPeriod }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-area">
          <p class="hint">
            {{ $t('stake.unlock_on') }}
          </p>
        </div>
        <div class="value-area">
          <div class="h6 highlighted">
            {{ extendedWeeksDate }}
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="buttons-area">
      <button :class="{ 'btn cyan': true, 'disabled': loading || weeksExtend <= 0 }" type="button" :disabled="loading || weeksExtend <= 0" @click="confirmExtend">
        <span v-if="!loading">
          {{ $t('stake.confirm_extend') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
  </div>
  <div v-else-if="loadingScreen" class="loading-area">
    <ModalLoader />
    <div class="desc-area">
      <div v-if="action === 'stake'" class="h5">
        {{ $t('stake.staking') }}
        {{ $formatCryptoAmount(parseFloat(stakeAmount)) + ' ' + pool.token.symbol }}
        {{ $t('common.to_the_pool') }}
      </div>
      <div v-else-if="action === 'unstake'" class="h5">
        {{ $t('stake.unstaking') }}
        {{ (parseFloat(stakeAmount) > 1000 ? $priceFormat(parseFloat(stakeAmount), 2) : $formatCryptoAmount(parseFloat(stakeAmount))) + ' ' + pool.token.symbol }}
        {{ $t('common.from_the_pool') }}
      </div>
      <div v-else-if="action === 'extend'" class="h5">
        {{ $t('stake.extending_for') + ' ' + weeksExtend }}&nbsp;
        {{ $num2str([$t('common.week'), $t('common.weeks'), $t('common.many_weeks')], weeksExtend) }}
      </div>
      <p>
        {{ $t('swap.confirm_transaction') }}
      </p>
    </div>
  </div>
  <div v-else-if="successScreen" class="success-area">
    <div class="icon-area">
      <CheckIcon />
    </div>
    <div v-if="action === 'stake'" class="desc-area">
      <div class="h4">
        {{ $t('stake.success') }}
      </div>
      <p class="desc">
        {{ $t('stake.success_text') }}
      </p>
    </div>
    <div v-else-if="action === 'unstake'" class="desc-area">
      <div class="h4">
        {{ $t('stake.success_unstake') }}
      </div>
      <p class="desc">
        {{ $t('stake.success_unstake_text') }}
      </p>
    </div>
    <div v-else-if="action === 'extend'" class="desc-area">
      <div class="h4">
        {{ $t('stake.success_extend') }}
      </div>
      <p class="desc">
        {{ $t('stake.success_extend_text') }}
      </p>
    </div>
    <hr>
    <div class="buttons-area">
      <a class="btn btn-outline pink" :href="transactionLink(transaction.tx)" rel="nofollow noopener noreferrer" target="_blank">
        <span class="text">
          {{ $t('swap.view_explorer') }}
          <span class="icon icon-right">
            <NewTabIcon />
          </span>
        </span>
      </a>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.close') }}
      </button>
    </div>
  </div>
  <div v-else-if="errorScreen" class="error-area">
    <WarnTriangle />
    <div class="desc-area">
      <div class="h4">
        {{ $t('swap.error') }}
      </div>
      <p class="desc">
        {{ errorText }}
      </p>
      <hr>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.dismiss') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenIdle,
  hydrateWhenVisible
} from 'vue-lazy-hydration'

import 'nouislider/distribute/nouislider.min.css'

export default {
  components: {
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    ModalLoader: hydrateWhenVisible(() => import('~/components/app/interface/ModalLoader')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    WarnCircleIcon: hydrateWhenVisible(() => import('~/components/svg/WarnCircleIcon')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    veeno: hydrateWhenIdle(() => import('veeno'))
  },
  props: {
    pool: {
      type: Object,
      default: () => {
        return {
          apr: 0,
          ends: 0,
          flexibleApy: 0,
          index: 0,
          lockedApy: 0,
          lockDuration: 0,
          ctaType: 'stake',
          mode: 'manual',
          opened: false,
          performance: 2,
          total: 10013286.35,
          stakeAmount: null,
          allocPoint: 1000,
          lastRewardBlock: 20977886,
          accPeriodPerShare: 12078500000000000,
          staked: 0,
          earned: 0,
          pending: 0,
          token: {
            address: '0x46bCDb274f199CA04d678ee773e39BCD4Dbc38ad'
          },
          loadingStake: false,
          loadingHarvest: false
        }
      }
    },
    type: {
      type: String,
      default: () => {
        return 'Global'
      }
    },
    action: {
      type: String,
      default: () => {
        return 'stake'
      }
    },
    transaction: {
      type: Object,
      default: () => {
        return {
          gasFee: 5000000,
          tx: '',
          loading: false,
          pending: false
        }
      }
    }
  },
  data() {
    return {
      errorText: this.$t('liquidity.error_text'),
      loading: false,
      loadingScreen: false,
      successScreen: false,
      errorScreen: false,
      percent: 0,
      stakeAmount: null,
      extendLock: false,
      weeksExtend: 1
    }
  },
  computed: {
    ...mapGetters(['floatieToUsd']),
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    },
    extendedWeeksPeriod() {
      const startDate = parseInt(this.pool.info.lockStartTime, 10) !== 0 ? new Date(this.pool.info.lockStartTime * 1000) : new Date()
      const endDate = parseInt(this.pool.info.lockEndTime, 10) !== 0 ? new Date(this.pool.info.lockEndTime * 1000) : new Date()
      const diffTime = Math.abs(endDate - startDate)
      const numberOfWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))
      const updatedNumberOfWeeks = numberOfWeeks + this.weeksExtend
      return numberOfWeeks + ' ' + this.$num2str([this.$t('common.week'), this.$t('common.weeks'), this.$t('common.many_weeks')], numberOfWeeks) +
        ' -> ' +
        updatedNumberOfWeeks + ' ' + this.$num2str([this.$t('common.week'), this.$t('common.weeks'), this.$t('common.many_weeks')], numberOfWeeks)
    },
    extendedWeeksDate() {
      const startDate = this.pool.info.lockStartTime !== 0 ? new Date(this.pool.info.lockStartTime * 1000) : new Date()
      const endDate = parseInt(this.pool.info.lockEndTime, 10) !== 0 ? new Date(this.pool.info.lockEndTime * 1000) : new Date()
      const diffTime = Math.abs(endDate - startDate)
      const nowDate = new Date()
      const extendedTime = this.weeksExtend * 7 * 24 * 60 * 60 * 1000
      nowDate.setTime(nowDate.getTime() + diffTime + extendedTime)
      return this.$beautifyDate(nowDate)
    }
  },
  watch: {
    isModalOpened(modalOpen) {
      if (!modalOpen) {
        this.returnInitState()
      }
    }
  },
  methods: {
    setMax() {
      this.percent = 100
      this.changeInputByPercent()
    },
    changeInputByPercent() {
      const currentBalance = this.getCurrentBalance()
      this.stakeAmount = parseFloat(currentBalance) / 100 * this.percent
    },
    setPercent(newPercent) {
      if (newPercent !== undefined && newPercent !== null && !isNaN(newPercent)) {
        this.percent = newPercent
        this.changeInputByPercent()
      }
    },
    setSlide(handles) {
      this.percent = parseFloat(handles.values[1])
      this.changeInputByPercent()
    },
    updateVeeno(handles) {
      if (handles !== undefined && handles.handle === 0) {
        handles.positions[1] = handles.positions[0]
        handles.positions[0] = 0
        handles.unencoded[1] = handles.unencoded[0]
        handles.unencoded[0] = 0
        handles.values[1] = handles.values[0]
        handles.values[0] = 0
      }
      if (this.$refs.percentSlider !== undefined) {
        this.$refs.percentSlider.$el.noUiSlider.set(handles.values)
      }
    },
    setWeeks(weeks) {
      this.weeksExtend = weeks
      this.checkweeks()
    },
    checkweeks() {
      const startDate = parseInt(this.pool.info.lockStartTime, 10) !== 0 ? new Date(this.pool.info.lockStartTime * 1000) : new Date()
      const endDate = parseInt(this.pool.info.lockEndTime, 10) !== 0 ? new Date(this.pool.info.lockEndTime * 1000) : new Date()
      const diffTime = Math.abs(endDate - startDate)
      const numberOfWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))
      const updatedNumberOfWeeks = numberOfWeeks + this.weeksExtend
      if (this.weeksExtend <= 0) {
        this.weeksExtend = 1
      }
      if (updatedNumberOfWeeks > 52) {
        this.weeksExtend = 52 - numberOfWeeks
      }
    },
    checkStakeAmount() {
      const currentBalance = this.getCurrentBalance()
      this.percent = this.stakeAmount / parseFloat(currentBalance) * 100
      if (this.percent > 100) {
        this.percent = 100
        this.changeInputByPercent()
      } else if (this.percent < 0) {
        this.percent = 0
        this.changeInputByPercent()
      }
    },
    getCurrentBalance() {
      if (this.action !== 'unstake') {
        return this.pool !== null && this.pool.balance !== null && this.pool.balance !== 0 ? this.pool.balance : 0
      } else {
        const balance = this.pool !== null &&
          this.pool.info.lockedAmount !== null && this.pool.info.lockedAmount !== 0 &&
          this.pool.earned !== null && this.pool.earned !== 0
          ? (this.pool.info.lockedAmount + this.pool.earned)
          : 0
        return balance
        // switch (this.pool.type) {
        //   case 'locked':
        //     return this.pool !== null && this.pool.balance !== null && this.pool.balance !== 0 ? this.pool.balance : 0
        //   case 'flexible':
        //     return this.pool !== null && this.pool.info.lockedAmount !== null && this.pool.info.lockedAmount !== 0 ? this.pool.info.lockedAmount : 0
        // }
      }
    },
    confirmStake() {
      if (this.stakeAmount >= 0.00001) {
        this.loading = true
        this.initLoadingScreen()
        this.$emit('stake', this.pool, this.stakeAmount, this.extendLock, this.weeksExtend)
      }
    },
    confirmUnStake() {
      this.loading = true
      this.initLoadingScreen()
      this.$emit('unstake', this.pool, this.stakeAmount)
    },
    confirmExtend() {
      if (this.weeksExtend > 0) {
        this.loading = true
        this.initLoadingScreen()
        this.$emit('extend', this.weeksExtend)
      }
    },
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    },
    convertToUsd(amount) {
      return this.$priceFormat(parseFloat(amount * this.floatieToUsd), 2)
    },
    getDepositPeriod() {
      const startDate = parseInt(this.pool.info.lockStartTime, 10) !== 0 ? new Date(this.pool.info.lockStartTime * 1000) : new Date()
      const endDate = parseInt(this.pool.info.lockEndTime, 10) !== 0 ? new Date(this.pool.info.lockEndTime * 1000) : new Date()
      const diffTime = Math.abs(endDate - startDate)
      const numberOfWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))
      return numberOfWeeks + ' ' + this.$num2str([this.$t('common.week'), this.$t('common.weeks'), this.$t('common.many_weeks')], numberOfWeeks)
    },
    getUnlockDate() {
      if (this.pool.info === undefined) {
        return '-'
      }
      const initDate = parseInt(this.pool.info.lockEndTime, 10) !== 0 ? new Date(this.pool.info.lockEndTime * 1000) : new Date()
      const extendedTime = this.weeksExtend * 7 * 24 * 60 * 60 * 1000
      initDate.setTime(initDate.getTime() + extendedTime)
      return this.$beautifyDate(initDate)
    },
    getNewUnlockDate() {
      if (this.pool.info === undefined) {
        return '-'
      }
      const startDate = parseInt(this.pool.info.lockStartTime, 10) !== 0 ? new Date(this.pool.info.lockStartTime * 1000) : new Date()
      const endDate = parseInt(this.pool.info.lockEndTime, 10) !== 0 ? new Date(this.pool.info.lockEndTime * 1000) : new Date()
      const diffTime = Math.abs(endDate - startDate)
      const nowDate = new Date()
      const extendedTime = this.weeksExtend * 7 * 24 * 60 * 60 * 1000
      nowDate.setTime(nowDate.getTime() + diffTime + extendedTime)
      return this.$beautifyDate(nowDate)
    },
    closeModal(redirect = false) {
      this.$emit('closeGlobalModal')
      this.returnInitState()
      if (redirect) {
        this.$router.push({
          path: this.localePath('/stake')
        })
      }
    },
    returnInitState() {
      if (this.transaction.pending === false) {
        this.$emit('initConfirmState')
        this.loading = false
        this.loadingScreen = false
        this.successScreen = false
        this.errorScreen = false
        this.stakeAmount = null
        this.weeksExtend = 1
        this.percent = 0
      } else {
        this.initLoadingScreen()
      }
    },
    rejectTransaction(error) {
      if (error === null || typeof error !== 'object') {
        this.initErrorScreen()
      } else {
        switch (error.type.toLowerCase()) {
          case 'denied':
            this.errorText = this.$t('common.deined_operation')
            break
          case 'error':
            this.errorText = error.message !== undefined && error.message.length > 0 ? this.$t('swap.error_text_with_error', { error: error.message }) : this.$t('liquidity.error_text')
            break
          default:
            this.errorText = this.$t('liquidity.error_text')
        }
        this.initErrorScreen()
      }
    },
    initLoadingScreen() {
      this.$emit('initLoading')
      this.loading = false
      this.loadingScreen = true
      this.errorScreen = false
      this.successScreen = false
    },
    initSuccessScreen() {
      this.$emit('initSuccess')
      this.loading = false
      this.loadingScreen = false
      this.errorScreen = false
      this.successScreen = true
      this.stakeAmount = null
      this.weeksExtend = 1
      this.percent = 0
    },
    initErrorScreen() {
      this.$emit('initError')
      this.loading = false
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = true
    }
  }
}
</script>

<style scoped>
  @import url('../../../assets/css/new/modalContent.css');
</style>
