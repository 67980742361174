<template>
  <div class="error-area">
    <picture>
      <source srcset="/img/new/404.webp" type="image/webp">
      <nuxt-img
        fit="inside"
        width="975"
        sizes="sm:300px md:400px lg:500px xl:500px xxl:600px"
        src="/img/new/404.png"
        alt="Page not found on Defi Pool Finance"
        title="Page not found"
      />
    </picture>
    <h1>
      {{ $t('errors.not_found_header') }}
    </h1>
    <p class="desc">
      {{ $t('errors.not_found_desc') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Error404Page',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
  @import url('../../assets/css/new/errors.css');
</style>
