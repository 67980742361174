var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 20 20",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.2987 9.60895L9.14579 6.58968C8.99338 6.47918 8.79106 6.46254 8.62401 6.54841C8.45563 6.63361 8.35047 6.80667 8.35047 6.99371V13.0303C8.35047 13.2193 8.45563 13.3917 8.62401 13.4769C8.69522 13.5128 8.77309 13.5308 8.85162 13.5308C8.95411 13.5308 9.05793 13.4982 9.14579 13.4336L13.2987 10.417C13.4298 10.3205 13.5064 10.1714 13.5064 10.013C13.507 9.8519 13.4285 9.70346 13.2987 9.60895Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M10.0003 0C4.47639 0 0 4.47699 0 10.0017C0 15.5243 4.47639 20 10.0003 20C15.5229 20 20 15.5237 20 10.0017C20.0007 4.47699 15.5229 0 10.0003 0ZM10.0003 18.3313C5.40015 18.3313 1.67049 14.6031 1.67049 10.0017C1.67049 5.4022 5.40015 1.66938 10.0003 1.66938C14.5998 1.66938 18.3288 5.40154 18.3288 10.0017C18.3295 14.6031 14.5998 18.3313 10.0003 18.3313Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }