var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 31 30",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M24.4912 1.69958C23.3302 1.69958 22.3131 2.30539 21.7583 3.20714L16.3567 2.19148L14.8492 9.66769H16.6999L17.7918 4.25199L21.3162 4.91468C21.3916 6.54658 22.7867 7.85187 24.4913 7.85187C26.244 7.85187 27.67 6.47187 27.67 4.7757C27.6699 3.07953 26.2439 1.69958 24.4912 1.69958Z",
          fill: "#FF4340",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M31 14.7883C31 12.492 29.0695 10.6238 26.6966 10.6238C25.8097 10.6238 24.9622 10.8826 24.2477 11.3632C21.8018 9.92563 18.7245 9.13977 15.5 9.13977L14.8945 18.7198L15.5 28.2999C19.0824 28.2999 22.4616 27.3375 25.0151 25.59C27.6642 23.7772 29.123 21.3373 29.123 18.7198C29.123 18.5602 29.1173 18.3995 29.1059 18.2383C30.274 17.4713 31 16.1771 31 14.7883Z",
          fill: "#FF4340",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M6.75237 11.3632C6.0378 10.8826 5.19032 10.6238 4.30343 10.6238C1.93048 10.6237 0 12.4919 0 14.7883C0 16.1771 0.726078 17.4713 1.89415 18.2383C1.88271 18.3995 1.87695 18.5603 1.87695 18.7198C1.87695 21.3374 3.33583 23.7773 5.98488 25.5901C8.53838 27.3375 11.9176 28.2999 15.5 28.2999V9.13977C12.2755 9.13971 9.19828 9.92557 6.75237 11.3632Z",
          fill: "#FD6717",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M19.8496 20.3926C18.698 21.419 17.2752 21.918 15.5 21.918L15.1973 22.7338L15.5 23.6758C17.721 23.6758 19.5984 23.0062 21.0801 21.6855L19.8496 20.3926Z",
          fill: "#F7F0EB",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M11.1504 20.3926L9.91989 21.6855C11.4015 23.0062 13.279 23.6758 15.5 23.6758V21.918C13.7248 21.918 12.302 21.419 11.1504 20.3926Z",
          fill: "#FFFBF5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M9.48419 15.6443H11.3006V18.574H9.48419V15.6443Z",
          fill: "#FFFBF5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M19.6994 15.6443H21.5158V18.574H19.6994V15.6443Z",
          fill: "#F7F0EB",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }