var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "loader",
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2595 5105 c-48 -19 -99 -66 -128 -120 -22 -40 -22 -46 -22 -555 0\n        -563 0 -561 59 -628 61 -70 173 -94 264 -57 55 22 116 93 131 150 7 29 11 206\n        11 533 0 525 -1 533 -52 603 -54 73 -175 107 -263 74z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M962 4426 c-132 -43 -198 -191 -141 -316 26 -59 679 -717 754 -761\n        151 -87 344 23 345 196 0 98 -18 120 -401 501 -195 194 -369 359 -387 368 -46\n        24 -119 28 -170 12z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M255 2771 c-148 -69 -182 -266 -64 -378 68 -64 55 -63 626 -63 483 0\n        521 2 558 19 144 67 181 255 72 370 -69 73 -52 71 -632 71 -486 0 -523 -2\n        -560 -19z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M4201 2764 c-168 -84 -168 -324 0 -408 l53 -26 283 0 c313 0 328 2\n        392 63 95 90 95 244 0 334 -64 61 -79 63 -392 63 l-283 0 -53 -26z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3623 1799 c-140 -25 -222 -164 -178 -300 16 -50 43 -80 384 -421\n        360 -360 367 -367 425 -383 118 -34 235 19 280 127 20 48 20 138 1 186 -17 40\n        -693 726 -751 763 -41 25 -108 37 -161 28z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M1575 1771 c-75 -44 -728 -702 -754 -761 -29 -64 -27 -134 5 -199 57\n        -113 196 -160 309 -104 16 8 189 173 384 367 383 381 401 403 401 501 -1 173\n        -194 283 -345 196z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M2627 1389 c-55 -13 -90 -33 -124 -73 -58 -65 -58 -64 -58 -626 0\n        -513 0 -515 22 -556 100 -188 359 -171 429 28 11 33 14 129 14 530 0 327 -4\n        504 -11 533 -28 111 -160 190 -272 164z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }