<template>
  <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2296 16.7632C22.4966 16.7632 22.713 16.5467 22.713 16.2798V12.7888C22.713 12.5873 22.588 12.4069 22.3993 12.3362L16.6698 10.1877C16.5603 10.1466 16.4398 10.1466 16.3302 10.1877L10.6007 12.3362C10.412 12.407 10.287 12.5873 10.287 12.7888V14.3408C10.287 14.6078 10.5034 14.8242 10.7704 14.8242C11.0375 14.8242 11.2538 14.6078 11.2538 14.3408V13.4864L16.0166 15.2724V21.6622L11.2538 19.8762V16.6021C11.2538 16.3351 11.0375 16.1187 10.7704 16.1187C10.5034 16.1187 10.287 16.3351 10.287 16.6021V20.2112C10.287 20.4127 10.412 20.5931 10.6007 20.6638L16.3302 22.8123C16.3303 22.812 16.3304 22.8114 16.3305 22.811C16.385 22.8314 16.4422 22.8431 16.5001 22.8431C16.5572 22.8431 16.6147 22.833 16.6698 22.8123L22.3993 20.6637C22.588 20.593 22.713 20.4126 22.713 20.2111V18.5329C22.713 18.2659 22.4966 18.0495 22.2296 18.0495C21.9625 18.0495 21.7462 18.2659 21.7462 18.5329V19.8761L16.9834 21.6622V15.2724L21.7462 13.4864V16.2798C21.7462 16.5468 21.9625 16.7632 22.2296 16.7632ZM16.5 14.4211L12.1472 12.7889L16.5 11.1565L20.8528 12.7889L16.5 14.4211Z" fill="currentColor" />
    <path d="M16.0166 4.82496V8.29641C16.0166 8.56337 16.233 8.77981 16.5 8.77981C16.767 8.77981 16.9834 8.56337 16.9834 8.29641V4.82496C18.0962 4.60008 18.9366 3.61472 18.9366 2.43665C18.9366 1.09306 17.8436 0 16.5 0C15.1564 0 14.0633 1.09306 14.0633 2.43665C14.0633 3.61472 14.9038 4.60008 16.0166 4.82496ZM16.5 0.966797C17.3104 0.966797 17.9699 1.62615 17.9699 2.43665C17.9699 3.24715 17.3104 3.9065 16.5 3.9065C15.6896 3.9065 15.0301 3.24715 15.0301 2.43665C15.0301 1.62615 15.6896 0.966797 16.5 0.966797Z" fill="currentColor" />
    <path d="M25.7997 22.4516C25.0506 22.4516 24.3583 22.6989 23.7998 23.1162L22.6427 21.9591C22.4539 21.7703 22.1478 21.7703 21.9591 21.9591C21.7703 22.1478 21.7703 22.4539 21.9591 22.6426L23.1162 23.7998C22.699 24.3582 22.4517 25.0506 22.4517 25.7997C22.4517 27.6459 23.9537 29.1478 25.7998 29.1478C27.6459 29.1478 29.148 27.6459 29.148 25.7997C29.148 23.9535 27.6459 22.4516 25.7997 22.4516ZM25.7997 28.1811C24.4866 28.1811 23.4184 27.1128 23.4184 25.7997C23.4184 24.4867 24.4866 23.4184 25.7997 23.4184C27.1129 23.4184 28.1811 24.4867 28.1811 25.7997C28.1811 27.1128 27.1129 28.1811 25.7997 28.1811Z" fill="currentColor" />
    <path d="M7.20025 10.5484C7.94939 10.5484 8.64175 10.3011 9.20017 9.88382L10.3573 11.0409C10.4517 11.1353 10.5755 11.1826 10.6992 11.1826C10.8228 11.1826 10.9466 11.1354 11.041 11.0409C11.2298 10.8522 11.2298 10.5461 11.041 10.3574L9.88389 9.20023C10.3012 8.64181 10.5485 7.94939 10.5485 7.20032C10.5485 5.35412 9.04645 3.85217 7.20032 3.85217C5.35419 3.85217 3.85217 5.35412 3.85217 7.20032C3.85217 9.04651 5.35406 10.5484 7.20025 10.5484ZM7.20025 4.8189C8.51336 4.8189 9.5816 5.88721 9.5816 7.20025C9.5816 8.51329 8.51336 9.5816 7.20025 9.5816C5.88715 9.5816 4.8189 8.51329 4.8189 7.20025C4.8189 5.88721 5.88715 4.8189 7.20025 4.8189Z" fill="currentColor" />
    <path d="M16.9834 28.175V24.7036C16.9834 24.4366 16.767 24.2202 16.5 24.2202C16.233 24.2202 16.0166 24.4366 16.0166 24.7036V28.175C14.9038 28.3999 14.0633 29.3853 14.0633 30.5634C14.0633 31.9069 15.1564 33 16.5 33C17.8436 33 18.9366 31.9069 18.9366 30.5634C18.9366 29.3853 18.0962 28.3999 16.9834 28.175ZM16.5 32.0332C15.6896 32.0332 15.0301 31.3738 15.0301 30.5634C15.0301 29.7529 15.6896 29.0935 16.5 29.0935C17.3104 29.0935 17.9699 29.7529 17.9699 30.5634C17.9699 31.3738 17.3104 32.0332 16.5 32.0332Z" fill="currentColor" />
    <path d="M30.5634 14.0633C29.3853 14.0633 28.3999 14.9038 28.175 16.0166H24.7036C24.4366 16.0166 24.2202 16.233 24.2202 16.5C24.2202 16.767 24.4366 16.9834 24.7036 16.9834H28.175C28.3999 18.0962 29.3853 18.9366 30.5634 18.9366C31.9069 18.9366 33 17.8436 33 16.5C33 15.1564 31.9069 14.0633 30.5634 14.0633ZM30.5634 17.9699C29.7529 17.9699 29.0935 17.3105 29.0935 16.5C29.0935 15.6895 29.7529 15.0301 30.5634 15.0301C31.3738 15.0301 32.0332 15.6895 32.0332 16.5C32.0332 17.3105 31.3738 17.9699 30.5634 17.9699Z" fill="currentColor" />
    <path d="M4.82496 16.9834H8.29641C8.56344 16.9834 8.77981 16.767 8.77981 16.5C8.77981 16.233 8.56344 16.0166 8.29641 16.0166H4.82496C4.60008 14.9038 3.61472 14.0633 2.43665 14.0633C1.09306 14.0633 0 15.1564 0 16.5C0 17.8436 1.09306 18.9366 2.43665 18.9366C3.61472 18.9366 4.60008 18.0962 4.82496 16.9834ZM2.43665 17.9699C1.62615 17.9699 0.966797 17.3105 0.966797 16.5C0.966797 15.6895 1.62615 15.0301 2.43665 15.0301C3.24715 15.0301 3.90644 15.6895 3.90644 16.5C3.90644 17.3105 3.24708 17.9699 2.43665 17.9699Z" fill="currentColor" />
    <path d="M22.3008 11.1826C22.4245 11.1826 22.5483 11.1354 22.6427 11.0409L23.7998 9.88382C24.3583 10.3011 25.0507 10.5484 25.7997 10.5484C27.6459 10.5484 29.1479 9.04645 29.1479 7.20025C29.1479 5.35406 27.6459 3.85211 25.7997 3.85211C23.9536 3.85211 22.4516 5.35406 22.4516 7.20025C22.4516 7.94939 22.6989 8.64175 23.1162 9.20017L21.959 10.3573C21.7702 10.5461 21.7702 10.8522 21.959 11.0409C22.0534 11.1354 22.1772 11.1826 22.3008 11.1826ZM25.7997 4.8189C27.1129 4.8189 28.1811 5.88721 28.1811 7.20025C28.1811 8.51329 27.1129 9.5816 25.7997 9.5816C24.4866 9.5816 23.4184 8.51329 23.4184 7.20025C23.4184 5.88721 24.4866 4.8189 25.7997 4.8189Z" fill="currentColor" />
    <path d="M10.3573 21.9591L9.20017 23.1162C8.64175 22.6989 7.94933 22.4516 7.20025 22.4516C5.35406 22.4516 3.85211 23.9536 3.85211 25.7997C3.85211 27.6459 5.35412 29.1479 7.20025 29.1479C9.04638 29.1479 10.5484 27.6459 10.5484 25.7997C10.5484 25.0506 10.3011 24.3583 9.88382 23.7998L11.0409 22.6427C11.2297 22.4539 11.2297 22.1478 11.0409 21.9591C10.8522 21.7703 10.5461 21.7703 10.3573 21.9591ZM7.20025 28.1811C5.88715 28.1811 4.8189 27.1128 4.8189 25.7997C4.8189 24.4867 5.88715 23.4184 7.20025 23.4184C8.51336 23.4184 9.5816 24.4867 9.5816 25.7997C9.5816 27.1128 8.51336 28.1811 7.20025 28.1811Z" fill="currentColor" />
  </svg>
</template>
<script>
export default {
  name: 'DecentrilizedIcon'
}
</script>
