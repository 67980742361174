<template>
  <div class="menu-area mobile">
    <div class="main-menu">
      <div class="menu-item">
        <nuxt-link :to="localePath('/swap')">
          {{ $t('common.swap') }}
        </nuxt-link>
      </div>
      <div class="menu-item">
        <nuxt-link :to="localePath('/account')">
          {{ $t('common.invest') }}
        </nuxt-link>
      </div>
      <div class="menu-item">
        <nuxt-link :to="localePath('/listing')">
          {{ $t('common.listing') }}
        </nuxt-link>
      </div>
      <!-- <div class="menu-item">
        <nuxt-link :to="localePath('/limit')">
          {{ $t('common.limit') }}
        </nuxt-link>
      </div> -->
      <div class="menu-item">
        <nuxt-link :to="localePath('/liquidity')">
          {{ $t('common.liquidity') }}
        </nuxt-link>
      </div>
      <div class="menu-item">
        <nuxt-link :to="localePath('/farm')">
          {{ $t('common.farm') }}
        </nuxt-link>
      </div>
      <div class="menu-item">
        <nuxt-link :to="localePath('/stake')">
          {{ $t('common.stake') }}
        </nuxt-link>
      </div>
      <div class="menu-item">
        <nuxt-link :to="localePath('/rewards')">
          {{ $t('common.rewards') }}
        </nuxt-link>
      </div>
      <div class="menu-item">
        <nuxt-link :to="localePath('/analytics')">
          {{ $t('common.analytics') }}
        </nuxt-link>
      </div>
      <div ref="accordion0" class="menu-item accordion mr-0">
        <p class="accordion-header">
          {{ $t('common.game') }}
          <ChevronDown />
        </p>
        <div class="accordion-area">
          <div class="accordion-list">
            <nuxt-link :to="localePath('/lottery')">
              {{ $t('common.lottery') }}
            </nuxt-link>
          </div>
          <!-- <div class="accordion-list">
            <nuxt-link :to="localePath('/prediction')">
              {{ $t('common.prediction') }}
            </nuxt-link>
          </div> -->
        </div>
      </div>
      <div ref="accordion1" class="menu-item accordion mr-0">
        <p class="accordion-header">
          {{ $t('common.about') }}
          <ChevronDown />
        </p>
        <div class="accordion-area">
          <div class="accordion-list">
            <nuxt-link :to="localePath('/contacts')">
              {{ $t('common.contacts') }}
            </nuxt-link>
            <nuxt-link :to="localePath('/faq')">
              {{ $t('common.faq') }}
            </nuxt-link>
            <nuxt-link :to="localePath('/voting')">
              {{ $t('common.voting') }}
            </nuxt-link>
            <nuxt-link :to="localePath('/whitepaper')">
              {{ $t('common.whitepaper') }}
            </nuxt-link>
            <a href="https://medium.com/@info_19215/meeting-with-defipool-5ab219f3be2e" target="_blank" rel="nofollow noreferrer noopener">
              {{ $t('common.blog') }}
            </a>
          </div>
        </div>
      </div>
      <div ref="accordion2" class="menu-item accordion mr-0">
        <p class="accordion-header">
          {{ $t('common.developers') }}
          <ChevronDown />
        </p>
        <div class="accordion-area">
          <div class="accordion-list">
            <a :href="contacts.github" rel="nofollow noreferrer noopener" target="_blank">
              Github
            </a>
            <nuxt-link :to="localePath('/docs')">
              {{ $t('common.documentation') }}
            </nuxt-link>
            <nuxt-link :to="localePath('/bounty')">
              {{ $t('common.bug_bounty') }}
            </nuxt-link>
            <nuxt-link :to="localePath('/audits')">
              {{ $t('common.audits') }}
            </nuxt-link>
            <nuxt-link :to="localePath('/careers')">
              {{ $t('common.careers') }}
            </nuxt-link>
            <nuxt-link :to="localePath('/report')">
              {{ $t('common.report') }}
            </nuxt-link>
          </div>
        </div>
      </div>
      <div ref="accordion3" class="menu-item accordion mr-0">
        <p class="accordion-header">
          {{ $t('common.language') }}
          <ChevronDown />
        </p>
        <div class="accordion-area">
          <div class="accordion-list">
            <nuxt-link
              v-for="locale in availableLocales"
              :key="locale.code"
              :to="switchLocalePath(locale.code)"
              rel="nofollow noreferrer"
            >
              {{ locale.name }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div class="setting-section">
      <div class="divider-horizontal"></div>
      <div class="socials-area">
        <a :href="$config.discord" rel="nofollow noreferrer noopener" target="_blank">
          <DiscordLogo />
        </a>
        <a :href="$config.twitter" rel="nofollow noreferrer noopener" target="_blank">
          <TwitterLogo />
        </a>
        <a :href="$config.discourse" rel="nofollow noreferrer noopener" target="_blank">
          <DiscourseLogo />
        </a>
        <a :href="$config.reddit" rel="nofollow noreferrer noopener" target="_blank">
          <RedditLogo />
        </a>
      </div>
    </div>
    <div :class="`switcher-area ${darkTheme ? 'dark' : 'light'}`" @click="changeTheme">
      <div class="switcher">
        <div class="light">
          <Sun />
        </div>
        <div class="dark">
          <Moon />
        </div>
        <div class="circle">
          <SwitchCircle />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

export default {
  components: {
    Moon: hydrateWhenIdle(() => import('~/components/svg/new/Moon')),
    Sun: hydrateWhenIdle(() => import('~/components/svg/new/Sun')),
    SwitchCircle: hydrateWhenIdle(() => import('~/components/svg/new/SwitchCircle')),
    ChevronDown: hydrateWhenIdle(() => import('~/components/svg/new/ChevronDown')),
    DiscordLogo: hydrateWhenIdle(() => import('~/components/svg/new/DiscordLogo')),
    DiscourseLogo: hydrateWhenIdle(() => import('~/components/svg/new/DiscourseLogo')),
    TwitterLogo: hydrateWhenIdle(() => import('~/components/svg/new/TwitterLogo')),
    RedditLogo: hydrateWhenIdle(() => import('~/components/svg/new/RedditLogo'))
  },
  data() {
    return {
      accordions: 4
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    ...mapGetters('seos', [
      'contacts'
    ]),
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  },
  mounted() {
    for (let i = 0; i < this.accordions; ++i) {
      if (this.$refs['accordion' + i] !== undefined && this.$refs['accordion' + i] !== null) {
        this.$refs['accordion' + i].addEventListener('click', () => this.toggleAccodrion(this.$refs['accordion' + i]))
      }
    }
  },
  beforeDestroy() {
    for (let i = 0; i < this.accordions; ++i) {
      if (this.$refs['accordion' + i] !== undefined && this.$refs['accordion' + i] !== null) {
        this.$refs['accordion' + i].querySelector('.accordion-area').style.maxHeight = null
        this.$refs['accordion' + i].removeEventListener('click', this.toggleAccodrion)
      }
    }
  },
  methods: {
    changeTheme() {
      if (!this.darkTheme) {
        this.$onTheme()
      } else {
        this.$offTheme()
      }
    },
    toggleAccodrion(accordion) {
      accordion.classList.toggle('active')
      if (accordion.classList.contains('active')) {
        accordion.querySelector('.accordion-area').style.maxHeight = accordion.querySelector('.accordion-area').scrollHeight + 'px'
      } else {
        accordion.querySelector('.accordion-area').style.maxHeight = null
      }
    }
  }
}
</script>

<style>
  @import url('../../assets/css/new/nav.css');
</style>
