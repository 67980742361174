var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalArea",
      class: { "modal-container": true, active: _vm.modalVoteOpen },
    },
    [
      _c("div", { staticClass: "modal-wrap" }, [
        _c("div", { ref: "modalInner", staticClass: "modal-content-wrap" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "h2" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      !_vm.descScreen
                        ? _vm.$t("voting.confirm_vote")
                        : _vm.$t("voting.voting_power")
                    ) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "close-area", on: { click: _vm.closeModal } },
                [_c("Plus")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.descScreen
              ? _c(
                  "div",
                  { ref: "innerContainer", staticClass: "modal-inner" },
                  [
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("voting.voting_desc")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-row" }, [
                      _c("div", { staticClass: "header" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("voting.overview")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "overview" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("voting.your_voting_power")) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.power.loading
                          ? _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$formatCryptoAmount(_vm.power.sum, 8)
                                  ) +
                                  "\n              "
                              ),
                            ])
                          : _c(
                              "div",
                              { staticClass: "value" },
                              [
                                _c("Skeleton", {
                                  attrs: { width: "150px", height: "30px" },
                                }),
                              ],
                              1
                            ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-row" }, [
                      _c("div", { staticClass: "header" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("voting.your_voting_power_at_block")
                            ) +
                            "\n              "
                        ),
                        _c(
                          "a",
                          {
                            ref: "nofollow noopener noreferrer",
                            staticClass: "value",
                            attrs: {
                              href: _vm.$getExplorerLink(
                                "block",
                                _vm.proposal.block
                              ),
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.proposal.block) +
                                "\n                "
                            ),
                            _c("i", { staticClass: "bx bx-link-external" }),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    !_vm.power.loading
                      ? _c("div", { staticClass: "modal-table" }, [
                          _c("div", { staticClass: "table-row" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("voting.wallet")) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$formatCryptoAmount(_vm.power.wallet, 8)
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "table-row" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("voting.fixed_staking")) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$formatCryptoAmount(_vm.power.fixed, 8)
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "table-row" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("voting.flexible_staking")) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$formatCryptoAmount(
                                      _vm.power.flexible,
                                      8
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "table-row" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("voting.other_pools")) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$formatCryptoAmount(
                                      _vm.power.staking,
                                      8
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                        ])
                      : _c(
                          "div",
                          { staticClass: "modal-table" },
                          _vm._l(4, function (ind) {
                            return _c(
                              "div",
                              { key: ind, staticClass: "table-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "label" },
                                  [
                                    _c("Skeleton", {
                                      attrs: { width: "100px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "value" },
                                  [
                                    _c("Skeleton", {
                                      attrs: { width: "50px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                    _vm._v(" "),
                    _c("div", { staticClass: "buttons-area" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn cyan btn-outline",
                          attrs: { type: "button" },
                          on: { click: _vm.toggleDesc },
                        },
                        [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("common.back")) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  { ref: "innerContainer", staticClass: "modal-inner" },
                  [
                    _c("div", { staticClass: "modal-row" }, [
                      _c("div", { staticClass: "header" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("voting.voting_for")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.vote.choice.title) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal-row power-area",
                        on: { click: _vm.toggleDesc },
                      },
                      [
                        _c("div", { staticClass: "header" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("voting.your_voting_power")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.power.loading
                          ? _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$formatCryptoAmount(_vm.power.sum, 8)
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _c(
                              "div",
                              { staticClass: "value" },
                              [
                                _c("Skeleton", {
                                  attrs: { width: "150px", height: "30px" },
                                }),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _vm._m(0),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.power.sum !== 0
                      ? _c("p", { staticClass: "desc" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("voting.cannot_be_undone")) +
                              "\n          "
                          ),
                        ])
                      : !_vm.power.loading
                      ? _c("div", { staticClass: "warning" }, [
                          _c("div", { staticClass: "icon" }, [_c("Warn")], 1),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-area" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("voting.low_balance")) +
                                "\n            "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "buttons-area" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn cyan btn-outline",
                          attrs: { type: "button" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("common.cancel")) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          class: {
                            "btn pink": true,
                            disabled:
                              _vm.power.sum === 0 || _vm.loading || _vm.voted,
                          },
                          attrs: {
                            type: "button",
                            disabled:
                              _vm.power.sum === 0 || _vm.loading || _vm.voted,
                          },
                          on: { click: _vm.voteForChoice },
                        },
                        [
                          !_vm.loading
                            ? _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("voting.confirm_cta")) +
                                    "\n              "
                                ),
                              ])
                            : _c("span", [
                                _c("i", {
                                  staticClass: "bx bx-loader-alt loading",
                                }),
                              ]),
                        ]
                      ),
                    ]),
                  ]
                ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "more-info" }, [
      _c("i", { staticClass: "bx bxs-chevron-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }