var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 18 18",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.00612 3.44968L1.68238 2.05427C1.59299 1.63787 1.22476 1.34845 0.810778 1.36927C0.272293 1.39572 -0.107074 1.92363 0.0271638 2.46077L0.873865 6.06811C0.983781 6.53636 1.441 6.82502 1.89655 6.71376L5.40455 5.84942C6.50913 5.57377 6.10737 3.8693 5.00278 4.14486L3.31602 4.56326C4.48377 3.04612 6.20461 1.98601 8.22689 1.76387C12.2259 1.32454 15.7954 4.29209 16.2224 8.40633C16.3142 9.60543 18.0746 9.41015 17.9158 8.21861C17.424 3.48035 13.5035 -0.0481237 8.50995 0.000496381C5.57846 0.298629 3.44747 1.59253 2.00612 3.44968ZM12.5816 12.1247C11.4315 12.367 11.8466 14.1483 12.9784 13.8277L14.6635 13.4092C13.4957 14.9258 11.7751 15.9813 9.75261 16.2035C5.75358 16.6429 2.18578 13.6821 1.75875 9.56782C1.7178 9.10985 1.33905 8.76271 0.892127 8.77359C0.389953 8.78681 0.00841141 9.24286 0.0703288 9.75574C0.594918 14.8099 5.02096 18.4871 9.93357 17.9474C12.4052 17.6761 14.5337 16.3778 15.975 14.5229L16.3038 15.92C16.5108 17.1431 18.3079 16.7004 17.954 15.5135L17.1123 11.9044C17.0166 11.4881 16.6422 11.2041 16.1552 11.246L12.5816 12.1247Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M10.24 11.3645C10.3228 11.4259 10.4213 11.4545 10.5198 11.4545C10.6519 11.4545 10.7817 11.4014 10.869 11.3011C11.0234 11.1252 10.9921 10.8675 10.7996 10.7264L8.72902 9.21272V6.13636C8.72902 5.91135 8.52757 5.72727 8.28134 5.72727C8.03511 5.72727 7.83366 5.91135 7.83366 6.13636V9.40909C7.83366 9.53388 7.89635 9.65046 8.00154 9.72817L10.24 11.3645Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }