var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading-area" }, [
      _c("div", { staticClass: "loader" }, [
        _c("div", { staticClass: "circle" }, [
          _c("picture", [
            _c("source", {
              attrs: {
                srcset: "/img/new/loading.gif",
                media: "(min-width: 1024px)",
              },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: {
                src: "/img/new/loading_mobile.gif",
                title: "Loading transaction",
                alt: "DeFiPool loading blockchain transaction",
              },
            }),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "1" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "2" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "3" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "4" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "5" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "6" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "7" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "8" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "9" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "10" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "11" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "12" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "13" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "14" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "15" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "16" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "17" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "18" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "19" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "20" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "21" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "22" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "23" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "24" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "25" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "26" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "27" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "28" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "29" } }),
          _vm._v(" "),
          _c("span", { staticStyle: { "--i": "30" } }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }