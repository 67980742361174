var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalArea",
      class: { "modal-container settings": true, active: _vm.modalOpen },
    },
    [
      _c("div", { staticClass: "modal-wrap" }, [
        _c("div", { ref: "modalInner", staticClass: "modal-content-wrap" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "h2" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.settings")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "close-area", on: { click: _vm.closeModal } },
                [_c("Plus")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "settings-area" }, [
              _c("div", { staticClass: "h6" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.global")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "text-area" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common.dark_mode")) +
                        "\n              "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value-area" }, [
                  _c(
                    "div",
                    {
                      class: `switcher-area ${
                        _vm.darkTheme ? "dark" : "light"
                      }`,
                      on: { click: _vm.changeTheme },
                    },
                    [
                      _c("div", { staticClass: "switcher" }, [
                        _c("div", { staticClass: "light" }, [_c("Sun")], 1),
                        _vm._v(" "),
                        _c("div", { staticClass: "dark" }, [_c("Moon")], 1),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "circle" },
                          [_c("SwitchCircle")],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "text-area" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common.tx_speed")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "hint-icon wide-tooltip",
                      attrs: { tooltip: _vm.$t("common.tx_speed_hint") },
                    },
                    [_c("Question")],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "range-switch-area" }, [
                _c(
                  "div",
                  {
                    class: {
                      "switch-item": true,
                      active: _vm.speed === "standard",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSpeed("standard")
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.standard")) +
                          " (~5)\n              "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "switch-item": true,
                      active: _vm.speed === "fast",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSpeed("fast")
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.fast")) +
                          " (~6)\n              "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "switch-item": true,
                      active: _vm.speed === "instant",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSpeed("instant")
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.instant")) +
                          " (~7)\n              "
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "settings-area mb-4" }, [
              _c("div", { staticClass: "h6" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.swaps_liquidity")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "text-area" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("swap.slippage")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "hint-icon wide-tooltip",
                      attrs: { tooltip: _vm.$t("swap.slippage_desc") },
                    },
                    [_c("Question")],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "range-switch-area mb-4" },
                [
                  _vm._l(_vm.slippageTolerances, function (tolerance, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: {
                          "switch-item": true,
                          active: _vm.slippageTolerance === tolerance,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setSlippageToleranceAction(tolerance)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(tolerance) +
                            "%\n            "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-area" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.slippageTolerance,
                          expression: "slippageTolerance",
                        },
                      ],
                      class: {
                        active: !_vm.slippageTolerances.includes(
                          _vm.slippageTolerance
                        ),
                      },
                      attrs: { type: "number", placeholder: "0.5..." },
                      domProps: { value: _vm.slippageTolerance },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.slippageTolerance = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v("%")]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "text-area" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("swap.transaction_deadline")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "hint-icon wide-tooltip",
                      attrs: {
                        tooltip: _vm.$t("swap.transaction_deadline_desc"),
                      },
                    },
                    [_c("Question")],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value-area" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.deadline,
                        expression: "deadline",
                      },
                    ],
                    attrs: {
                      type: "number",
                      placeholder: "30...",
                      max: "999",
                      min: "1",
                      step: "1",
                    },
                    domProps: { value: _vm.deadline },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.deadline = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }