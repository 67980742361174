import { render, staticRenderFns } from "./Calculator.vue?vue&type=template&id=a753ff12&"
import script from "./Calculator.vue?vue&type=script&lang=js&"
export * from "./Calculator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a753ff12')) {
      api.createRecord('a753ff12', component.options)
    } else {
      api.reload('a753ff12', component.options)
    }
    module.hot.accept("./Calculator.vue?vue&type=template&id=a753ff12&", function () {
      api.rerender('a753ff12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/svg/new/Calculator.vue"
export default component.exports