import * as cryptoJS from 'crypto-js'

import tokensList from '~/assets/lists/testnet.json'

import PeriodPair from '~/assets/abis/PeriodPair.json'
import PeriodFactory from '~/assets/abis/PeriodFactory.json'
import PeriodToken from '~/assets/abis/PeriodToken.json'
import ERC20 from '~/assets/abis/ERC20.json'

export default (context, inject) => {
  const BSC_BLOCK_TIME = 3
  const BLOCKS_PER_YEAR = 60 / BSC_BLOCK_TIME * 60 * 24 * 365

  const i18n = context.app.i18n
  if (process.client) {
    ;(function() {
      const throttle = function(type, name, obj) {
        const object = obj || window
        let running = false
        const func = function() {
          if (running) { return }
          running = true
          requestAnimationFrame(function() {
            object.dispatchEvent(new CustomEvent(name))
            running = false
          })
        }
        object.addEventListener(type, func)
      }
      throttle('scroll', 'optimizedScroll')
    })()
  }
  function changeNullValues(jsonObj) {
    for (const property in jsonObj) {
      jsonObj[property] = jsonObj[property] !== null ? jsonObj[property] : ''
    }
    return jsonObj
  }
  function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16)
    })
  }
  async function urlExists(url) {
    try {
      const http = await context.$axios.$head(url)
        .catch(() => {
          return false
        })
      return new Promise((resolve) => {
        resolve(http.status !== 404)
      })
    } catch (err) {
      return false
    }
  }
  inject('urlExists', urlExists)
  function getTokenHeader(dataObject) {
    const params = new URLSearchParams(changeNullValues(dataObject))
    return cryptoJS
      .HmacSHA512(
        fixedEncodeURIComponent(params.toString()).toLowerCase(),
        context.$config.appSecret
      )
      .toString()
  }
  inject('getTokenHeader', getTokenHeader)
  function copy(evt, text) {
    evt.stopPropagation()
    navigator.clipboard.writeText(text)
  }
  inject('copy', copy)
  inject('getBigNumber', function(number, digits = 1) {
    if (number < 1000) {
      return number
    }
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'common.k' },
      { value: 1e6, symbol: 'common.M' },
      { value: 1e9, symbol: 'common.B' },
      { value: 1e12, symbol: 'common.T' }
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup.slice().reverse().find(function(item) {
      return number >= item.value
    })
    return item ? (number / item.value).toFixed(digits).replace(rx, '$1') + i18n.t(item.symbol) : '0'
  })
  function getDateDayAndMonth(dateInfo) {
    if (dateInfo === null) {
      return '...'
    }

    const dateNew = new Date(dateInfo)
    return (
      ('0' + dateNew.getDate()).slice(-2) +
      '.' +
      ('0' + (dateNew.getMonth() + 1)).slice(-2)
    )
  }
  inject('getDateDayAndMonth', getDateDayAndMonth)
  inject('getFormatDate', function(dateInfo) {
    if (dateInfo === null) {
      return '...'
    }

    const dateNew = new Date(dateInfo)
    return (
      ('0' + dateNew.getDate()).slice(-2) +
      '.' +
      ('0' + (dateNew.getMonth() + 1)).slice(-2) +
      '.' +
      dateNew.getFullYear() +
      ', ' +
      ' ' +
      (dateNew.getHours() < 10 ? '0' : '') +
      dateNew.getHours() +
      ':' +
      (dateNew.getMinutes() < 10 ? '0' : '') +
      dateNew.getMinutes()
    )
  })
  inject('getDateString', function(dateInfo, withTime = false) {
    if (dateInfo === null) {
      return '...'
    }

    const dateNew = new Date(dateInfo)
    let answer = dateNew.getFullYear() + '-' + ('0' + (dateNew.getMonth() + 1)).slice(-2) + '-' +
      ('0' + dateNew.getDate()).slice(-2)
    if (withTime) {
      answer += 'T' + (dateNew.getHours() < 10 ? '0' : '') +
      dateNew.getHours() + ':' +
      (dateNew.getMinutes() < 10 ? '0' : '') +
      dateNew.getMinutes() + ':00Z'
    }
    return answer
  })
  inject('getTimestamp', function(dateInfo) {
    if (dateInfo === null) {
      return Math.floor(new Date().getTime() / 1000)
    }

    const dateNew = new Date(dateInfo)
    return Math.floor(dateNew.getTime() / 1000)
  })
  function formatSpaces(number, isMoney = false, separator = '\xA0') {
    if (number === undefined || number === null) {
      return '...'
    }
    if (isMoney) {
      number = Math.round((number + Number.EPSILON) * 100) / 100
    }
    const afterComma = number.toString().split('.')
    const result = Number(afterComma[0]).toFixed(0)
    if (afterComma[1] !== undefined && afterComma[1] !== '00') {
      return (
        result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator) +
        '.' +
        afterComma[1]
      )
    } else {
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
    }
  }
  inject('formatSpaces', formatSpaces)
  inject('largeNumberToFixed', function(number, toFixed = 0) {
    if (Math.abs(number) < 1) {
      let exponent = parseInt(number.toString().split('e-')[1])
      if (exponent) {
        if (toFixed !== 0) {
          exponent = toFixed
        }
        number *= Math.pow(10, exponent - 1)
        number = '0.' + (new Array(exponent)).join('0') + number.toString().substring(2)
      } else {
        number = Number(number).toFixed(toFixed)
      }
    } else {
      let exponent = parseInt(number.toString().split('+')[1])
      if (exponent > 20) {
        exponent -= 20
        number /= Math.pow(10, exponent)
        number += (new Array(exponent + 1)).join('0')
      } else {
        number = Number(number).toFixed(toFixed)
      }
    }
    return number
  })
  async function setXSRFCookie() {
    const apiBase = context.$config.mediaUrl.substr(0, context.$config.mediaUrl.length - 1)
    const xsrf = await context.$axios.$get(apiBase + '/sanctum/csrf-cookie')
    context.app.$cookies.set('XSRF-TOKEN', xsrf)
    return new Promise((resolve) => {
      resolve(true)
    })
  }
  inject('setXSRFCookie', setXSRFCookie)
  inject('fetchForPair', async (address) => {
    const request = await generateApiRequest()
    const response = await request.$get('tokens/pair/' + address, {
      address
    })
    return new Promise((resolve) => {
      resolve(response.answer.tokens)
    })
  })
  inject('metamaskNonce', async (withRedirect = false) => {
    if (window.eth === undefined) {
      return 0
    }
    const address = await getUserAddress()
    await setXSRFCookie()
    const request = await generateApiRequest()
    return new Promise((resolve) => {
      request.$post('users/nonce', {
        address
      }).then(async (response) => {
        let message = i18n.t('auth.login_metamask_message')
        message += i18n.t('auth.current_wallet_address') + ': ' + address + '\n\n'
        message += i18n.t('auth.nonce') + ': ' + response.answer.nonce + '\n\n'
        try {
          const signature = await window.eth.eth.personal.sign(message, address)
          context.app.store.dispatch('auth/metamask', {
            message,
            address,
            signature
          }).then(() => {
            context.$cookies.set('recent', 'metamask')
            if (withRedirect) {
              context.app.router.push({
                path: context.app.localePath('/swap')
              })
            }
            closeModals()
            resolve(true)
          }).catch((error) => {
            resolve(false)
            console.log(error)
          })
        } catch (err) {
          resolve(false)
          console.log(err)
        }
      })
    })
  })
  function closeModals() {
    context.app.store.commit('modal/closeAll')
  }
  inject('closeModals', closeModals)
  async function logoutFromWallet() {
    clearCookies()
    await this.$store.dispatch('auth/logout')
  }
  inject('logoutFromWallet', logoutFromWallet)
  function setHeaders(headers) {
    const userToken = context.app.store.getters['auth/userToken']
    if (userToken !== undefined && userToken !== null && userToken.length > 0) {
      headers.Authorization = `Bearer ${userToken}`
      return headers
    }
  }
  inject('setHeaders', setHeaders)
  inject('signTypedData', (web3, from, data) => {
    return new Promise((resolve, reject) => {
      function cb(err, result) {
        if (err) {
          return reject(err)
        }
        if (result.error) {
          return reject(result.error)
        }

        const sig = result.result
        const sig0 = sig.substring(2)
        const r = '0x' + sig0.substring(0, 64)
        const s = '0x' + sig0.substring(64, 128)
        const v = parseInt(sig0.substring(128, 130), 16)

        resolve({
          data,
          sig,
          v,
          r,
          s
        })
      }
      if (web3.currentProvider.isMetaMask) {
        web3.currentProvider.sendAsync({
          jsonrpc: '2.0',
          method: 'eth_signTypedData_v4',
          params: [from, JSON.stringify(data)]
        }, cb)
      } else {
        let send = web3.currentProvider.sendAsync
        if (!send) {
          send = web3.currentProvider.send
        }
        send.bind(web3.currentProvider)({
          jsonrpc: '2.0',
          method: 'eth_signTypedData',
          params: [from, data]
        }, cb)
      }
    })
  })
  inject('closeMessageDialog', function(event) {
    let parentMessage = event.target.parentElement
    if (parentMessage.classList.contains('message')) {
      parentMessage.classList.add('closed')
      setTimeout(function() {
        parentMessage.classList.add('closed-full')
      }, 200)
    } else {
      parentMessage = parentMessage.parentElement
      if (!parentMessage.classList.contains('message')) {
        parentMessage = parentMessage.parentElement
      }
      if (!parentMessage.classList.contains('message')) {
        parentMessage = parentMessage.parentElement
      }
      parentMessage.classList.add('closed')
      setTimeout(function() {
        parentMessage.classList.add('closed-full')
      }, 200)
    }
  })
  inject('downgradeNumber', function(event, step, limit) {
    const numberValue = event.target.parentElement.querySelector('input')
    if (parseFloat(numberValue.value) > limit) {
      numberValue.value = parseFloat(numberValue.value) - step
      event.target.parentElement
        .querySelector('.right-button')
        .classList.remove('disabled')
      if (parseFloat(numberValue.value) === limit) {
        event.target.classList.add('disabled')
      }
    } else {
      event.target.classList.add('disabled')
    }
  })
  inject('upgradeNumber', function(event, step, limit) {
    const numberValue = event.target.parentElement.querySelector('input')
    if (parseFloat(numberValue.value) < limit) {
      numberValue.value = parseFloat(numberValue.value) + step
      event.target.parentElement
        .querySelector('.left-button')
        .classList.remove('disabled')
      if (parseFloat(numberValue.value) === limit) {
        event.target.classList.add('disabled')
      }
    } else {
      event.target.classList.add('disabled')
    }
  })
  inject('getErrorsText', (errors) => {
    /* eslint-disable */
    let textWarning = []
    /* eslint-enable */
    if (Object.keys(errors).length > 0) {
      for (const erorr in errors) {
        textWarning.push(erorr + ' - ' + errors[erorr][0])
      }
    } else if (errors.length > 0) {
      for (let itr = 0; itr < errors.length; ++itr) {
        textWarning.push(errors[itr])
      }
    }
    return textWarning
  })
  function isChecksumAddress(address) {
    // Check each case
    address = address.replace('0x', '')
    const addressHash = cryptoJS.SHA3(address.toLowerCase())
    for (let ind = 0; ind < 40; ++ind) {
      // the nth letter should be uppercase if the nth digit of casemap is 1
      if ((parseInt(addressHash[ind], 16) > 7 && address[ind].toUpperCase() !== address[ind]) || (parseInt(addressHash[ind], 16) <= 7 && address[ind].toLowerCase() !== address[ind])) {
        return false
      }
    }
    return true
  }
  function isAddress(address) {
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
      // check if it has the basic requirements of an address
      return false
    } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
      // If it's all small caps or all all caps, return true
      return true
    } else {
      // Otherwise check each case
      return isChecksumAddress(address)
    }
  }
  inject('isAddress', isAddress)
  inject('isChecksumAddress', isChecksumAddress)
  inject('shortenTitle', function(title, chars = 16) {
    if (title !== undefined && title !== null) {
      return (title.length > chars) ? title.substr(0, chars) + '…' : title
    } else {
      return ''
    }
  })
  function changeImageExtension(fileItem, webpSupport = false, isThumb = false) {
    const fileExtesion = fileItem.substr(fileItem.lastIndexOf('.') + 1, fileItem.length - fileItem.lastIndexOf('.') + 1) || fileItem
    const extension = webpSupport ? 'webp' : fileExtesion
    if (!isThumb) {
      return fileItem.substr(0, fileItem.length - fileExtesion.length) + extension
    } else {
      return fileItem.substr(0, fileItem.length - fileExtesion.length - 1) + '_thumb.' + extension
    }
  }
  inject('changeImageExtension', changeImageExtension)
  function supportsWebp() {
    if (process.client) {
      const elem = document.createElement('canvas')
      if (elem.getContext && elem.getContext('2d')) {
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
      } else {
        return false
      }
    } else {
      return false
    }
  }
  inject('supportsWebp', supportsWebp)
  inject('shortenAddress', (address) => {
    if (address !== undefined && address !== null && address.length > 10) {
      return address.substr(0, 8) + '...' + address.substr(-5)
    } else {
      return address
    }
  })
  inject('calculateUSD', (amount, tokenPrice) => {
    return amount * tokenPrice
  })
  function numberToString(number, textForms) {
    number = Math.abs(number) % 100
    const numberForm = number % 10
    if (number > 10 && number < 20) {
      return textForms[2]
    } else if (numberForm > 1 && numberForm < 5) {
      return textForms[1]
    } else if (numberForm === 1) {
      return textForms[0]
    }
    return textForms[2]
  }
  inject('numberToString', numberToString)
  function beautifyDate(date, showFull = false) {
    if (date === null) {
      return '...'
    }

    const dateObj = new Date(date)
    const nowTime = new Date()
    let hours = dateObj.getHours()
    const timeText = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours !== 0 ? hours : 12
    if (!showFull &&
      dateObj.getDate() === nowTime.getDate() &&
      dateObj.getMonth() === nowTime.getMonth() &&
      dateObj.getFullYear() === nowTime.getFullYear()) {
      return hours + ':' + (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes() + ' ' + timeText
    } else {
      return (
        ('0' + dateObj.getDate()).slice(-2) +
        '.' +
        ('0' + (dateObj.getMonth() + 1)).slice(-2) +
        '.' +
        dateObj.getFullYear() +
        ', ' +
        ' ' +
        hours + ':' +
        (dateObj.getMinutes() < 10 ? '0' : '') +
        dateObj.getMinutes() + ' ' + timeText
      )
    }
  }
  inject('beautifyDate', beautifyDate)
  inject('realisticDate', (date, hidePast = false) => {
    if (date === null) {
      return '...'
    }

    const sentDate = new Date(date)
    let seconds = Math.floor((new Date() - sentDate) / 1000)
    let dateText = 'past'
    if (sentDate > new Date()) {
      seconds = Math.floor((sentDate - new Date()) / 1000)
      dateText = 'future'
    }
    if (hidePast && dateText === 'past') {
      return '-'
    }
    let interval = seconds / 31536000
    if (interval > 1) {
      return beautifyDate(date)
    }
    interval = seconds / 2592000
    if (interval > 1) {
      const monthsText = numberToString(Math.floor(interval), [i18n.t('common.month'), i18n.t('common.months'), i18n.t('common.many_months')])
      return dateText === 'past'
        ? beautifyDate(date)
        : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + monthsText
    }
    interval = seconds / 86400
    if (interval > 1) {
      const daysText = numberToString(Math.floor(interval), [i18n.t('common.day'), i18n.t('common.days'), i18n.t('common.many_days')])
      return dateText === 'past'
        ? beautifyDate(date)
        : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + daysText
    }
    interval = seconds / 3600
    if (interval > 1) {
      const hoursText = numberToString(Math.floor(interval), [i18n.t('common.hour'), i18n.t('common.hours'), i18n.t('common.many_hours')])
      return dateText === 'past'
        ? Math.floor(interval) + ' ' + hoursText + ' ' + i18n.t('common.ago')
        : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + hoursText
    }
    interval = seconds / 60
    if (interval > 1) {
      const minutesText = numberToString(Math.floor(interval), [i18n.t('common.minute'), i18n.t('common.minutes'), i18n.t('common.many_minutes')])
      return dateText === 'past'
        ? Math.floor(interval) + ' ' + minutesText + ' ' + i18n.t('common.ago')
        : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + minutesText
    }
    const secondsText = numberToString(Math.floor(interval), [i18n.t('common.second'), i18n.t('common.seconds'), i18n.t('common.many_seconds')])
    return dateText === 'past'
      ? Math.floor(interval) + ' ' + secondsText + ' ' + i18n.t('common.ago')
      : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + secondsText
  })
  inject('stringifyDate', (date, withTime = false, short = false) => {
    if (date === null) {
      return '...'
    }

    const dateNew = new Date(date)
    const monthNames = short
      ? [
          i18n.t('common.jan'),
          i18n.t('common.feb'),
          i18n.t('common.mar'),
          i18n.t('common.apr'),
          i18n.t('common.may'),
          i18n.t('common.jun'),
          i18n.t('common.jul'),
          i18n.t('common.aug'),
          i18n.t('common.sep'),
          i18n.t('common.oct'),
          i18n.t('common.nov'),
          i18n.t('common.dec')
        ]
      : [
          i18n.t('common.january'),
          i18n.t('common.february'),
          i18n.t('common.march'),
          i18n.t('common.april'),
          i18n.t('common.may'),
          i18n.t('common.june'),
          i18n.t('common.july'),
          i18n.t('common.august'),
          i18n.t('common.september'),
          i18n.t('common.october'),
          i18n.t('common.november'),
          i18n.t('common.december')
        ]

    if (!withTime) {
      return monthNames[dateNew.getMonth()] + ' ' + ('0' + dateNew.getDate()).slice(-2) + ', ' + dateNew.getFullYear()
    } else {
      const ampm = dateNew.getHours() >= 12 ? 'PM' : 'AM'
      const hours = dateNew.getHours() % 12
      return monthNames[dateNew.getMonth()] + ' ' + ('0' + dateNew.getDate()).slice(-2) + ', ' + dateNew.getFullYear() +
        ' ' + i18n.t('common.at') + ' ' + (hours !== 0 ? hours : 12) + ':' + ('0' + dateNew.getMinutes()).slice(-2) +
        ' ' + ampm
    }
  })
  function treatAsUTC(date) {
    const result = new Date(date)
    return result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
  }
  inject('treatAsUTC', treatAsUTC)
  inject('daysBetweenDates', (startDate, endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000
    return Math.round(Math.abs(treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay)
  })
  function clearCookies(file) {
    const accept = context.app.$cookies.get('cookiesAccept')
    const recent = context.app.$cookies.get('recent')
    const tokensData = context.$cookies.get(context.$config.tokensStorageName)
    const listsData = context.$cookies.get(context.$config.listsStorageName)
    const userTransactions = context.$cookies.get(context.$config.transactionsStorageName)
    const userData = context.$cookies.get(context.$config.userStorageName)
    delete userData.address
    delete userData.chainId
    context.app.$cookies.removeAll()
    context.app.$cookies.set('cookiesAccept', accept)
    context.app.$cookies.set('recent', recent)
    context.app.$cookies.set(context.$config.tokensStorageName, tokensData)
    context.app.$cookies.set(context.$config.listsStorageName, listsData)
    context.app.$cookies.set(context.$config.transactionsStorageName, userTransactions)
    context.app.$cookies.set(context.$config.userStorageName, userData)
  }
  inject('clearCookies', clearCookies)
  function formatCryptoAmount(number, max = null, isNumber = false) {
    const initNumber = parseFloat(number)
    if (isNaN(initNumber)) {
      return '...'
    }
    if (number !== null && number !== undefined && typeof initNumber === 'number') {
      const stringAmount = initNumber.toString().split('.')
      const decPoint = isNumber ? '' : ','
      if (initNumber > 1) {
        return priceFormat(initNumber, 2, '.', decPoint)
      }
      if (stringAmount[1] !== undefined) {
        let zerosAfterComma = 0
        const detailedAmount = initNumber.toPrecision(15).toString().split('.')
        for (const char of detailedAmount[1].split('')) {
          if (char === '0') {
            ++zerosAfterComma
          } else {
            break
          }
        }
        if (zerosAfterComma === detailedAmount[1].length) {
          zerosAfterComma = 0
        }
        if (zerosAfterComma > 0) {
          let denominator = zerosAfterComma
          if (max !== null && zerosAfterComma > max) {
            denominator = max
          } else {
            denominator += 2
          }
          return number !== null ? priceFormat(initNumber, denominator, '.', decPoint) : '...'
        } else {
          return number !== null ? priceFormat(initNumber, 2, '.', decPoint) : '...'
        }
      } else {
        return number
      }
    } else {
      return '...'
    }
  }
  inject('formatCryptoAmount', formatCryptoAmount)
  inject('timeSince', (date) => {
    let dateToChange = date
    const parsedDate = date.split(' ')
    if (parsedDate.length > 1) {
      dateToChange = new Date(parsedDate[0] + 'T' + parsedDate[1] + 'Z')
    }
    const seconds = Math.floor((new Date() - new Date(dateToChange)) / 1000)

    let interval = seconds / 31536000

    if (interval > 1) {
      return beautifyDate(dateToChange)
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return beautifyDate(dateToChange)
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' ' + num2str([i18n.t('common.day'), i18n.t('common.days'), i18n.t('common.many_days')], Math.floor(interval))
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' ' + num2str([i18n.t('common.hour'), i18n.t('common.hours'), i18n.t('common.many_hours')], Math.floor(interval))
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' ' + num2str([i18n.t('common.minute'), i18n.t('common.minutes'), i18n.t('common.many_minutes')], Math.floor(interval))
    }
    return Math.floor(interval) + ' ' + num2str([i18n.t('common.second'), i18n.t('common.seconds'), i18n.t('common.seconds')], Math.floor(interval))
  })
  inject('ratePriceImpact', (priceImpact) => {
    const impactPercent = parseFloat(priceImpact)
    if (impactPercent < 1) {
      return 'low'
    } else if (impactPercent >= 1 && impactPercent < 5) {
      return 'normal'
    } else {
      return 'high'
    }
  })
  function num2str(textForms, n) {
    n = Math.abs(n) % 100
    const n1 = n % 10
    if (n > 10 && n < 20) { return textForms[2] }
    if (n1 > 1 && n1 < 5) { return textForms[1] }
    if (n1 === 1) { return textForms[0] }
    return textForms[2]
  }
  inject('num2str', num2str)
  function preciseTokenValue(number) {
    let priceValue = parseFloat(number)
    if (isNaN(priceValue)) {
      return number
    }
    const stringPrice = Number(priceValue).toPrecision(10).toString().split('.')
    const zeros = stringPrice[1]
    let zerosAfterComma = 0
    if (zeros !== undefined) {
      for (const char of zeros.split('')) {
        if (char === '0') {
          ++zerosAfterComma
        } else {
          break
        }
      }
      if (zerosAfterComma > 0 && zerosAfterComma !== zeros.length) {
        priceValue = number !== null ? priceFormat(priceValue, (zerosAfterComma + 2), '.', ',') : priceValue
      } else if (zerosAfterComma !== zeros.length) {
        priceValue = number !== null ? priceFormat(priceValue, 2, '.', ',') : priceValue
      } else {
        priceValue = number !== null ? priceFormat(priceValue, 0, '.', ',') : priceValue
      }
    }
    if (priceValue.toString().includes('e-') && window !== undefined && window.eth !== undefined) {
      const multiplyBy = 10 ** (zerosAfterComma + 2)
      const decimals = parseFloat(number) * (multiplyBy)
      priceValue = window.eth.utils.toBN(decimals).div(window.eth.utils.toBN(multiplyBy)).toString()
    }
    return priceValue
  }
  inject('preciseTokenValue', preciseTokenValue)
  function priceFormat(number, decimals, decPoint = '.', thousandsSep = ',') {
    const NewNumber = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+NewNumber) ? 0 : +NewNumber
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    let s = null
    const toFixedFix = (n, prec) => {
      const k = Math.pow(10, prec)
      return '' + Math.round(n * k) / k
    }
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    return s.join(dec)
  }
  inject('priceFormat', priceFormat)
  function getImage(uri) {
    if (uri === undefined || uri === null) {
      return context.$config.appUrl + '/img/default_token.svg'
    }
    if (uri.includes('ipfs://')) {
      const imageID = uri.split('ipfs://')[1]
      return 'https://ipfs.io/ipfs/' + imageID
    }
    if (uri.includes('http')) {
      const filepath = uri.split('.')
      if (filepath.length === 2) {
        const filename = filepath[0].split('/')
        if (isAddress(filename[filename.length - 1])) {
          const checkSummedAddress = window.eth.utils.toChecksumAddress(filename[filename.length - 1])
          filename[filename.length - 1] = checkSummedAddress
          return filename.join('/') + '.' + filepath[1]
        } else {
          return uri
        }
      } else {
        return uri
      }
    } else {
      return context.$config.mediaUrl + 'img/' + uri
    }
  }
  inject('getImage', getImage)
  function getImageByAddress(address) {
    if (typeof window === 'undefined' || address === undefined || address === null || address === '') {
      return context.$config.mediaUrl + 'img/tokens/bnb.webp'
    }
    const addressCheck = window.eth.utils.toChecksumAddress(address)
    return `https://pancakeswap.finance/images/tokens/${addressCheck}.png`
  }
  inject('getImageByAddress', getImageByAddress)
  function getImageByGeckoId(geckoId) {
    if (geckoId !== undefined && geckoId !== null && typeof geckoId === 'string') {
      return `https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/128/${geckoId.toLowerCase()}.png`
    }
    return '/img/default_token.svg'
  }
  inject('getImageByGeckoId', getImageByGeckoId)
  inject('getBlockchain', (blockchainId) => {
    switch (parseInt(blockchainId, 10)) {
      case 1:
        return 'Ethereum'
      case 42:
        return 'Kovan'
      case 56:
        return 'Binance Smart Chain (BEP20)'
      case 97:
        return 'BSC Testnet'
      default:
        return i18n.t('common.unrecognized')
    }
  })
  inject('isChainSupported', (blockchainId) => {
    switch (parseInt(blockchainId, 10)) {
      case 56:
      case 97:
        return true
      default:
        return false
    }
  })
  async function calculateGasCost() {
    const defaultPrice = Number(await window.eth.eth.getGasPrice())
    if (context.$config.chainId !== 56) {
      return new Promise((resolve) => {
        resolve(defaultPrice)
      })
    }
    return new Promise((resolve) => {
      try {
        context.$axios.get(context.$config.bscApiUrl, {
          params: {
            module: 'gastracker',
            action: 'gasoracle',
            apikey: context.$config.bscApiKey
          }
        }).then((answer) => {
          const currentSpeed = context.store.getters['settings/speed']
          let calculatedVal = Math.round(parseFloat(answer.result?.FastGasPrice) * 1.1)
          switch (currentSpeed) {
            case 'standard':
              calculatedVal = Math.round(parseFloat(answer.result?.ProposeGasPrice) * 1.1)
              break
            case 'fast':
              calculatedVal = Math.round(parseFloat(answer.result?.FastGasPrice) * 1.1)
              break
            case 'instant':
              calculatedVal = Math.round(parseFloat(answer.result?.FastGasPrice) * 1.5)
              break
          }
          resolve(window.eth.utils.toWei(calculatedVal.toString(), 'gwei'))
        }).catch((err) => {
          console.log(err)
          resolve(defaultPrice)
        })
      } catch (err) {
        console.log(err)
        resolve(defaultPrice)
      }
    })
  }
  inject('calculateGasCost', calculateGasCost)
  inject('checkNetwork', async () => {
    if (window.ethereum !== null) {
      const currentNetwork = await window.eth.eth.net.getId()
      if (currentNetwork !== context.$config.chainId) {
        changeNetwork()
      }
    } else {
      window.$nuxt.$emit('openError', i18n.t('common.went_wrong'), i18n.t('common.wallet_not_installed'))
    }
  })
  async function addNetwork() {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          iconUrls: '/icons/bnb.webp',
          chainName: context.$config.chainName,
          chainId: window.eth.utils.toHex(context.$config.chainId),
          nativeCurrency: { name: 'Binance Chain Native Token', decimals: 18, symbol: context.$config.chainCode },
          rpcUrls: [context.$config.chainRPC],
          blockExplorerUrls: [context.$config.chainExplorer]
        }]
      })
        .then((success) => {
          if (success !== null) {
            window.$nuxt.$emit('openSuccess', i18n.t('common.network_has_been_added'))
          } else {
            window.$nuxt.$emit('openInfo', i18n.t('common.deined_operation'))
          }
        })
        .catch((err) => {
          window.$nuxt.$emit('openError', i18n.t('common.deined_operation') + ': ' + err.message)
        })
    } catch (err) {
      console.error(err)
    }
  }
  async function changeNetwork() {
    if (window.ethereum !== null) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: window.eth.utils.toHex(context.$config.chainId) }]
        })
      } catch (err) {
        if (err.code === 4001) {
          window.$nuxt.$emit('openInfo', i18n.t('common.change_network'))
        } else if (err.code === 4902) {
          await addNetwork()
        }
      }
    } else {
      window.$nuxt.$emit('openError', i18n.t('common.went_wrong'), i18n.t('common.wallet_not_installed'))
    }
  }
  inject('changeNetwork', changeNetwork)
  inject('addNetwork', addNetwork)
  inject('addToMetamask', async (tokenData) => {
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenData.address,
            symbol: tokenData.symbol,
            decimals: tokenData.decimals,
            image: getImage(tokenData?.logoURI)
          }
        }
      })
        .catch((err) => {
          window.$nuxt.$emit('openError', i18n.t('common.deined_operation') + ': ' + err.message)
        })
    } catch (err) {
      console.error(err)
    }
  })
  inject('saveTransaction', (transactionData, summary) => {
    const userTransactions = context.$cookies.get(context.$config.transactionsStorageName)
    const networkId = window.ethereum.networkVersion
    const toPush = {
      identity: context.store.getters['auth/user'] !== null ? context.store.getters['auth/user'].address : null,
      chainId: context.$config.chainId,
      tid0: transactionData.tid0,
      tid1: transactionData.tid1 !== undefined ? transactionData.tid1 : null,
      t0_amount: transactionData.t0_amount,
      t1_amount: transactionData.t1_amount !== undefined ? transactionData.t1_amount : null,
      tid0_token: transactionData.tid0_token !== undefined ? transactionData.tid0_token : null,
      tid1_token: transactionData.tid1_token !== undefined ? transactionData.tid1_token : null,
      created_at: Date.now(),
      index: transactionData.transactionIndex !== undefined ? transactionData.transactionIndex : null,
      gasUsed: transactionData.gasUsed !== undefined ? transactionData.gasUsed : 0,
      hash: transactionData.hash !== undefined ? transactionData.hash : '',
      status: 'success',
      type: transactionData.type !== undefined ? transactionData.type : '',
      summary
    }
    if (userTransactions !== undefined && Array.isArray(userTransactions)) {
      if (!Object.prototype.hasOwnProperty.call(userTransactions, networkId)) {
        userTransactions[networkId] = []
      }
      // const listID = new Set(userTransactions[networkId].map(item => item.hash))
      // userTransactions[networkId] = [...new Set([...userTransactions[networkId], ...toPush.filter(item => !listID.has(item.hash))])]
      userTransactions[networkId].unshift(toPush)
      if (userTransactions[networkId].length > 10) {
        userTransactions[networkId].shift()
      }
      context.$cookies.set(context.$config.transactionsStorageName, userTransactions)
      context.store.dispatch('transactions/addTransaction', toPush)
    } else {
      const newUserTransactions = {}
      newUserTransactions[networkId] = [toPush]
      context.$cookies.set(context.$config.transactionsStorageName, newUserTransactions)
      context.store.dispatch('transactions/addTransaction', toPush)
    }
  })
  inject('loadTokensAndLists', async (networkId) => {
    const userAddress = await getUserAddress()
    const tokensData = context.$cookies.get(context.$config.tokensStorageName)
    const listsData = context.$cookies.get(context.$config.listsStorageName)
    const walletBalance = await window.eth.eth.getBalance(userAddress)
    context.store.commit('tokens/editListItem', {
      address: '0x0000000000000000000000000000000000000000',
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
      logoURI: context.$config.mediaUrl + 'img/tokens/bnb.webp',
      balance: parseFloat(await window.eth.utils.fromWei(walletBalance))
    })
    if (context.$config.chainId !== 56) {
      const testnetTokens = tokensList
      for (const item of testnetTokens) {
        try {
          const tokenContract = new window.eth.eth.Contract(ERC20, item.address)
          const currentDecimal = getDecimalsName(item.decimals)
          let balance = parseFloat(await window.eth.utils.fromWei(await tokenContract.methods.balanceOf(userAddress).call({ from: userAddress }), currentDecimal.name))
          if (currentDecimal.multiply > 0) {
            balance /= 10 ** currentDecimal.multiply
          }
          await context.store.dispatch('tokens/addToken', {
            bid: context.$config.chainId,
            address: item.address,
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            logoURI: item.logoURI,
            balance
          })
        } catch (err) {
          console.log(err)
        }
      }
    } else {
      await context.store.dispatch('tokens/fetchList', 'https://defipool.finance/src/json/mainnet.json')
    }
    if (tokensData !== undefined && Array.isArray(tokensData) && tokensData[networkId] !== undefined && Array.isArray(tokensData[networkId])) {
      for (const item of tokensData[networkId]) {
        await context.store.dispatch('tokens/addToken', {
          bid: context.$config.chainId,
          ...item
        })
      }
    }
    if (listsData !== undefined && Array.isArray(tokensData) && listsData[networkId] !== undefined && Array.isArray(listsData[networkId])) {
      for (const item of listsData[networkId]) {
        await context.store.dispatch('tokens/addList', {
          bid: context.$config.chainId,
          ...item
        })
      }
    }
    window.$nuxt.$emit('tokensLoaded')
  })
  inject('loadPairs', async (networkId) => {
    const userAddress = await getUserAddress()
    const pairsData = context.$cookies.get(networkId + '/' + userAddress)
    if (pairsData !== undefined && pairsData.pairs !== undefined && typeof pairsData.pairs === 'object') {
      for (const item in pairsData.pairs) {
        context.store.commit('pairs/addPairs', pairsData.pairs[item])
      }
    }
  })
  inject('loadTransactions', (networkId) => {
    const transactionsData = context.$cookies.get(context.$config.transactionsStorageName)
    if (transactionsData !== undefined && transactionsData[networkId] !== undefined && Array.isArray(transactionsData[networkId])) {
      for (const item of transactionsData[networkId]) {
        context.store.commit('transactions/addTransaction', item)
      }
    }
  })
  inject('loadBalances', async () => {
    const userAddress = await getUserAddress()
    await context.$axios.get(context.$config.moralisApiBase + userAddress + '/erc20', {
      params: {
        chain: context.$config.chainHexadecimal
      },
      headers: {
        'x-api-key': context.$config.moralisKey
      }
    }).then(async (tokens) => {
      if (tokens !== undefined && tokens.data !== null && tokens.data.length > 0) {
        for (const item of tokens.data) {
          const currentDecimal = getDecimalsName(item.decimals)
          let balance = parseFloat(await window.eth.utils.fromWei(item.balance, currentDecimal.name))
          if (currentDecimal.multiply > 0) {
            balance /= 10 ** currentDecimal.multiply
          }
          await context.store.dispatch('tokens/addToken', {
            address: item.token_address,
            name: item.name,
            symbol: item.symbol,
            decimals: item.decimals,
            logoURI: item.logo !== null ? item.logo : getImageByAddress(item.token_address),
            balance
          })
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  })
  inject('loadSettings', () => {
    const userData = context.$cookies.get(context.$config.userStorageName)
    if (userData !== undefined && userData.settings !== undefined && typeof userData.settings === 'object') {
      context.store.commit('settings/setSlippageTolerance', userData.settings.slippageTolerance)
      context.store.commit('settings/setDeadline', userData.settings.deadline)
      context.store.commit('settings/setExpertMode', userData.settings.expertMode)
      context.store.commit('settings/setMultihops', userData.settings.multihops)
      context.store.commit('settings/setSpeed', userData.settings.speed)
    }
  })
  inject('checkWalletBalance', async (currencyCode, currentCurrency = null) => {
    if (window.eth === undefined) {
      return 0
    }
    const address = await getUserAddress()
    const netId = await window.eth.eth.net.getId()
    return new Promise((resolve) => {
      if (currencyCode.toLowerCase() === 'eth') {
        window.eth.eth.getBalance(address).then((balance) => {
          resolve(Number(window.eth.utils.fromWei(balance)).toFixed(4))
        })
      } else {
        let contractAddress = currentCurrency.address_mainnet
        if (parseInt(netId, 10) !== 1) {
          contractAddress = currentCurrency.address_testnet
        }
        const contract = new window.eth.eth.Contract(JSON.parse(currentCurrency.abi), contractAddress)
        contract.methods.balanceOf(address).call().then((balance) => {
          resolve(Number(window.eth.utils.fromWei(balance)).toFixed(4))
        })
      }
    })
  })
  inject('calculateDeadline', (deadline) => {
    const newDate = new Date()
    const calculatedDate = new Date(newDate.getTime() + parseInt(deadline, 10) * 60000)
    return Math.round(calculatedDate.getTime() / 1000)
  })
  inject('calculateWithSlippage', (value, slippage = 0.5, type = 'min', toWei = true) => {
    if (isNaN(parseFloat(value)) || window === undefined) {
      return value
    }
    if (type === 'min') {
      const finalValue = parseFloat(value) - parseFloat(value) / 100 * parseFloat(slippage)
      return toWei ? window.eth.utils.toWei(Number(finalValue).toFixed(10)) : finalValue
    } else if (type === 'max') {
      const finalValue = parseFloat(value) + parseFloat(value) / 100 * parseFloat(slippage)
      return toWei ? window.eth.utils.toWei(Number(finalValue).toFixed(10)) : finalValue
    }
  })
  inject('getRPCError', (errorText) => {
    if (typeof errorText !== 'string') {
      return '#error'
    }
    errorText = errorText.replace(/(\r\n|\n|\r| )/gm, '').replace(' ', '')
    const indexOfJSON = errorText.indexOf('{"code":')
    if (indexOfJSON !== -1) {
      const jsonError = JSON.parse(
        errorText.slice(indexOfJSON)
      )

      return jsonError.message
    } else {
      return '#error'
    }
  })
  inject('isStableCurrency', (symbol) => {
    return symbol.toLowerCase() === 'bnb'
  })
  inject('denyWalletOperation', () => {
    window.$nuxt.$emit('openInfo', i18n.t('common.deined_operation'))
  })
  async function createUserActivity() {
    const userAddress = await getUserAddress()
    const networkId = window.ethereum.networkVersion
    context.$cookies.set(networkId + '/' + userAddress, {
      pairs: {}
    })
  }
  inject('createUserActivity', createUserActivity)
  inject('savePair', async (token0, token1, address = null, savePairToDB = true) => {
    try {
      const userAddress = await getUserAddress()
      const networkId = window.ethereum.networkVersion
      let userPairs = context.$cookies.get(networkId + '/' + userAddress)
      const pairKey = changeZeroToWrapped(token0.address) + '/' + changeZeroToWrapped(token1.address)
      const reversePairKey = changeZeroToWrapped(token1.address) + '/' + changeZeroToWrapped(token0.address)
      if (userPairs === undefined) {
        await createUserActivity()
        userPairs = context.$cookies.get(networkId + '/' + userAddress)
      }
      if (userPairs.pairs !== undefined) {
        if (!Object.prototype.hasOwnProperty.call(userPairs.pairs, pairKey) &&
            !Object.prototype.hasOwnProperty.call(userPairs.pairs, reversePairKey)) {
          userPairs.pairs[pairKey] = {
            token0,
            token1
          }
        }
        context.$cookies.set(networkId + '/' + userAddress, userPairs)
      } else {
        userPairs.pairs = {}
        userPairs.pairs[pairKey] = {
          token0,
          token1
        }
        context.$cookies.set(networkId + '/' + userAddress, userPairs)
      }
      if (savePairToDB) {
        context.store.dispatch('pairs/addPair', {
          chainId: context.$config.chainId,
          address,
          name: token0.symbol + '-' + token1.symbol,
          token0,
          token1
        })
      }
    } catch (err) {
      console.log(err)
    }
  })
  inject('removePair', async (token0, token1) => {
    try {
      const userAddress = await getUserAddress()
      const networkId = window.ethereum.networkVersion
      const userPairs = context.$cookies.get(networkId + '/' + userAddress)
      const pairKey = changeZeroToWrapped(token0.address) + '/' + changeZeroToWrapped(token1.address)
      const reversePairKey = changeZeroToWrapped(token1.address) + '/' + changeZeroToWrapped(token0.address)
      if (userPairs !== undefined) {
        if (Object.prototype.hasOwnProperty.call(userPairs.pairs, pairKey)) {
          delete userPairs.pairs[pairKey]
        }
        if (Object.prototype.hasOwnProperty.call(userPairs.pairs, reversePairKey)) {
          delete userPairs.pairs[reversePairKey]
        }
        context.$cookies.set(networkId + '/' + userAddress, userPairs)
      }
    } catch (err) {
      console.log(err)
    }
  })
  inject('getBNBPrice', async () => {
    const baseUrl = context.$config.bscApiUrl
    const answer = await context.$axios.get(baseUrl, {
      params: {
        module: 'stats',
        action: 'bnbprice',
        apikey: context.$config.bscApiKey
      }
    })
    return new Promise((resolve) => {
      if (answer.status !== undefined && answer.status === '1') {
        context.$cookies.set('bnbPrice', answer.result.ethusd)
        context.$cookies.set('bnbPriceUpdated', answer.result.ethusd_timestamp)
        resolve(Number(answer.result.ethusd).toFixed(2))
      }
    })
  })
  function refreshCookieExpires() {
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)

    const userData = context.app.$cookies.get('auth.user')
    const tokenData = context.app.$cookies.get('auth.token')

    if (userData !== undefined && userData !== null) {
      context.app.$cookies.set('auth.user', userData, {
        expires: nowDate,
        path: '/'
      })
    }
    if (tokenData !== undefined && tokenData !== null) {
      context.app.$cookies.set('auth.token', tokenData, {
        expires: nowDate,
        path: '/'
      })
    }
  }
  inject('refreshCookieExpires', refreshCookieExpires)
  inject('replaceChain', async () => {
    if (window.ethereum !== undefined && parseInt(window.ethereum.networkVersion, 10) !== parseInt(context.$config.chainId, 10)) {
      try {
        const result = await window.ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: window.eth.utils.toHex(context.$config.chainId)
              }
            ]
          })
          .catch(async (err) => {
            if (err.code === 4902) {
              await addNetwork()
            }
          })
        if (result === null) {
          const userData = context.$cookies.get(context.$config.userStorageName)
          userData.chainId = context.$config.chainId
          context.$cookies.set(context.$config.userStorageName, userData)
          return new Promise((resolve) => {
            resolve(true)
          })
        } else {
          return new Promise((resolve) => {
            resolve(false)
          })
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      return new Promise((resolve) => {
        resolve(true)
      })
    }
  })
  async function getUserAddress() {
    if (window.eth === undefined) {
      window.$nuxt.$emit('openInfo', i18n.t('account.install_wallet'), i18n.t('account.install_wallet_text'))
      return ''
    }
    try {
      const metamaskAddress = (await window.eth.eth.requestAccounts())
      if (metamaskAddress[0] !== undefined) {
        const userData = context.$cookies.get(context.$config.userStorageName)
        if (userData !== undefined) {
          context.$cookies.set(context.$config.userStorageName, {
            ...userData,
            address: metamaskAddress[0]
          })
        } else {
          context.$cookies.set(context.$config.userStorageName, {
            address: metamaskAddress[0]
          })
        }
        return metamaskAddress[0]
      } else {
        window.$nuxt.$emit('openInfo', i18n.t('account.selected_address_incorrect'), i18n.t('account.selected_address_incorrect_text'))
      }
      return ''
    } catch (err) {
      console.error(err)
    }
  }
  inject('getUserAddress', getUserAddress)
  function changeZeroToWrapped(address) {
    if (address === context.$config.ZERO) {
      return context.$config.wbnbAddress
    } else {
      return address
    }
  }
  inject('changeZeroToWrapped', changeZeroToWrapped)
  inject('getTokenData', async (address, type = 'ERC20') => {
    const userAddress = await getUserAddress()
    const abi = type === 'ERC20' ? ERC20 : PeriodToken
    const tokenContract = new window.eth.eth.Contract(abi, address)
    const name = await tokenContract.methods.name().call({ from: userAddress })
    const symbol = await tokenContract.methods.symbol().call({ from: userAddress })
    const decimals = await tokenContract.methods.decimals().call({ from: userAddress })
    const totalSupply = await tokenContract.methods.totalSupply().call({ from: userAddress })

    return {
      address,
      name,
      symbol,
      decimals: parseInt(decimals, 10),
      totalSupply,
      logoURI: type === 'ERC20' ? getImageByAddress(address) : '/tokens/floatie_item.svg'
    }
  })
  inject('loadPairByAddress', async (pairAddress, reverse = false) => {
    const answer = {
      reserves: {},
      price0: 0,
      price1: 0,
      totalSupply: 0,
      balance: 0,
      constantProduct: 0,
      token0: '',
      token1: ''
    }
    try {
      const userAddress = await this.$getUserAddress()
      const pairContract = new window.eth.eth.Contract(PeriodPair, pairAddress)
      answer.reserves = await pairContract.methods.getReserves().call({ from: userAddress })
      answer.price0 = await pairContract.methods.price0CumulativeLast().call({ from: userAddress })
      answer.price1 = await pairContract.methods.price1CumulativeLast().call({ from: userAddress })
      answer.totalSupply = await pairContract.methods.totalSupply().call({ from: userAddress })
      answer.balance = await pairContract.methods.balanceOf(userAddress).call({ from: userAddress })
      answer.token0 = await pairContract.methods.token0().call({ from: userAddress })
      answer.token1 = await pairContract.methods.token1().call({ from: userAddress })

      const ERC20file = await import('~/assets/abis/ERC20.json')
      const ERC20 = ERC20file.default
      let erc20Contract = new window.eth.eth.Contract(ERC20, answer.token0)
      const decimals0 = await erc20Contract.methods.decimals().call({ from: userAddress })
      erc20Contract = new window.eth.eth.Contract(ERC20, answer.token1)
      const decimals1 = await erc20Contract.methods.decimals().call({ from: userAddress })

      const reserve0Decimal = getDecimalsName(parseInt(decimals0, 10))
      const reserve1Decimal = getDecimalsName(parseInt(decimals1, 10))
      let reserve0Balance = parseFloat(window.eth.utils.fromWei(answer.reserves.reserve0, reserve0Decimal.name))
      if (reserve0Decimal.multiply > 0) {
        reserve0Balance /= 10 ** reserve0Decimal.multiply
      }
      let reserve1Balance = parseFloat(window.eth.utils.fromWei(answer.reserves.reserve1, reserve1Decimal.name))
      if (reserve1Decimal.multiply > 0) {
        reserve1Balance /= 10 ** reserve1Decimal.multiply
      }
      answer.balance = parseFloat(window.eth.utils.fromWei(answer.balance))
      answer.price0 = parseFloat(window.eth.utils.fromWei(answer.price0))
      answer.price1 = parseFloat(window.eth.utils.fromWei(answer.price1))
      answer.totalSupply = parseFloat(window.eth.utils.fromWei(answer.totalSupply))
      answer.reserves.reserve0 = reserve0Balance
      answer.reserves.reserve1 = reserve1Balance
      answer.reserves[2] = parseFloat(window.eth.utils.fromWei(answer.reserves[2]))

      answer.constantProduct = answer.reserves.reserve0 * answer.reserves.reserve1
      return new Promise((resolve) => {
        resolve(answer)
      })
    } catch (err) {
      console.log(err)
      return new Promise((resolve, reject) => {
        reject(new Error('No pair found by this address'))
      })
    }
  })
  inject('loadPairData', async (fromToken, toToken, reverse = false) => {
    if (fromToken !== null && fromToken.address !== undefined && fromToken.address.length > 0 &&
        toToken !== null && toToken.address !== undefined && toToken.address.length > 0) {
      try {
        const userAddress = await getUserAddress()
        const factoryContract = new window.eth.eth.Contract(PeriodFactory, context.$config.periodFactory)
        let fromAddress = fromToken.address
        let toAddress = toToken.address
        if (fromToken.address === context.$config.ZERO) {
          fromAddress = context.$config.wbnbAddress
        }
        if (toToken.address === context.$config.ZERO) {
          toAddress = context.$config.wbnbAddress
        }
        const pairAddress = await factoryContract.methods.getPair(fromAddress, toAddress).call({ from: userAddress })

        const answer = {
          reserves: {},
          price0: 0,
          price1: 0,
          totalSupply: 0,
          balance: 0,
          constantProduct: 0,
          token0: '',
          token1: ''
        }
        if (pairAddress !== context.$config.ZERO) {
          const pairContract = new window.eth.eth.Contract(PeriodPair, pairAddress)
          answer.reserves = await pairContract.methods.getReserves().call({ from: userAddress })
          answer.price0 = await pairContract.methods.price0CumulativeLast().call({ from: userAddress })
          answer.price1 = await pairContract.methods.price1CumulativeLast().call({ from: userAddress })
          answer.totalSupply = await pairContract.methods.totalSupply().call({ from: userAddress })
          answer.balance = await pairContract.methods.balanceOf(userAddress).call({ from: userAddress })
          answer.token0 = await pairContract.methods.token0().call({ from: userAddress })
          answer.token1 = await pairContract.methods.token1().call({ from: userAddress })

          const ERC20file = await import('~/assets/abis/ERC20.json')
          const ERC20 = ERC20file.default
          let erc20Contract = new window.eth.eth.Contract(ERC20, answer.token0)
          const decimals0 = await erc20Contract.methods.decimals().call({ from: userAddress })
          erc20Contract = new window.eth.eth.Contract(ERC20, answer.token1)
          const decimals1 = await erc20Contract.methods.decimals().call({ from: userAddress })

          const reserve0Decimal = getDecimalsName(parseInt(decimals0, 10))
          const reserve1Decimal = getDecimalsName(parseInt(decimals1, 10))
          let reserve0Balance = parseFloat(window.eth.utils.fromWei(answer.reserves.reserve0, reserve0Decimal.name))
          if (reserve0Decimal.multiply > 0) {
            reserve0Balance /= 10 ** reserve0Decimal.multiply
          }
          let reserve1Balance = parseFloat(window.eth.utils.fromWei(answer.reserves.reserve1, reserve1Decimal.name))
          if (reserve1Decimal.multiply > 0) {
            reserve1Balance /= 10 ** reserve1Decimal.multiply
          }

          answer.balance = parseFloat(window.eth.utils.fromWei(answer.balance))
          answer.price0 = parseFloat(window.eth.utils.fromWei(answer.price0))
          answer.price1 = parseFloat(window.eth.utils.fromWei(answer.price1))
          answer.totalSupply = parseFloat(window.eth.utils.fromWei(answer.totalSupply))
          answer.reserves.reserve0 = reserve0Balance
          answer.reserves.reserve1 = reserve1Balance
          answer.reserves[2] = parseFloat(window.eth.utils.fromWei(answer.reserves[2]))

          answer.constantProduct = answer.reserves.reserve0 * answer.reserves.reserve1

          // If errors occured add this line to if statement:
          // || reverse
          if (fromAddress.toLowerCase() !== answer.token0.toLowerCase() || reverse) {
            const reserve0 = answer.reserves.reserve0
            answer.reserves.reserve0 = answer.reserves.reserve1
            answer.reserves.reserve1 = reserve0
          }
        } else {
          return new Promise((resolve, reject) => {
            reject(new Error('No pair'))
          })
        }
        return new Promise((resolve) => {
          resolve(answer)
        })
      } catch (err) {
        console.log(err)
        return null
      }
    } else {
      return null
    }
  })
  function generateApiRequest() {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    headers = setHeaders(headers)
    const api = context.$axios.create({
      baseURL: context.$config.apiUrl,
      debug: false,
      headers
    })
    api.onRequest((config) => {
      if (config.data === undefined) {
        api.setHeader('X-DEFIPOOL-TOKEN', getTokenHeader(config.params))
      } else {
        api.setHeader('X-DEFIPOOL-TOKEN', getTokenHeader(config.data))
      }
    })

    return api
  }
  inject('generateApiRequest', generateApiRequest)
  function generateUploadRequest() {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
    headers = setHeaders(headers)
    const upload = context.$axios.create({
      baseURL: context.$config.apiUrl,
      debug: false,
      headers
    })
    upload.onRequest((config) => {
      if (config.data === undefined) {
        upload.setHeader('X-DEFIPOOL-TOKEN', getTokenHeader(config.params))
      } else {
        upload.setHeader('X-DEFIPOOL-TOKEN', getTokenHeader(config.data))
      }
    })

    return upload
  }
  inject('generateUploadRequest', generateUploadRequest)
  /**
   * Get the APR value in percent.
   *
   * @param stakingTokenPrice Token price in the same quote currency
   * @param rewardTokenPrice Token price in the same quote currency
   * @param stakedAmount Total amount of stakingToken in the pool
   * @param tokenPerBlock Amount of new Floatie allocated to the pool for each new block
   * @returns Null if the APR is NaN or infinite.
   */
  function calculateApr(stakingTokenPrice, rewardTokenPrice, stakedAmount, tokenPerBlock) {
    const stackingWeiPrice = window.eth.utils.toWei(stakingTokenPrice.toString())
    const rewardWeiPrice = window.eth.utils.toWei(rewardTokenPrice.toString())
    const stakedWeiAmount = window.eth.utils.toWei(stakedAmount.toString())
    const totalRewardPricePerYear = window.eth.utils.toBN(rewardWeiPrice)
      .mul(window.eth.utils.toBN(tokenPerBlock.toString()))
      .mul(window.eth.utils.toBN(BLOCKS_PER_YEAR.toString()))
    const totalStakingTokenInPool = window.eth.utils.toBN(stackingWeiPrice).mul(window.eth.utils.toBN(stakedWeiAmount))
    if (totalStakingTokenInPool.toString() !== '0') {
      const apr = totalRewardPricePerYear
        .div(totalStakingTokenInPool)
        .mul(window.eth.utils.toBN(100))
      try {
        return apr.toNumber()
      } catch (err) {
        console.error(err)
        return 0
      }
    } else {
      return 0
    }
  }
  inject('calculateApr', calculateApr)
  /**
  * Get farm APR value in percent
  *
  * @param poolWeight allocationPoint / totalAllocationPoint
  * @param floatiePriceUsd Floatie price in USD
  * @param poolLiquidityUsd Total pool liquidity in USD
  * @returns
  */
  function calculateFarmApr(poolWeight, floatiePriceUsd, poolLiquidityUsd, lpRewardsApr = 0) {
    const FLOATIE_PER_YEAR = BLOCKS_PER_YEAR * window.eth.utils.fromWei(context.$config.floatiePerBlock)
    const yearlyRewardAllocation = FLOATIE_PER_YEAR * poolWeight
    const floatieRewardsApr = yearlyRewardAllocation * floatiePriceUsd * poolLiquidityUsd * 100 + lpRewardsApr
    return parseFloat(floatieRewardsApr)
  }
  inject('calculateFarmApr', calculateFarmApr)
  /**
   * Given APR returns APY. Everything here is worked out relative to a year,
   * with the asset compounding at the compoundFrequency rate (1 = once per day).
   *
   * @param apr farm or pool APR as percentage
   * @param compoundFrequency how many compounds per 1 day, e.g. 1 = one per day, 0.142857142 - once per week
   * @param days if other than 365 adjusts (A)PY for period less than a year
   * @param performanceFee performance fee as percentage
   * @returns APY as decimal
  */
  function calculateApy(apr, compoundFrequency = 1, days = 365, performanceFee = 0) {
    const daysAsDecimalOfYear = days / 365
    const aprAsDecimal = apr / 100
    const timesCompounded = 365 * compoundFrequency
    let apyAsDecimal = aprAsDecimal * daysAsDecimalOfYear
    if (timesCompounded > 0) {
      apyAsDecimal = (1 + aprAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear) - 1
    }
    if (performanceFee) {
      const performanceFeeAsDecimal = performanceFee / 100
      const takenAsPerformanceFee = apyAsDecimal * performanceFeeAsDecimal
      apyAsDecimal -= takenAsPerformanceFee
    }
    return apyAsDecimal
  }
  inject('calculateApy', calculateApy)
  function getRoi(amountEarned, amountInvested) {
    if (amountInvested === 0) {
      return 0
    }
    const percentage = (amountEarned / amountInvested) * 100
    return percentage
  }
  inject('getRoi', getRoi)
  /**
   * Функция расчета в ROI калькуляторе срока инвестиции,
   * по сумме, которую пользователь хочет получить.
   *
   * @param principalInUSD - amount user wants to invest in USD
   * @param apr - farm or pool apr as percentage. If its farm APR its only PR1 rewards APR without LP rewards APR
   * @param earningTokenPrice - price of reward token
   * @param compoundFrequency - how many compounds per 1 day, e.g. 1 = one per day, 0.142857142 - once per week
   * @param performanceFee - performance fee as percentage
   * @returns an array of token values earned as interest, with each element representing interest earned over a different period of time (DAYS_TO_CALCULATE_AGAINST)
  */
  function getInterestBreakdown(daysType = 'flexible', principalInUSD, apr, earningTokenPrice, compoundFrequency = 1, performanceFee = 2) {
    const timesCompounded = 365 * compoundFrequency
    const aprAsDecimal = apr / 100
    const DAYS_TO_CALCULATE_AGAINST = daysType === 'flexible' ? [1, 7, 30, 364, 1825] : [7, 35, 70, 175, 364]

    // special handling for tokens like tBTC or BIFI where the daily token rewards for $1000 dollars will be less than 0.001 of that token
    // and also cause rounding errors
    const isHighValueToken = Math.round(Number(earningTokenPrice) / 1000) > 0
    const roundingDecimalsNew = isHighValueToken ? 5 : 3

    return DAYS_TO_CALCULATE_AGAINST.map((days) => {
      const daysAsDecimalOfYear = days / 365
      // Calculate the starting TOKEN balance with a dollar balance of principalInUSD.
      const principal = principalInUSD / Number(earningTokenPrice)
      let interestEarned = principal * aprAsDecimal * (days / 365)
      if (timesCompounded !== 0) {
      // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
        const accruedAmount = principal * (1 + aprAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear)
        // To get the TOKEN amount earned, deduct the amount after compounding (accruedAmount) from the starting TOKEN balance (principal)
        interestEarned = accruedAmount - principal
        if (performanceFee) {
          const performanceFeeAsDecimal = performanceFee / 10000
          const perfomanceFeeAsAmount = interestEarned * performanceFeeAsDecimal
          interestEarned -= perfomanceFeeAsAmount
        }
      }
      return parseFloat(interestEarned.toFixed(roundingDecimalsNew))
    })
  }
  inject('getInterestBreakdown', getInterestBreakdown)
  /**
   * Поулчить прцоентную ставку фермы по количеству USD,
   * которое хочет поулчить пользователь.
   *
   * @param interest how much USD amount you aim to make
   * @param apr APR of farm/pool
   * @param compoundingFrequency how many compounds per 1 day, e.g. 1 = one per day, 0.142857142 - once per week
   * @returns an array of principal values needed to reach target interest, with each element representing principal needed for a different period of time (DAYS_TO_CALCULATE_AGAINST)
  */
  function getPrincipalForInterest(interest, apr, compoundingFrequency, performanceFee) {
    const DAYS_TO_CALCULATE_AGAINST = [1, 7, 30, 365, 1825]
    return DAYS_TO_CALCULATE_AGAINST.map((days) => {
      const apyAsDecimal = calculateApy({
        apr,
        compoundFrequency: compoundingFrequency,
        days,
        performanceFee
      })
      return parseFloat((interest / apyAsDecimal).toFixed(2))
    })
  }
  inject('getPrincipalForInterest', getPrincipalForInterest)
  inject('loadTokenPrice', async (address) => {
    try {
      const tokenData = await context.$axios.$get(context.$config.pancakeApiBase + 'tokens/' + changeZeroToWrapped(address))
      if (tokenData !== null && tokenData.data !== null && parseFloat(tokenData.data.price) !== 0) {
        return new Promise((resolve) => {
          resolve(priceFormat(parseFloat(tokenData.data.price), 2, '.', ','))
        })
      } else {
        return new Promise((resolve) => {
          resolve(0)
        })
      }
    } catch (err) {
      console.log(err)
      return new Promise((resolve) => {
        resolve(0)
      })
    }
  })
  inject('switchPairData', (pairData) => {
    const pairDataPrice0 = pairData.price0
    const pairDataReserve0 = pairData.reserves.reserve0
    pairData.price0 = pairData.price1
    pairData.price1 = pairDataPrice0
    pairData.reserves.reserve0 = pairData.reserves.reserve1
    pairData.reserves.reserve1 = pairDataReserve0
    return pairData
  })
  function getNumberFromPx(string) {
    return parseFloat(string.replace('px', ''))
  }
  inject('getNumberFromPx', getNumberFromPx)
  function getExplorerUserLink(address) {
    switch (parseInt(context.$config.chainId, 10)) {
      case 56:
        return `https://bscscan.com/address/${address}`
      case 97:
        return `https://testnet.bscscan.com/address/${address}`
    }
  }
  inject('getExplorerUserLink', getExplorerUserLink)
  function getExplorerLinkByTx(tx) {
    switch (tx.blockchain) {
      case 1:
        return `https://bscscan.com/tx/${tx.hash}`
      case 2:
        return `https://testnet.bscscan.com/tx/${tx.hash}`
    }
  }
  inject('getExplorerLinkByTx', getExplorerLinkByTx)
  function getExplorerLink(entity, hash) {
    switch (parseInt(context.$config.chainId, 10)) {
      case 56:
        return `https://bscscan.com/${entity}/${hash}`
      case 97:
        return `https://testnet.bscscan.com/${entity}/${hash}`
    }
  }
  inject('getExplorerLink', getExplorerLink)
  function removeHashFromUrl() {
    if ('pushState' in history) {
      history.pushState('', document.title, window.location.pathname + window.location.search)
    } else {
      const scrollV = document.body.scrollTop
      const scrollH = document.body.scrollLeft

      window.location.hash = ''
      document.body.scrollTop = scrollV
      document.body.scrollLeft = scrollH
    }
  }
  inject('removeHashFromUrl', removeHashFromUrl)
  function getDecimalsName(decimal = 18) {
    if (isNaN(parseInt(decimal))) {
      return 'ether'
    }
    const allowedDecimals = [1, 3, 6, 9, 12, 15, 18, 21]
    let additionalMultiply = 0
    const decimalAllowed = allowedDecimals.findIndex(el => el === parseInt(decimal))
    const loweDecimalIndex = allowedDecimals.findIndex(el => el >= parseInt(decimal))
    let decimalsName = 'ether'
    switch (decimalAllowed === -1 ? allowedDecimals[loweDecimalIndex > 0 ? loweDecimalIndex - 1 : 0] : allowedDecimals[decimalAllowed]) {
      case 1:
        decimalsName = 'wei'
        break
      case 3:
        decimalsName = 'kwei'
        break
      case 6:
        decimalsName = 'picoether'
        break
      case 9:
        decimalsName = 'nanoether'
        break
      case 12:
        decimalsName = 'microether'
        break
      case 15:
        decimalsName = 'milliether'
        break
      case 18:
        decimalsName = 'ether'
        break
      case 21:
        decimalsName = 'kether'
        break
    }
    if (decimalAllowed === -1) {
      additionalMultiply = parseInt(decimal) - allowedDecimals[loweDecimalIndex > 0 ? loweDecimalIndex - 1 : 0]
    }
    return {
      name: decimalsName,
      multiply: additionalMultiply
    }
  }
  inject('getDecimalsName', getDecimalsName)
  function toWeiDecimal(amount, decimal = 18) {
    const initDecimals = decimalCount(amount)
    let finalAmount = parseFloat(Number(amount).toFixed(initDecimals))
    for (let ind = 0; ind < decimal; ++ind) {
      if (typeof finalAmount === 'number' && !Number.isInteger(finalAmount)) {
        finalAmount = parseFloat(Number(finalAmount * 10).toFixed(initDecimals - 1 - ind))
      } else {
        finalAmount = finalAmount + '0'
      }
    }
    return finalAmount.toString()
  }
  inject('toWeiDecimal', toWeiDecimal)
  function fromWeiDecimal(amount, decimal = 18) {
    const finalAmount = window.eth.utils.toBN(amount)
    return finalAmount.div(decimal).toNumber()
  }
  inject('fromWeiDecimal', fromWeiDecimal)
  function random(lower = 0, upper = 1) {
    if (lower > upper) {
      const temp = lower
      lower = upper
      upper = temp
    }
    if (lower % 1 || upper % 1) {
      const rand = Math.random()
      const randLength = `${rand}`.length - 1
      return Math.min(lower + (rand * (upper - lower + parseFloat(`1e-${randLength}`))), upper)
    }
    return lower + Math.floor(Math.random() * (upper - lower + 1))
  }
  inject('random', random)
  function decimalCount(number) {
    const numberAsString = number.toString()
    if (numberAsString.includes('.')) {
      return numberAsString.split('.')[1].length
    }
    return 0
  }
  inject('decimalCount', decimalCount)
  inject('closeAllModals', () => {
    context.store.dispatch('modal/closeAllModals')
  })
  inject('slideUp', (target) => {
    if (target === null) {
      return
    }
    target.style.maxHeight = null
  })
  inject('slideDown', (target, heightElem = null) => {
    if (target === null) {
      return
    }
    let height = 1
    if (heightElem !== null) {
      const styles = window.getComputedStyle(heightElem)
      const scrollHeight = heightElem.scrollHeight
      height += scrollHeight + getNumberFromPx(styles.marginTop) + getNumberFromPx(styles.marginBottom) +
                getNumberFromPx(styles.paddingTop) + getNumberFromPx(styles.paddingBottom)
    }
    target.style.maxHeight = height + 'px'
  })
  inject('fadeIn', (target, display = null) => {
    target.style.opacity = 0
    target.style.display = display || 'block';
    (function fade() {
      let val = parseFloat(target.style.opacity)
      if (!((val += 0.1) > 1)) {
        target.style.opacity = val
        requestAnimationFrame(fade)
      }
    })()
  })
  inject('fadeOut', (target) => {
    target.style.opacity = 1;
    (function fade() {
      if ((target.style.opacity -= 0.1) < 0) {
        target.style.display = 'none'
      } else {
        requestAnimationFrame(fade)
      }
    })()
  })
}
