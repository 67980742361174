<template>
  <svg viewBox="0 0 11 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.64826 12.9063C3.59124 12.9063 3.54501 12.8601 3.54501 12.8031C3.55648 11.7075 3.67121 10.8356 3.88918 10.1874C4.10715 9.5392 4.4169 9.01434 4.81843 8.61281C5.21996 8.21128 5.7018 7.8413 6.26394 7.50287C6.60237 7.29637 6.90639 7.05258 7.17598 6.77151C7.44558 6.4847 7.65782 6.15488 7.8127 5.78203C7.97331 5.40918 8.05361 4.99618 8.05361 4.54302C8.05361 3.98088 7.92168 3.49331 7.65782 3.08031C7.39396 2.6673 7.04119 2.34895 6.5995 2.12524C6.15782 1.90153 5.66738 1.78967 5.12818 1.78967C4.65782 1.78967 4.20467 1.88719 3.76872 2.08222C3.33277 2.27725 2.96853 2.58413 2.67599 3.00287C2.54469 3.1908 2.43824 3.40472 2.35663 3.64464C2.17137 4.18926 1.72163 4.64627 1.14636 4.64627C0.522195 4.64627 0.00967209 4.11867 0.161763 3.51331C0.28879 3.00772 0.487285 2.55077 0.757247 2.14245C1.22187 1.44838 1.83277 0.917782 2.58994 0.550669C3.35285 0.183556 4.19893 0 5.12818 0C6.13774 0 7.01537 0.200765 7.76107 0.602294C8.5125 1.00382 9.09186 1.55449 9.49912 2.2543C9.91212 2.95411 10.1186 3.75143 10.1186 4.64627C10.1186 5.27725 10.0211 5.84799 9.82608 6.35851C9.63679 6.86902 9.36145 7.32505 9.00008 7.72658C8.64444 8.12811 8.21423 8.48375 7.70945 8.7935C7.20467 9.10899 6.80027 9.44168 6.49625 9.79159C6.19224 10.1358 5.9714 10.5459 5.83373 11.022C5.69606 11.4981 5.62149 12.0918 5.61002 12.8031C5.61002 12.8601 5.56379 12.9063 5.50677 12.9063H3.64826ZM4.64635 18C4.22187 18 3.85763 17.848 3.55361 17.544C3.2496 17.24 3.09759 16.8757 3.09759 16.4512C3.09759 16.0268 3.2496 15.6625 3.55361 15.3585C3.85763 15.0545 4.22187 14.9025 4.64635 14.9025C5.07082 14.9025 5.43507 15.0545 5.73908 15.3585C6.0431 15.6625 6.19511 16.0268 6.19511 16.4512C6.19511 16.7323 6.1234 16.9904 5.98 17.2256C5.84233 17.4608 5.65591 17.6501 5.42073 17.7935C5.19128 17.9312 4.93316 18 4.64635 18Z" />
  </svg>
</template>

<script>
export default {
  name: 'QuestionSignIcon'
}
</script>
