<template>
  <div class="table-area list">
    <div v-if="withSearch" class="filter-area">
      <div class="input-area">
        <input v-model="filter.search" type="text" :placeholder="$t('limit.search_by_hash')" @input="searchModel">
        <Search />
      </div>
    </div>
    <div v-if="!ordersLoading && orders.length > 0" class="table-item">
      <div class="header row">
        <div class="col">
          <p>
            {{ $t('limit.from') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('limit.to') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('limit.limit_price') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('limit.status') }}
          </p>
        </div>
        <div class="col action">
          <p>
            {{ $t('limit.actions') }}
          </p>
        </div>
      </div>
      <div v-for="(order, index) of orders" :key="index" class="row">
        <div class="col small-visible">
          <div class="number">
            {{ (filter.step * (filter.page - 1)) + index + 1 }}
          </div>
          <nuxt-link :to="localePath('/analytics/tokens/' + order.t0_address)" class="logo mini">
            <nuxt-img
              fit="inside"
              :src="$getImage(order.t0_image)"
              :alt="order.t0_name"
              :title="order.t0_symbol"
              @error="$event.target.src = require('~/static/img/default_token.svg')"
            />
          </nuxt-link>
          <nuxt-link :to="localePath('/analytics/tokens/' + order.t0_address)" class="name">
            {{ $formatCryptoAmount(order.amount_in, 6) }}
            {{ order.t0_symbol }}
          </nuxt-link>
        </div>
        <div class="col horizontal small-visible xs-hidden">
          <nuxt-link :to="localePath('/analytics/tokens/' + order.t1_address)" class="logo  mini">
            <nuxt-img
              fit="inside"
              :src="$getImage(order.t1_image)"
              :alt="order.t1_name"
              :title="order.t1_symbol"
              @error="$event.target.src = require('~/static/img/default_token.svg')"
            />
          </nuxt-link>
          <nuxt-link :to="localePath('/analytics/tokens/' + order.t1_address)" class="name">
            {{ $formatCryptoAmount(order.amount_out, 6) }}
            {{ order.t1_symbol }}
          </nuxt-link>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('limit.limit_price') }}
          </p>
          <div class="name">
            {{ $formatCryptoAmount(order.price_out) }}
            {{ order.t1_symbol }}
            {{ $t('common.per') }}
            {{ order.t0_symbol }}
          </div>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('limit.status') }}
          </p>
          <div class="name">
            {{ $t(`limit.${order.status}`) }}
          </div>
        </div>
        <div class="col action">
          <p class="hint">
            {{ $t('limit.actions') }}
          </p>
          <div v-if="order.status === 'new'" class="name">
            <button type="button" class="btn cyan btn-circle danger" @click="cancelOrder(order.hash)">
              <i class="bx bx-trash"></i>
            </button>
          </div>
          <div v-else class="name">
            ---
          </div>
        </div>
        <div class="col accordion small-visible" @click="openAccordion(index)">
          <div :class="{'switcher': true, 'active': accordions[index] !== undefined && accordions[index] === true}">
            <p v-if="accordions[index] !== undefined && accordions[index] === true">
              {{ $t('common.hide') }}
            </p>
            <p v-else>
              {{ $t('common.show') }}
            </p>
            <ChevronDown />
          </div>
        </div>
        <div :ref="'accordionArea' + index" class="accordion-area mobile-visible">
          <div class="all-params">
            <div class="item xs-visible">
              <p class="hint">
                {{ $t('limit.token_receive') }}
              </p>
              <nuxt-link :to="localePath('/analytics/tokens/' + order.t1_address)" class="logo">
                <nuxt-img
                  fit="inside"
                  :src="$getImage(order.t1_image)"
                  :alt="order.t1_name"
                  :title="order.t1_symbol"
                  @error="$event.target.src = require('~/static/img/default_token.svg')"
                />
              </nuxt-link>
              <nuxt-link :to="localePath('/analytics/tokens/' + order.t1_address)" class="name">
                {{ order.t1_name }}
              </nuxt-link>
            </div>
            <div class="item">
              <p class="hint">
                {{ $t('limit.limit_price') }}
              </p>
              <div class="name">
                {{ $formatCryptoAmount(order.price_out) }}
                {{ order.t1_symbol }}
                {{ $t('common.per') }}
                {{ order.t0_symbol }}
              </div>
            </div>
            <div class="item">
              <p class="hint">
                {{ $t('limit.status') }}
              </p>
              <div class="name">
                {{ $t(`limit.${order.status}`) }}
              </div>
            </div>
            <div class="item">
              <p class="hint">
                {{ $t('limit.actions') }}
              </p>
              <div v-if="order.status === 'new'" class="name">
                <button type="button" class="btn cyan btn-circle danger" @click="cancelOrder(order.hash)">
                  <i class="bx bx-trash"></i>
                </button>
              </div>
              <div v-else class="name">
                ---
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="ordersLoading" class="table-item">
      <div class="header row">
        <div class="col">
          <p>
            {{ $t('limit.from') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('limit.to') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('limit.limit_price') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('limit.status') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('limit.actions') }}
          </p>
        </div>
      </div>
      <div v-for="index in 3" :key="index" class="row">
        <div class="col small-visible">
          <div class="number">
            {{ index + 1 }}
          </div>
          <div class="logo">
            <Skeleton width="40px" height="40px" circle />
          </div>
          <div class="name">
            <Skeleton width="70px" height="25px" />
          </div>
        </div>
        <div class="col horizontal small-visible xs-hidden">
          <div class="logo">
            <Skeleton width="40px" height="40px" circle />
          </div>
          <div class="name">
            <Skeleton width="70px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="40px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="60px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton circle width="1rem" height="1rem" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="table-item">
      <div class="not-found-area">
        <div v-if="status === 'new'" class="h3">
          {{ $t('limit.no_open_orders') }}
        </div>
        <div v-else-if="status === 'no_expired_orders'" class="h3">
          {{ $t('limit.no_open_orders') }}
        </div>
        <div v-else class="h3">
          {{ $t('limit.no_orders_history') }}
        </div>
      </div>
    </div>
    <div v-if="orders.length > 0 && withPagination" :class="{'pagination-area': true, 'disabled': ordersLoading}">
      <Pagination :page="filter.page" :step="filter.step" :total="total" @change="setPage" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'
import { Skeleton } from 'vue-loading-skeleton'

export default {
  components: {
    ChevronDown: hydrateWhenIdle(() => import('~/components/svg/new/ChevronDown')),
    Search: hydrateWhenIdle(() => import('~/components/svg/new/Search')),
    Pagination: hydrateWhenIdle(() => import('~/components/analytics/parts/Pagination')),
    Skeleton
  },
  props: {
    withSearch: {
      type: Boolean,
      default: () => false
    },
    withPagination: {
      type: Boolean,
      default: () => true
    },
    status: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      accordions: [],
      filter: {
        page: 1,
        step: 10,
        orderField: 'volume_desc',
        search: ''
      },
      searchTimeout: null,
      timeOutTime: 600
    }
  },
  computed: {
    ...mapGetters('orders', [
      'orders',
      'total',
      'ordersLoading'
    ])
  },
  watch: {
    status(newStatus, oldStatus) {
      if (newStatus !== oldStatus) {
        this.loadModels()
      }
    }
  },
  mounted() {
    this.loadModels()
  },
  methods: {
    ...mapActions('orders', [
      'fetchOrders'
    ]),
    setPage(newPage) {
      this.filter.page = parseInt(newPage, 10)
      this.loadModels()
    },
    loadModels() {
      this.fetchOrders({
        ...this.filter,
        status: this.status
      })
    },
    getNumberSign(number) {
      return number > 0 ? '+' : (number < 0 ? '-' : '')
    },
    openAccordion(indexClicked) {
      const ind = parseInt(indexClicked, 10)
      this.accordions.forEach((item, index) => {
        this.accordions[index] = false
        if (this.$refs['accordionArea' + index][0] !== undefined) {
          this.$refs['accordionArea' + index][0].style.maxHeight = null
        }
      })
      this.accordions[ind] = true
      if (this.$refs['accordionArea' + ind][0] !== undefined) {
        this.$refs['accordionArea' + ind][0].style.maxHeight = this.$refs['accordionArea' + ind][0].scrollHeight + 'px'
      }
    },
    cancelOrder(hash) {
      this.$emit('cancelOrder', hash)
    }
  }
}
</script>

<style scoped>
    @import url('~/assets/css/new/table.css');
</style>
