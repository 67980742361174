<template>
  <div v-if="action === 'deposit'" class="confirm-area">
    <div class="settings-area">
      <div class="inputs-area">
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $t('farm.deposit_action') }}
            </p>
            <p class="balance">
              {{ $t('common.balance') }}: {{ farm !== null ? $formatCryptoAmount(farm.balance, 6) : '...' }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="farmAmount" type="number" step="0.0000001" min="0" @input="checkDepositAmount">
            <button type="button" class="max-button active" @click="setMax()">
              <p class="text">
                MAX
              </p>
            </button>
          </div>
        </div>
      </div>
      <div class="stake-percent percent-area">
        <div class="handler">
          <veeno
            ref="percentSlider"
            connect
            :handles="[0, 0]"
            :range="{
              'min': 0,
              'max': 100
            }"
            :set="[0, parseFloat(percent)]"
            @change="updateVeeno"
            @slide="setSlide"
          />
        </div>
        <div class="range-switch-area">
          <div :class="{'switch-item': true, 'active': percent === 25}" @click="setPercent(25)">
            <span>
              25%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 50}" @click="setPercent(50)">
            <span>
              50%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 75}" @click="setPercent(75)">
            <span>
              75%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 100}" @click="setPercent(100)">
            <span>
              100%
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="buttons-area">
      <button :class="{'btn cyan': true, 'disabled': loading || transaction.pending || farmAmount === 0 }" type="button" :disabled="loading || transaction.pending" @click="confirmDeposit">
        <span v-if="!loading && !transaction.pending">
          {{ $t('farm.confirm_deposit') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
    <div class="links-area">
      <p class="link" @click="createLPLink">
        {{ $t('farm.get_lp_token', {from: farm.token_from.symbol, to: farm.token_to.symbol}) }}
        <NewTabIcon />
      </p>
    </div>
  </div>
  <div v-else-if="action === 'withdraw'" class="confirm-area">
    <div class="settings-area">
      <div class="inputs-area">
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $t('farm.withdraw_action') }}
            </p>
            <p class="balance">
              {{ $t('common.balance') }}: {{ farm !== null ? $formatCryptoAmount(farm.deposited, 6) : '...' }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="farmAmount" type="number" step="0.0000001" min="0" @input="checkWithdrawAmount">
            <button type="button" class="max-button active" @click="setMax()">
              <p class="text">
                MAX
              </p>
            </button>
          </div>
        </div>
      </div>
      <div class="stake-percent percent-area">
        <div class="handler">
          <veeno
            ref="percentSlider"
            connect
            :handles="[0, 0]"
            :range="{
              'min': 0,
              'max': 100
            }"
            :set="[0, parseFloat(percent)]"
            @change="updateVeeno"
            @slide="setSlide"
          />
        </div>
        <div class="range-switch-area">
          <div :class="{'switch-item': true, 'active': percent === 25}" @click="setPercent(25)">
            <span>
              25%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 50}" @click="setPercent(50)">
            <span>
              50%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 75}" @click="setPercent(75)">
            <span>
              75%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 100}" @click="setPercent(100)">
            <span>
              100%
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="buttons-area">
      <button :class="{ 'btn cyan': true, 'disabled': loading || transaction.pending || farmAmount === 0 }" type="button" :disabled="loading || transaction.pending" @click="confirmWithdraw">
        <span v-if="!loading && !transaction.pending">
          {{ $t('farm.confirm_withdraw') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
    <div class="links-area">
      <p class="link" @click="createLPLink">
        {{ $t('farm.get_lp_token', {from: farm.token_from.symbol, to: farm.token_to.symbol}) }}
        <NewTabIcon />
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenIdle,
  hydrateWhenVisible
} from 'vue-lazy-hydration'

import 'nouislider/distribute/nouislider.min.css'

export default {
  components: {
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    veeno: hydrateWhenIdle(() => import('veeno'))
  },
  props: {
    farm: {
      type: Object,
      default: () => {
        return {
          pid: 1,
          apr: -1,
          core: true,
          earned: 0,
          enabled: false,
          index: 0,
          liquidity: -1,
          lpAddress: '0x475A66Ca48A0936788De9780Be25e290eBf42D90',
          multiplier: -1,
          balance: -1,
          opened: false,
          token_from: {
            address: '0x500eeD98c86e34908d06eCc7494FdCf8E1B783fD',
            name: 'Floatie',
            symbol: 'FLE',
            decimals: 18,
            logoURI: 'tokens/floatie_item.svg'
          },
          token_to: {
            address: this.$config.ZERO,
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
            logoURI: 'https://pancakeswap.finance/images/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png'
          },
          lp: {
            address: '0x475A66Ca48A0936788De9780Be25e290eBf42D90',
            name: 'Floatie-BNB LP',
            symbol: 'LP',
            decimals: 18
          },
          reserves: {
            reserve0: 0,
            reserve1: 0
          }
        }
      }
    },
    type: {
      type: String,
      default: () => {
        return 'Global'
      }
    },
    action: {
      type: String,
      default: () => {
        return 'deposit'
      }
    },
    transaction: {
      type: Object,
      default: () => {
        return {
          gasFee: 5000000,
          tx: '',
          loading: false,
          pending: false
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      percent: 0,
      farmAmount: null
    }
  },
  computed: {
    ...mapGetters(['floatieToUsd']),
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    }
  },
  watch: {
    isModalOpened(modalOpen) {
      if (!modalOpen) {
        this.returnInitState()
      }
    }
  },
  methods: {
    setMax() {
      this.percent = 100
      this.changeInputByPercent()
    },
    getCurrentBalance() {
      if (this.action === 'deposit') {
        return this.farm !== null && this.farm.balance !== null && this.farm.balance !== 0 ? this.farm.balance : 0
      } else {
        return this.farm !== null && this.farm.deposited !== null && this.farm.deposited !== 0 ? this.farm.deposited : 0
      }
    },
    changeInputByPercent() {
      const currentBalance = this.getCurrentBalance()
      this.farmAmount = parseFloat(currentBalance) / 100 * this.percent
    },
    setPercent(newPercent) {
      if (newPercent !== undefined && newPercent !== null && !isNaN(newPercent)) {
        this.percent = newPercent
        this.changeInputByPercent()
      }
    },
    setSlide(handles) {
      this.percent = parseFloat(handles.values[1])
      this.changeInputByPercent()
    },
    updateVeeno(handles) {
      if (handles !== undefined && handles.handle === 0) {
        handles.positions[1] = handles.positions[0]
        handles.positions[0] = 0
        handles.unencoded[1] = handles.unencoded[0]
        handles.unencoded[0] = 0
        handles.values[1] = handles.values[0]
        handles.values[0] = 0
      }
      if (this.$refs.percentSlider !== undefined) {
        this.$refs.percentSlider.$el.noUiSlider.set(handles.values)
      }
    },
    checkDepositAmount() {
      const currentBalance = this.getCurrentBalance()
      this.percent = this.farmAmount / parseFloat(currentBalance) * 100
      if (this.percent > 100) {
        this.percent = 100
        this.changeInputByPercent()
      } else if (this.percent < 0) {
        this.percent = 0
        this.changeInputByPercent()
      }
    },
    checkWithdrawAmount() {
      const currentBalance = this.getCurrentBalance()
      this.percent = this.farmAmount / parseFloat(currentBalance) * 100
      if (this.percent > 100) {
        this.percent = 100
      } else if (this.percent < 0) {
        this.percent = 0
      }
      this.changeInputByPercent()
    },
    confirmDeposit() {
      this.loading = true
      this.$emit('deposit', this.farm, this.farmAmount)
      this.closeModal()
    },
    confirmWithdraw() {
      this.loading = true
      this.$emit('withdraw', this.farm, this.farmAmount)
      this.closeModal()
    },
    closeModal(redirect = false) {
      this.$emit('closeGlobalModal')
      this.returnInitState()
      this.farmAmount = null
      this.percent = 0
      if (redirect) {
        this.$router.push({
          path: this.localePath('/farm')
        })
      }
    },
    returnInitState() {
      this.loading = false
      if (this.transaction.pending === false) {
        this.$emit('initConfirmState')
        this.farmAmount = null
        this.percent = 0
      }
    },
    createLPLink() {
      const linkAddress = this.$router.resolve({
        path: this.localePath('/liquidity/add'),
        query: {
          from: this.farm.token_from.address,
          to: this.farm.token_to.address
        }
      })
      this.closeModal()
      window.open(linkAddress.href, '_blank')
    }
  }
}
</script>

  <style scoped>
    @import url('~/assets/css/new/modalContent.css');
  </style>
