var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M17.689 7.68788L15.3779 9.99875L17.689 12.3096L20 9.99875L17.689 7.68788Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M2.31104 7.68912L0 9.99999L2.31104 12.3109L4.62208 9.99999L2.31104 7.68912Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M9.99943 15.3791L6.16861 11.5486L3.85801 13.859L9.99943 20L16.1408 13.859L13.8302 11.5486L9.99943 15.3791Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M9.99943 4.62086L13.8302 8.45141L16.1408 6.14098L9.99943 0L3.85801 6.14098L6.16861 8.45141L9.99943 4.62086Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M9.99811 7.71808L7.71602 10L9.99811 12.2819L12.2802 10L9.99811 7.71808Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }