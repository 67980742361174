<template>
  <div ref="modalArea" :class="{'modal-container': true, 'active': modalVoteOpen}">
    <div class="modal-wrap">
      <div ref="modalInner" class="modal-content-wrap">
        <div class="modal-content">
          <div class="header">
            <div class="h2">
              {{ !descScreen ? $t('voting.confirm_vote') : $t('voting.voting_power') }}
            </div>
            <div class="close-area" @click="closeModal">
              <Plus />
            </div>
          </div>
          <hr>
          <div v-if="descScreen" ref="innerContainer" class="modal-inner">
            <p class="desc">
              {{ $t('voting.voting_desc') }}
            </p>
            <div class="modal-row">
              <div class="header">
                {{ $t('voting.overview') }}
              </div>
              <div class="overview">
                <div class="label">
                  {{ $t('voting.your_voting_power') }}
                </div>
                <div v-if="!power.loading" class="value">
                  {{ $formatCryptoAmount(power.sum, 8) }}
                </div>
                <div v-else class="value">
                  <Skeleton width="150px" height="30px" />
                </div>
              </div>
            </div>
            <div class="modal-row">
              <div class="header">
                {{ $t('voting.your_voting_power_at_block') }}
                <a ref="nofollow noopener noreferrer" :href="$getExplorerLink('block', proposal.block)" target="_blank" class="value">
                  {{ proposal.block }}
                  <i class="bx bx-link-external"></i>
                </a>
              </div>
            </div>
            <div v-if="!power.loading" class="modal-table">
              <div class="table-row">
                <div class="label">
                  {{ $t('voting.wallet') }}
                </div>
                <div class="value">
                  {{ $formatCryptoAmount(power.wallet, 8) }}
                </div>
              </div>
              <div class="table-row">
                <div class="label">
                  {{ $t('voting.fixed_staking') }}
                </div>
                <div class="value">
                  {{ $formatCryptoAmount(power.fixed, 8) }}
                </div>
              </div>
              <div class="table-row">
                <div class="label">
                  {{ $t('voting.flexible_staking') }}
                </div>
                <div class="value">
                  {{ $formatCryptoAmount(power.flexible, 8) }}
                </div>
              </div>
              <div class="table-row">
                <div class="label">
                  {{ $t('voting.other_pools') }}
                </div>
                <div class="value">
                  {{ $formatCryptoAmount(power.staking, 8) }}
                </div>
              </div>
            </div>
            <div v-else class="modal-table">
              <div v-for="ind in 4" :key="ind" class="table-row">
                <div class="label">
                  <Skeleton width="100px" height="16px" />
                </div>
                <div class="value">
                  <Skeleton width="50px" height="16px" />
                </div>
              </div>
            </div>
            <div class="buttons-area">
              <button type="button" class="btn cyan btn-outline" @click="toggleDesc">
                <span class="text">
                  {{ $t('common.back') }}
                </span>
              </button>
            </div>
          </div>
          <div v-else ref="innerContainer" class="modal-inner">
            <div class="modal-row">
              <div class="header">
                {{ $t('voting.voting_for') }}
              </div>
              <div class="value">
                {{ vote.choice.title }}
              </div>
            </div>
            <div class="modal-row power-area" @click="toggleDesc">
              <div class="header">
                {{ $t('voting.your_voting_power') }}
              </div>
              <div v-if="!power.loading" class="value">
                {{ $formatCryptoAmount(power.sum, 8) }}
              </div>
              <div v-else class="value">
                <Skeleton width="150px" height="30px" />
              </div>
              <div class="more-info">
                <i class="bx bxs-chevron-right"></i>
              </div>
            </div>
            <p v-if="power.sum !== 0" class="desc">
              {{ $t('voting.cannot_be_undone') }}
            </p>
            <div v-else-if="!power.loading" class="warning">
              <div class="icon">
                <Warn />
              </div>
              <div class="text-area">
                {{ $t('voting.low_balance') }}
              </div>
            </div>
            <div class="buttons-area">
              <button type="button" class="btn cyan btn-outline" @click="closeModal">
                <span class="text">
                  {{ $t('common.cancel') }}
                </span>
              </button>
              <button
                type="button"
                :class="{'btn pink': true, 'disabled': power.sum === 0 || loading || voted}"
                :disabled="power.sum === 0 || loading || voted"
                @click="voteForChoice"
              >
                <span v-if="!loading">
                  {{ $t('voting.confirm_cta') }}
                </span>
                <span v-else>
                  <i class="bx bx-loader-alt loading"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'
import { Skeleton } from 'vue-loading-skeleton'

export default {
  components: {
    Plus: hydrateWhenVisible(() => import('~/components/svg/new/Plus')),
    Skeleton,
    Warn: hydrateWhenVisible(() => import('~/components/svg/new/Warn'))
  },
  props: {
    proposal: {
      type: Object,
      default: () => {
        return {
          snapshot: '',
          block: '0'
        }
      }
    },
    vote: {
      type: Object,
      default: () => {
        return {
          choice: {
            title: 'none',
            id: 0
          },
          index: null
        }
      }
    },
    power: {
      type: Object,
      default: () => {
        return {
          wallet: 0,
          fixed: 0,
          flexible: 0,
          staking: 0,
          sum: 0,
          loading: true
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      descScreen: false
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalVoteOpen'
    ]),
    ...mapGetters('proposals', [
      'voted'
    ])
  },
  mounted() {
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapActions('proposals', [
      'voteProposal'
    ]),
    ...mapActions('modal', [
      'changeModalVoteState'
    ]),
    handleClickEvents(evt) {
      if (
        this.$refs.modalArea !== undefined &&
          this.$refs.modalArea.contains(evt.target) &&
          !this.$refs.modalInner.contains(evt.target)
      ) {
        this.closeModal()
      }
    },
    toggleDesc() {
      this.descScreen = !this.descScreen
    },
    async voteForChoice() {
      this.loading = true
      try {
        const userAddress = await this.$getUserAddress()
        const nowDate = new Date().getTime()
        const message = {
          message: {
            from: userAddress,
            space: 'Defi Pool',
            timestamp: Math.floor(nowDate / 1000),
            proposal: this.proposal.snapshot,
            choice: this.vote.index,
            reason: '',
            app: 'snapshot'
          }
        }
        const signature = await window.eth.eth.personal.sign(JSON.stringify(message), userAddress)
        await this.voteProposal({
          pid: this.proposal.snapshot,
          cid: this.vote.choice.id,
          votes: this.power.sum,
          signature
        })
        this.closeModal()
        this.$emit('voted')
        this.$nuxt.$emit('openSuccess', this.$t('voting.voted_header'), this.$t('voting.voted_text'))
        this.loading = false
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    },
    closeModal() {
      this.descScreen = false
      this.$emit('closeVoteModal')
      this.changeModalVoteState({
        state: false
      })
    }
  }
}
</script>

<style scoped>
    @import url('~/assets/css/new/modal.css');
</style>
