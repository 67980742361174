var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 30 30",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_102_103)" } }, [
        _c("path", {
          attrs: {
            d: "M22.7435 8.67815L22.3136 7.9104L13.827 12.6621L13.2403 19.8961L13.827 27.5187C15.215 27.2706 16.567 26.7907 17.8334 26.0817C20.8135 24.4132 22.9654 21.6839 23.8928 18.3969C24.8202 15.1098 24.412 11.6583 22.7435 8.67815Z",
            fill: "#12B0FF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M0 20.4038L0.429865 21.1715C2.09835 24.1517 4.8276 26.3036 8.11465 27.231C9.26745 27.5563 10.44 27.7172 11.6058 27.7172C12.3515 27.7172 13.0942 27.6497 13.827 27.5187V12.6621L0 20.4038Z",
            fill: "#36C6FF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M17.4594 12.3927L17.3612 11.7926L13.827 11.0795L13.2403 15.7941L13.827 20.5087C14.9675 19.7795 15.9182 18.7697 16.5707 17.5755C17.4327 15.998 17.7483 14.1575 17.4594 12.3927Z",
            fill: "#36C6FF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M1.75783 8.64404L1.67131 9.62373C1.48881 11.6921 2.09079 13.7004 3.3439 15.3026L1.67588 15.9997L2.01517 16.8116C2.8481 18.8047 4.4073 20.354 6.40557 21.1743C7.39676 21.5812 8.43681 21.7845 9.47687 21.7845C10.5337 21.7845 11.5905 21.5744 12.5949 21.1547C13.025 20.9749 13.4369 20.758 13.8269 20.5087V11.0795L1.75783 8.64404Z",
            fill: "#86DDFF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M25.7764 8.50317H30V10.263H25.7764V8.50317Z",
            fill: "#4477BF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M25.7515 14.4045C28.5241 11.6318 28.5241 7.13656 25.7515 4.36396C22.9789 1.59136 18.4836 1.59138 15.711 4.364C12.9384 7.13662 12.9384 11.6319 15.711 14.4045C18.4836 17.1771 22.9789 17.1771 25.7515 14.4045Z",
            fill: "#86DDFF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M19.8546 7.9165H21.6144V10.8495H19.8546V7.9165Z",
            fill: "#F7F0EB",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_102_103" } }, [
          _c("rect", {
            attrs: {
              width: "30",
              height: "30",
              fill: "white",
              transform: "translate(0 -0.000244141)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }