var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 512 512",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", { attrs: { fill: "currentColor" } }, [
        _c("g", [
          _c("g", [
            _c("path", {
              attrs: {
                d: "M477.899,128.257c-21.639-37.511-52.679-69.173-89.763-91.563l-7.8,12.919c15.644,9.445,29.932,20.414,42.735,32.653\n            l-29.159,29.159c-34.199-32.638-79.925-53.283-130.366-55.166V15.215c33.523,1.015,65.771,8.795,95.964,23.182l6.492-13.623\n            C331.506,8.335,294.496,0,256,0C187.619,0,123.332,26.628,74.981,74.981C26.629,123.333,0,187.62,0,256\n            s26.628,132.668,74.981,181.019C123.333,485.372,187.62,512,256,512s132.668-26.628,181.019-74.981S512,324.38,512,256\n            C512,211.101,500.208,166.928,477.899,128.257z M433.647,93.031c38.724,41.972,61.358,96.582,63.144,155.423h-41.05\n            c-1.807-48.424-20.911-92.498-51.313-126.204L433.647,93.031z M248.454,15.221v41.038\n            c-49.433,1.844-94.328,21.719-128.292,53.232L91.161,80.489C132.577,41.567,187.717,17.099,248.454,15.221z M80.489,91.16\n            l29.003,29.002c-31.513,33.965-51.388,78.859-53.232,128.292H15.221C17.099,187.717,41.567,132.576,80.489,91.16z\n            M15.221,263.546h41.037c1.882,50.441,22.528,96.167,55.166,130.366l-28.985,28.985\n            C42.384,381.258,17.131,325.293,15.221,263.546z M248.454,496.779c-59.727-1.847-114.044-25.536-155.219-63.336l29.016-29.016\n            c33.706,30.402,77.78,49.507,126.204,51.313V496.779z M71.194,256c0-101.903,82.904-184.806,184.806-184.806\n            S440.806,154.098,440.806,256c0,101.903-82.904,184.806-184.806,184.806S71.194,357.903,71.194,256z M263.546,496.779v-41.038\n            c49.433-1.844,94.328-21.719,128.292-53.232l29.003,29.002C379.424,470.432,324.283,494.901,263.546,496.779z M431.511,420.839\n            l-29.003-29.002c31.513-33.964,51.388-78.859,53.232-128.291h41.038C494.901,324.283,470.432,379.423,431.511,420.839z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M255.998,136.249c-66.031,0-119.75,53.72-119.75,119.751c0,31.518,12.151,61.277,34.214,83.795l10.779-10.562\n            c-19.284-19.681-29.903-45.688-29.903-73.233c0-57.709,46.95-104.659,104.659-104.659c57.709,0,104.659,46.95,104.659,104.659\n            c0,57.709-46.95,104.659-104.659,104.659c-21.528,0-42.207-6.488-59.801-18.765l-8.635,12.377\n            c20.139,14.052,43.804,21.479,68.436,21.479c66.031,0,119.751-53.72,119.751-119.751\n            C375.749,189.969,322.029,136.249,255.998,136.249z",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "247.991",
                y: "96.172",
                width: "16.029",
                height: "15.091",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "247.991",
                y: "400.735",
                width: "16.029",
                height: "15.091",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "95.709",
                y: "248.453",
                width: "16.03",
                height: "15.091",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "135.782",
                y: "136.254",
                width: "16.029",
                height: "15.091",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "368.208",
                y: "144.263",
                width: "16.029",
                height: "15.091",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "368.208",
                y: "352.644",
                width: "16.029",
                height: "15.091",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "127.763",
                y: "352.644",
                width: "16.029",
                height: "15.091",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                x: "400.262",
                y: "248.453",
                width: "16.029",
                height: "15.091",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M184.337,256h15.091c0-31.194,25.378-56.571,56.571-56.571v-15.091C216.485,184.337,184.337,216.485,184.337,256z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M256,327.663c39.515,0,71.663-32.148,71.663-71.663h-15.091c0,31.194-25.379,56.571-56.571,56.571V327.663z",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }