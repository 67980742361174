export const state = () => ({
  pairs: [],
  shares: [],
  total: 0,
  pair: null,
  pairsLoading: false,
  pairLoading: false
})

export const mutations = {
  setPairs(state, pairs) {
    state.pairs = pairs
  },
  setPair(state, pair) {
    state.pair = pair
  },
  setPairsTotal(state, total) {
    state.total = total
  },
  addPairs(state, pairs) {
    state.pairs = state.pairs.concat(pairs)
  },
  removePair(state, pairIndex) {
    const itemIndex = state.pairs.findIndex((pair, index) => index === pairIndex)
    if (itemIndex !== -1) {
      state.pairs.splice(itemIndex, 1)
    }
  },
  setShares(state, shares) {
    state.shares = shares
  },
  setLoadingPairs(state, status) {
    state.pairsLoading = status
  },
  setLoadingPair(state, status) {
    state.pairLoading = status
  }
}

export const actions = {
  async fetchPairs({
    commit
  }, payload) {
    commit('setLoadingPairs', true)
    const request = await this.$generateApiRequest()
    const pairs = await request.$get('pairs', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        search: payload.search !== undefined && payload.search !== null ? payload.search : '',
        tokens: payload.tokens !== undefined && payload.tokens !== null ? payload.tokens : null
      }
    })
    commit('setPairs', pairs.answer.pairs)
    commit('setPairsTotal', pairs.answer.total)
    commit('setLoadingPairs', false)
    this.$refreshCookieExpires()
  },
  async fetchShares({
    commit
  }, payload) {
    commit('setLoadingPairs', true)
    const request = await this.$generateApiRequest()
    const pools = await request.$get('shares/' + payload.address)
    commit('setShares', pools.answer.shares)
    commit('setLoadingPairs', false)
    this.$refreshCookieExpires()
  },
  async addPair({
    commit
  }, payload) {
    commit('setLoadingPair', true)
    const request = await this.$generateApiRequest()
    await request.$post('pairs', {
      bid: payload.chainId,
      address: payload.address,
      name: payload.name,
      tid0: payload.token0 !== undefined && payload.token0 !== null ? payload.token0 : '',
      tid1: payload.token1 !== undefined && payload.token1 !== null ? payload.token1 : ''
    })
    commit('setLoadingPair', false)
    this.$refreshCookieExpires()
  },
  async updatePairShare({
    commit
  }, payload) {
    commit('setLoadingPair', true)
    const request = await this.$generateApiRequest()
    await request.$put('shares/' + payload.address, {
      bid: payload.chainId,
      reservres: payload.reservres,
      total_supply: payload.totalSupply,
      share: payload.share !== undefined && payload.share !== null ? payload.share : '',
      tid0: payload.tid0 !== undefined ? payload.tid0 : '',
      tid1: payload.tid1 !== undefined ? payload.tid0 : '',
      t0_amount: payload.from_value !== undefined ? payload.from_value : 0,
      t1_amount: payload.to_value !== undefined ? payload.to_value : 0
    })
    commit('setLoadingPair', false)
    this.$refreshCookieExpires()
  },
  async checkPairAddress({
    commit
  }, payload) {
    commit('setLoadingPair', true)
    const request = await this.$generateApiRequest()
    await request.$get('pairs/' + payload.hash + '/check')
    commit('setLoadingPair', false)
    this.$refreshCookieExpires()
  },
  async fetchPair({
    commit
  }, payload) {
    commit('setLoadingPair', true)
    const request = await this.$generateApiRequest()
    const pair = await request.$get('pairs/' + payload.address)
    commit('setPair', pair.answer.pair)
    commit('setLoadingPair', false)
  }
}

export const getters = {
  pairs: state => state.pairs,
  shares: state => state.shares,
  pair: state => state.pair,
  total: state => state.total,
  pairsLoading: state => state.pairsLoading,
  pairLoading: state => state.pairLoading
}
