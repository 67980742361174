var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "20",
        viewbox: "0 0 16 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M15.2311 0H0.769246C0.344314 0 0 0.320043 0 0.715021V19.285C0 19.5624 0.172927 19.815 0.443086 19.9325C0.713245 20.0501 1.03264 20.0117 1.26187 19.8338L8.00015 14.6085L14.7381 19.8338C14.8791 19.9434 15.0541 20 15.2311 20C15.3415 20 15.4529 19.9777 15.5569 19.9325C15.8274 19.815 16 19.5624 16 19.285V0.715021C16.0003 0.320043 15.656 0 15.2311 0ZM14.4618 17.7571L8.49309 13.1284C8.35032 13.0177 8.17523 12.9622 8.00015 12.9622C7.82507 12.9622 7.64999 13.0174 7.50722 13.1284L1.53849 17.7571V1.43004H14.4618V17.7571Z",
          fill: "#0C275C",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }