<template>
  <svg viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
      <path d="M3.16208 12.2268C3.1414 12.2268 3.12028 12.2255 3.09873 12.2223C2.09336 12.0724 1.12891 11.7467 0.233094 11.2539C0.0188205 11.1358 -0.0625769 10.8605 0.0518196 10.6388C0.166656 10.4181 0.431968 10.334 0.647561 10.4517C1.45274 10.8955 2.31995 11.188 3.22412 11.3225C3.46479 11.3583 3.63155 11.5886 3.59723 11.8371C3.56555 12.0638 3.37724 12.2268 3.16208 12.2268Z" />
      <path d="M18.8379 12.2268C18.6228 12.2268 18.4345 12.0638 18.4028 11.8376C18.3685 11.5891 18.5352 11.3588 18.7759 11.3229C19.6805 11.1885 20.5473 10.8959 21.3524 10.4521C21.5685 10.3345 21.8333 10.4185 21.9482 10.6393C22.0626 10.8605 21.9812 11.1362 21.7669 11.2543C20.8711 11.7472 19.9066 12.0729 18.9013 12.2228C18.8797 12.2255 18.8586 12.2268 18.8379 12.2268Z" />
      <path d="M14.2357 8.14911C14.1529 8.14911 14.0698 8.12504 13.9959 8.07553C13.7922 7.93834 13.7345 7.65717 13.8674 7.44685C14.4909 6.45978 15.5442 5.87018 16.6855 5.87018C16.9288 5.87018 17.1255 6.07322 17.1255 6.32442C17.1255 6.57562 16.9288 6.77866 16.6855 6.77866C15.8425 6.77866 15.0646 7.21383 14.6048 7.94289C14.5208 8.07643 14.38 8.14911 14.2357 8.14911Z" />
      <path d="M17.661 6.77866C16.1012 6.77866 14.7839 5.41866 14.7839 3.80882C14.7839 2.19899 16.1012 0.838986 17.661 0.838986C19.2203 0.838986 20.5376 2.19899 20.5376 3.80882C20.5376 5.41866 19.2203 6.77866 17.661 6.77866ZM17.661 1.74747C16.5786 1.74747 15.6639 2.69139 15.6639 3.80882C15.6639 4.92626 16.5786 5.87018 17.661 5.87018C18.7433 5.87018 19.6576 4.92626 19.6576 3.80882C19.6576 2.69139 18.7433 1.74747 17.661 1.74747Z" />
      <path d="M21.5597 9.79801C21.3164 9.79801 21.1197 9.59497 21.1197 9.34377C21.1197 7.92971 20.0052 6.77912 18.6355 6.77912C18.3922 6.77912 18.1955 6.57607 18.1955 6.32487C18.1955 6.07368 18.3922 5.87063 18.6355 5.87063C20.4905 5.87063 21.9997 7.42914 21.9997 9.34377C21.9997 9.59451 21.803 9.79801 21.5597 9.79801Z" />
      <path d="M18.6355 6.77866H16.6859C16.4426 6.77866 16.246 6.57562 16.246 6.32442C16.246 6.07322 16.4426 5.87018 16.6859 5.87018H18.6355C18.8788 5.87018 19.0755 6.07322 19.0755 6.32442C19.0755 6.57562 18.8784 6.77866 18.6355 6.77866Z" />
      <path d="M21.5597 11.3075C21.3164 11.3075 21.1197 11.1044 21.1197 10.8532V9.34377C21.1197 9.09257 21.3164 8.88953 21.5597 8.88953C21.803 8.88953 21.9997 9.09257 21.9997 9.34377V10.8532C21.9997 11.1044 21.803 11.3075 21.5597 11.3075Z" />
      <path d="M5.80156 11.6427C5.55825 11.6427 5.36158 11.4396 5.36158 11.1884C5.36158 8.71828 7.30807 6.70916 9.70028 6.70916C9.94359 6.70916 10.1403 6.91221 10.1403 7.16341C10.1403 7.4146 9.94359 7.61765 9.70028 7.61765C7.79294 7.61765 6.24155 9.21931 6.24155 11.1884C6.24155 11.4396 6.04443 11.6427 5.80156 11.6427Z" />
      <path d="M12.2997 7.61765H9.70028C9.45697 7.61765 9.26029 7.4146 9.26029 7.16341C9.26029 6.91221 9.45697 6.70916 9.70028 6.70916H12.2997C12.543 6.70916 12.7397 6.91221 12.7397 7.16341C12.7397 7.4146 12.543 7.61765 12.2997 7.61765Z" />
      <path d="M7.76434 8.14911C7.62046 8.14911 7.47967 8.07643 7.39519 7.94289C6.93497 7.21428 6.15751 6.77866 5.3145 6.77866C5.07118 6.77866 4.87451 6.57562 4.87451 6.32442C4.87451 6.07322 5.07118 5.87018 5.3145 5.87018C6.45626 5.87018 7.50915 6.45978 8.13261 7.44685C8.26548 7.65717 8.20785 7.93834 8.00413 8.07553C7.92978 8.12504 7.84662 8.14911 7.76434 8.14911Z" />
      <path d="M5.80156 13.6554C5.55825 13.6554 5.36158 13.4524 5.36158 13.2012V11.1884C5.36158 10.9373 5.55825 10.7342 5.80156 10.7342C6.04487 10.7342 6.24155 10.9373 6.24155 11.1884V13.2012C6.24155 13.4524 6.04443 13.6554 5.80156 13.6554Z" />
      <path d="M11 7.61765C9.00026 7.61765 7.31072 5.87336 7.31072 3.80882C7.31072 1.74429 9.00026 0 11 0C12.9997 0 14.6893 1.74429 14.6893 3.80882C14.6893 5.87336 12.9997 7.61765 11 7.61765ZM11 0.908485C9.47721 0.908485 8.19069 2.23669 8.19069 3.80882C8.19069 5.38096 9.47721 6.70916 11 6.70916C12.5228 6.70916 13.8093 5.38096 13.8093 3.80882C13.8093 2.23669 12.5228 0.908485 11 0.908485Z" />
      <path d="M16.1984 11.6427C15.9551 11.6427 15.7585 11.4396 15.7585 11.1884C15.7585 9.21931 14.2071 7.61765 12.2997 7.61765C12.0564 7.61765 11.8597 7.4146 11.8597 7.16341C11.8597 6.91221 12.0564 6.70916 12.2997 6.70916C14.6924 6.70916 16.6384 8.71873 16.6384 11.1884C16.6384 11.4396 16.4418 11.6427 16.1984 11.6427Z" />
      <path d="M16.1984 13.6554C15.9551 13.6554 15.7585 13.4524 15.7585 13.2012V11.1884C15.7585 10.9373 15.9551 10.7342 16.1984 10.7342C16.4418 10.7342 16.6384 10.9373 16.6384 11.1884V13.2012C16.6384 13.4524 16.4418 13.6554 16.1984 13.6554Z" />
      <path d="M11 15C9.14326 15 7.28696 14.5339 5.59433 13.6023C5.38005 13.4837 5.29866 13.2089 5.41305 12.9873C5.52789 12.7665 5.79276 12.6825 6.0088 12.8001C9.13446 14.5212 12.8664 14.5212 15.9916 12.8001C16.2072 12.6825 16.473 12.7665 16.5874 12.9873C16.7018 13.2085 16.6204 13.4837 16.4061 13.6023C14.713 14.5335 12.8567 15 11 15Z" />
      <path d="M4.33905 6.77866C2.77974 6.77866 1.46242 5.41866 1.46242 3.80882C1.46242 2.19899 2.77974 0.838986 4.33905 0.838986C5.89836 0.838986 7.21568 2.19899 7.21568 3.80882C7.21568 5.41866 5.89836 6.77866 4.33905 6.77866ZM4.33905 1.74747C3.25668 1.74747 2.34239 2.69139 2.34239 3.80882C2.34239 4.92626 3.25668 5.87018 4.33905 5.87018C5.42141 5.87018 6.33571 4.92626 6.33571 3.80882C6.33571 2.69139 5.42141 1.74747 4.33905 1.74747Z" />
      <path d="M0.440328 9.79801C0.197015 9.79801 0.000341137 9.59497 0.000341137 9.34377C0.000341137 7.42868 1.50993 5.87063 3.36448 5.87063C3.60779 5.87063 3.80446 6.07368 3.80446 6.32487C3.80446 6.57607 3.60779 6.77912 3.36448 6.77912C1.9948 6.77866 0.880314 7.92926 0.880314 9.34377C0.880314 9.59451 0.68364 9.79801 0.440328 9.79801Z" />
      <path d="M5.31406 6.77866H3.36448C3.12116 6.77866 2.92449 6.57562 2.92449 6.32442C2.92449 6.07322 3.12116 5.87018 3.36448 5.87018H5.31406C5.55737 5.87018 5.75404 6.07322 5.75404 6.32442C5.75404 6.57562 5.55737 6.77866 5.31406 6.77866Z" />
      <path d="M0.440328 11.3075C0.197015 11.3075 0.000341137 11.1044 0.000341137 10.8532V9.34377C0.000341137 9.09257 0.197015 8.88953 0.440328 8.88953C0.68364 8.88953 0.880314 9.09257 0.880314 9.34377V10.8532C0.880314 11.1044 0.68364 11.3075 0.440328 11.3075Z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CommunityIcon'
}
</script>
