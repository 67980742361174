<template>
  <svg viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.36349 20.2458C0.161551 20.2458 0 20.0785 0 19.8765V10.4258C0 10.2239 0.161551 10.0623 0.36349 10.0623C0.571198 10.0623 0.732749 10.2239 0.732749 10.4258V19.8996C0.732749 20.0785 0.571198 20.2458 0.36349 20.2458Z" fill="currentColor" />
    <path d="M19.9169 20.2631C19.715 20.2631 19.5534 20.1015 19.5534 19.8996V10.4258C19.5534 10.2239 19.715 10.0623 19.9169 10.0623C20.1189 10.0623 20.2804 10.2239 20.2804 10.4258V19.8996C20.2631 20.1015 20.1189 20.2631 19.9169 20.2631Z" fill="currentColor" />
    <path d="M10.1431 15.5262C4.4715 15.5262 0.017309 13.2933 0.017309 10.4258C0.017309 8.943 1.2174 7.58135 3.41565 6.5832C3.51373 6.54281 3.65797 6.54281 3.76183 6.60628C3.85991 6.66397 3.92338 6.79091 3.92338 6.91207V6.96977C3.92338 7.17748 3.72144 7.35634 3.51373 7.31595C1.74821 8.15255 0.732749 9.28918 0.732749 10.4258C0.732749 12.8029 5.0427 14.8165 10.1431 14.8165C15.2435 14.8165 19.5534 12.8029 19.5534 10.4258C19.5534 9.28918 18.5553 8.16986 16.7898 7.31595C16.582 7.33903 16.3801 7.17748 16.3801 6.96977V6.91207C16.3801 6.79091 16.4436 6.68705 16.5417 6.60628C16.6455 6.54281 16.7667 6.5255 16.8878 6.5832C19.063 7.55827 20.2631 8.91992 20.2631 10.4258C20.2631 13.2933 15.8147 15.5262 10.1431 15.5262Z" fill="currentColor" />
    <path d="M10.1431 20.2862C4.4715 20.2862 0.017309 18.0475 0.017309 15.18C0.017309 14.9781 0.18463 14.8165 0.386568 14.8165C0.588507 14.8165 0.750058 14.9781 0.750058 15.18C0.750058 17.5629 5.06 19.5707 10.1604 19.5707C15.2608 19.5707 19.5707 17.5629 19.5707 15.18C19.5707 14.9781 19.7323 14.8165 19.94 14.8165C20.1419 14.8165 20.3035 14.9781 20.3035 15.18C20.2631 18.0302 15.8147 20.2862 10.1431 20.2862Z" fill="currentColor" />
    <path d="M10.1431 25C4.4715 25 0.017309 22.7614 0.017309 19.8996C0.017309 19.6919 0.18463 19.5303 0.386568 19.5303C0.588507 19.5303 0.750058 19.6919 0.750058 19.8996C0.750058 22.2767 5.06 24.2903 10.1604 24.2903C15.2608 24.2903 19.5707 22.2767 19.5707 19.8996C19.5707 19.6919 19.7323 19.5303 19.94 19.5303C20.1419 19.5303 20.3035 19.6919 20.3035 19.8996C20.2631 22.7614 15.8147 25 10.1431 25Z" fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1431 13.9453C6.30048 13.9453 3.19063 10.8124 3.19063 6.96977C3.19063 3.13293 6.30048 0 10.1431 0C13.9799 0 17.1129 3.13293 17.1129 6.96977C17.1129 10.8124 13.9799 13.9453 10.1431 13.9453ZM10.1431 0.70967C6.70436 0.70967 3.9003 3.51373 3.9003 6.96977C3.9003 10.4258 6.70436 13.2299 10.1431 13.2299C13.5991 13.2299 16.4032 10.4258 16.4032 6.96977C16.4032 3.51373 13.5991 0.70967 10.1431 0.70967Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'CoinsIcon'
}
</script>
