var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 26 26",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M13 0C5.85965 0 0 5.85965 0 13C0 20.1404 5.85965 26 13 26C20.1404 26 26 20.1404 26 13C26 5.85965 20.1404 0 13 0ZM12.8378 17.6301C12.0479 18.3483 10.8332 18.3194 10.0783 17.5645L6.81104 14.2973C6.21616 13.7024 6.21616 12.7379 6.81104 12.1431C7.40591 11.5482 8.3704 11.5482 8.96527 12.1431L10.178 13.3558C10.9329 14.1107 12.1476 14.1396 12.9375 13.4215L17.7035 9.08893C18.3259 8.52309 19.2892 8.56884 19.8552 9.19114C20.4214 9.81366 20.3757 10.7774 19.753 11.3434L12.8378 17.6301Z",
            fill: "currentColor",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }