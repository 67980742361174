<template>
  <div ref="modalArea" :class="{'modal-container': true, 'active': isModalOpened}">
    <div class="modal-wrap">
      <div ref="modalInner" :class="{'modal-content-wrap': true, 'visible': visible}">
        <div class="modal-content">
          <div class="header">
            <div class="h2">
              {{ title }}
            </div>
            <div class="close-area" @click="closeModal">
              <Plus />
            </div>
          </div>
          <hr>
          <div ref="innerContainer" class="modal-inner">
            <slot
              ref="modalContent"
              name="modal-content"
              :type="type"
            ></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  components: {
    Plus: hydrateWhenVisible(() => import('~/components/svg/new/Plus'))
  },
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      }
    },
    type: {
      type: String,
      default: () => {
        return 'Global'
      }
    },
    visible: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    handleClickEvents(evt) {
      if (
        this.$refs.modalArea !== undefined &&
        this.$refs.modalArea.contains(evt.target) &&
        !this.$refs.modalInner.contains(evt.target)
      ) {
        this.closeModal()
        this.modaTitle = this.title
      }
    },
    closeModal() {
      this.$emit('close' + this.type + 'Modal')
      this.$store.dispatch('modal/changeModal' + this.type + 'State', {
        state: false
      })
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modal.css');
</style>
