export const state = () => ({
  currencies: [],
  currenciesLoading: false
})

export const mutations = {
  setCurrencies(state, currencies) {
    state.currencies = currencies
  },
  setLoadingCurrencies(state, status) {
    state.currenciesLoading = status
  }
}

export const actions = {
  async fetchCurrencies({
    commit
  }, payload) {
    commit('setLoadingCurrencies', true)
    const request = await this.$generateApiRequest()
    const currencies = await request.$get('currencies')
    commit('setCurrencies', currencies.answer.currencies)
    commit('setLoadingCurrencies', false)
  }
}

export const getters = {
  currencies: state => state.currencies,
  currenciesLoading: state => state.lcurrenciesLoading
}
