var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "error-screen" },
    [
      _c(_vm.errorType, { tag: "component", attrs: { error: _vm.error } }),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-area" }, [
        _c(
          "button",
          {
            staticClass: "btn cyan btn-outline",
            attrs: { type: "button" },
            on: { click: _vm.loadPreviousPage },
          },
          [
            _c("span", { staticClass: "text" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("errors.go_back")) + "\n      "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn cyan",
            attrs: { type: "button" },
            on: { click: _vm.loadHomePage },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("errors.go_home")) + "\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cyan-cloud" }, [_c("CloudCyanError")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }