var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            stroke: "none",
            fill: "currentColor",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2510 4901 c-74 -24 -119 -63 -145 -123 -22 -55 -22 -541 0 -596 64\n        -152 273 -172 370 -34 l30 44 3 266 c4 286 0 320 -45 372 -48 57 -151 91 -213\n        71z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M965 4256 c-59 -27 -77 -46 -104 -106 -26 -57 -26 -113 0 -170 24\n        -53 321 -358 384 -395 90 -52 181 -40 255 35 75 74 87 165 35 255 -37 63 -342\n        360 -395 384 -59 27 -114 26 -175 -3z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3980 4259 c-54 -25 -358 -321 -398 -389 -38 -64 -38 -147 1 -212 58\n        -100 189 -134 287 -76 68 40 364 344 389 398 82 179 -100 361 -279 279z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M2435 3619 c-164 -21 -338 -87 -476 -180 -89 -59 -209 -178 -270\n        -266 -259 -375 -259 -852 1 -1227 60 -86 189 -214 273 -269 258 -171 574 -225\n        868 -148 432 114 740 478 790 934 13 113 1 246 -31 372 -26 102 -114 281 -178\n        365 -180 237 -445 386 -747 420 -102 11 -132 11 -230 -1z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M340 2754 c-141 -61 -168 -258 -50 -357 52 -45 86 -49 372 -45 l266\n        3 44 30 c138 97 118 306 -34 370 -54 22 -546 22 -598 -1z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M4180 2754 c-141 -61 -168 -258 -50 -357 52 -45 86 -49 372 -45 l266\n        3 44 30 c138 97 118 306 -34 370 -54 22 -546 22 -598 -1z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M1261 1544 c-52 -22 -366 -338 -396 -398 -92 -184 97 -373 281 -281\n        63 31 376 344 399 398 58 140 -46 298 -196 297 -30 -1 -69 -7 -88 -16z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3669 1537 c-64 -34 -102 -93 -107 -166 -7 -101 4 -118 191 -309 183\n      -184 236 -222 311 -222 43 0 117 29 150 58 30 26 66 113 66 157 -1 75 -32 121\n      -208 298 -99 99 -183 176 -208 187 -57 28 -138 27 -195 -3z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M2510 1061 c-74 -24 -119 -63 -145 -123 -22 -55 -22 -541 0 -596 64\n        -152 273 -172 370 -34 l30 44 3 266 c4 286 0 320 -45 372 -48 57 -151 91 -213\n        71z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }