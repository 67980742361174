export const state = () => ({
  device: {},
  blockchains: [],
  blockchainsTotal: 0,
  blockchainsLoading: false,
  blockchain: null,
  blockchainLoading: false,
  eth: {},
  darkTheme: false,
  userAddress: null,
  floatieToUsd: 1.521
})

export const mutations = {
  setDarkTheme(state, value) {
    state.darkTheme = value
    if (typeof document !== 'undefined') {
      if (state.darkTheme) {
        document.querySelector('html').classList.add('dark')
      } else {
        document.querySelector('html').classList.remove('dark')
      }
    }
  },
  setUserAddress(state, value) {
    state.userAddress = value
  },
  setDevice(state, value) {
    state.device = value
  },
  setBlockchains(state, blockchains) {
    state.blockchains = blockchains
  },
  setBlockchain(state, blockchain) {
    state.blockchain = blockchain
  },
  setBlockchainsTotal(state, total) {
    state.blockchainsTotal = total
  },
  addBlockchains(state, blockchains) {
    state.blockchains = state.blockchains.concat(blockchains)
  },
  setLoadingBlockchains(state, status) {
    state.blockchainsLoading = status
  },
  setLoadingBlockchain(state, status) {
    state.blockchainLoading = status
  },
  setEth(state, eth) {
    state.eth = eth
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { req }) {
    const token = this.$cookies.get('auth.token')
    const user = this.$cookies.get('auth.user')
    if (token !== undefined) {
      commit('auth/setUserToken', token)
    }
    if (user !== undefined) {
      commit('auth/setUser', user)
    }

    const request = await this.$generateApiRequest()
    const eth = await request.$get('blockchains', {
      params: {
        search: 'BNB'
      }
    })
    commit('setEth', eth.answer.currencies[0])
  },
  async fetchBlockchains({
    commit
  }, payload) {
    commit('setLoadingBlockchains', true)
    const request = await this.$generateApiRequest()
    const blockchains = await request.$get('blockchains/list', {
      params: {
        step: payload.step !== undefined ? payload.step : 15,
        search: payload.search !== undefined ? payload.search : '',
        ids: payload.ids !== undefined ? payload.ids : ''
      }
    })
    commit('setBlockchains', blockchains.answer.currencies)
    commit('setBlockchainsTotal', blockchains.total)
    commit('setLoadingBlockchains', false)
  },
  async fetchBlockchain({
    commit
  }, payload) {
    commit('setLoadingBlockchain', true)
    const request = await this.$generateApiRequest()
    const blockchains = await request.$get('blockchains/' + payload.code.toLowerCase(), {})
    commit('setBlockchain', blockchains.answer.currency)
    commit('setLoadingBlockchain', false)
  }
}

export const getters = {
  darkTheme: state => state.darkTheme,
  userAddress: state => state.userAddress,
  floatieToUsd: state => state.floatieToUsd,
  blockchain: state => state.blockchain,
  blockchains: state => state.blockchains,
  blockchainsTotal: state => state.blockchainsTotal,
  blockchainsLoading: state => state.blockchainsLoading,
  blockchainLoading: state => state.blockchainLoading,
  eth: state => state.eth
}
