<template>
  <div class="tokens-list">
    <div
      v-for="(token, index) in list"
      :key="index"
      :class="{ 'item': true, 'active': isTokenActive(token.symbol) }"
      @click="selectFromToken(token)"
    >
      <div class="info-area">
        <div class="icon-area">
          <nuxt-img
            :src="token.address !== $config.ZERO ? $getImageByAddress(token.address) : $getImage(token.logoURI)"
            :alt="token.name"
            :title="token.symbol"
            :quality="80"
            format="webp"
            lazy
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
        </div>
        <div class="text-area">
          {{ token.symbol }}
        </div>
      </div>
      <div class="tooltip-name">
        {{ token.balance !== undefined && token.balance !== null && parseFloat(token.balance) !== 0 ? $formatCryptoAmount(parseFloat(token.balance), 4) : '' }}
        {{ token.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TokensList',
  props: {
    currentToken: {
      type: Object,
      default: () => {
        return {
          symbol: '',
          address: '',
          decimals: 18
        }
      }
    },
    pairToken: {
      type: Object,
      default: () => {
        return {
          symbol: '',
          address: '',
          decimals: 18
        }
      }
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tokens: this.list,
      refreshPeriod: null,
      refreshTime: 250
    }
  },
  created() {
    this.$nuxt.$on('tokenListToggle', () => this.refreshList())
  },
  methods: {
    isTokenActive(symbol) {
      return this.currentToken.symbol.toLowerCase() === symbol.toLowerCase() ||
        this.pairToken.symbol.toLowerCase() === symbol.toLowerCase()
    },
    refreshList() {
      if (this.refreshPeriod !== null) {
        clearTimeout(this.refreshPeriod)
      }
      this.refreshPeriod = setTimeout(() => {
        this.$store.commit('tokens/sortList', 'balance')
      }, this.refreshTime)
    },
    selectFromToken(token) {
      this.$emit('pick', token)
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/tokenSelector.css');
</style>
