var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 27 27",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.6155 -0.000244141C6.2235 -0.000244141 0 5.93976 0 13.2643V26.9998L13.6125 26.9848C21.0045 26.9848 27 20.8198 27 13.4953C27 6.16926 21 -0.000244141 13.6155 -0.000244141ZM19.4955 19.2883C18.2959 20.4255 16.7858 21.1813 15.1565 21.4598C13.5271 21.7383 11.8517 21.527 10.3425 20.8528L4.95 22.0603L6.4515 17.2723C5.76646 16.0221 5.4212 14.6142 5.45015 13.189C5.47911 11.7638 5.88126 10.371 6.6165 9.14976C7.3584 7.92296 8.40454 6.90855 9.65361 6.20478C10.9027 5.50102 12.3123 5.13175 13.746 5.13276L13.755 5.13426C15.4052 5.13129 17.0189 5.62033 18.3898 6.53891C19.7607 7.45749 20.8267 8.7639 21.4515 10.2913C22.0686 11.8136 22.212 13.4867 21.863 15.0918C21.514 16.697 20.6889 18.1595 19.4955 19.2883Z",
          fill: "#9EDDFB",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }