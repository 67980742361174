var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "32",
        viewbox: "0 0 20 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M3.02251 31.9031C3.41626 32.0976 3.89458 31.993 4.17051 31.6469L19.1705 12.8344C19.395 12.5529 19.4384 12.1676 19.2821 11.8432C19.1259 11.5188 18.7976 11.3125 18.4375 11.3125H12.4507L17.402 1.35494C17.5465 1.06431 17.5304 0.719625 17.3594 0.44375C17.1886 0.167875 16.887 0 16.5625 0H5.31251C4.88195 0 4.50676 0.29325 4.40276 0.711L0.652764 15.7735C0.583077 16.0535 0.646202 16.35 0.823827 16.5773C1.00151 16.8047 1.27401 16.9375 1.56251 16.9375H5.98801L2.52783 30.836C2.42126 31.2638 2.6272 31.708 3.02251 31.9031ZM8.09726 16.2265C8.16695 15.9465 8.10383 15.65 7.9262 15.4227C7.74851 15.1953 7.47601 15.0625 7.18751 15.0625H2.76201L6.0452 1.875H15.0493L10.0979 11.8326C9.95345 12.1232 9.96951 12.4679 10.1405 12.7437C10.3114 13.0197 10.6129 13.1875 10.9374 13.1875H16.4909L5.38651 27.1143L8.09726 16.2265Z",
          fill: "#89D8FC",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }