<template>
  <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path d="m106 72h-32a2 2 0 0 0 -2 2v32a2 2 0 0 0 2 2h32a2 2 0 0 0 2-2v-32a2 2 0 0 0 -2-2zm-2 32h-28v-28h28z" />
    <path d="m54 72h-32a2 2 0 0 0 -2 2v32a2 2 0 0 0 2 2h32a2 2 0 0 0 2-2v-32a2 2 0 0 0 -2-2zm-2 32h-28v-28h28z" />
    <path d="m54 20h-32a2 2 0 0 0 -2 2v32a2 2 0 0 0 2 2h32a2 2 0 0 0 2-2v-32a2 2 0 0 0 -2-2zm-2 32h-28v-28h28z" />
    <path
      d="m114 36.59-22.59-22.59a2 2 0 0 0 -2.82 0l-22.59 22.59a2 2 0 0 0 0 2.82l22.59 22.59a2 2 0 0 0 2.82 0l22.59-22.59a2 2 0 0 0 0-2.82zm-24 21.21-19.8-19.8 19.8-19.8 19.8 19.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CubesIcon'
}
</script>
