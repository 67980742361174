var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewbox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M31.1416 6.81017C31.1416 6.4042 30.8963 6.03841 30.5209 5.88411L16.3804 0.0750859C16.1366 -0.0250286 15.8633 -0.0250286 15.6196 0.0750859L1.47912 5.88411C1.10363 6.03835 0.858414 6.4042 0.858414 6.81017V25.1898C0.858414 25.5958 1.10357 25.9616 1.47912 26.1159L15.6196 31.9249C15.8607 32.024 16.1318 32.0261 16.3792 31.9249C16.3913 31.9208 15.8559 32.1404 30.5209 26.1159C30.8964 25.9617 31.1416 25.5958 31.1416 25.1898V6.81017ZM16 11.6211L10.7898 9.48066L22.0496 4.56873L27.6083 6.85228L16 11.6211ZM2.8607 8.38798L7.35591 10.2347V15.4446C7.35591 15.9975 7.80417 16.4458 8.35706 16.4458C8.90994 16.4458 9.3582 15.9975 9.3582 15.4446V11.0572L14.9988 13.3744V29.5052L2.8607 24.5188V8.38798ZM16 2.08345L19.4706 3.5092L8.21076 8.42114L4.39171 6.85222L16 2.08345ZM17.0011 13.3744L29.1393 8.38798V24.5188L17.0011 29.5052V13.3744Z",
          fill: "#89D8FC",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }