var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewbox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M31.7006 12.9193C30.9496 9.26707 29.1563 6.2145 26.3236 3.79158C23.6494 1.5043 20.5412 0.246434 17.0303 0.0308407C15.3527 -0.0719586 13.6865 0.0793853 12.0589 0.527705C7.33301 1.82697 3.80788 4.62826 1.57344 9.00151C0.554019 10.9961 0.0171821 13.1335 0.0100433 15.3765C-0.00851752 20.6178 0.0043323 25.8592 0.0043323 31.1005C0.0043323 31.3889 0.0043323 31.6788 0.0043323 32C0.187085 32 0.338428 32 0.488342 32C5.68681 31.9971 10.8838 31.9929 16.0823 31.9943C17.6057 31.9943 19.1049 31.8073 20.5583 31.3518C25.1229 29.9255 28.4224 27.0214 30.4998 22.7309C31.2694 21.1432 31.7234 19.4571 31.9062 17.6966C32.0732 16.0947 32.0247 14.4984 31.7006 12.9193ZM27.1017 17.8808C26.7748 21.6587 24.2634 24.824 20.7739 26.1062C20.0986 26.4046 19.3861 26.6373 18.6437 26.7944C16.0081 27.3526 13.4981 26.9757 11.1166 25.7164C10.9738 25.6407 10.7825 25.6007 10.6226 25.6193C8.79361 25.8235 6.96608 26.0419 5.13855 26.2547C4.68881 26.3075 4.23906 26.3546 3.74791 26.4089C3.81645 26.2375 3.86785 26.099 3.92781 25.9634C4.69594 24.1858 5.46408 22.4097 6.23792 20.635C6.3036 20.485 6.30931 20.3608 6.24792 20.2066C4.21622 15.0952 6.49635 9.14286 11.4578 6.75277C15.2671 4.91809 19.0335 5.12369 22.5001 7.53519C25.6469 9.72396 27.2474 12.7822 27.1403 16.5544C27.1531 16.9856 27.1403 17.4268 27.1017 17.8808Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }