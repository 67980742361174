var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.transactionsLoading && _vm.transactions.length > 0
    ? _c(
        "div",
        { staticClass: "transactions-list" },
        _vm._l(_vm.transactions, function (transaction, index) {
          return _c("div", { key: index, staticClass: "transaction-item" }, [
            _c("div", { staticClass: "transaction-data" }, [
              transaction.status === 1
                ? _c(
                    "div",
                    { staticClass: "icon success" },
                    [_c("CheckIcon")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              transaction.status === 0
                ? _c(
                    "div",
                    { staticClass: "icon primary" },
                    [_c("ClockIcon")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              transaction.status === 2
                ? _c("div", { staticClass: "icon danger" }, [_c("WarnSign")], 1)
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "transaction-text" }, [
                _c(
                  "a",
                  {
                    staticClass: "summary",
                    attrs: {
                      href: _vm.transactionLink(transaction.hash),
                      target: "_blank",
                      rel: "nofollow noopener",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(transaction.title) +
                        "\n          "
                    ),
                    _c("NewTabIcon"),
                  ],
                  1
                ),
                _vm._v(" "),
                transaction.gasUsed !== undefined
                  ? _c("div", { staticClass: "stats" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.gas_used") + ": ") +
                          "\n          "
                      ),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$priceFormat(
                                parseFloat(transaction.gasUsed),
                                0,
                                ".",
                                ","
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v("\n          Gwei\n        "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "stats" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("common.transaction_completed") + ": ") +
                      "\n          "
                  ),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$getFormatDate(transaction.created_at)) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ])
        }),
        0
      )
    : _vm.transactionsLoading
    ? _c("div", { staticClass: "transactions-list empty-area" }, [
        _c("div", { staticClass: "icon-area" }, [_c("Loading")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "h4" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("common.loading")) + "\n  "),
        ]),
      ])
    : _c("div", { staticClass: "transactions-list" }, [
        _c("p", { staticClass: "desc" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("common.no_transactions")) + "\n  "),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }