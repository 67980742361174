export const state = () => ({
  user: null,
  userToken: '',
  watchlist: [],
  watchlistTotal: 0,
  watchlistLoading: false,
  userLoading: false
})

export const mutations = {
  setUser(state, user) {
    state.user = user
  },
  addUserBalance(state, amount) {
    state.user.usdt_balance += amount
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', state.user, {
      expires: nowDate
    })
  },
  setUserToken(state, userToken) {
    state.userToken = userToken
  },
  setWatchlist(state, watchlist) {
    state.watchlist = watchlist
  },
  setWatchlistTotal(state, watchlist) {
    state.watchlistTotal = watchlist
  },
  addToWatchlist(state, watchlist) {
    state.watchlist.unshift(watchlist)
  },
  removeFromWatchlist(state, watchlist) {
    state.watchlist = state.watchlist.filter(item => item !== watchlist)
  },
  setUserAddress(state, address) {
    if (state.user !== undefined && state.user !== null) {
      state.user.address = address
    }
  },
  unsetUser(state) {
    state.user = null
  },
  unsetUserToken(state) {
    state.userToken = ''
  },
  setLoadingUser(state, status) {
    state.userLoading = status
  },
  setWatchlistLoading(state, status) {
    state.watchlistLoading = status
  }
}

export const actions = {
  async checkAuth({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$get('auth/check', payload)
    commit('setLoadingUser', false)
  },
  async metamask({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('users/auth', payload)
    commit('setUser', userData.answer.user)
    commit('setUserToken', userData.answer.token)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    this.$cookies.set('auth.token', userData.answer.token, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async logout({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$post('users/logout', payload)
    commit('unsetUser')
    commit('unsetUserToken')
    this.$cookies.remove('auth.token')
    this.$cookies.remove('auth.user')
    commit('setLoadingUser', false)
  },
  async fetchWatchlist({
    commit
  }, payload) {
    commit('setWatchlistLoading', true)
    const request = await this.$generateApiRequest()
    const response = await request.$get('watchlist', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        search: payload.search !== undefined && payload.search !== null ? payload.search : '',
        model: payload.model !== undefined ? payload.model : null
      }
    })
    commit('setWatchlist', response.answer.list)
    commit('setWatchlistTotal', response.answer.total)
    commit('setWatchlistLoading', false)
  },
  async checkWatchlist({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$get('watchlist/' + payload.address, {
      params: {
        model: payload.model !== undefined ? payload.model : null
      }
    })
      .then(() => {
        commit('addToWatchlist', payload.address)
      })
    commit('setLoadingUser', false)
  },
  async addToWatchlist({
    commit
  }, payload) {
    commit('setWatchlistLoading', true)
    const request = await this.$generateApiRequest()
    await request.$post('watchlist', {
      mid: payload.mid !== undefined ? payload.mid : null,
      model: payload.model !== undefined ? payload.model : null
    })
      .then(() => {
        commit('addToWatchlist', payload.mid)
      })
    commit('setWatchlistLoading', false)
  },
  async removeFromWatchlist({
    commit
  }, payload) {
    commit('setWatchlistLoading', true)
    const request = await this.$generateApiRequest()
    await request.$delete('watchlist/' + payload.address, {
      params: {
        model: payload.model !== undefined ? payload.model : null
      }
    })
      .then(() => {
        commit('removeFromWatchlist', payload.address)
      })
    commit('setWatchlistLoading', false)
  },
  async investInDfp({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('invest/direct', {
      amount: payload.amount !== undefined && payload.amount !== null ? payload.amount : 0,
      currency: payload.currency !== undefined && payload.currency !== null ? payload.currency : 'USD',
      type: payload.type !== undefined && payload.type !== null ? payload.type : 'invest',
      network: payload.network !== undefined && payload.network !== null ? payload.network : '2609',
      description: payload.description !== undefined && payload.description !== null ? payload.description : ''
    })
    commit('setUser', userData.answer.user)
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
    this.$cookies.set('auth.user', userData.answer.user, {
      expires: nowDate
    })
    commit('setLoadingUser', false)
  },
  async generatePaymentLink({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    const userData = await request.$post('invest/payment_link', {
      amount: payload.amount !== undefined && payload.amount !== null ? payload.amount : 0,
      currency: payload.currency !== undefined && payload.currency !== null ? payload.currency : 'USD',
      type: payload.type !== undefined && payload.type !== null ? payload.type : 'invest',
      network: payload.network !== undefined && payload.network !== null ? payload.network : '2609',
      description: payload.description !== undefined && payload.description !== null ? payload.description : ''
    })
    commit('setLoadingUser', false)
    return userData.answer.link
  },
  async topupAccountBalance({
    commit
  }, payload) {
    commit('setLoadingUser', true)
    const request = await this.$generateApiRequest()
    await request.$post('invest/topup', payload)
    commit('addUserBalance', payload.amount)
    commit('setLoadingUser', false)
  }
}

export const getters = {
  user: state => state.user,
  userToken: state => state.userToken,
  userLoading: state => state.userLoading,
  watchlist: state => state.watchlist,
  watchlistTotal: state => state.watchlistTotal,
  watchlistLoading: state => state.watchlistLoading
}
