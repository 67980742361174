var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "31",
        height: "30",
        viewBox: "0 0 31 30",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.08301 28.7845C9.84123 29.1099 10.6341 29.3627 11.4494 29.5617V20.2588H9.08301V28.7845Z",
          fill: "#0C275C",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M16.184 15H13.8176V30H16.184V15Z", fill: "#0C275C" },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M18.5522 29.5606C19.3676 29.3615 20.1622 29.1105 20.9187 28.7833V9.74121H18.5522V29.5606Z",
          fill: "#0C275C",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M15.0017 0C6.71665 0 0.00170898 6.71668 0.00170898 15C0.00170898 20.2193 2.6728 24.805 6.71665 27.4916V24.5159C4.05768 22.1996 2.36812 18.7963 2.36812 15C2.36812 8.03404 8.03575 2.36642 15.0017 2.36642C21.9677 2.36642 27.6353 8.03404 27.6353 15C27.6353 18.7963 25.9457 22.1979 23.2868 24.5159V27.4916C27.3306 24.805 30.0017 20.2193 30.0017 15C30.0017 6.71668 23.2868 0 15.0017 0Z",
          fill: "#0C275C",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }