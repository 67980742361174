<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0,512) scale(0.1,-0.1)" fill="currentColor" stroke="none">
      <path
        d="M2479 4997 c-62 -23 -108 -57 -138 -100 -15 -23 -160 -351 -321 -729
    -291 -682 -293 -688 -324 -693 -17 -2 -359 -34 -761 -70 -783 -71 -774 -69
    -846 -133 -57 -50 -84 -114 -84 -202 1 -92 19 -132 102 -211 32 -31 281 -251
    553 -489 272 -238 507 -444 522 -459 l28 -26 -166 -725 c-174 -759 -182 -808
    -150 -884 51 -123 177 -192 301 -166 58 12 84 27 840 479 269 161 499 298 510
    303 17 8 124 -53 650 -368 347 -207 650 -386 674 -397 29 -13 66 -19 108 -19
    151 -1 268 117 266 267 -1 39 -59 311 -167 785 l-166 725 62 56 c35 31 290
    255 568 499 278 243 517 460 533 483 95 141 32 341 -127 402 -28 11 -283 38
    -794 84 -414 38 -755 71 -757 73 -2 2 -136 312 -296 688 -161 377 -306 705
    -323 729 -62 87 -202 133 -297 98z m365 -1045 c152 -357 288 -664 301 -683 30
    -40 95 -85 140 -98 18 -5 348 -37 734 -72 385 -35 701 -67 701 -70 0 -3 -231
    -208 -512 -455 -282 -246 -528 -464 -547 -484 -49 -50 -71 -104 -71 -177 0
    -45 43 -252 155 -745 85 -375 153 -683 152 -685 -2 -2 -271 157 -598 353 -327
    196 -615 365 -642 375 -56 22 -129 24 -183 5 -22 -7 -311 -176 -643 -375 -332
    -199 -606 -360 -607 -358 -2 2 66 311 151 686 111 489 155 700 155 744 0 75
    -24 131 -77 184 -21 21 -267 238 -546 482 -279 245 -507 447 -507 451 0 3 316
    34 701 69 386 35 716 67 734 72 45 13 110 58 139 98 13 18 149 325 302 682
    152 357 280 649 284 649 4 0 131 -292 284 -648z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'StarOutlinedIcon'
}
</script>
