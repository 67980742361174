<template>
  <svg viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6838 7.59092C12.473 7.37878 12.2174 7.27279 11.9163 7.27279H11.5554V5.09085C11.5554 3.69693 11.059 2.50007 10.0659 1.50007C9.07284 0.500022 7.88439 0 6.49994 0C5.11553 0 3.92684 0.500022 2.93389 1.50003C1.94077 2.50007 1.44437 3.69689 1.44437 5.09085V7.27279H1.08331C0.782483 7.27279 0.526636 7.37878 0.315965 7.59092C0.105295 7.8029 0 8.06054 0 8.36374V14.9091C0 15.2121 0.105335 15.4698 0.315965 15.6819C0.526636 15.8939 0.782483 16 1.08331 16H11.9166C12.2176 16 12.4733 15.894 12.684 15.6819C12.8945 15.4698 13 15.2121 13 14.9091V8.36362C13.0002 8.06066 12.8945 7.80306 12.6838 7.59092ZM9.38881 7.27279H3.61107V5.09085C3.61107 4.28786 3.89325 3.60221 4.45747 3.03412C5.02177 2.46596 5.70256 2.18194 6.50006 2.18194C7.29764 2.18194 7.97827 2.46592 8.54261 3.03412C9.10668 3.60217 9.38881 4.28786 9.38881 5.09085V7.27279Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'LockIcon'
}
</script>
