<template>
  <div ref="modalArea" :class="{ 'modal-container settings': true, 'active': modalOpen }">
    <div class="modal-wrap">
      <div ref="modalInner" class="modal-content-wrap">
        <div class="modal-content">
          <div class="header">
            <div class="h2">
              {{ $t('common.settings') }}
            </div>
            <div class="close-area" @click="closeModal">
              <Plus />
            </div>
          </div>
          <hr>
          <div class="settings-area">
            <div class="h6">
              {{ $t('common.global') }}
            </div>
            <div class="row">
              <div class="text-area">
                <p class="hint">
                  {{ $t('common.dark_mode') }}
                </p>
              </div>
              <div class="value-area">
                <div :class="`switcher-area ${darkTheme ? 'dark' : 'light'}`" @click="changeTheme">
                  <div class="switcher">
                    <div class="light">
                      <Sun />
                    </div>
                    <div class="dark">
                      <Moon />
                    </div>
                    <div class="circle">
                      <SwitchCircle />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="text-area">
                <p class="hint">
                  {{ $t('common.tx_speed') }}
                </p>
                <div class="hint-icon wide-tooltip" :tooltip="$t('common.tx_speed_hint')">
                  <Question />
                </div>
              </div>
            </div>
            <div class="range-switch-area">
              <div :class="{'switch-item': true, 'active': speed === 'standard'}" @click="setSpeed('standard')">
                <span>
                  {{ $t('common.standard') }} (~5)
                </span>
              </div>
              <div :class="{'switch-item': true, 'active': speed === 'fast'}" @click="setSpeed('fast')">
                <span>
                  {{ $t('common.fast') }} (~6)
                </span>
              </div>
              <div :class="{'switch-item': true, 'active': speed === 'instant'}" @click="setSpeed('instant')">
                <span>
                  {{ $t('common.instant') }} (~7)
                </span>
              </div>
            </div>
          </div>
          <hr>
          <div class="settings-area mb-4">
            <div class="h6">
              {{ $t('common.swaps_liquidity') }}
            </div>
            <div class="row">
              <div class="text-area">
                <p class="hint">
                  {{ $t('swap.slippage') }}
                </p>
                <div class="hint-icon wide-tooltip" :tooltip="$t('swap.slippage_desc')">
                  <Question />
                </div>
              </div>
            </div>
            <div class="range-switch-area mb-4">
              <div
                v-for="(tolerance, index) in slippageTolerances"
                :key="index"
                :class="{'switch-item': true, 'active': slippageTolerance === tolerance}"
                @click="setSlippageToleranceAction(tolerance)"
              >
                {{ tolerance }}%
              </div>
              <div class="input-area">
                <input v-model="slippageTolerance" type="number" placeholder="0.5..." :class="{'active': !slippageTolerances.includes(slippageTolerance)}">
                <label>%</label>
              </div>
            </div>
            <div class="row">
              <div class="text-area">
                <p class="hint">
                  {{ $t('swap.transaction_deadline') }}
                </p>
                <div class="hint-icon wide-tooltip" :tooltip="$t('swap.transaction_deadline_desc')">
                  <Question />
                </div>
              </div>
              <div class="value-area">
                <input
                  v-model="deadline"
                  type="number"
                  placeholder="30..."
                  max="999"
                  min="1"
                  step="1"
                >
              </div>
            </div>
            <!-- <div class="row">
              <div class="text-area">
                <p class="hint">
                  {{ $t('swap.expert_mode') }}
                </p>
                <div class="hint-icon wide-tooltip" :tooltip="$t('swap.expert_mode_desc')">
                  <Question />
                </div>
              </div>
              <div class="value-area">
                <Switcher id="ExpertMode" v-model="expertMode" />
              </div>
            </div>
            <div class="row">
              <div class="text-area">
                <p class="hint">
                  {{ $t('swap.disable_multihops') }}
                </p>
                <div class="hint-icon" :tooltip="$t('swap.disable_multihops_desc')">
                  <Question />
                </div>
              </div>
              <div class="value-area">
                <Switcher id="multiHops" v-model="multihops" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  name: 'SettingsModalComponent',
  components: {
    Question: hydrateWhenVisible(() => import('~/components/svg/new/Question')),
    Moon: hydrateWhenVisible(() => import('~/components/svg/new/Moon')),
    Plus: hydrateWhenVisible(() => import('~/components/svg/new/Plus')),
    Sun: hydrateWhenVisible(() => import('~/components/svg/new/Sun')),
    // Switcher: hydrateWhenIdle(() => import('~/components/app/interface/Switcher')),
    SwitchCircle: hydrateWhenVisible(() => import('~/components/svg/new/SwitchCircle'))
  },
  data() {
    return {
      slippageTolerances: [0.1, 0.5, 1]
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    slippageTolerance: {
      get() {
        return this.$store.getters['settings/slippageTolerance']
      },
      set(value) {
        this.setSlippageTolerance(value)
      }
    },
    deadline: {
      get() {
        return this.$store.getters['settings/deadline']
      },
      set(value) {
        this.setDeadline(value)
      }
    },
    expertMode: {
      get() {
        return this.$store.getters['settings/expertMode']
      },
      set(value) {
        this.setExpertMode(value)
      }
    },
    multihops: {
      get() {
        return this.$store.getters['settings/multihops']
      },
      set(value) {
        this.setMultihops(value)
      }
    },
    speed: {
      get() {
        return this.$store.getters['settings/speed']
      },
      set(value) {
        this.setSpeed(value)
      }
    },
    modalOpen() {
      return this.$store.getters['modal/modalSettingsOpen'] !== undefined ? this.$store.getters['modal/modalSettingsOpen'] : false
    }
  },
  mounted() {
    this.darkMode = this.darkTheme
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapMutations('settings', [
      'setSlippageTolerance',
      'setDeadline',
      'setExpertMode',
      'setMultihops',
      'setSpeed'
    ]),
    ...mapActions('modal', [
      'changeModalSettingsState'
    ]),
    async closeModal() {
      await this.changeModalSettingsState({
        state: false
      })
    },
    handleClickEvents(evt) {
      if (
        this.$refs.modalArea !== undefined &&
        this.$refs.modalArea.contains(evt.target) &&
        !this.$refs.modalInner.contains(evt.target)
      ) {
        this.closeModal()
      }
    },
    changeTheme() {
      if (!this.darkTheme) {
        this.$onTheme()
      } else {
        this.$offTheme()
      }
    },
    setSlippageToleranceAction(tolerance) {
      if (this.slippageTolerances.includes(tolerance)) {
        this.setSlippageTolerance(tolerance)
      }
    }
  }
}
</script>

<style scoped>
  @import url('../../assets/css/new/modal.css');
</style>
