var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalArea",
      class: { "modal-container": true, active: _vm.isModalOpened },
    },
    [
      _c("div", { staticClass: "modal-wrap" }, [
        _c(
          "div",
          {
            ref: "modalInner",
            class: { "modal-content-wrap": true, visible: _vm.visible },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "h2" }, [
                  _vm._v("\n            " + _vm._s(_vm.title) + "\n          "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "close-area", on: { click: _vm.closeModal } },
                  [_c("Plus")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                { ref: "innerContainer", staticClass: "modal-inner" },
                [_vm._t("modal-content", null, { type: _vm.type })],
                2
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }