var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M406 5104 c-134 -32 -279 -146 -344 -270 -66 -126 -62 24 -62 -2274\n          0 -2298 -4 -2148 62 -2274 41 -78 146 -183 224 -224 126 -66 -24 -62 2274 -62\n          2298 0 2148 -4 2274 62 78 41 183 146 224 224 66 126 62 -24 62 2274 0 2298 4\n          2148 -62 2274 -41 78 -146 183 -224 224 -126 66 25 62 -2279 61 -1769 -1\n          -2099 -3 -2149 -15z m2044 -1314 l0 -1120 -1120 0 -1120 0 0 968 c0 1027 0\n          1035 47 1110 46 74 125 131 210 151 21 5 476 9 1011 10 l972 1 0 -1120z m2213\n          1105 c121 -31 200 -111 232 -232 13 -49 15 -199 15 -1025 l0 -968 -1120 0\n          -1120 0 0 1120 0 1120 968 0 c824 0 976 -2 1025 -15z m-2213 -3565 l0 -1120\n          -992 2 -993 3 -60 28 c-67 32 -106 64 -141 120 -55 84 -54 71 -54 1119 l0 968\n          1120 0 1120 0 0 -1120z m2458 128 l-3 -993 -28 -60 c-32 -67 -64 -106 -120\n          -141 -84 -55 -71 -54 -1119 -54 l-968 0 0 1120 0 1120 1120 0 1120 0 -2 -992z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M1233 4254 c-54 -27 -63 -61 -63 -248 l0 -166 -170 0 c-184 0 -212\n          -6 -236 -51 -24 -47 -18 -97 15 -130 l29 -29 181 0 181 0 0 -165 c0 -121 4\n          -175 14 -200 15 -35 59 -65 96 -65 37 0 81 30 96 65 10 25 14 79 14 200 l0\n          165 181 0 181 0 29 29 c32 32 38 79 17 126 -22 48 -51 55 -238 55 l-170 0 0\n          166 c0 188 -9 222 -65 248 -41 20 -52 20 -92 0z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3361 3826 c-58 -32 -69 -119 -22 -167 l29 -29 472 0 472 0 29 29\n          c48 49 34 142 -26 169 -39 18 -921 16 -954 -2z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M925 1701 c-40 -18 -70 -60 -70 -102 0 -37 8 -46 140 -179 l140 -140\n          -140 -140 c-133 -133 -140 -142 -140 -180 0 -57 48 -105 105 -105 38 0 47 7\n          180 140 l140 140 140 -140 c133 -133 142 -140 180 -140 57 0 105 48 105 105 0\n          38 -7 47 -140 180 l-140 140 140 140 c133 133 140 142 140 180 0 57 -48 105\n          -105 105 -38 0 -47 -7 -180 -140 l-140 -140 -137 137 c-141 141 -170 159 -218\n          139z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3383 1703 c-59 -11 -94 -109 -57 -162 35 -49 52 -51 514 -51 462 0\n          479 2 514 51 9 13 16 39 16 59 0 20 -7 46 -16 59 -35 50 -51 51 -516 50 -238\n          -1 -443 -4 -455 -6z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3383 1063 c-59 -11 -94 -109 -57 -162 35 -49 52 -51 514 -51 462 0\n          479 2 514 51 9 13 16 39 16 59 0 20 -7 46 -16 59 -35 50 -51 51 -516 50 -238\n          -1 -443 -4 -455 -6z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }