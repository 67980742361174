var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 1115 254",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M4 26H994.5C1050.56 26 1096 71.4431 1096 127.5C1096 183.557 1050.56 229 994.5 229H4V26Z",
          stroke: "url(#paint0_linear_518_89)",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "422",
          cy: "25",
          r: "22.5",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M423.227 25.5001L419 29.9941L420.887 32.0001L427 25.5001L420.887 19.0001L419 21.006L423.227 25.5001Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "828",
          cy: "25",
          r: "22.5",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M829.227 25.5001L825 29.9941L826.887 32.0001L833 25.5001L826.887 19.0001L825 21.006L829.227 25.5001Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "25",
          cy: "25",
          r: "22.5",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M26.2267 25.5001L22 29.9941L23.8867 32.0001L30 25.5001L23.8867 19.0001L22 21.006L26.2267 25.5001Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "1093.5",
          cy: "128.5",
          r: "18.5625",
          transform: "rotate(90 1093.5 128.5)",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "4.125",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M1093.91 129.512L1090.21 126.025L1088.55 127.582L1093.91 132.625L1099.28 127.582L1097.62 126.025L1093.91 129.512Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "422",
          cy: "229",
          r: "22.5",
          transform: "rotate(-180 422 229)",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M420.773 228.5L425 224.006L423.113 222L417 228.5L423.113 235L425 232.994L420.773 228.5Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "828",
          cy: "229",
          r: "22.5",
          transform: "rotate(-180 828 229)",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M826.773 228.5L831 224.006L829.113 222L823 228.5L829.113 235L831 232.994L826.773 228.5Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "25",
          cy: "229",
          r: "22.5",
          transform: "rotate(-180 25 229)",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "5",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M23.7733 228.5L28 224.006L26.1133 222L20 228.5L26.1133 235L28 232.994L23.7733 228.5Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_518_89",
                x1: "331.5",
                y1: "140.5",
                x2: "47.507",
                y2: "214.93",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "currentColor" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "currentColor",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }