var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "20",
        viewbox: "0 0 21 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M11.0604 9.2518H8.93866C8.30263 9.2518 7.7809 8.73503 7.7809 8.09404C7.7809 7.45304 8.29767 6.93627 8.93866 6.93627C8.94363 6.93627 8.9486 6.93627 8.95357 6.93627H10.9859C11.4132 6.94124 11.8356 7.13006 12.1436 7.45801C12.4269 7.76112 12.8989 7.77602 13.202 7.49279C13.5051 7.20956 13.52 6.73751 13.2368 6.43441C12.5312 5.67913 11.7163 5.44062 10.7474 5.44062V4.54621C10.7474 4.13379 10.4144 3.7959 9.99704 3.7959C9.58462 3.7959 9.24673 4.12882 9.24673 4.54621V5.44062C7.25916 5.44062 6.28027 6.73255 6.28027 8.09404C6.28027 9.55491 7.47282 10.7475 8.93369 10.7475H11.0554C11.6915 10.7475 12.2132 11.2642 12.2132 11.9052C12.2132 12.5462 11.6964 13.063 11.0554 13.063C11.0505 13.063 11.0455 13.063 11.0405 13.063H8.82437C8.5163 13.058 8.20326 12.9537 7.92499 12.7599C7.58711 12.5263 7.12002 12.6108 6.88152 12.9487C6.64798 13.2866 6.73245 13.7537 7.07034 13.9922C7.98462 14.6282 8.76475 14.5636 9.24673 14.5636V15.458C9.24673 15.8704 9.57965 16.2083 9.99704 16.2083C10.4144 16.2083 10.7474 15.8754 10.7474 15.458V14.5636C12.7598 14.5636 13.7138 13.2468 13.7138 11.9102C13.7138 10.4443 12.5262 9.2518 11.0604 9.2518Z",
          fill: "#0C275C",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M20.005 10.0025C20.005 4.76522 16.0099 0.636025 11.1553 0.0695651C11.1453 0.0695651 11.1304 0.0645962 11.1205 0.0645962C11.0509 0.0546583 10.9764 0.0496893 10.9068 0.0447204C10.877 0.0397514 10.8472 0.0397516 10.8174 0.0347826C10.7627 0.0298137 10.713 0.0248447 10.6584 0.0248447C10.6186 0.0198758 10.5739 0.0198757 10.5342 0.0149068C10.4894 0.0149068 10.4447 0.00993793 10.4 0.00993793C10.3404 0.00993793 10.2807 0.00496887 10.2211 0.00496887C10.1466 0.00496887 10.0671 0 9.99255 0C9.97764 0 9.96274 0 9.94783 0C9.08323 0.00496894 8.19876 0.124224 7.31429 0.372671C7.30932 0.372671 7.30435 0.367702 7.29938 0.367702C6.3851 0.626087 5.52547 1.0087 4.73541 1.49565C4.74037 1.49565 4.75031 1.49565 4.75528 1.49565C1.90311 3.2646 0 6.41491 0 10.0025C0 13.5901 1.90311 16.7404 4.75031 18.5043C4.74534 18.5043 4.73541 18.5043 4.73044 18.5043C5.5205 18.9963 6.38013 19.3789 7.29441 19.6323C7.29938 19.6323 7.30435 19.6273 7.30932 19.6273C8.19876 19.8758 9.08323 19.995 9.9528 20C9.96771 20 9.97764 20 9.99255 20C10.0671 20 10.1416 20 10.2162 19.995C10.2857 19.995 10.3553 19.9901 10.4248 19.9901C10.4596 19.9901 10.4994 19.9851 10.5342 19.9851C10.5839 19.9801 10.6286 19.9801 10.6783 19.9752C10.723 19.9702 10.7727 19.9702 10.8174 19.9652C10.8522 19.9602 10.8919 19.9602 10.9267 19.9553C10.9913 19.9503 11.0509 19.9453 11.1155 19.9354C11.1354 19.9354 11.1553 19.9304 11.1752 19.9304C16.0149 19.364 20.005 15.2298 20.005 10.0025ZM10.0025 18.5043C5.3118 18.5043 1.49565 14.6882 1.49565 10.0025C1.49565 5.31677 5.3118 1.50062 10.0025 1.50062C14.6932 1.50062 18.5093 5.31677 18.5093 10.0025C18.5093 14.6882 14.6932 18.5043 10.0025 18.5043Z",
          fill: "#0C275C",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }