<template>
  <div class="table-area">
    <div v-if="withSearch" class="filter-area">
      <div class="input-area">
        <input v-model="filter.search" type="text" :placeholder="$t('analytics.search_address_or_name')" @input="searchModel">
        <Search />
      </div>
    </div>
    <div v-if="!modelLoading && modelList.length > 0" class="table-item">
      <div class="header row">
        <div class="col sortable" @click="changeSort('name')">
          <p>
            {{ $t('analytics.name') }}
          </p>
          <div
            v-if="filter.orderField === 'name'"
            :class="{'sort-icon': true, 'active': filter.order === 'ASC'}"
          >
            <ChevronDown />
          </div>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.symbol') }}
          </p>
        </div>
        <div class="col sortable" @click="changeSort('liquidity')">
          <p>
            {{ $t('analytics.liquidity') }}
          </p>
          <div
            v-if="filter.orderField === 'liquidity'"
            :class="{'sort-icon': true, 'active': filter.order === 'ASC'}"
          >
            <ChevronDown />
          </div>
        </div>
        <div class="col sortable" @click="changeSort('volume_24h')">
          <p>
            {{ $t('analytics.volume_24h') }}
          </p>
          <div
            v-if="filter.orderField === 'volume_24h'"
            :class="{'sort-icon': true, 'active': filter.order === 'ASC'}"
          >
            <ChevronDown />
          </div>
        </div>
        <div class="col sortable" @click="changeSort('price')">
          <p>
            {{ $t('analytics.price') }}
          </p>
          <div
            v-if="filter.orderField === 'price'"
            :class="{'sort-icon': true, 'active': filter.order === 'ASC'}"
          >
            <ChevronDown />
          </div>
        </div>
        <div class="col sortable" @click="changeSort('price_change')">
          <p>
            {{ $t('analytics.price_change') }}
          </p>
          <div
            v-if="filter.orderField === 'price_change'"
            :class="{'sort-icon': true, 'active': filter.order === 'ASC'}"
          >
            <ChevronDown />
          </div>
        </div>
      </div>
      <div v-for="(token, index) of modelList" :key="index" class="row">
        <div class="col small-visible">
          <div class="number">
            {{ (filter.step * (filter.page - 1)) + index + 1 }}
          </div>
          <nuxt-link :to="localePath('/analytics/tokens/' + token.address)" class="logo">
            <nuxt-img
              fit="inside"
              :src="$getImage(token.logoURI)"
              :alt="token.name"
              :title="token.symbol"
            />
          </nuxt-link>
          <nuxt-link :to="localePath('/analytics/tokens/' + token.address)" class="name">
            {{ token.name }}
          </nuxt-link>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('analytics.symbol') }}
          </p>
          <div class="name">
            {{ token.symbol !== null && token.symbol !== undefined ? token.symbol.toUpperCase() : '...' }}
          </div>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('analytics.liquidity') }}
          </p>
          <div class="name">
            ${{ $getBigNumber(token.liquidity) }}
          </div>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('analytics.volume_24h') }}
          </p>
          <div class="name">
            ${{ $getBigNumber(token.volume) }}
          </div>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('analytics.price') }}
          </p>
          <div class="name">
            ${{ $priceFormat(token.usd_price, 2, ',', '.') }}
          </div>
        </div>
        <div class="col small-visible xs-hidden">
          <p class="hint">
            {{ $t('analytics.price_change') }}
          </p>
          <div class="value">
            {{ getNumberSign(token.price_change) }}
            {{ $priceFormat(token.price_change, 2, ',', '.') }}%
          </div>
        </div>
        <div class="col accordion small-visible" @click="openAccordion(index)">
          <div :class="{'switcher': true, 'active': accordions[index] !== undefined && accordions[index] === true}">
            <p v-if="accordions[index] !== undefined && accordions[index] === true">
              {{ $t('common.hide') }}
            </p>
            <p v-else>
              {{ $t('common.show') }}
            </p>
            <ChevronDown />
          </div>
        </div>
        <div :ref="'accordionArea' + index" class="accordion-area mobile-visible">
          <div class="all-params">
            <div class="item">
              <p class="hint">
                {{ $t('analytics.symbol') }}
              </p>
              <div class="name">
                {{ token.symbol !== null && token.symbol !== undefined ? token.symbol.toUpperCase() : '...' }}
              </div>
            </div>
            <div class="item">
              <p class="hint">
                {{ $t('analytics.liquidity') }}
              </p>
              <div class="name">
                ${{ $getBigNumber(token.liquidity) }}
              </div>
            </div>
            <div class="item">
              <p class="hint">
                {{ $t('analytics.volume_24h') }}
              </p>
              <div class="name">
                ${{ $getBigNumber(token.volume) }}
              </div>
            </div>
            <div class="item no-border">
              <p class="hint">
                {{ $t('analytics.price') }}
              </p>
              <div class="name">
                ${{ $priceFormat(token.usd_price, 2, ',', '.') }}
              </div>
            </div>
            <div class="item xs-visible no-border">
              <p class="hint">
                {{ $t('analytics.price_change') }}
              </p>
              <div class="value">
                {{ getNumberSign(token.price_change) }}
                {{ $priceFormat(token.price_change, 2, ',', '.') }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="modelLoading" class="table-item">
      <div class="header row">
        <div class="col">
          <p>
            {{ $t('analytics.name') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.symbol') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.liquidity') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.volume_24h') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.price') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.price_change') }}
          </p>
        </div>
      </div>
      <div v-for="index in 3" :key="index" class="row">
        <div class="col small-visible">
          <div class="number">
            {{ index + 1 }}
          </div>
          <div class="logo">
            <Skeleton width="40px" height="40px" circle />
          </div>
          <div class="name">
            <Skeleton width="70px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="40px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="60px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="60px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="80px" height="25px" />
          </div>
        </div>
        <div class="col small-visible xs-hidden">
          <div class="name">
            <Skeleton width="80px" height="25px" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tablie-item">
      <div class="not-found-area">
        <div class="h3">
          {{ !watchlistEnabled ? $t('analytics.no_top_tokens') : $t('analytics.saved_tokens') }}
        </div>
      </div>
    </div>
    <div v-if="modelList.length > 0 && withPagination" :class="{'pagination-area': true, 'disabled': modelLoading}">
      <Pagination :page="filter.page" :step="filter.step" :total="modelTotal" @change="setPage" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

import { Skeleton } from 'vue-loading-skeleton'

import Search from '~/components/svg/new/Search'

export default {
  components: {
    ChevronDown: hydrateWhenIdle(() => import('~/components/svg/new/ChevronDown')),
    Search,
    Skeleton,
    Pagination: hydrateWhenIdle(() => import('~/components/analytics/parts/Pagination'))
  },
  props: {
    withSearch: {
      type: Boolean,
      default: () => false
    },
    watchlistEnabled: {
      type: Boolean,
      default: () => false
    },
    withPagination: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      accordions: [],
      filter: {
        page: 1,
        step: 15,
        orderField: 'volume_desc',
        search: ''
      },
      searchTimeout: null,
      timeOutTime: 600
    }
  },
  computed: {
    ...mapGetters('tokens', [
      'tokens',
      'tokensTotal',
      'tokensLoading'
    ]),
    ...mapGetters('auth', [
      'watchlist',
      'watchlistTotal',
      'watchlistLoading'
    ]),
    modelList() {
      return this.watchlistEnabled ? this.watchlist : this.tokens
    },
    modelTotal() {
      return this.watchlistEnabled ? this.watchlistTotal : this.tokensTotal
    },
    modelLoading() {
      return this.watchlistEnabled ? this.watchlistLoading : this.tokensLoading
    }
  },
  mounted() {
    if (!this.watchlistEnabled) {
      this.fetchTokens(this.filter)
    } else {
      this.fetchWatchlist({
        ...this.filter,
        model: 'tokens'
      })
    }
  },
  methods: {
    ...mapActions('tokens', [
      'fetchTokens'
    ]),
    ...mapActions('auth', [
      'fetchWatchlist'
    ]),
    setPage(newPage) {
      this.filter.page = parseInt(newPage, 10)
      this.loadModels()
    },
    loadModels() {
      if (!this.watchlistEnabled) {
        this.fetchTokens(this.filter)
      } else {
        this.fetchWatchlist({
          ...this.filter,
          model: 'tokens'
        })
      }
    },
    changeSort(orderField) {
      if (!this.filter.orderField.includes(orderField)) {
        this.filter.orderField = orderField + '_desc'
      } else {
        this.filter.orderField = this.filter.orderField.includes('_desc')
          ? this.filter.orderField.replace('_desc', '_asc')
          : this.filter.orderField.replace('_asc', '_desc')
      }
    },
    getNumberSign(number) {
      return number > 0 ? '+' : ''
    },
    openAccordion(indexClicked) {
      const ind = parseInt(indexClicked, 10)
      this.accordions.forEach((item, index) => {
        this.accordions[index] = false
        if (this.$refs['accordionArea' + index][0] !== undefined) {
          this.$refs['accordionArea' + index][0].style.maxHeight = null
        }
      })
      this.accordions[ind] = true
      if (this.$refs['accordionArea' + ind][0] !== undefined) {
        this.$refs['accordionArea' + ind][0].style.maxHeight = this.$refs['accordionArea' + ind][0].scrollHeight + 'px'
      }
    },
    searchModel() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(async () => {
        await this.loadModels()
      }, this.timeOutTime)
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/table.css');
</style>
