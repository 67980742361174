<template>
  <div v-if="!loadingScreen && !successScreen && !errorScreen && !confirmScreen" class="settings-area">
    <div class="setting-item amount-area">
      <div class="header-area">
        <div class="h5">
          {{ $t('liquidity.amount') }}
        </div>
        <div class="h5 switcher" @click="changeView">
          <SwapArrows />
          {{ simpleView ? $t('pool.expert_view') : $t('pool.simple_view') }}
        </div>
      </div>
      <div class="h3">
        {{ $priceFormat(percent, 0, '.', ',') }}%
      </div>
      <div v-if="simpleView" class="percent-area">
        <div class="handler">
          <veeno
            ref="percentSlider"
            connect
            :handles="[0, 0]"
            :range="{
              'min': 0,
              'max': 100
            }"
            :set="[0, parseFloat(percent)]"
            @change="updateVeeno"
            @set="setPercentValue"
          />
        </div>
        <div class="range-switch-area">
          <div :class="{'switch-item': true, 'active': percent === 25}" @click="setPercent(25)">
            <span>
              25%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 50}" @click="setPercent(50)">
            <span>
              50%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 75}" @click="setPercent(75)">
            <span>
              75%
            </span>
          </div>
          <div :class="{'switch-item': true, 'active': percent === 100}" @click="setPercent(100)">
            <span>
              100%
            </span>
          </div>
        </div>
      </div>
      <div v-else class="inputs-area">
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $t('pool.input') }} LP
            </p>
            <p class="balance">
              {{ $t('common.balance') }}: {{ pool !== null ? $formatCryptoAmount(pool.balance, 6) : '...' }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="outputs.lpToken" type="number" step="0.0000001" min="0" @input="calculateLpToken">
            <button type="button" class="max-button active" @click="setMax()">
              <p class="text">
                MAX
              </p>
            </button>
          </div>
        </div>
        <div class="icon-area">
          <ArrowDown />
        </div>
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $t('pool.output') }} {{ pool.token0.symbol }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="outputs.token0" type="number" step="0.0000001" min="0" @input="calculateReserve0">
            <button type="button" class="max-button active" @click="setMax()">
              <p class="text">
                MAX
              </p>
            </button>
          </div>
        </div>
        <div class="icon-area rotated">
          <Plus />
        </div>
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $t('pool.output') }} {{ pool.token1.symbol }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="outputs.token1" type="number" step="0.0000001" min="0" @input="calculateReserve1">
            <button type="button" class="max-button active" @click="setMax()">
              <p class="text">
                MAX
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow-area">
      <ArrowDown />
    </div>
    <div class="setting-item results-area">
      <div class="result-item">
        <div class="value">
          {{ pool !== null ? $formatCryptoAmount(lpRatio * pool.reserves.reserve0, 6) : '...' }}
        </div>
        <div class="token">
          <nuxt-img
            v-if="pool !== null"
            :src="pool.token0.address !== $config.ZERO ? $getImageByAddress(pool.token0.address) : $getImage(pool.token0.logoURI)"
            :alt="pool.token0.name + ' ' + pool.token0.symbol"
            :title="pool.token0.name"
            placeholder
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <p>
            {{ pool !== null ? getTokenSymbol(pool.token0) : '...' }}
          </p>
          <div v-if="pool !== null && (pool.token0.address === $config.ZERO || pool.token0.address === $config.wbnbAddress)" class="swap" @click="swapBnb">
            {{ !receiveBnb ? $t('liquidity.receive_bnb') : $t('liquidity.receive_wbnb') }}
          </div>
        </div>
      </div>
      <div class="result-item">
        <div class="value">
          {{ pool !== null ? $formatCryptoAmount(lpRatio * pool.reserves.reserve1, 6) : '...' }}
        </div>
        <div class="token">
          <nuxt-img
            v-if="pool !== null"
            :src="pool.token1.address !== $config.ZERO ? $getImageByAddress(pool.token1.address) : $getImage(pool.token1.logoURI)"
            :alt="pool.token1.name + ' ' + pool.token1.symbol"
            :title="pool.token1.name"
            placeholder
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <p>
            {{ pool !== null ? getTokenSymbol(pool.token1) : '...' }}
          </p>
          <div v-if="pool !== null && (pool.token1.address === $config.ZERO || pool.token1.address === $config.wbnbAddress)" class="swap" @click="swapBnb">
            {{ !receiveBnb ? $t('liquidity.receive_bnb') : $t('liquidity.receive_wbnb') }}
          </div>
        </div>
      </div>
    </div>
    <div class="desc-table">
      <div class="desc-row">
        <div class="title">
          {{ $t('liquidity.prices') }}
        </div>
        <div class="value column">
          <div class="value-row">
            1 {{ pool !== null ? pool.token0.symbol.toUpperCase() : '...' }} = {{ toPrice + ' ' + (pool !== null ? pool.token1.symbol.toUpperCase() : '...') }}
          </div>
          <div class="value-row">
            1 {{ pool !== null ? pool.token1.symbol.toUpperCase() : '...' }} = {{ fromPrice + ' ' + (pool !== null ? pool.token0.symbol.toUpperCase() : '...') }}
          </div>
        </div>
      </div>
    </div>
    <div class="button-area">
      <button :class="{ 'btn btn-outline pink': true, 'disabled': approved || loadingAllowance }" type="button" :disabled="approved || loadingAllowance" @click="approveTokens">
        <span v-if="!loadingAllowance" class="text">
          {{ $t('common.approve_action') }}
        </span>
        <span v-else class="text">
          <Loading />
        </span>
      </button>
      <button :class="{ 'btn cyam': true, 'disabled': !approved || loadingAllowance }" type="button" :disabled="!approved || loadingAllowance" @click="initConfirmScreen">
        {{ $t('common.remove_action') }}
      </button>
    </div>
  </div>
  <div v-else-if="confirmScreen" class="confirm-area">
    <div class="confirm-table">
      <div class="confirm-row">
        <div class="token-data">
          <nuxt-img
            :src="pool.token0.address !== $config.ZERO ? $getImageByAddress(pool.token0.address) : $getImage(pool.token0.logoURI)"
            :alt="pool.token0.name + ' ' + pool.token0.symbol"
            :title="pool.token0.name"
            placeholder
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <p class="value">
            {{ pool !== null ? $formatCryptoAmount(lpRatio * pool.reserves.reserve0, 6) : '...' }}
          </p>
        </div>
        <div class="token-symbol">
          <div class="h3">
            {{ pool.token0.symbol.toUpperCase() }}
          </div>
        </div>
      </div>
      <div class="confirm-row">
        <div class="arrow-area plus">
          <Plus />
        </div>
      </div>
      <div class="confirm-row">
        <div class="token-data">
          <nuxt-img
            :src="pool.token1.address !== $config.ZERO ? $getImageByAddress(pool.token1.address) : $getImage(pool.token1.logoURI)"
            :alt="pool.token1.name + ' ' + pool.token1.symbol"
            :title="pool.token1.name"
            placeholder
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <p class="value">
            {{ pool !== null ? $formatCryptoAmount(lpRatio * pool.reserves.reserve1, 6) : '...' }}
          </p>
        </div>
        <div class="token-symbol">
          <div class="h3">
            {{ pool.token1.symbol.toUpperCase() }}
          </div>
        </div>
      </div>
    </div>
    <p class="desc">
      {{ $t('liquidity.estimated_output', {percent: slippageTolerance}) }}
    </p>
    <hr>
    <div class="desc-table">
      <div class="desc-row">
        <div class="title">
          {{ $t('liquidity.lp_burned', {tokens: pool.token0.symbol.toUpperCase() + '/' + pool.token1.symbol.toUpperCase()}) }}
        </div>
        <div class="value">
          <div class="logos">
            <nuxt-img
              :src="pool.token0.address !== $config.ZERO ? $getImageByAddress(pool.token0.address) : $getImage(pool.token0.logoURI)"
              :alt="pool.token0.name + ' ' + pool.token0.symbol"
              :title="pool.token0.name"
              placeholder
              :lazy="true"
              @error="$event.target.src = require('~/static/img/default_token.svg')"
            />
            <nuxt-img
              :src="pool.token1.address !== $config.ZERO ? $getImageByAddress(pool.token1.address) : $getImage(pool.token1.logoURI)"
              :alt="pool.token1.name + ' ' + pool.token1.symbol"
              :title="pool.token1.name"
              placeholder
              :lazy="true"
              @error="$event.target.src = require('~/static/img/default_token.svg')"
            />
          </div>
          <p class="value">
            {{ $formatCryptoAmount(lpTokensToRemove, 6) }}
          </p>
        </div>
      </div>
      <div class="desc-row">
        <div class="title">
          {{ $t('liquidity.prices') }}
        </div>
        <div class="value column">
          <div class="value-row">
            1 {{ pool !== null ? pool.token0.symbol.toUpperCase() : '...' }} = {{ toPrice + ' ' + (pool !== null ? pool.token1.symbol.toUpperCase() : '...') }}
          </div>
          <div class="value-row">
            1 {{ pool !== null ? pool.token1.symbol.toUpperCase() : '...' }} = {{ fromPrice + ' ' + (pool !== null ? pool.token0.symbol.toUpperCase() : '...') }}
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="buttons-area">
      <button :class="{ 'btn pink': true, 'disabled': loading }" type="button" :disabled="loading" @click="confirmRemove">
        <span v-if="!loading" class="text">
          {{ $t('common.confirm') }}
        </span>
        <span v-else class="text">
          <Loading />
        </span>
      </button>
    </div>
  </div>
  <div v-else-if="loadingScreen" class="loading-area">
    <ModalLoader />
    <div class="desc-area">
      <div class="h5">
        {{ $t('liquidity.removing') }}
        {{ pool !== null ? $formatCryptoAmount(lpRatio * pool.reserves.reserve0, 6) + ' ' + pool.token0.symbol.toUpperCase() : '...' }}
        {{ $t('common.and') }}
        {{ pool !== null ? $formatCryptoAmount(lpRatio * pool.reserves.reserve1, 6) + ' ' + pool.token1.symbol.toUpperCase() : '...' }}
      </div>
      <p>
        {{ $t('swap.confirm_transaction') }}
      </p>
    </div>
  </div>
  <div v-else-if="successScreen" class="success-area">
    <div class="icon-area">
      <CheckIcon />
    </div>
    <div class="desc-area">
      <div class="h4">
        {{ $t('pools.success') }}
      </div>
      <p class="desc">
        {{ $t('pools.success_text') }}
      </p>
      <hr>
      <div class="buttons-area">
        <a class="btn btn-outline pink" :href="transactionLink(transaction.tx)" rel="nofollow noopener" target="_blank">
          <span class="text">
            {{ $t('swap.view_explorer') }}
            <span class="icon icon-right">
              <NewTabIcon />
            </span>
          </span>
        </a>
        <button type="button" class="btn pink" @click="closeModal(true)">
          {{ $t('common.close') }}
        </button>
      </div>
    </div>
  </div>
  <div v-else-if="errorScreen" class="error-area">
    <WarnTriangle />
    <div class="desc-area">
      <div class="h4">
        {{ $t('pools.error') }}
      </div>
      <p class="desc">
        {{ errorText }}
      </p>
      <hr>
      <div class="buttons-area">
        <button type="button" class="btn pink" @click="closeModal(false)">
          {{ $t('common.dismiss') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenIdle,
  hydrateWhenVisible
} from 'vue-lazy-hydration'

import 'nouislider/distribute/nouislider.min.css'

export default {
  components: {
    ArrowDown: hydrateWhenVisible(() => import('~/components/svg/ArrowDown')),
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    ModalLoader: hydrateWhenVisible(() => import('~/components/app/interface/ModalLoader')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    Plus: hydrateWhenVisible(() => import('~/components/svg/new/Plus')),
    SwapArrows: hydrateWhenVisible(() => import('~/components/svg/new/SwapArrows')),
    veeno: hydrateWhenIdle(() => import('veeno')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  props: {
    pool: {
      type: Object,
      default: () => {
        return {
          address: '',
          token0: {
            name: '',
            symbol: '',
            address: '',
            logoURI: null,
            decimals: 18
          },
          token1: {
            name: '',
            symbol: '',
            address: '',
            logoURI: null,
            decimals: 18
          },
          reserves: {
            reserve0: 0,
            reserve1: 0
          },
          balance: 0,
          totalSupply: 0,
          poolShare: 0
        }
      }
    },
    transaction: {
      type: Object,
      default: () => {
        return {
          gasFee: 3000000,
          tx: '',
          loading: false,
          pending: false
        }
      }
    },
    type: {
      type: String,
      default: () => {
        return 'Remove'
      }
    }
  },
  data() {
    return {
      percent: 0,
      errorText: this.$t('pools.error_text'),
      approved: false,
      loadingAllowance: false,
      loading: false,
      confirmScreen: false,
      loadingScreen: false,
      successScreen: false,
      errorScreen: false,
      receiveBnb: false,
      simpleView: true,
      outputs: {
        lpToken: 0,
        token0: 0,
        token1: 0
      }
    }
  },
  computed: {
    ...mapGetters('settings', [
      'slippageTolerance',
      'deadline',
      'expertMode',
      'multihops'
    ]),
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    },
    fromPrice() {
      return this.pool !== null && this.pool.reserves.reserve1 !== 0 ? parseFloat(this.$formatCryptoAmount(this.pool.reserves.reserve0 / this.pool.reserves.reserve1, 6)) : 0
    },
    toPrice() {
      return this.pool !== null && this.pool.reserves.reserve0 !== 0 ? parseFloat(this.$formatCryptoAmount(this.pool.reserves.reserve1 / this.pool.reserves.reserve0, 6)) : 0
    },
    lpTokensToRemove() {
      if (this.pool !== null && this.pool.balance !== null && this.pool.balance !== 0) {
        return this.percent / 100 * this.pool.balance
      } else {
        return 0
      }
    },
    lpRatio() {
      if (this.pool !== null && this.pool.totalSupply !== null && this.pool.totalSupply !== 0) {
        const currentBalance = this.pool.balance !== null && this.pool.balance !== 0 ? this.pool.balance : 0
        return this.percent / 100 * currentBalance / this.pool.totalSupply
      } else {
        return 0
      }
    }
  },
  watch: {
    pool() {
      this.checkIfApproved()
    },
    isModalOpened(modalOpen) {
      if (!modalOpen) {
        this.returnInitState()
      }
    },
    percent(newPercent, oldPercent) {
      if (newPercent < 0) {
        this.percent = 0
      }
      if (newPercent > 100) {
        this.percent = 100
      }
      if (newPercent !== oldPercent) {
        this.checkIfApproved()
      }
    }
  },
  methods: {
    setPercent(newPercent) {
      if (newPercent !== undefined && newPercent !== null && !isNaN(newPercent)) {
        this.percent = newPercent
        this.changeInputs()
      }
    },
    setPercentValue(handles) {
      this.percent = parseFloat(handles.values[1])
      this.changeInputs()
    },
    updateVeeno(handles) {
      if (handles !== undefined && handles.handle === 0) {
        handles.positions[1] = handles.positions[0]
        handles.positions[0] = 0
        handles.unencoded[1] = handles.unencoded[0]
        handles.unencoded[0] = 0
        handles.values[1] = handles.values[0]
        handles.values[0] = 0
      }
      if (this.$refs.percentSlider !== undefined) {
        this.$refs.percentSlider.$el.noUiSlider.set(handles.values)
      }
    },
    confirmRemove() {
      this.loading = true
      this.initLoadingScreen()
      const percentValue = this.percent / 100
      this.$emit('removeLiquidity', null, percentValue, this.receiveBnb)
    },
    async checkIfApproved() {
      if (this.transaction.pending === false && this.pool !== null) {
        const userAddress = await this.$getUserAddress()
        const PeriodPairfile = await import('~/assets/abis/PeriodPair.json')
        const PeriodPair = PeriodPairfile.default
        const pairContract = new window.eth.eth.Contract(PeriodPair, this.pool.address)
        this.loadingAllowance = true
        try {
          await pairContract.methods.allowance(userAddress, this.$config.periodRouter)
            .call({ from: userAddress })
            .then((allowed) => {
              const remainingAllowance = window.eth.utils.toBN((allowed).toString())
              const neededAllowance = window.eth.utils.toBN(window.eth.utils.toWei(Number(this.lpTokensToRemove).toPrecision(10)))
              if (neededAllowance.gt(remainingAllowance)) {
                this.approved = false
                this.loadingAllowance = false
              } else {
                this.approved = true
                this.loadingAllowance = false
              }
            })
        } catch (err) {
          console.log(err)
          this.approved = false
          this.loadingAllowance = false
        }
      }
    },
    async approveTokens() {
      const userAddress = await this.$getUserAddress()
      const PeriodPairfile = await import('~/assets/abis/PeriodPair.json')
      const PeriodPair = PeriodPairfile.default
      const pairContract = new window.eth.eth.Contract(PeriodPair, this.pool.address)
      this.loadingAllowance = true
      try {
        await pairContract.methods.allowance(userAddress, this.$config.periodRouter)
          .call({ from: userAddress })
          .then(async (allowed) => {
            const remainingAllowance = window.eth.utils.toBN((allowed).toString())
            const neededAllowance = window.eth.utils.toBN(window.eth.utils.toWei(Number(this.lpTokensToRemove).toPrecision(10)))
            if (neededAllowance.gt(remainingAllowance)) {
              const gasPrice = await this.$calculateGasCost()
              const gasFeeEstimated = await pairContract.methods.approve(this.$config.periodRouter, window.eth.utils.toWei(Number(2 ** 64 - 1).toString(), 'ether'))
                .estimateGas({ from: userAddress, gas: this.transaction.gasFee })

              await pairContract.methods.approve(this.$config.periodRouter, window.eth.utils.toWei(Number(2 ** 64 - 1).toString(), 'ether'))
                .send({ from: userAddress, gas: Math.round(gasFeeEstimated * 1.15).toString(), gasPrice })
                .then((result) => {
                  const emitedApproval = result.events.Approval.returnValues.value
                  if (parseInt(emitedApproval, 10) > 0) {
                    this.approved = true
                    this.loadingAllowance = false
                  }
                })
                .catch(() => {
                  this.approved = false
                  this.loadingAllowance = false
                  this.$denyWalletOperation()
                })
            } else {
              this.approved = true
              this.loadingAllowance = false
            }
          })
      } catch (err) {
        console.log(err)
        this.approved = false
        this.loadingAllowance = false
      }
    },
    swapBnb() {
      this.receiveBnb = !this.receiveBnb
    },
    getTokenSymbol(token) {
      if (token.address !== this.$config.ZERO && token.address !== this.$config.wbnbAddress) {
        return token.symbol
      } else if (this.receiveBnb) {
        return 'BNB'
      } else {
        return 'WBNB'
      }
    },
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    },
    closeModal() {
      this.$emit('closeRemoveModal')
      this.returnInitState()
    },
    returnInitState() {
      if (this.transaction.pending === false) {
        this.$emit('initSettingsState')
        this.percent = 0
        this.loading = false
        this.confirmScreen = false
        this.loadingScreen = false
        this.successScreen = false
        this.errorScreen = false
        this.changeInputs()
      } else {
        this.initLoadingScreen()
      }
    },
    rejectTransaction(error) {
      if (error === null || typeof error !== 'object') {
        this.initErrorScreen()
      } else {
        switch (error.type.toLowerCase()) {
          case 'denied':
            this.errorText = this.$t('common.deined_operation')
            break
          case 'error':
            this.errorText = error.message !== undefined && error.message.length > 0 ? this.$t('pools.error_text_with_error', { error: error.message }) : this.$t('pools.error_text')
            break
          default:
            this.errorText = this.$t('pools.error_text')
        }
        this.initErrorScreen()
      }
    },
    changeView() {
      this.simpleView = !this.simpleView
      setTimeout(() => {
        if (this.simpleView && this.$refs.percentSlider !== undefined) {
          this.$refs.percentSlider.$el.noUiSlider.set([0, this.percent])
        }
      }, 100)
    },
    changeInputs(field = '') {
      const currentBalance = this.pool !== null && this.pool.balance !== null && this.pool.balance !== 0 ? this.pool.balance : 0
      if (field !== 'lpToken') {
        this.outputs.lpToken = parseFloat(currentBalance) / 100 * this.percent
      }
      if (field !== 'reserve0') {
        this.outputs.token0 = this.pool.reserves.reserve0 * this.lpRatio
      }
      if (field !== 'reserve1') {
        this.outputs.token1 = this.pool.reserves.reserve1 * this.lpRatio
      }
    },
    setMax() {
      this.percent = 100
      this.changeInputs()
    },
    calculateLpToken() {
      const currentBalance = this.pool !== null && this.pool.balance !== null && this.pool.balance !== 0 ? this.pool.balance : 0
      this.percent = this.outputs.lpToken / parseFloat(currentBalance) * 100
      if (this.percent > 100) {
        this.percent = 100
      } else if (this.percent < 0) {
        this.percent = 0
      }
      this.changeInputs('lpToken')
    },
    calculateReserve0() {
      const currentBalance = this.pool !== null && this.pool.balance !== null && this.pool.balance !== 0 ? this.pool.balance : 0
      const fullLp = currentBalance / this.pool.totalSupply
      const fullReserve = this.pool.reserves.reserve0 * fullLp
      this.percent = this.outputs.token0 / fullReserve * 100
      if (this.percent > 100) {
        this.percent = 100
      } else if (this.percent < 0) {
        this.percent = 0
      }
      this.changeInputs('reserve0')
    },
    calculateReserve1() {
      const currentBalance = this.pool !== null && this.pool.balance !== null && this.pool.balance !== 0 ? this.pool.balance : 0
      const fullLp = currentBalance / this.pool.totalSupply
      const fullReserve = this.pool.reserves.reserve1 * fullLp
      this.percent = this.outputs.token1 / fullReserve * 100
      if (this.percent > 100) {
        this.percent = 100
      } else if (this.percent < 0) {
        this.percent = 0
      }
      this.changeInputs('reserve1')
    },
    initConfirmScreen() {
      this.$emit('initConfirmState')
      this.loading = false
      this.confirmScreen = true
      this.loadingScreen = false
      this.errorScreen = false
      this.successScreen = false
    },
    initLoadingScreen() {
      this.$emit('initLoading')
      this.loading = false
      this.confirmScreen = false
      this.loadingScreen = true
      this.errorScreen = false
      this.successScreen = false
    },
    initSuccessScreen() {
      this.$emit('initSuccess')
      this.loading = false
      this.confirmScreen = false
      this.loadingScreen = false
      this.errorScreen = false
      this.successScreen = true
    },
    initErrorScreen() {
      this.$emit('initError')
      this.loading = false
      this.confirmScreen = false
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = true
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modalContent.css');
</style>
