<template>
  <svg viewBox="0 0 511.322 511.322" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path
      d="m511.322 349.221c0-45.435-36.963-82.398-82.397-82.398h-33.127c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h33.127c37.163 0 67.397 30.234 67.397 67.398 0 37.163-30.234 67.397-67.397 67.397h-244.006c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h235.981c-3.67 26.743-26.654 47.415-54.39 47.415h-221.697c-27.735 0-50.719-20.672-54.389-47.415h66.373c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-74.399c-37.164.001-67.398-30.234-67.398-67.397 0-37.164 30.234-67.398 67.398-67.398h285.438c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-192.101l36.245-36.245c24.129-21.175 38.02-50.776 39.118-83.377 1.153-34.239-12.328-68.018-36.986-92.676-49.65-49.649-130.433-49.649-180.084.002l-.562.563c-15.378 15.377-17.959 38.766-7.771 56.848l-10.697 50.82c-1.614 7.667 1.94 15.306 8.844 19.01 2.641 1.417 5.488 2.107 8.308 2.107 4.553 0 9.029-1.801 12.423-5.25l27.789-28.235 28.768-28.768c12.65-12.65 33.235-12.65 45.885 0s12.65 33.234 0 45.885l-123.827 123.826c-.689.689-1.209 1.48-1.574 2.323-13.415 14.663-21.614 34.173-21.614 55.565 0 43.029 33.16 78.438 75.264 82.069 3.602 35.195 33.419 62.744 69.549 62.744h221.697c36.13 0 65.947-27.549 69.549-62.744 42.104-3.631 75.263-39.04 75.263-82.069zm-477.441-181.117c-1.08 1.098-2.199.848-2.948.447-.749-.402-1.575-1.197-1.257-2.703l8.251-39.199 18.523 18.523zm123.739 10.01c18.499-18.499 18.499-48.599 0-67.098s-48.599-18.499-67.098 0l-23.507 23.507-22.942-22.942c-12.65-12.65-12.65-33.234.002-45.887l.562-.563c21.218-21.217 49.428-32.902 79.434-32.902s58.216 11.685 79.434 32.903c21.732 21.732 33.615 51.461 32.602 81.564-.959 28.494-13.101 54.332-34.188 72.756-.127.111-.25.226-.369.345l-47.027 47.027h-72.125c-5.073 0-10.032.485-14.854 1.367z"
    />
    <path
      d="m59.151 76.116c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l8.019 8.019c1.464 1.464 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197c2.929-2.929 2.929-7.678 0-10.606z"
    />
  </svg>
</template>

<script>
export default {
  name: 'FlamingoIcon'
}
</script>
