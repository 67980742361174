var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewbox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: { d: "M9.07111 9.07129L23.2132 23.2134", stroke: "#66CFFF" },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M9.07111 23.2129L23.2132 9.07075", stroke: "#66CFFF" },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "0.5",
          width: "31",
          height: "31",
          rx: "15.5",
          stroke: "#66CFFF",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }