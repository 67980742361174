<template>
  <div v-if="!loadingScreen && !successScreen && !errorScreen" class="confirm-area">
    <div v-if="tab === 'buy'" class="buy-area">
      <div class="inputs-area">
        <div class="input">
          <div class="label-area">
            <p class="label">
              {{ $t('lottery.buy') }}
            </p>
            <p class="balance">
              {{ $t('common.balance') }}: {{ floatieBalance !== null && !loadingBalance ? $formatCryptoAmount(floatieBalance, 6) : '...' }}
            </p>
          </div>
          <div class="field-area">
            <input v-model="form.tickets" type="number" step="1" min="0" @input="checkTicketsAmount">
            <button type="button" class="max-button active" @click="setMax">
              <p class="text">
                {{ $t('swap.max') }}
              </p>
            </button>
            <p v-show="errors.tickets.length > 0" class="hint error">
              {{ errors.tickets }}
            </p>
          </div>
        </div>
      </div>
      <div class="desc-table">
        <div class="desc-row">
          <div class="title">
            <p>
              {{ $t('lottery.price') }} ($FLE)
            </p>
          </div>
          <div class="value">
            {{ $formatCryptoAmount(lottery.priceTicketInFloatie, 6) }}
            $FLE
          </div>
        </div>
        <div class="desc-row">
          <div class="title">
            <p>
              <strong v-if="discountAmount > 0" class="bold">
                {{ $priceFormat(discountAmount, 2) }}%
              </strong>
              {{ $t('lottery.discount') }}
            </p>
            <div class="hint-icon wide-tooltip" :tooltip="$t('lottery.discount_hint')">
              <Question />
            </div>
          </div>
          <div class="value">
            ~{{ $formatCryptoAmount(totalDiscount, 6) }}
            $FLE
          </div>
        </div>
      </div>
      <hr>
      <div class="desc-table">
        <div class="desc-row">
          <div class="title">
            <p>
              {{ $t('lottery.you_pay') }}
            </p>
          </div>
          <div class="value">
            <p class="bold">
              ~{{ $formatCryptoAmount(total, 6) }}
              $FLE
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 'edit'" class="buy-area">
      <div class="desc-table">
        <div class="desc-row">
          <div class="title">
            <p>
              {{ $t('lottery.you_pay') }}
            </p>
          </div>
          <div class="value">
            <p class="bold">
              ~{{ $formatCryptoAmount(total, 6) }}
              $FLE
            </p>
          </div>
        </div>
      </div>
      <hr>
      <p class="desc">
        {{ $t('lottery.edit_desc') }}
      </p>
      <div class="buttons-area">
        <button class="btn cyan btn-outline" type="button" @click="randomizeTickets">
          <span class="text">
            {{ $t('lottery.randomize') }}
          </span>
        </button>
      </div>
      <div class="tickets-list">
        <div v-for="(number, ind) in form.numbers" :key="ind" class="ticket">
          <p class="label">
            #{{ ind + 1 }}
          </p>
          <div class="numbers-area">
            <div v-for="(num, index) in number.split('')" :key="index" class="number">
              <input :ref="'tnum' + ind + index" type="number" :value="num" @input="changeTicketNumber($event, ind, index)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons-area">
      <button v-if="!floatieApproved" :class="{'btn btn-outline pink': true, 'disabled': loadingFloatieApproved}" :disabled="loadingFloatieApproved" type="button" @click="approveToken">
        <span v-if="!loadingFloatieApproved" class="text">
          {{ $t('pools.approve') }} $FLE
        </span>
        <span v-else class="text">
          <Loading />
        </span>
      </button>
      <button v-if="tab === 'buy'" :class="{'btn cyan btn-outline': true, 'disabled': loading || errorsExist || loadingBalance || total === 0 }" type="button" :disabled="loading || errorsExist || loadingBalance || total === 0" @click="changeTabToEdit">
        <span class="text">
          {{ $t('lottery.view_edit_numbers') }}
        </span>
      </button>
      <button v-if="floatieApproved && tab === 'buy'" :class="{'btn pink': true, 'disabled': loading || errorsExist || loadingBalance || total === 0 }" type="button" :disabled="loading || errorsExist || loadingBalance || total === 0" @click="buyTickets">
        <span v-if="!loading">
          {{ $t('lottery.buy_now') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
      <button v-if="tab === 'edit'" :class="{'btn cyan btn-outline': true, 'disabled': loading || errorsExist || loadingBalance || total === 0 }" type="button" :disabled="loading || errorsExist || loadingBalance || total === 0" @click="changeTabToBuy">
        <span class="text">
          {{ $t('lottery.return_back') }}
        </span>
      </button>
      <button v-if="floatieApproved && tab === 'edit'" :class="{'btn pink': true, 'disabled': loading || errorsExist || loadingBalance || total === 0 }" type="button" :disabled="loading || errorsExist || loadingBalance || total === 0" @click="buyTickets">
        <span class="text">
          {{ $t('lottery.confirm_buy') }}
        </span>
      </button>
    </div>
    <div class="other-desc">
      <p class="hint">
        {{ $t('lottery.buy_now_desc', {price: $formatCryptoAmount(lottery.priceTicketInFloatie, 6)}) }}
      </p>
    </div>
  </div>
  <div v-else-if="loadingScreen" class="loading-area">
    <ModalLoader />
    <div class="desc-area">
      <div class="h5">
        {{ $t('lottery.confirming', {
          'tickets': form.tickets,
          'tickets_text': $numberToString(form.tickets, [
            $t('lottery.ticket'),
            $t('lottery.tickets'),
            $t('lottery.many_tickets')
          ]),
          'lottery': lottery.id
        }) }}
      </div>
      <p>
        {{ $t('swap.confirm_transaction') }}
      </p>
    </div>
  </div>
  <div v-else-if="successScreen" class="success-area">
    <div class="icon-area">
      <CheckIcon />
    </div>
    <div class="desc-area">
      <div class="h4">
        {{ $t('lottery.success') }}
      </div>
      <p class="desc">
        {{ $t('lottery.success_text') }}
      </p>
    </div>
    <hr>
    <div class="buttons-area">
      <a class="btn btn-outline pink" :href="transactionLink(transaction.tx)" rel="nofollow noopener noreferrer" target="_blank">
        <span class="text">
          {{ $t('swap.view_explorer') }}
          <span class="icon icon-right">
            <NewTabIcon />
          </span>
        </span>
      </a>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.close') }}
      </button>
    </div>
  </div>
  <div v-else-if="errorScreen" class="error-area">
    <WarnTriangle />
    <div class="desc-area">
      <div class="h4">
        {{ $t('limit.error') }}
      </div>
      <p class="desc">
        {{ errorText }}
      </p>
      <hr>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.dismiss') }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  components: {
    Question: hydrateWhenVisible(() => import('~/components/svg/new/Question')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    ModalLoader: hydrateWhenVisible(() => import('~/components/app/interface/ModalLoader')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  props: {
    transaction: {
      type: Object,
      default: () => {
        return {
          gasFee: 3000000,
          tx: '',
          loading: false,
          pending: false
        }
      }
    },
    lottery: {
      type: Object,
      default: () => {
        return null
      }
    },
    soldTickets: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: () => {
        return 'Global'
      }
    }
  },
  data() {
    return {
      form: {
        tickets: 0,
        numbers: []
      },
      errors: {
        tickets: ''
      },
      floatieBalance: 0,
      errorText: this.$t('lottery.error_text'),
      tab: 'buy',
      loading: false,
      loadingBalance: false,
      loadingScreen: false,
      successScreen: false,
      errorScreen: false,
      floatieApproved: false,
      loadingFloatieApproved: false,
      minNumber: 1000000,
      maxNumber: 1999999
    }
  },
  computed: {
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    },
    total() {
      const tickets = parseInt(this.form.tickets, 10)
      const price = tickets * this.lottery.priceTicketInFloatie
      const discount = tickets > 1 ? (tickets - 1) * 0.05 : 0
      const discountAmount = price / 100 * discount
      return price - discountAmount
    },
    totalDiscount() {
      const tickets = parseInt(this.form.tickets, 10)
      const price = tickets * this.lottery.priceTicketInFloatie
      const discount = tickets > 1 ? (tickets - 1) * 0.05 : 0
      const discountAmount = price / 100 * discount
      return discountAmount
    },
    discountAmount() {
      const tickets = parseInt(this.form.tickets, 10)
      const discount = tickets > 1 ? (tickets - 1) * 0.05 : 0
      return discount
    },
    errorsExist() {
      let exist = false
      for (const prop in this.errors) {
        if (this.errors[prop].length > 0) {
          exist = true
          break
        }
      }
      return exist
    }
  },
  watch: {
    isModalOpened(modalOpen) {
      if (!modalOpen) {
        this.returnInitState()
      } else {
        this.checkForApproval()
      }
    }
  },
  mounted() {
    this.checkForApproval()
    this.loadFloatieBalance()
  },
  methods: {
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    },
    closeModal() {
      this.$emit('closeGlobalModal')
      this.returnInitState()
    },
    returnInitState() {
      if (this.transaction.pending === false) {
        this.$emit('initConfirmState')
        this.loading = false
        this.loadingScreen = false
        this.successScreen = false
        this.errorScreen = false
      } else {
        this.initLoadingScreen()
      }
    },
    changeTabToEdit() {
      this.tab = 'edit'
    },
    changeTabToBuy() {
      this.tab = 'buy'
    },
    checkTicketsAmount() {
      this.errors.tickets = ''
      const ticketsNumber = parseInt(this.form.tickets, 10)
      if (ticketsNumber < 0) {
        this.form.tickets = 0
      } else if (ticketsNumber > 100) {
        this.form.tickets = 100
      }
      if (this.total > this.floatieBalance) {
        this.errors.tickets = this.$t('lottery.low_balance')
      }
      this.randomizeTickets()
    },
    randomizeTickets() {
      this.form.numbers = []
      const ticketsNumber = parseInt(this.form.tickets, 10)
      for (let ind = 0; ind < ticketsNumber; ++ind) {
        let randomNumber = this.$random(this.minNumber, this.maxNumber)
        while (this.soldTickets.includes(randomNumber)) {
          randomNumber = this.$random(this.minNumber, this.maxNumber)
        }
        const numString = randomNumber.toString()
        this.$set(this.form.numbers, ind, numString.substring(1, numString.length))
      }
    },
    async loadFloatieBalance() {
      try {
        this.loadingBalance = true
        const userAddress = (await window.eth.eth.requestAccounts())[0]
        const ERC20file = await import('~/assets/abis/ERC20.json')
        const ERC20 = ERC20file.default
        const erc20Contract = new window.eth.eth.Contract(ERC20, this.$config.periodToken)
        const weiBalance = await erc20Contract.methods.balanceOf(userAddress).call({ from: userAddress })
        this.floatieBalance = parseFloat(window.eth.utils.fromWei(weiBalance))
        this.loadingBalance = false
      } catch (err) {
        console.log(err)
        this.loadingBalance = false
      }
    },
    changeTicketNumber(evt, ticketIndex, numberIndex) {
      const lastChar = evt.target.value.substring(1)
      const value = parseInt(lastChar, 10)
      let replacement = lastChar
      if (isNaN(value)) {
        replacement = '0'
      } else if (value < 0) {
        replacement = '0'
      } else if (value > 9) {
        replacement = '9'
      }
      this.$set(this.form.numbers, ticketIndex, this.form.numbers[ticketIndex].substring(0, numberIndex) + replacement + this.form.numbers[ticketIndex].substring(numberIndex + 1))
      if (this.$refs['tnum' + ticketIndex + (numberIndex + 1)] !== undefined) {
        this.$refs['tnum' + ticketIndex + (numberIndex + 1)][0].focus()
      }
    },
    setMax() {
      this.form.tickets = Math.floor(this.floatieBalance / this.lottery.priceTicketInFloatie)
      if (this.form.tickets > 100) {
        this.form.tickets = 100
      }
    },
    async buyTickets() {
      this.loading = true
      try {
        const userAddress = (await window.eth.eth.requestAccounts())[0]
        const ERC20file = await import('~/assets/abis/ERC20.json')
        const ERC20 = ERC20file.default
        const erc20Contract = new window.eth.eth.Contract(ERC20, this.$config.periodToken)
        const contractAddress = this.$config.periodLottery
        erc20Contract.methods.allowance(userAddress, contractAddress)
          .call({ from: userAddress })
          .then(async (allowed) => {
            const remainingAllowance = window.eth.utils.toBN(window.eth.utils.toWei((allowed).toString()))
            const serviceFeeWei = window.eth.utils.toBN(
              window.eth.utils.toWei(
                Number(this.total).toPrecision(6)
              )
            )
            if (serviceFeeWei.gt(remainingAllowance)) {
              const gasPrice = await this.$calculateGasCost()
              const gasFeeEstimated = await erc20Contract.methods.approve(contractAddress, window.eth.utils.toWei(Number(2 ** 64 - 1).toString()))
                .estimateGas({ from: userAddress, gas: this.transaction.gasFee })

              erc20Contract.methods.approve(contractAddress, window.eth.utils.toWei(Number(2 ** 64 - 1).toString(), 'ether'))
                .send({ from: userAddress, gas: Math.round(gasFeeEstimated * 1.15).toString(), gasPrice })
                .then((result) => {
                  const emitedApproval = result.events.Approval.returnValues[2]
                  if (parseInt(emitedApproval, 10) > 0) {
                    this.initLoadingScreen()
                    this.$emit('buyTickets', this.form)
                  }
                })
                .catch(() => {
                  this.loading = false
                  this.$denyWalletOperation()
                })
            } else {
              this.initLoadingScreen()
              this.$emit('buyTickets', this.form)
            }
          })
          .catch(() => {
            this.loading = false
          })
      } catch (err) {
        this.loading = false
        this.rejectTransaction({
          type: 'error',
          message: this.$t('swap.token_not_erc20_text')
        })
      }
    },
    async approveToken() {
      this.loadingFloatieApproved = true
      const approvalContract = this.$config.periodLottery
      const userAddress = (await window.eth.eth.requestAccounts())[0]
      const ERC20file = await import('~/assets/abis/ERC20.json')
      const ERC20 = ERC20file.default
      const erc20Contract = new window.eth.eth.Contract(ERC20, this.$config.periodToken)
      const gasPrice = await this.$calculateGasCost()
      const gasFeeEstimated = await erc20Contract.methods.approve(approvalContract, window.eth.utils.toWei(Number(2 ** 64 - 1).toString()))
        .estimateGas({ from: userAddress, gas: this.transaction.gasFee })

      erc20Contract.methods.approve(approvalContract, window.eth.utils.toWei(Number(2 ** 64 - 1).toString(), 'ether'))
        .send({ from: userAddress, gas: Math.round(gasFeeEstimated * 1.15).toString(), gasPrice })
        .then((result) => {
          const emitedApproval = result.events.Approval.returnValues[2]
          if (parseInt(emitedApproval, 10) > 0) {
            this.floatieApproved = true
            this.loadingFloatieApproved = false
          }
        })
        .catch(() => {
          this.loading = false
          this.loadingFloatieApproved = false
          this.$denyWalletOperation()
        })
    },
    async checkForApproval() {
      const chainId = await window.eth.eth.net.getId()
      if (this.$isChainSupported(chainId)) {
        try {
          const approvalContract = this.$config.periodLottery
          const userAddress = await this.$getUserAddress()
          const ERC20file = await import('~/assets/abis/ERC20.json')
          const ERC20 = ERC20file.default
          const erc20Contract = new window.eth.eth.Contract(ERC20, this.$config.periodToken)
          erc20Contract.methods.allowance(userAddress, approvalContract)
            .call({ from: userAddress })
            .then((allowed) => {
              const remainingAllowance = window.eth.utils.toBN(window.eth.utils.toWei((allowed).toString()))
              const serviceFeeWei = window.eth.utils.toBN(
                window.eth.utils.toWei(
                  Number(this.lottery.priceTicketInFloatie).toPrecision(6)
                )
              )
              if (serviceFeeWei.gt(remainingAllowance)) {
                this.floatieApproved = false
              } else {
                this.floatieApproved = true
              }
            })
        } catch (err) {
          console.log(err)
          this.floatieApproved = false
        }
      }
    },
    rejectTransaction(error) {
      if (error === null || typeof error !== 'object') {
        this.initErrorScreen()
      } else {
        switch (error.type.toLowerCase()) {
          case 'denied':
            this.errorText = this.$t('common.deined_operation')
            break
          case 'error':
            this.errorText = error.message !== undefined && error.message.length > 0 ? this.$t('lottery.error_text_with_error', { error: error.message }) : this.$t('lottery.error_text')
            break
          default:
            this.errorText = this.$t('lottery.error_text')
        }
        this.initErrorScreen()
      }
    },
    initLoadingScreen() {
      this.$emit('initLoading')
      this.loading = false
      this.loadingScreen = true
      this.errorScreen = false
      this.successScreen = false
    },
    initSuccessScreen() {
      this.$emit('initSuccess')
      this.loading = false
      this.loadingScreen = false
      this.errorScreen = false
      this.successScreen = true
    },
    initErrorScreen() {
      this.$emit('initError')
      this.loading = false
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = true
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modalContent.css');
</style>
