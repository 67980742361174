<template>
  <div class="confirm-area">
    <div v-if="!ticketsLoading && tickets.length > 0" class="transactions-list">
      <div class="header">
        {{ $t('lottery.your_tickets') }}
      </div>
      <div v-for="(ticket, index) in tickets" :key="index" class="transaction-item">
        <div class="label">
          <span>
            #{{ index + 1 }}
          </span>
          <span v-if="ticket.tx !== null" class="smaller">
            &nbsp;|&nbsp;
          </span>
          <a v-if="ticket.tx !== null" :href="ticket.tx" class="link smaller" rel="nofollow noopener noreferrer" target="_blank">
            <i class="bx bx-link-external"></i>
            {{ $t('lottery.scan_link') }}
          </a>
        </div>
        <div class="number">
          <div v-for="(num, ind) in ticket.roll.toString().split('')" :key="ind" class="num">
            {{ num }}
          </div>
        </div>
      </div>
      <div class="buttons-area">
        <button type="button" class="btn cyan btn-outline" @click="closeModal(false)">
          <span class="text">
            {{ $t('common.close') }}
          </span>
        </button>
        <button type="button" class="btn pink" @click="openBuyTickets">
          <span class="text">
            {{ $t('lottery.buy_tickets_cta') }}
          </span>
        </button>
      </div>
    </div>
    <div v-else-if="ticketsLoading" class="transactions-list empty-area">
      <div class="icon-area">
        <Loading />
      </div>
      <div class="h4">
        {{ $t('common.loading') }}
      </div>
    </div>
    <div v-else class="transactions-list">
      <p class="desc">
        {{ $t('lottery.no_tickets') }}
      </p>
      <div class="buttons-area">
        <button type="button" class="btn cyan btn-outline" @click="closeModal(false)">
          <span class="text">
            {{ $t('common.close') }}
          </span>
        </button>
        <button type="button" class="btn pink" @click="openBuyTickets">
          <span class="text">
            {{ $t('lottery.buy_tickets_cta') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  components: {
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading'))
  },
  props: {
    tickets: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapGetters('lottery', [
      'ticketsLoading'
    ])
  },
  methods: {
    ...mapActions('modal', [
      'changeModalGlobalState'
    ]),
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    },
    closeModal() {
      this.$emit('closeListModal')
    },
    openBuyTickets() {
      this.closeModal()
      this.changeModalGlobalState({
        state: true
      })
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modalContent.css');
</style>
