<template>
  <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path
      d="m111 35.71-46-26.56a2 2 0 0 0 -2 0l-46 26.56a2 2 0 0 0 -1 1.73v53.12a2 2 0 0 0 1 1.73l46 26.56a2 2 0 0 0 2 0l46-26.56a2 2 0 0 0 1-1.73v-53.12a2 2 0 0 0 -1-1.73zm-47-22.52 42 24.25-29.27 16.9a16 16 0 0 0 -25.46 0l-29.27-16.9zm0 62.81a12 12 0 1 1 12-12 12 12 0 0 1 -12 12zm-44-35.09 29.25 16.89a16 16 0 0 0 12.75 22.06v33.79l-42-24.25zm46 72.74v-33.79a16 16 0 0 0 12.75-22.07l29.25-16.88v48.49z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CubeIcon'
}
</script>
