var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 1027 888",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { filter: "url(#filter0_f_102_29)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "513.387",
            cy: "444.121",
            rx: "306.253",
            ry: "200.382",
            transform: "rotate(155.893 513.387 444.121)",
            fill: "url(#paint0_linear_102_29)",
            "fill-opacity": "0.6",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "filter",
            {
              attrs: {
                id: "filter0_f_102_29",
                x: "0.0333862",
                y: "0.512329",
                width: "1026.71",
                height: "887.216",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _vm._v(" "),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape",
                },
              }),
              _vm._v(" "),
              _c("feGaussianBlur", {
                attrs: {
                  stdDeviation: "111",
                  result: "effect1_foregroundBlur_102_29",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_102_29",
                x1: "639.395",
                y1: "640.883",
                x2: "124.15",
                y2: "165.912",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#2523AA" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#2523AA",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }