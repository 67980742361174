var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "switcher-pick" }, [
    _c("div", { staticClass: "switcher" }, [
      _c("input", {
        attrs: {
          id: "switcher" + _vm.id,
          "v-model": _vm.currentState,
          type: "checkbox",
          "true-value": true,
          "false-value": false,
        },
        domProps: { checked: _vm.currentState },
        on: { change: _vm.selectHandler },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "switcher" + _vm.id } }, [_vm._v("Toggle")]),
    ]),
    _vm._v(" "),
    _vm.label.length > 0
      ? _c("p", { staticClass: "label" }, [
          _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }