var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 27 27",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "13.5",
          cy: "13.5",
          r: "13.25",
          fill: "#D9D9D9",
          "fill-opacity": "0.1",
          stroke: "#3DE0EC",
          "stroke-width": "0.5",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "13.4996",
          cy: "13.5",
          r: "9.9",
          fill: "#3DE0EC",
          "fill-opacity": "0.3",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: { cx: "13.4992", cy: "13.5", r: "6.3", fill: "#3DE0EC" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }