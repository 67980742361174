var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewbox: "0 0 16 16",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M15.8045 14.8618L11.2545 10.3119C12.1359 9.22324 12.6665 7.83991 12.6665 6.33327C12.6665 2.84131 9.8252 0 6.33324 0C2.84128 0 0 2.84128 0 6.33324C0 9.8252 2.84131 12.6665 6.33327 12.6665C7.83991 12.6665 9.22324 12.1359 10.3119 11.2545L14.8618 15.8045C14.9918 15.9345 15.1625 15.9998 15.3332 15.9998C15.5039 15.9998 15.6745 15.9345 15.8045 15.8045C16.0652 15.5438 16.0652 15.1225 15.8045 14.8618ZM6.33327 11.3332C3.57596 11.3332 1.33333 9.09056 1.33333 6.33324C1.33333 3.57593 3.57596 1.3333 6.33327 1.3333C9.09059 1.3333 11.3332 3.57593 11.3332 6.33324C11.3332 9.09056 9.09056 11.3332 6.33327 11.3332Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }