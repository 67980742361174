var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        preserveAspectRatio: "xMidYMid meet",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "26.025 26.025 147.95 147.95",
        fill: "currentColor",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M168.8 143.8L125 100l43.8-43.8c6.9-6.9 6.9-18.1 0-25-6.9-6.9-18.1-6.9-25 0L100 75 56.2 31.2c-6.9-6.9-18.1-6.9-25 0s-6.9 18.1 0 25L75 100l-43.8 43.8c-6.9 6.9-6.9 18.1 0 25 6.9 6.9 18.1 6.9 25 0L100 125l43.8 43.8c6.9 6.9 18.1 6.9 25 0 6.9-6.8 6.9-18.1 0-25z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }