<template>
  <svg viewBox="0 0 6.3499999 6.3500002" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
    <g fill="currentColor" transform="translate(0 -290.65)">
      <path
        d="m3.1760456 291.03547c-1.5378237 0-2.79104489 1.25115-2.79104413 2.78898.00000003 1.53782 1.25321963 2.79104 2.79104413 2.79104 1.5378242 0 2.7889764-1.25322 2.7889772-2.79104a.2648417.2648417 0 0 0 -.5296834 0c-.0000008 1.25184-1.0074529 2.26187-2.2592938 2.26187-1.2518418 0-2.26187746-1.01003-2.26187746-2.26187-.00000063-1.25185 1.01003626-2.25981 2.26187746-2.25982.6338562 0 1.2014703.2615 1.611788.68006l-.5379517-.0708a.26460982.26460982 0 1 0 -.068213.52348l1.1131103.14676a.26460982.26460982 0 0 0 .2950723-.22893l.1462445-1.11311a.26460982.26460982 0 0 0 -.2573489-.30282.26460982.26460982 0 0 0 -.2676839.23461l-.056327.43047c-.5055194-.51036-1.2048587-.82888-1.9786906-.82888z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'UpdateComponent'
}
</script>
