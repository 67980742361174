var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-item" }, [
    _c(
      "div",
      {
        ref: "selectedHeader",
        class: { "select-header": true, active: _vm.isOpen },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleSelect.apply(null, arguments)
          },
        },
      },
      [
        _c("p", { staticClass: "current" }, [
          _vm._v("\n      " + _vm._s(_vm.currentOption) + "\n    "),
        ]),
        _vm._v(" "),
        _c("ChevronDown"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { ref: "selectedArea", staticClass: "select-area" }, [
      _vm.options.length > 0
        ? _c(
            "div",
            { staticClass: "options-list" },
            _vm._l(_vm.options, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.pickOption(item.value)
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v("\n          " + _vm._s(item.name) + "\n        "),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "empty-options" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("common.list_empty")) + "\n    "),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }