var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-area list" }, [
    _vm.withSearch
      ? _c("div", { staticClass: "filter-area" }, [
          _c(
            "div",
            { staticClass: "input-area" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter.search,
                    expression: "filter.search",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("limit.search_by_hash"),
                },
                domProps: { value: _vm.filter.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.filter, "search", $event.target.value)
                    },
                    _vm.searchModel,
                  ],
                },
              }),
              _vm._v(" "),
              _c("Search"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.ordersLoading && _vm.orders.length > 0
      ? _c(
          "div",
          { staticClass: "table-item" },
          [
            _c("div", { staticClass: "header row" }, [
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("limit.from")) + "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("limit.to")) + "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("limit.limit_price")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("limit.status")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col action" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("limit.actions")) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.orders, function (order, index) {
              return _c("div", { key: index, staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col small-visible" },
                  [
                    _c("div", { staticClass: "number" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.filter.step * (_vm.filter.page - 1) + index + 1
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "logo mini",
                        attrs: {
                          to: _vm.localePath(
                            "/analytics/tokens/" + order.t0_address
                          ),
                        },
                      },
                      [
                        _c("nuxt-img", {
                          attrs: {
                            fit: "inside",
                            src: _vm.$getImage(order.t0_image),
                            alt: order.t0_name,
                            title: order.t0_symbol,
                          },
                          on: {
                            error: function ($event) {
                              $event.target.src = require("~/static/img/default_token.svg")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "name",
                        attrs: {
                          to: _vm.localePath(
                            "/analytics/tokens/" + order.t0_address
                          ),
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$formatCryptoAmount(order.amount_in, 6)
                            ) +
                            "\n          " +
                            _vm._s(order.t0_symbol) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col horizontal small-visible xs-hidden" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "logo mini",
                        attrs: {
                          to: _vm.localePath(
                            "/analytics/tokens/" + order.t1_address
                          ),
                        },
                      },
                      [
                        _c("nuxt-img", {
                          attrs: {
                            fit: "inside",
                            src: _vm.$getImage(order.t1_image),
                            alt: order.t1_name,
                            title: order.t1_symbol,
                          },
                          on: {
                            error: function ($event) {
                              $event.target.src = require("~/static/img/default_token.svg")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "name",
                        attrs: {
                          to: _vm.localePath(
                            "/analytics/tokens/" + order.t1_address
                          ),
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$formatCryptoAmount(order.amount_out, 6)
                            ) +
                            "\n          " +
                            _vm._s(order.t1_symbol) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("limit.limit_price")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$formatCryptoAmount(order.price_out)) +
                        "\n          " +
                        _vm._s(order.t1_symbol) +
                        "\n          " +
                        _vm._s(_vm.$t("common.per")) +
                        "\n          " +
                        _vm._s(order.t0_symbol) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("limit.status")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t(`limit.${order.status}`)) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col action" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("limit.actions")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  order.status === "new"
                    ? _c("div", { staticClass: "name" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn cyan btn-circle danger",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelOrder(order.hash)
                              },
                            },
                          },
                          [_c("i", { staticClass: "bx bx-trash" })]
                        ),
                      ])
                    : _c("div", { staticClass: "name" }, [
                        _vm._v("\n          ---\n        "),
                      ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col accordion small-visible",
                    on: {
                      click: function ($event) {
                        return _vm.openAccordion(index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          switcher: true,
                          active:
                            _vm.accordions[index] !== undefined &&
                            _vm.accordions[index] === true,
                        },
                      },
                      [
                        _vm.accordions[index] !== undefined &&
                        _vm.accordions[index] === true
                          ? _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.hide")) +
                                  "\n          "
                              ),
                            ])
                          : _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.show")) +
                                  "\n          "
                              ),
                            ]),
                        _vm._v(" "),
                        _c("ChevronDown"),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "accordionArea" + index,
                    refInFor: true,
                    staticClass: "accordion-area mobile-visible",
                  },
                  [
                    _c("div", { staticClass: "all-params" }, [
                      _c(
                        "div",
                        { staticClass: "item xs-visible" },
                        [
                          _c("p", { staticClass: "hint" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("limit.token_receive")) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "nuxt-link",
                            {
                              staticClass: "logo",
                              attrs: {
                                to: _vm.localePath(
                                  "/analytics/tokens/" + order.t1_address
                                ),
                              },
                            },
                            [
                              _c("nuxt-img", {
                                attrs: {
                                  fit: "inside",
                                  src: _vm.$getImage(order.t1_image),
                                  alt: order.t1_name,
                                  title: order.t1_symbol,
                                },
                                on: {
                                  error: function ($event) {
                                    $event.target.src = require("~/static/img/default_token.svg")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "nuxt-link",
                            {
                              staticClass: "name",
                              attrs: {
                                to: _vm.localePath(
                                  "/analytics/tokens/" + order.t1_address
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(order.t1_name) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("limit.limit_price")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$formatCryptoAmount(order.price_out)) +
                              "\n              " +
                              _vm._s(order.t1_symbol) +
                              "\n              " +
                              _vm._s(_vm.$t("common.per")) +
                              "\n              " +
                              _vm._s(order.t0_symbol) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("limit.status")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t(`limit.${order.status}`)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("limit.actions")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        order.status === "new"
                          ? _c("div", { staticClass: "name" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn cyan btn-circle danger",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelOrder(order.hash)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "bx bx-trash" })]
                              ),
                            ])
                          : _c("div", { staticClass: "name" }, [
                              _vm._v("\n              ---\n            "),
                            ]),
                      ]),
                    ]),
                  ]
                ),
              ])
            }),
          ],
          2
        )
      : _vm.ordersLoading
      ? _c(
          "div",
          { staticClass: "table-item" },
          [
            _c("div", { staticClass: "header row" }, [
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("limit.from")) + "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("limit.to")) + "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("limit.limit_price")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("limit.status")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("limit.actions")) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(3, function (index) {
              return _c("div", { key: index, staticClass: "row" }, [
                _c("div", { staticClass: "col small-visible" }, [
                  _c("div", { staticClass: "number" }, [
                    _vm._v("\n          " + _vm._s(index + 1) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "40px", height: "40px", circle: "" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "70px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col horizontal small-visible xs-hidden" },
                  [
                    _c(
                      "div",
                      { staticClass: "logo" },
                      [
                        _c("Skeleton", {
                          attrs: { width: "40px", height: "40px", circle: "" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "name" },
                      [
                        _c("Skeleton", {
                          attrs: { width: "70px", height: "25px" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "40px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "60px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { circle: "", width: "1rem", height: "1rem" },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            }),
          ],
          2
        )
      : _c("div", { staticClass: "table-item" }, [
          _c("div", { staticClass: "not-found-area" }, [
            _vm.status === "new"
              ? _c("div", { staticClass: "h3" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("limit.no_open_orders")) +
                      "\n      "
                  ),
                ])
              : _vm.status === "no_expired_orders"
              ? _c("div", { staticClass: "h3" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("limit.no_open_orders")) +
                      "\n      "
                  ),
                ])
              : _c("div", { staticClass: "h3" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("limit.no_orders_history")) +
                      "\n      "
                  ),
                ]),
          ]),
        ]),
    _vm._v(" "),
    _vm.orders.length > 0 && _vm.withPagination
      ? _c(
          "div",
          { class: { "pagination-area": true, disabled: _vm.ordersLoading } },
          [
            _c("Pagination", {
              attrs: {
                page: _vm.filter.page,
                step: _vm.filter.step,
                total: _vm.total,
              },
              on: { change: _vm.setPage },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }