<template>
  <div v-if="!loadingScreen && !successScreen && !errorScreen" class="confirm-area">
    <div class="confirm-table">
      <div class="confirm-row">
        <div class="token-data">
          <nuxt-img
            v-if="inputMethod !== 'exchange'"
            :quality="80"
            placeholder
            :src="swap.from_token.address !== $config.ZERO ? $getImageByAddress(swap.from_token.address) : $getImage(swap.from_token.logoURI)"
            :alt="swap.from_token.name + ' ' + swap.from_token.symbol"
            :title="swap.from_token.name"
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <nuxt-img
            v-else
            :quality="80"
            placeholder
            :src="'/img/new/' + swap.from_token.name.toLowerCase() + '_item.svg'"
            :alt="swap.from_token.name + ' ' + swap.from_token.symbol"
            :title="swap.from_token.name"
            :lazy="true"
          />
          <p class="value">
            {{ $formatCryptoAmount(swap.from_value, 6) }}
          </p>
        </div>
        <div class="token-symbol">
          <div class="h3">
            {{ swap.from_token.symbol.toUpperCase() }}
          </div>
        </div>
      </div>
      <div class="confirm-row">
        <div class="arrow-area">
          <ArrowDown />
        </div>
      </div>
      <div class="confirm-row">
        <div class="token-data">
          <nuxt-img
            v-if="inputMethod !== 'exchange'"
            :quality="80"
            placeholder
            :src="swap.to_token.address !== $config.ZERO ? $getImageByAddress(swap.to_token.address) : $getImage(swap.to_token.logoURI)"
            :alt="swap.to_token.name + ' ' + swap.to_token.symbol"
            :title="swap.to_token.name"
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <nuxt-img
            v-else
            :quality="80"
            placeholder
            :src="'/img/new/' + swap.to_token.name.toLowerCase() + '_item.svg'"
            :alt="swap.to_token.name + ' ' + swap.to_token.symbol"
            :title="swap.to_token.name"
            :lazy="true"
          />
          <p class="value">
            {{ $formatCryptoAmount(swap.to_value, 6) }}
          </p>
        </div>
        <div class="token-symbol">
          <div class="h3">
            {{ swap.to_token.symbol.toUpperCase() }}
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="desc-table">
      <div class="desc-row">
        <div class="title">
          <p>
            {{ $t('limit.current_price') }}
          </p>
        </div>
        <div class="value column">
          <p class="value-row bold">
            {{ '1 ' + swap.from_token.symbol.toUpperCase() }}
            =
            {{ toPrice + ' ' + swap.to_token.symbol.toUpperCase() }}
          </p>
          <p class="value-row bold">
            {{ '1 ' + swap.to_token.symbol.toUpperCase() }}
            =
            {{ fromPrice + ' ' + swap.from_token.symbol.toUpperCase() }}
          </p>
        </div>
      </div>
      <div class="desc-row">
        <div class="title">
          <p>
            {{ $t('limit.limit_price') }}
          </p>
        </div>
        <div class="value column">
          <p class="value-row bold">
            {{ '1 ' + swap.from_token.symbol.toUpperCase() }}
            =
            {{ $formatCryptoAmount(swap.price, 8) + ' ' + swap.to_token.symbol.toUpperCase() }}
          </p>
          <p class="value-row bold">
            {{ '1 ' + swap.to_token.symbol.toUpperCase() }}
            =
            {{ $formatCryptoAmount(priceDifference / 100 * swap.from_value + swap.from_value, 8) + ' ' + swap.from_token.symbol.toUpperCase() }}
          </p>
        </div>
      </div>
    </div>
    <div class="notice-area">
      <div class="icon-area">
        <Info />
      </div>
      <div class="text-area">
        <p>
          {{ $t('limit.limit_desc', {percent: $priceFormat(priceDifference, 0)}) }}
        </p>
      </div>
    </div>
    <div class="notice-area danger">
      <div class="icon-area">
        <WarnTriangle />
      </div>
      <div class="text-area">
        <p class="bold">
          {{ $t('limit.real_price_header') }}
        </p>
        <p>
          {{ $t('limit.real_price_desc1') }}
        </p>
        <p>
          {{ $t('limit.real_price_desc2') }}
        </p>
        <p>
          {{ $t('limit.real_price_desc3') }}
        </p>
        <p class="bold">
          {{ $t('limit.fee_on_transfer') }}
        </p>
        <p>
          {{ $t('limit.fee_on_transfer_desc') }}
        </p>
      </div>
    </div>
    <div class="buttons-area">
      <button v-if="!fromApproved" :class="{'btn btn-outline pink': true, 'disabled': loadingFromApprove}" :disabled="loadingFromApprove" type="button" @click="approveToken">
        <span v-if="!loadingFromApprove" class="text">
          {{ $t('pools.approve') }} {{ swap.from_token.symbol }}
        </span>
        <span v-else class="text">
          <Loading />
        </span>
      </button>
      <button :class="{'btn pink': true, 'disabled': loading || !approvedTokens }" type="button" :disabled="loading || !approvedTokens" @click="confirmOrder">
        <span v-if="!loading">
          {{ $t('limit.confirm_order') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
  </div>
  <div v-else-if="loadingScreen" class="loading-area">
    <ModalLoader />
    <div class="desc-area">
      <div class="h5">
        {{ $t('limit.creating_order_buy') }}
        {{ $formatCryptoAmount(swap.from_value, 6) + ' ' + swap.from_token.symbol.toUpperCase() }}
        {{ $t('common.for') }}
        {{ $formatCryptoAmount(swap.to_value, 6) + ' ' + swap.to_token.symbol.toUpperCase() }}
      </div>
      <p>
        {{ $t('swap.confirm_transaction') }}
      </p>
    </div>
  </div>
  <div v-else-if="successScreen" class="success-area">
    <div class="icon-area">
      <CheckIcon />
    </div>
    <div class="desc-area">
      <div class="h4">
        {{ $t('limit.success') }}
      </div>
      <p class="desc">
        {{ $t('limit.success_text') }}
      </p>
    </div>
    <hr>
    <div class="buttons-area">
      <a class="btn btn-outline pink" :href="transactionLink(transaction.tx)" rel="nofollow noopener noreferrer" target="_blank">
        <span class="text">
          {{ $t('swap.view_explorer') }}
          <span class="icon icon-right">
            <NewTabIcon />
          </span>
        </span>
      </a>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.close') }}
      </button>
    </div>
  </div>
  <div v-else-if="errorScreen" class="error-area">
    <WarnTriangle />
    <div class="desc-area">
      <div class="h4">
        {{ $t('limit.error') }}
      </div>
      <p class="desc">
        {{ errorText }}
      </p>
      <hr>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.dismiss') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  name: 'ConfirmOrder',
  components: {
    ArrowDown: hydrateWhenVisible(() => import('~/components/svg/ArrowDown')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    Info: hydrateWhenVisible(() => import('~/components/svg/new/Info')),
    ModalLoader: hydrateWhenVisible(() => import('~/components/app/interface/ModalLoader')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  props: {
    swap: {
      type: Object,
      default: () => {
        return {
          from_value: null,
          from_token: {
            symbol: '',
            address: '',
            decimals: 18
          },
          to_value: null,
          to_token: {
            symbol: '',
            address: '',
            decimals: 18
          },
          method: 'swapExactETHForTokens'
        }
      }
    },
    transaction: {
      type: Object,
      default: () => {
        return {
          gasFee: 3000000,
          tx: '',
          loading: false,
          pending: false
        }
      }
    },
    pairData: {
      type: Object,
      default: () => {
        return null
      }
    },
    inputMethod: {
      type: String,
      default: () => {
        return 'from'
      }
    },
    type: {
      type: String,
      default: () => {
        return 'Global'
      }
    }
  },
  data() {
    return {
      errorText: this.$t('swap.error_text'),
      price: 'from-to',
      loading: false,
      loadingScreen: false,
      successScreen: false,
      errorScreen: false,
      fromApproved: false,
      loadingFromApprove: false
    }
  },
  computed: {
    ...mapGetters('settings', [
      'slippageTolerance',
      'deadline',
      'expertMode',
      'multihops'
    ]),
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    },
    fromPrice() {
      return this.pairData !== null && this.pairData.reserves.reserve1 !== 0 ? this.$preciseTokenValue(this.pairData.reserves.reserve0 / this.pairData.reserves.reserve1) : 0
    },
    toPrice() {
      return this.pairData !== null && this.pairData.reserves.reserve0 !== 0 ? this.$preciseTokenValue(this.pairData.reserves.reserve1 / this.pairData.reserves.reserve0) : 0
    },
    priceDifference() {
      return this.swap.price !== null && this.swap.initPrice !== null ? (this.swap.price / this.swap.initPrice - 1) * 100 : 0
    },
    approvedTokens() {
      return this.fromApproved
    }
  },
  watch: {
    isModalOpened(modalOpen) {
      if (!modalOpen) {
        this.returnInitState()
      } else {
        this.checkForApproval()
      }
    }
  },
  mounted() {
    this.checkForApproval()
  },
  methods: {
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    },
    closeModal() {
      this.$emit('closeGlobalModal')
      this.returnInitState()
    },
    returnInitState() {
      if (this.transaction.pending === false) {
        this.$emit('initConfirmState')
        this.loading = false
        this.loadingScreen = false
        this.successScreen = false
        this.errorScreen = false
      } else {
        this.initLoadingScreen()
      }
    },
    async approveToken() {
      const token = this.swap.from_token
      this.loadingFromApprove = true
      const approvalContract = this.$config.periodLimitOrder
      const userAddress = (await window.eth.eth.requestAccounts())[0]
      const ERC20file = await import('~/assets/abis/ERC20.json')
      const ERC20 = ERC20file.default
      const erc20Contract = new window.eth.eth.Contract(ERC20, token.address)
      const gasPrice = await this.$calculateGasCost()
      const gasFeeEstimated = await erc20Contract.methods.approve(approvalContract, window.eth.utils.toWei(Number(2 ** 64 - 1).toString()))
        .estimateGas({ from: userAddress, gas: this.transaction.gasFee })

      erc20Contract.methods.approve(approvalContract, window.eth.utils.toWei(Number(2 ** 64 - 1).toString(), 'ether'))
        .send({ from: userAddress, gas: Math.round(gasFeeEstimated * 1.15).toString(), gasPrice })
        .then((result) => {
          const emitedApproval = result.events.Approval.returnValues[2]
          if (parseInt(emitedApproval, 10) > 0) {
            this.fromApproved = true
            this.loadingFromApprove = false
          }
        })
        .catch(() => {
          this.loading = false
          this.loadingFromApprove = false
          this.$denyWalletOperation()
        })
    },
    async confirmOrder() {
      this.loading = true
      if (!this.$isStableCurrency(this.swap.from_token.symbol)) {
        try {
          const userAddress = (await window.eth.eth.requestAccounts())[0]
          const ERC20file = await import('~/assets/abis/ERC20.json')
          const ERC20 = ERC20file.default
          const erc20Contract = new window.eth.eth.Contract(ERC20, this.swap.from_token.address)
          const contractAddress = this.$config.periodLimitOrder
          erc20Contract.methods.allowance(userAddress, contractAddress)
            .call({ from: userAddress })
            .then(async (allowed) => {
              const remainingAllowance = window.eth.utils.toBN(window.eth.utils.toWei((allowed).toString()))
              const serviceFeeWei = window.eth.utils.toBN(
                window.eth.utils.toWei(
                  Number(this.swap.from_value).toPrecision(6)
                )
              )
              if (serviceFeeWei.gt(remainingAllowance)) {
                const gasPrice = await this.$calculateGasCost()
                const gasFeeEstimated = await erc20Contract.methods.approve(contractAddress, window.eth.utils.toWei(Number(2 ** 64 - 1).toString()))
                  .estimateGas({ from: userAddress, gas: this.transaction.gasFee })

                erc20Contract.methods.approve(contractAddress, window.eth.utils.toWei(Number(2 ** 64 - 1).toString(), 'ether'))
                  .send({ from: userAddress, gas: Math.round(gasFeeEstimated * 1.15).toString(), gasPrice })
                  .then((result) => {
                    const emitedApproval = result.events.Approval.returnValues[2]
                    if (parseInt(emitedApproval, 10) > 0) {
                      this.initLoadingScreen()
                      this.$emit('placeOrder')
                    }
                  })
                  .catch(() => {
                    this.loading = false
                    this.$denyWalletOperation()
                  })
              } else {
                this.initLoadingScreen()
                this.$emit('placeOrder')
              }
            })
            .catch(() => {
              this.loading = false
            })
        } catch (err) {
          this.loading = false
          this.rejectTransaction({
            type: 'error',
            message: this.$t('swap.token_not_erc20_text')
          })
        }
      } else {
        this.initLoadingScreen()
        this.$emit('placeOrder')
      }
    },
    async checkForApproval() {
      const approvalContract = this.$config.periodLimitOrder
      const userAddress = (await window.eth.eth.requestAccounts())[0]
      const ERC20file = await import('~/assets/abis/ERC20.json')
      const ERC20 = ERC20file.default
      if (!this.$isStableCurrency(this.swap.from_token.symbol) && this.swap.from_token.address.length > 0) {
        const erc20Contract = new window.eth.eth.Contract(ERC20, this.swap.from_token.address)
        erc20Contract.methods.allowance(userAddress, approvalContract)
          .call({ from: userAddress })
          .then((allowed) => {
            const remainingAllowance = window.eth.utils.toBN(window.eth.utils.toWei((allowed).toString()))
            const serviceFeeWei = window.eth.utils.toBN(
              window.eth.utils.toWei(
                Number(this.swap.from_value).toPrecision(6)
              )
            )
            if (serviceFeeWei.gt(remainingAllowance)) {
              this.fromApproved = false
            } else {
              this.fromApproved = true
            }
          })
      } else {
        this.fromApproved = true
      }
    },
    rejectTransaction(error) {
      if (error === null || typeof error !== 'object') {
        this.initErrorScreen()
      } else {
        switch (error.type.toLowerCase()) {
          case 'denied':
            this.errorText = this.$t('common.deined_operation')
            break
          case 'error':
            this.errorText = error.message !== undefined && error.message.length > 0 ? this.$t('swap.error_text_with_error', { error: error.message }) : this.$t('swap.error_text')
            break
          default:
            this.errorText = this.$t('swap.error_text')
        }
        this.initErrorScreen()
      }
    },
    initLoadingScreen() {
      this.$emit('initLoading')
      this.loading = false
      this.loadingScreen = true
      this.errorScreen = false
      this.successScreen = false
    },
    initSuccessScreen() {
      this.$emit('initSuccess')
      this.loading = false
      this.loadingScreen = false
      this.errorScreen = false
      this.successScreen = true
    },
    initErrorScreen() {
      this.$emit('initError')
      this.loading = false
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = true
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modalContent.css');
</style>
