var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2315 5109 c-597 -61 -1141 -318 -1563 -739 -1002 -1000 -1002 -2620\n      0 -3620 407 -405 900 -647 1493 -732 119 -17 512 -16 635 1 997 138 1799 800\n      2111 1743 90 273 124 488 124 798 0 310 -34 525 -124 798 -308 932 -1098 1593\n      -2076 1738 -122 18 -477 26 -600 13z m570 -414 c606 -94 1138 -434 1475 -943\n      400 -602 470 -1375 187 -2036 -299 -697 -917 -1175 -1672 -1293 -149 -24 -487\n      -23 -634 0 -952 154 -1670 872 -1818 1822 -24 151 -24 479 0 630 96 613 429\n      1140 936 1479 302 202 634 319 1026 360 81 8 403 -4 500 -19z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M2469 3807 c-24 -13 -57 -43 -74 -66 l-30 -43 -3 -686 c-2 -477 0\n      -699 8 -725 13 -44 61 -102 104 -124 40 -21 132 -21 172 0 43 22 91 80 104\n      124 8 27 10 247 8 725 l-3 686 -30 43 c-38 54 -103 89 -165 89 -30 0 -63 -8\n      -91 -23z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M2472 1882 c-60 -22 -123 -77 -155 -137 -16 -31 -22 -58 -22 -115 0\n      -65 4 -82 29 -128 66 -119 199 -170 327 -127 62 21 109 63 144 126 26 47 30\n      64 30 129 0 57 -6 85 -22 115 -66 125 -205 182 -331 137z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }