<template>
  <div ref="manageFromArea" :class="{'manage-area': true, 'active': active}">
    <div class="header">
      <button class="back" @click="toggleManage">
        <ArrowBack />
      </button>
      <div class="h6">
        {{ $t('swap.manage') }}
      </div>
    </div>
    <div class="range-switch-area">
      <div :class="{'switch-item': true, 'active': tab === 'list'}" @click="changeTabFrom('list')">
        <span>
          {{ $t('swap.list') }}
        </span>
      </div>
      <div :class="{'switch-item': true, 'active': tab === 'tokens'}" @click="changeTabFrom('tokens')">
        <span>
          {{ $t('swap.tokens') }}
        </span>
      </div>
    </div>
    <div class="search-container">
      <div class="search-input with-loading">
        <Search />
        <input v-model="importManage" type="text" сlass="input" :placeholder="searchPlaceholder" @change="importTokensList">
        <div v-if="loading" class="loading-icon">
          <Loading />
        </div>
      </div>
    </div>
    <div ref="listFromContainer" :class="{'content-area left': true, 'active': tab === 'list'}">
      <div class="list-area">
        <div
          v-for="(importList, index) in computedLists"
          :key="index"
          class="list-item"
        >
          <div class="info-area">
            <div class="image-area">
              <img :src="$getImage(importList.logoURI)" :alt="importList.name + ' ' + $config.appName" :title="importList.name">
            </div>
            <div class="text-area">
              <div class="name">
                {{ importList.name }}
              </div>
              <div :ref="'importListFromButton' + index" class="settings" @click="toggleImportListSettingsFromonClick(index)">
                {{ importList.tokens }} {{ $tc('common.tokens_plural', importList.tokens) }}
                <Cog />
                <div :ref="'importListFromArea' + index" class="settings_window">
                  <div class="version">
                    v
                    {{ importList.version.major + '.' + importList.version.minor + '.' + importList.version.patch }}
                  </div>
                  <div class="actions">
                    <a :href="importList.uri" target="_blank" rel="nofollow noopener">
                      {{ $t('swap.watch_list') }}
                    </a>
                    <p class="remove-list" @click="removeSource(importList)">
                      {{ $t('swap.remove_list') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label class="switch-area">
            <div v-if="loadingList[importList.id] !== undefined && loadingList[importList.id] === true" class="loading-area">
              <Loading />
            </div>
            <Switcher :id="'importList' + index" :value="importList.included" @select="toggleImportList(importList)" />
          </label>
        </div>
      </div>
    </div>
    <div ref="tokensFromContainer" :class="{'content-area right': true, 'active': tab === 'tokens'}">
      <div :class="{'error-area': true, 'active': errorText.length > 0}">
        <div class="icon-area">
          <Warn />
        </div>
        <div class="text-area">
          <p>
            {{ errorText }}
          </p>
        </div>
      </div>
      <div class="tokens-area">
        <div
          v-for="(importToken, index) in importedList"
          :key="index"
          :class="{'token-item': true }"
        >
          <div class="name-area">
            <div class="image">
              <img :src="$getImage(importToken.logoURI)" :alt="importToken.name + ' ' + $config.appName" :title="importToken.name">
            </div>
            <div class="name">
              {{ importToken.name }}
            </div>
          </div>
          <div class="action-area">
            <button type="button" class="btn action-btn delete" @click="removeImportedToken(importToken)">
              <TrashCan />
            </button>
          </div>
        </div>
        <div class="footer-area">
          <p>
            {{ importedTotal }} {{ $t('common.custom') }} {{ $tc('common.tokens_plural', importedTotal) }}
          </p>
        </div>
      </div>
      <hr>
      <p class="tip">
        {{ $t('swap.custom_tokens_tip') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  name: 'ManageModalComponent',
  components: {
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    ArrowBack: hydrateWhenVisible(() => import('~/components/svg/ArrowBack')),
    Cog: hydrateWhenVisible(() => import('~/components/svg/new/Cog')),
    Search: hydrateWhenVisible(() => import('~/components/svg/new/Search')),
    Switcher: hydrateWhenVisible(() => import('~/components/app/interface/Switcher')),
    TrashCan: hydrateWhenVisible(() => import('~/components/svg/new/TrashCan')),
    Warn: hydrateWhenVisible(() => import('~/components/svg/new/Warn'))
  },
  props: {
    active: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      errorText: '',
      importManage: '',
      loading: false,
      searchPlaceholder: this.$t('swap.search_tokens_placeholder'),
      tab: 'list',
      loadingList: {}
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('tokens', [
      'imported',
      'source',
      'list'
    ]),
    computedLists() {
      return this.source
    },
    importedList() {
      return this.list
    },
    importedTotal() {
      return this.imported
    },
    computedListsLength() {
      return this.source.length
    }
  },
  mounted() {
    this.toggleImportList(this.computedLists[2])
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapMutations('tokens', [
      'removeSource'
    ]),
    ...mapActions('tokens', [
      'addToken',
      'removeToken',
      'fetchList',
      'removeList'
    ]),
    changeTabFrom(tab = 'list') {
      if (this.tab !== tab) {
        this.tab = tab
        this.searchPlaceholder = tab === 'tokens' ? this.$t('swap.search_tokens_placeholder') : this.$t('swap.search_list_placeholder')
      }
    },
    toggleImportList(importList) {
      const currentSource = this.source.find(item => item.id === importList.id)
      if (currentSource === undefined) {
        return
      }
      if (currentSource.included !== true) {
        this.loadingList[importList.id] = true
        if (this.$config.chainId === 56) {
          return new Promise((resolve) => {
            this.$axios.get(importList.uri)
              .then(async (result) => {
                await result.data.tokens.forEach(async (item) => {
                  let balance = 0
                  if (this.user !== null && this.user.address !== undefined) {
                    const ERC20file = await import('~/assets/abis/ERC20.json')
                    const ERC20 = ERC20file.default
                    const tokenContract = new window.eth.eth.Contract(ERC20, item.address)
                    const currentDecimal = this.$getDecimalsName(item.decimals)
                    balance = parseFloat(window.eth.utils.fromWei(await tokenContract.methods.balanceOf(this.user.address).call({ from: this.user.address }), currentDecimal.name))
                    if (currentDecimal.multiply > 0) {
                      balance /= 10 ** currentDecimal.multiply
                    }
                  }
                  this.addToken({
                    id: 'import',
                    address: item.address,
                    name: item.name,
                    symbol: item.symbol,
                    decimals: item.decimals,
                    logoURI: item.logoURI,
                    balance
                  })
                })
                this.loadingList[importList.id] = false
                resolve(true)
              }).catch(() => {
                resolve(false)
                this.loadingList[importList.id] = false
              })
          })
        } else {
          this.fetchList(importList.uri)
          this.loadingList[importList.id] = false
        }
      } else {
        this.removeList(importList)
      }
    },
    toggleImportListSettingsFromonClick(index) {
      if (this.$refs['importListFromArea' + index][0] !== undefined && !this.$refs['importListFromArea' + index][0].classList.contains('visible')) {
        this.toggleImportListSettingsFrom(index)
      }
    },
    toggleImportListSettingsFrom(index) {
      if (this.$refs['importListFromArea' + index][0] !== undefined) {
        this.$refs['importListFromArea' + index][0].classList.toggle('visible')
      }
    },
    async importTokensList() {
      let importLink = this.importManage
      setTimeout(() => {
        this.errorText = ''
      }, 500)

      if (this.importManage.includes('ipfs://')) {
        const sourceID = this.importLink.split('ipfs://')[1]
        importLink = 'https://ipfs.io/ipfs/' + sourceID
      }
      if (this.tab === 'list') {
        this.fetchList(importLink)
          .then(() => {
            this.importManage = ''
            this.errorText = ''
          })
          .catch(() => {
            this.importManage = ''
            this.errorText = this.$t('swap.import_error')
          })
      } else if (this.tab === 'tokens') {
        let tokenAddress = importLink
        if (!this.$isAddress(tokenAddress)) {
          tokenAddress = await window.eth.eth.ens.getOwner(tokenAddress)
        }
        if (this.$isChecksumAddress(tokenAddress)) {
          this.loading = true
          try {
            const ERC20file = await import('~/assets/abis/ERC20.json')
            const ERC20 = ERC20file.default
            const erc20Contract = new window.eth.eth.Contract(ERC20, importLink)
            const symbol = await erc20Contract.methods.symbol().call()
            if (this.list.find(item => item.symbol.toLowerCase() === symbol.toLowerCase()) !== undefined) {
              this.loading = false
              this.errorText = this.$t('swap.token_inside_list')
            } else {
              const name = await erc20Contract.methods.name().call()
              const decimals = await erc20Contract.methods.decimals().call()
              let balance = 0
              if (this.user !== null && this.user.address !== undefined) {
                balance = parseFloat(window.eth.utils.fromWei(await erc20Contract.methods.balanceOf(this.user.address).call({ from: this.user.address })))
              }
              const logoURI = this.$getImageByAddress(importLink)

              await this.addToken({
                id: 'import',
                address: importLink,
                name,
                symbol,
                decimals,
                logoURI,
                balance
              })
              this.importManage = ''
              this.loading = false
              this.errorText = ''
            }
          } catch (err) {
            console.log(err)
            this.loading = false
            this.errorText = this.$t('swap.import_address_error')
          }
        } else {
          this.errorText = this.$t('swap.import_address_incorrect')
        }
      }
    },
    removeImportedToken(token) {
      this.loading = true
      this.removeToken(token)
        .then((result) => {
          this.errorText = ''
          this.loading = false
        })
        .catch((err) => {
          this.errorText = err
          this.loading = false
        })
    },
    toggleManage() {
      this.errorText = ''
      this.importManage = ''
      this.$emit('toggle')
    },
    handleClickEvents(evt) {
      for (let ind = 0; ind < this.computedListsLength; ++ind) {
        if (
          this.$refs['importListFromArea' + ind][0] !== undefined &&
          !this.$refs['importListFromArea' + ind][0].contains(evt.target) &&
          !this.$refs['importListFromButton' + ind][0].contains(evt.target) &&
          this.$refs['importListFromArea' + ind][0].classList.contains('visible')
        ) {
          this.toggleImportListSettingsFrom(ind)
        }
      }
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/tokenSelector.css');
</style>
