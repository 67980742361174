var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalArea",
      class: {
        "modal-container": true,
        "connect-wallet": true,
        active: _vm.modalOpen,
      },
    },
    [
      _c("div", { staticClass: "modal-wrap" }, [
        _c("div", { ref: "modalInner", staticClass: "modal-content-wrap" }, [
          !_vm.errorScreen
            ? _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "h2" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.connect_wallet")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "close-area",
                      on: { click: _vm.closeModal },
                    },
                    [_c("Plus")],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "wallets-area" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "wallet active": true,
                        minified: _vm.isMinified,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.connectMetamask($event)
                        },
                      },
                    },
                    [
                      _vm.loadingWallet !== "metamask"
                        ? _c("div", { staticClass: "image-area metamask" }, [
                            _c(
                              "picture",
                              [
                                _c("source", {
                                  attrs: {
                                    srcset: "/img/new/metamask.webp",
                                    type: "image/webp",
                                  },
                                }),
                                _vm._v(" "),
                                _c("nuxt-img", {
                                  attrs: {
                                    fit: "inside",
                                    width: "275",
                                    sizes:
                                      "sm:175px md:200px lg:225px xl:250px xxl:275px",
                                    src: "/img/new/metamask.png",
                                    alt: "Metamask wallet connect on Defi Pool Finance",
                                    title: "Metamask",
                                    loading: "lazy",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _c(
                            "div",
                            { staticClass: "image-area" },
                            [_c("Loading")],
                            1
                          ),
                      _vm._v(" "),
                      _vm._m(0),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "wallet active": true,
                        minified: _vm.isMinified,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "image-area binance" },
                        [_c("Bnb")],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-area" }, [
                        _c("p", [
                          _vm._v(
                            "\n                Binance " +
                              _vm._s(_vm.$t("common.wallet")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "wallet active": true,
                        minified: _vm.isMinified,
                      },
                    },
                    [
                      _c("div", { staticClass: "image-area coinbase" }, [
                        _c(
                          "picture",
                          [
                            _c("source", {
                              attrs: {
                                srcset: "/img/new/coinbase.webp",
                                type: "image/webp",
                              },
                            }),
                            _vm._v(" "),
                            _c("nuxt-img", {
                              attrs: {
                                fit: "inside",
                                width: "275",
                                sizes:
                                  "sm:175px md:200px lg:225px xl:250px xxl:275px",
                                src: "/img/new/coinbase.png",
                                alt: "Coinbase wallet connect on Defi Pool Finance",
                                title: "Coinbase",
                                loading: "lazy",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-area" }, [
                        _c("p", [
                          _vm._v(
                            "\n                Coinbase " +
                              _vm._s(_vm.$t("common.wallet")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: { wallet: true, active: !_vm.isMinified },
                      on: { click: _vm.toggleMinified },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "image-area more" },
                        [_c("DropsLine")],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-area" }, [
                        _c("p", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("common.more")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "wallet minified": true,
                        active: _vm.isMinified,
                      },
                    },
                    [
                      _c("div", { staticClass: "image-area walletconnect" }, [
                        _c(
                          "picture",
                          [
                            _c("source", {
                              attrs: {
                                srcset: "/img/new/walletconnect.webp",
                                type: "image/webp",
                              },
                            }),
                            _vm._v(" "),
                            _c("nuxt-img", {
                              attrs: {
                                fit: "inside",
                                width: "275",
                                sizes:
                                  "sm:175px md:200px lg:225px xl:250px xxl:275px",
                                src: "/img/new/walletconnect.png",
                                alt: "Wallet Connect on Defi Pool Finance",
                                title: "Wallet Connect",
                                loading: "lazy",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(1),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "wallet minified": true,
                        active: _vm.isMinified,
                      },
                    },
                    [
                      _c("div", { staticClass: "image-area formatic" }, [
                        _c(
                          "picture",
                          [
                            _c("source", {
                              attrs: {
                                srcset: "/img/new/formatic.webp",
                                type: "image/webp",
                              },
                            }),
                            _vm._v(" "),
                            _c("nuxt-img", {
                              attrs: {
                                fit: "inside",
                                width: "275",
                                sizes:
                                  "sm:175px md:200px lg:225px xl:250px xxl:275px",
                                src: "/img/new/formatic.png",
                                alt: "Fortmatic Wallet on Defi Pool Finance",
                                title: "Fortmatic",
                                loading: "lazy",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(2),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "wallet minified": true,
                        active: _vm.isMinified,
                      },
                    },
                    [
                      _c("div", { staticClass: "image-area portis" }, [
                        _c(
                          "picture",
                          [
                            _c("source", {
                              attrs: {
                                srcset: "/img/new/portis.webp",
                                type: "image/webp",
                              },
                            }),
                            _vm._v(" "),
                            _c("nuxt-img", {
                              attrs: {
                                fit: "inside",
                                width: "275",
                                sizes:
                                  "sm:175px md:200px lg:225px xl:250px xxl:275px",
                                src: "/img/new/portis.png",
                                alt: "Portis wallet on Defi Pool Finance",
                                title: "Portis",
                                loading: "lazy",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(3),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("hr", { staticClass: "mt-0" }),
                _vm._v(" "),
                !_vm.isMinified
                  ? _c("div", { staticClass: "footer" }, [
                      _c("p", { staticClass: "desc" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("common.havent_got_wallet")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn pink",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.gotoDocs.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.learn_to_connect")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "footer" }, [
                      _c("p", { staticClass: "desc" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("common.new_to_eth")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn pink",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.gotoDocs.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.learn_more_wallets")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
              ])
            : _c("div", { staticClass: "modal-content error" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "h2" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.other_error")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "close-area",
                      on: { click: _vm.closeModal },
                    },
                    [_c("Plus")],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "icon-area" },
                  [_c("WarnTriangle")],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-area" }, [
                  _c("div", { staticClass: "h4" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.modal_error")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.errorText) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.errorCta.length > 0
                    ? _c("div", { staticClass: "buttons-area" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn pink",
                            attrs: {
                              href: _vm.errorCta,
                              rel: "nofollow noopener noreferrer",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("common.install_wallet")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-area" }, [
      _c("p", [_vm._v("\n                Metamask\n              ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-area" }, [
      _c("p", [_vm._v("\n                Wallet Connect\n              ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-area" }, [
      _c("p", [_vm._v("\n                Fortmatic\n              ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-area" }, [
      _c("p", [_vm._v("\n                Portis\n              ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }