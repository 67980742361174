export const state = () => ({
  pools: [],
  poolsTotal: 0,
  poolsLoading: false,
  pool: null,
  poolLoading: false
})

export const mutations = {
  setPools(state, pools) {
    state.pools = pools
  },
  setPoolsTotal(state, total) {
    state.poolsTotal = total
  },
  addPool(state, pool) {
    state.pools = state.pools.concat(pool)
  },
  removePool(state, poolIndex) {
    const itemIndex = state.pools.findIndex((pool, index) => index === poolIndex)
    if (itemIndex !== -1) {
      state.pools.splice(poolIndex, 1)
    }
  },
  setLoadingPools(state, status) {
    state.poolsLoading = status
  },
  setPool(state, pool) {
    state.pool = pool
  },
  setLoadingPool(state, status) {
    state.poolLoading = status
  }
}

export const actions = {
  async fetchPools({
    commit
  }, payload) {
    commit('setLoadingPools', true)
    const request = await this.$generateApiRequest()
    const pools = await request.$get('pools', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        active: payload.active !== undefined && payload.active !== null ? payload.active : '',
        search: payload.search !== undefined && payload.search !== null ? payload.search : ''
      }
    })
    commit('setPools', pools.answer.pools)
    commit('setPoolsTotal', pools.answer.total)
    commit('setLoadingPools', false)
    this.$refreshCookieExpires()
  },
  async addPool({
    commit
  }, payload) {
    commit('setLoadingPool', true)
    const request = await this.$generateApiRequest()
    await request.$post('pools', {
      bid: payload.chainId,
      address: payload.address,
      name: payload.name,
      description: payload.description !== undefined && payload.description !== null ? payload.description : '',
      flexible_apy: payload.flexible_apy !== undefined && payload.flexible_apy !== null ? payload.flexible_apy : '',
      locked_apy: payload.locked_apy !== undefined && payload.locked_apy !== null ? payload.locked_apy : '',
      apr: payload.apr !== undefined && payload.apr !== null ? payload.apr : '',
      total: payload.total !== undefined && payload.total !== null ? payload.total : '',
      website: payload.website !== undefined && payload.website !== null ? payload.website : '',
      auto: payload.auto !== undefined && payload.auto !== null ? payload.auto : '',
      blocks: payload.title !== undefined && payload.blocks !== null ? payload.blocks : '',
      avg_block: payload.avg_block !== undefined && payload.avg_block !== null ? payload.avg_block : '',
      fee: payload.fee !== undefined && payload.fee !== null ? payload.fee : '',
      active: payload.active !== undefined && payload.active !== null ? payload.active : ''
    })
    commit('setLoadingPool', false)
    this.$refreshCookieExpires()
  },
  async checkPoolAddress({
    commit
  }, payload) {
    commit('setLoadingPool', true)
    const request = await this.$generateApiRequest()
    await request.$get('pools/' + payload.hash + '/check')
    commit('setLoadingPool', false)
    this.$refreshCookieExpires()
  },
  async fetchPool({
    commit
  }, payload) {
    commit('setLoadingPool', true)
    const request = await this.$generateApiRequest()
    const pool = await request.$get('pools/' + payload.tid)
    commit('setPool', pool.answer.pool)
    commit('setLoadingPool', false)
  }
}

export const getters = {
  pools: state => state.pools,
  pool: state => state.pool,
  poolsTotal: state => state.poolsTotal,
  poolsLoading: state => state.poolsLoading,
  poolLoading: state => state.poolLoading
}
