import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _83dfe29a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _1507b58f = () => interopDefault(import('../pages/analytics/index.vue' /* webpackChunkName: "pages/analytics/index" */))
const _7addd4b6 = () => interopDefault(import('../pages/docs.vue' /* webpackChunkName: "pages/docs" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _71ff53c0 = () => interopDefault(import('../pages/farm.vue' /* webpackChunkName: "pages/farm" */))
const _73d4c67e = () => interopDefault(import('../pages/limit.vue' /* webpackChunkName: "pages/limit" */))
const _51d418fb = () => interopDefault(import('../pages/liquidity/index.vue' /* webpackChunkName: "pages/liquidity/index" */))
const _a47188ec = () => interopDefault(import('../pages/listing.vue' /* webpackChunkName: "pages/listing" */))
const _2ffc6dcf = () => interopDefault(import('../pages/lottery.vue' /* webpackChunkName: "pages/lottery" */))
const _730a0ec8 = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _001c2c79 = () => interopDefault(import('../pages/prediction.vue' /* webpackChunkName: "pages/prediction" */))
const _4ac450c4 = () => interopDefault(import('../pages/report.vue' /* webpackChunkName: "pages/report" */))
const _7dec6fac = () => interopDefault(import('../pages/rewards.vue' /* webpackChunkName: "pages/rewards" */))
const _5b25c600 = () => interopDefault(import('../pages/stake.vue' /* webpackChunkName: "pages/stake" */))
const _7c6098fd = () => interopDefault(import('../pages/swap.vue' /* webpackChunkName: "pages/swap" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _1160a3d4 = () => interopDefault(import('../pages/voting/index.vue' /* webpackChunkName: "pages/voting/index" */))
const _7ced4819 = () => interopDefault(import('../pages/analytics/pairs/index.vue' /* webpackChunkName: "pages/analytics/pairs/index" */))
const _1dd03d70 = () => interopDefault(import('../pages/analytics/tokens/index.vue' /* webpackChunkName: "pages/analytics/tokens/index" */))
const _95b1dab0 = () => interopDefault(import('../pages/analytics/transactions.vue' /* webpackChunkName: "pages/analytics/transactions" */))
const _36e53f5a = () => interopDefault(import('../pages/errors/error404.vue' /* webpackChunkName: "pages/errors/error404" */))
const _6b931c97 = () => interopDefault(import('../pages/errors/error500.vue' /* webpackChunkName: "pages/errors/error500" */))
const _cd834cec = () => interopDefault(import('../pages/liquidity/add.vue' /* webpackChunkName: "pages/liquidity/add" */))
const _91b50e10 = () => interopDefault(import('../pages/voting/proposals/create.vue' /* webpackChunkName: "pages/voting/proposals/create" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _58c4dabe = () => interopDefault(import('../pages/analytics/pairs/_address.vue' /* webpackChunkName: "pages/analytics/pairs/_address" */))
const _37ad5bf2 = () => interopDefault(import('../pages/analytics/tokens/_address.vue' /* webpackChunkName: "pages/analytics/tokens/_address" */))
const _e56123be = () => interopDefault(import('../pages/voting/proposals/_hash.vue' /* webpackChunkName: "pages/voting/proposals/_hash" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _83dfe29a,
    name: "account___en"
  }, {
    path: "/analytics",
    component: _1507b58f,
    name: "analytics___en"
  }, {
    path: "/docs",
    component: _7addd4b6,
    name: "docs___en"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq___en"
  }, {
    path: "/farm",
    component: _71ff53c0,
    name: "farm___en"
  }, {
    path: "/limit",
    component: _73d4c67e,
    name: "limit___en"
  }, {
    path: "/liquidity",
    component: _51d418fb,
    name: "liquidity___en"
  }, {
    path: "/listing",
    component: _a47188ec,
    name: "listing___en"
  }, {
    path: "/lottery",
    component: _2ffc6dcf,
    name: "lottery___en"
  }, {
    path: "/policy",
    component: _730a0ec8,
    name: "policy___en"
  }, {
    path: "/prediction",
    component: _001c2c79,
    name: "prediction___en"
  }, {
    path: "/report",
    component: _4ac450c4,
    name: "report___en"
  }, {
    path: "/rewards",
    component: _7dec6fac,
    name: "rewards___en"
  }, {
    path: "/stake",
    component: _5b25c600,
    name: "stake___en"
  }, {
    path: "/swap",
    component: _7c6098fd,
    name: "swap___en"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms___en"
  }, {
    path: "/voting",
    component: _1160a3d4,
    name: "voting___en"
  }, {
    path: "/analytics/pairs",
    component: _7ced4819,
    name: "analytics-pairs___en"
  }, {
    path: "/analytics/tokens",
    component: _1dd03d70,
    name: "analytics-tokens___en"
  }, {
    path: "/analytics/transactions",
    component: _95b1dab0,
    name: "analytics-transactions___en"
  }, {
    path: "/errors/error404",
    component: _36e53f5a,
    name: "errors-error404___en"
  }, {
    path: "/errors/error500",
    component: _6b931c97,
    name: "errors-error500___en"
  }, {
    path: "/liquidity/add",
    component: _cd834cec,
    name: "liquidity-add___en"
  }, {
    path: "/voting/proposals/create",
    component: _91b50e10,
    name: "voting-proposals-create___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/analytics/pairs/:address",
    component: _58c4dabe,
    name: "analytics-pairs-address___en"
  }, {
    path: "/analytics/tokens/:address",
    component: _37ad5bf2,
    name: "analytics-tokens-address___en"
  }, {
    path: "/voting/proposals/:hash?",
    component: _e56123be,
    name: "voting-proposals-hash___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
