var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "icon-header",
      attrs: {
        viewBox: "0 0 30 9",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0 5.39109C0 3.39795 3.21429 0 3.21429 0C3.21429 0 6.42857 3.39795 6.42857 5.39109C6.42857 7.38422 4.98949 9 3.21429 9C1.43908 9 0 7.38427 0 5.39109Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M11.5714 5.39109C11.5714 3.39795 14.7857 0 14.7857 0C14.7857 0 18 3.39795 18 5.39109C18 7.38422 16.5609 9 14.7857 9C13.0105 9 11.5714 7.38427 11.5714 5.39109Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M23.1429 5.39109C23.1429 3.39795 26.3571 0 26.3571 0C26.3571 0 29.5714 3.39795 29.5714 5.39109C29.5714 7.38422 28.1323 9 26.3571 9C24.5819 9 23.1429 7.38427 23.1429 5.39109Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }