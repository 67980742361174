export const state = () => ({
  slippageTolerance: 0.5,
  deadline: 20,
  expertMode: false,
  multihops: false,
  speed: 'standard',
  settings: null,
  settingsLoading: false
})

export const mutations = {
  setSlippageTolerance(state, value) {
    state.slippageTolerance = parseFloat(value)
    saveToCookie(this, state)
  },
  setDeadline(state, value) {
    state.deadline = parseInt(value, 10)
    saveToCookie(this, state)
  },
  setExpertMode(state, value) {
    state.expertMode = value === true
    saveToCookie(this, state)
  },
  setMultihops(state, value) {
    state.multihops = value === true
    saveToCookie(this, state)
  },
  setSpeed(state, value) {
    state.speed = value
    saveToCookie(this, state)
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  setLoadingSettings(state, status) {
    state.settingsLoading = status
  }
}

export const actions = {
  async fetchSettings({
    commit
  }, payload) {
    commit('setLoadingSettings', true)
    const request = await this.$generateApiRequest()
    const settings = await request.$get('settings/' + payload.group, {})
    commit('setSettings', settings.answer.setting)
    commit('setLoadingSettings', false)
  }
}

export const getters = {
  slippageTolerance: state => state.slippageTolerance,
  deadline: state => state.deadline,
  expertMode: state => state.expertMode,
  multihops: state => state.multihops,
  speed: state => state.speed,
  settings: state => state.settings,
  settingsLoading: state => state.settingsLoading
}

function saveToCookie(that, state) {
  const userData = that.$cookies.get(that.$config.userStorageName)
  if (userData !== undefined) {
    userData.settings = {
      slippageTolerance: state.slippageTolerance,
      deadline: state.deadline,
      expertMode: state.expertMode,
      multihops: state.multihops,
      speed: state.speed
    }
    that.$cookies.set(that.$config.userStorageName, userData)
  } else {
    that.$cookies.set(that.$config.userStorageName, {
      settings: {
        slippageTolerance: state.slippageTolerance,
        deadline: state.deadline,
        expertMode: state.expertMode,
        multihops: state.multihops,
        speed: state.speed
      }
    })
  }
}
