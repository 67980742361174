<template>
  <footer>
    <div class="menu-area">
      <div class="newsletter">
        <nuxt-link class="logo-area" :to="localePath('/')" :aria-label="$t('common.go_to_home_page')">
          <div v-if="!darkTheme" class="logo-picture">
            <nuxt-picture
              fit="inside"
              src="/img/df_wide_light.svg"
              alt="Defi Pool Finance eran and exchange cryptocurrency"
              :img-attrs="{'class': 'desktop', 'title': 'Defi Pool logo'}"
            />
          </div>
          <div v-else class="logo-picture">
            <nuxt-picture
              fit="inside"
              src="/img/df_wide_dark.svg"
              alt="Defi Pool Finance eran and exchange cryptocurrency"
              :img-attrs="{'class': 'desktop', 'title': 'Defi Pool logo'}"
            />
          </div>
        </nuxt-link>
        <div class="form-area">
          <p class="desc">
            {{ $t('common.subscribe') }}
          </p>
          <form action="POST" @submit.prevent="subscribeToNewsletter">
            <div class="input-area">
              <input v-model="email" type="email" class="email-input" name="email" :placeholder="emailPlaceholder">
              <button type="submit" class="subscribe" :aria-label="$t('common.subscribe_cta')">
                <span v-if="!subscribeLoading">
                  <Envelope />
                </span>
                <span v-else>
                  <Loading />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="menu-col">
        <div class="h4">
          {{ $t('common.about') }}
        </div>
        <div class="list">
        <!--  <div class="link-area">
            <ChevronRight />
            <nuxt-link :to="localePath('/contacts')">
              {{ $t('common.contacts') }}
            </nuxt-link>
          </div> -->
          <!--<div class="link-area">
            <ChevronRight />
            <nuxt-link :to="localePath('/brand')">
              {{ $t('common.brand') }}
            </nuxt-link>
          </div>--> 
          <!--<div class="link-area">
            <ChevronRight />
            <a href="https://medium.com/@info_19215/meeting-with-defipool-5ab219f3be2e" target="_blank" rel="nofollow noreferrer noopener">
              {{ $t('common.blog') }}
            </a>
          </div>-->
          <div class="link-area">
            <ChevronRight />
            <nuxt-link :to="localePath('/terms')">
              {{ $t('common.terms') }}
            </nuxt-link>
          </div>
          <div class="link-area">
            <ChevronRight />
            <nuxt-link :to="localePath('/policy')">
              {{ $t('common.policy') }}
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="menu-col">
        <div class="h4">
          {{ $t('common.developers') }}
        </div>
        <div class="list">
          <div v-show="contacts.github !== undefined && contacts.github !== null && contacts.github.length > 0" class="link-area">
            <ChevronRight />
            <a :href="contacts.github" rel="nofollow noreferrer noopener" target="_blank">
              Github
            </a>
          </div>
          <div class="link-area">
            <ChevronRight />
            <nuxt-link :to="localePath('/docs')">
              {{ $t('common.documentation') }}
            </nuxt-link>
          </div>
          <!--<div class="link-area">
            <ChevronRight />
            <nuxt-link :to="localePath('/bounty')">
              {{ $t('common.bug_bounty') }}
            </nuxt-link>
          </div>-->
          <!--<div class="link-area">
            <ChevronRight />
            <nuxt-link :to="localePath('/audits')">
              {{ $t('common.audits') }}
            </nuxt-link>
          </div>-->
          <!-- <div class="link-area">
            <ChevronRight />
            <nuxt-link :to="localePath('/careers')">
              {{ $t('common.careers') }}
            </nuxt-link>
          </div> -->
        </div>
      </div>
      <div class="menu-col no-margin">
        <div class="h4">
          {{ $t('common.customer_support') }}
        </div>
        <div class="list socials">
          <div v-show="contacts.discord !== undefined && contacts.discord !== null && contacts.discord.length > 0" class="link-area">
            <a :href="contacts.discord" class="social" rel="nofollow noopener noreferrer" target="_blank">
              <div class="icon-area">
                <DiscordLogo />
              </div>
              <div class="h6">
                Discord
              </div>
            </a>
          </div>
          <div v-show="contacts.twitter !== undefined && contacts.twitter !== null && contacts.twitter.length > 0" class="link-area">
            <a :href="contacts.twitter" class="social" rel="nofollow noopener noreferrer" target="_blank">
              <div class="icon-area">
                <TwitterLogo />
              </div>
              <div class="h6">
                Twitter
              </div>
            </a>
          </div>
          <div v-show="contacts.reddit !== undefined && contacts.reddit !== null && contacts.reddit.length > 0" class="link-area">
            <a :href="contacts.reddit" class="social" rel="nofollow noopener noreferrer" target="_blank">
              <div class="icon-area">
                <RedditLogo />
              </div>
              <div class="h6">
                Reddit
              </div>
            </a>
          </div>
          <div v-show="contacts.telegram !== undefined && contacts.telegram !== null && contacts.telegram.length > 0" class="link-area">
            <a :href="contacts.telegram" class="social" rel="nofollow noopener noreferrer" target="_blank">
              <div class="icon-area">
                <TelegramLogo />
              </div>
              <div class="h6">
                Telegram news
              </div>
            </a>
          </div>
          <div v-show="contacts.telegram_chat !== undefined && contacts.telegram_chat !== null && contacts.telegram_chat.length > 0" class="link-area">
            <a :href="contacts.telegram_chat" class="social" rel="nofollow noopener noreferrer" target="_blank">
              <div class="icon-area">
                <TelegramLogo />
              </div>
              <div class="h6">
                Telegram chat
              </div>
            </a>
          </div>
          <div v-show="contacts.instagram !== undefined && contacts.instagram !== null && contacts.instagram.length > 0" class="link-area">
            <a :href="contacts.instagram" class="social" rel="nofollow noopener noreferrer" target="_blank">
              <div class="icon-area">
                <InstagramLogo />
              </div>
              <div class="h6">
                Instagram
              </div>
            </a>
          </div>
          <div v-show="contacts.facebook !== undefined && contacts.facebook !== null && contacts.facebook.length > 0" class="link-area">
            <a :href="contacts.facebook" class="social" rel="nofollow noopener noreferrer" target="_blank">
              <div class="icon-area">
                <FacebookLogo />
              </div>
              <div class="h6">
                Facebook
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="divider-horizontal"></div>
    <div class="subfooter-area">
      <div class="dev-area">
        <p>
          {{ $t('common.rights') }} &copy; {{ fullYear }}
        </p>
      </div>
      <div class="settings">
        <div class="lang-switcher-area dropdown">
          <div class="dropdown-header">
            <Planet />
            <ChevronDown />
          </div>
          <div class="dropdown-area">
            <nuxt-link
              v-for="locale in availableLocales"
              :key="locale.code"
              :to="switchLocalePath(locale.code)"
            >
              {{ locale.name }}
            </nuxt-link>
          </div>
        </div>
        <div :class="`switcher-area ${darkTheme ? 'dark' : 'light'}`" @click="changeTheme">
          <div class="switcher">
            <div class="light">
              <Sun />
            </div>
            <div class="dark">
              <Moon />
            </div>
            <div class="circle">
              <SwitchCircle />
            </div>
          </div>
        </div>
        <div class="settings-area">
          <button type="button" class="settings-button" :aria-label="$t('common.settings')" @click="openModalSettings">
            <Cog />
          </button>
        </div>
        <button type="button" class="connect-wallet" :aria-label="$t('common.connect_wallet')" @click="openModalWallets">
          <Wallet />
          <span>
            {{ currentAccount }}
          </span>
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  name: 'FooterComponent',
  components: {
    ChevronRight: hydrateWhenVisible(() => import('~/components/svg/new/ChevronRight')),
    ChevronDown: hydrateWhenVisible(() => import('~/components/svg/new/ChevronDown')),
    Planet: hydrateWhenVisible(() => import('~/components/svg/new/Planet')),
    Moon: hydrateWhenVisible(() => import('~/components/svg/new/Moon')),
    Sun: hydrateWhenVisible(() => import('~/components/svg/new/Sun')),
    SwitchCircle: hydrateWhenVisible(() => import('~/components/svg/new/SwitchCircle')),
    Cog: hydrateWhenVisible(() => import('~/components/svg/new/Cog')),
    Wallet: hydrateWhenVisible(() => import('~/components/svg/new/Wallet')),
    Envelope: hydrateWhenVisible(() => import('~/components/svg/new/Envelope')),
    DiscordLogo: hydrateWhenVisible(() => import('~/components/svg/new/DiscordLogo')),
    TwitterLogo: hydrateWhenVisible(() => import('~/components/svg/new/TwitterLogo')),
    RedditLogo: hydrateWhenVisible(() => import('~/components/svg/new/RedditLogo')),
    TelegramLogo: hydrateWhenVisible(() => import('~/components/svg/new/TelegramLogo')),
    FacebookLogo: hydrateWhenVisible(() => import('~/components/svg/new/FacebookLogo')),
    InstagramLogo: hydrateWhenVisible(() => import('~/components/svg/new/InstagramLogo')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading'))
  },
  data() {
    const newDate = new Date()
    return {
      fullYear: newDate.getFullYear(),
      email: '',
      emailPlaceholder: this.$t('common.enter_email') + '...',
      subscribeLoading: false
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    ...mapGetters('seos', [
      'contacts'
    ]),
    ...mapGetters('auth', [
      'user'
    ]),
    currentAccount() {
      return this.user !== undefined && this.user !== null && this.user.address.length > 0
        ? this.$shortenAddress(this.user.address)
        : this.$t('common.connect_wallet')
    },
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  },
  methods: {
    ...mapMutations(['setUserAddress']),
    ...mapActions('modal', [
      'changeModalWalletState',
      'changeModalSettingsState',
      'changeModalProfileState'
    ]),
    async openModalWallets() {
      if (this.user !== undefined && this.user !== null && this.user.address.length > 0) {
        await this.changeModalProfileState({
          state: true
        })
      } else {
        await this.changeModalWalletState({
          state: true
        })
      }
    },
    async openModalSettings() {
      await this.changeModalSettingsState({
        state: true
      })
    },
    changeTheme() {
      if (!this.darkTheme) {
        this.$onTheme()
      } else {
        this.$offTheme()
      }
    },
    async subscribeToNewsletter() {
      this.subscribeLoading = true
      if (this.email.length > 0 && this.checkEmail()) {
        const request = await this.$generateApiRequest()
        const pageName = this.$nuxt.$route.name.split('___')
        request.post('subscribe', {
          email: this.email,
          page: pageName[0]
        }).then(() => {
          this.email = ''
          this.$nuxt.$emit('openSuccess', null, this.$t('common.email_registered_text'))
        }).catch((err) => {
          this.email = ''
          if (err.response.status !== 422) {
            this.$nuxt.$emit('openError', null, this.$t('common.email_not_registered_text'))
          } else {
            this.$nuxt.$emit('openError', null, this.$t('common.email_already_registered_text'))
          }
          console.error(err)
        })

        this.subscribeLoading = false
      } else {
        this.subscribeLoading = false
      }
    },
    checkEmail() {
      const regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      if (!regExp.test(this.email)) {
        this.emailError = this.$t('common.email_typed_invalid')
        return false
      } else {
        this.emailError = ''
        return true
      }
    }
  }
}
</script>

<style>
  @import url('~/assets/css/new/footer.css');
</style>
