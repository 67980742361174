var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 35 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M34.7717 6.55985L25.7993 0.107104C25.6286 -0.0156899 25.4015 -0.0344019 25.2119 0.058759C25.0224 0.151853 24.9029 0.341039 24.9029 0.547871V2.71521H17.6156C17.305 2.71521 17.0533 2.96047 17.0533 3.26299C17.0533 3.56551 17.305 3.81077 17.6156 3.81077H25.4653C25.7759 3.81077 26.0276 3.56551 26.0276 3.26299V1.63317L33.4909 7.00062L26.0276 12.3679V10.7382C26.0276 10.4356 25.7759 10.1904 25.4653 10.1904H13.3288V3.81083H15.7411C16.0517 3.81083 16.3034 3.56558 16.3034 3.26306C16.3034 2.96053 16.0517 2.71528 15.7411 2.71528H12.7664C12.4558 2.71528 12.2041 2.96053 12.2041 3.26306V10.7382C12.2041 11.0408 12.4558 11.286 12.7664 11.286H24.9029V13.4534C24.9029 13.6601 25.0224 13.8492 25.2118 13.9423C25.2918 13.9817 25.3788 14.0011 25.4651 14.0011C25.5833 14.0011 25.7006 13.9649 25.7991 13.8941L34.7716 7.44132C34.9153 7.33803 35 7.17449 35 7.00062C35.0001 6.82668 34.9154 6.66313 34.7717 6.55985Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M10.8538 7.71441H10.0973V5.54647C10.0973 5.3397 9.97777 5.15058 9.78828 5.05735C9.59879 4.96426 9.37163 4.9829 9.20087 5.1057L0.228321 11.559C0.084766 11.6623 0 11.8258 0 11.9996C0 12.1735 0.0846976 12.337 0.22839 12.4403L4.71791 15.6688C4.8184 15.7412 4.93536 15.776 5.05144 15.776C5.22391 15.776 5.39405 15.699 5.50432 15.5535C5.68875 15.3101 5.63577 14.9671 5.38585 14.7874L1.50911 11.9995L8.97248 6.63177V8.26212C8.97248 8.56464 9.22418 8.8099 9.5348 8.8099H10.8537C11.1644 8.8099 11.4161 8.56464 11.4161 8.26212C11.4161 7.95966 11.1644 7.71441 10.8538 7.71441Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M22.2338 12.0533C21.9232 12.0533 21.6715 12.2985 21.6715 12.6011V15.1893H9.53487C9.22425 15.1893 8.97255 15.4346 8.97255 15.7371V17.3668L7.36028 16.2074C7.11056 16.0278 6.75831 16.0794 6.57387 16.3228C6.38944 16.5663 6.44242 16.9092 6.69234 17.089L9.20087 18.8929C9.29937 18.9638 9.41681 19 9.53494 19C9.62135 19 9.70823 18.9806 9.78828 18.9412C9.97771 18.8481 10.0972 18.6589 10.0972 18.4522V16.285H22.2338C22.5445 16.285 22.7962 16.0398 22.7962 15.7372V12.6012C22.7962 12.2985 22.5445 12.0533 22.2338 12.0533Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }