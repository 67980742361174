export const state = () => ({
  proposals: [],
  votes: [],
  total: 0,
  totalVotes: 0,
  proposal: null,
  proposalsLoading: false,
  votesLoading: false,
  proposalLoading: false,
  voted: false
})

export const mutations = {
  setProposals(state, proposals) {
    state.proposals = proposals
  },
  setChoices(state, choices) {
    state.proposal.choices = choices
  },
  setVotes(state, votes) {
    state.votes = votes
  },
  addVotes(state, votes) {
    state.votes = state.votes.concat(votes)
  },
  setProposal(state, proposal) {
    state.proposal = proposal
  },
  setProposalsTotal(state, total) {
    state.total = total
  },
  setVotesTotal(state, totalVotes) {
    state.totalVotes = totalVotes
  },
  addProposals(state, proposals) {
    state.proposals = state.proposals.concat(proposals)
  },
  setProposalVoted(state, status) {
    state.voted = status
  },
  setLoadingProposals(state, status) {
    state.proposalsLoading = status
  },
  setLoadingVotes(state, status) {
    state.votesLoading = status
  },
  setLoadingProposal(state, status) {
    state.proposalLoading = status
  }
}

export const actions = {
  async fetchProposals({
    commit
  }, payload) {
    commit('setLoadingProposals', true)
    const request = await this.$generateApiRequest()
    const proposals = await request.$get('proposals', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        search: payload.search !== undefined && payload.search !== null ? payload.search : '',
        status: payload.status !== undefined && payload.status !== null ? payload.status : null,
        type: payload.type !== undefined && payload.type !== null ? payload.type : null
      }
    })
    commit('setProposals', proposals.answer.proposals)
    commit('setProposalsTotal', proposals.total)
    commit('setLoadingProposals', false)
    this.$refreshCookieExpires()
  },
  async addProposal({
    commit
  }, payload) {
    commit('setLoadingProposal', true)
    const request = await this.$generateApiRequest()
    await request.$post('proposals', payload)
    commit('setLoadingProposal', false)
    this.$refreshCookieExpires()
  },
  async voteProposal({
    commit
  }, payload) {
    commit('setLoadingProposal', true)
    const request = await this.$generateApiRequest()
    await request.$post('proposals/' + payload.pid + '/vote', payload)
    commit('setLoadingProposal', false)
    this.$refreshCookieExpires()
  },
  async voteCheck({
    commit
  }, payload) {
    commit('setLoadingProposal', true)
    const request = await this.$generateApiRequest()
    const voted = await request.$get('proposals/' + payload.pid + '/check')
    commit('setProposalVoted', voted.answer.vote)
    commit('setLoadingProposal', false)
    this.$refreshCookieExpires()
  },
  async fetchProposal({
    commit
  }, payload) {
    commit('setLoadingProposal', true)
    const request = await this.$generateApiRequest()
    const pair = await request.$get('proposals/' + payload.address)
    commit('setProposal', pair.answer.proposal)
    commit('setChoices', pair.answer.votes)
    commit('setLoadingProposal', false)
  },
  async fetchVotes({
    commit
  }, payload) {
    commit('setLoadingVotes', true)
    const request = await this.$generateApiRequest()
    const votes = await request.$get('proposals/votes', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        pid: payload.pid !== undefined && payload.pid !== null ? payload.pid : ''
      }
    })
    if (payload.page !== undefined && payload.page > 1) {
      commit('addVotes', votes.answer.votes)
    } else {
      commit('setVotes', votes.answer.votes)
    }
    commit('setVotesTotal', votes.answer.total)
    commit('setLoadingVotes', false)
  }
}

export const getters = {
  proposals: state => state.proposals,
  votes: state => state.votes,
  proposal: state => state.proposal,
  total: state => state.total,
  totalVotes: state => state.totalVotes,
  voted: state => state.voted,
  votesLoading: state => state.votesLoading,
  proposalsLoading: state => state.proposalsLoading,
  proposalLoading: state => state.proposalLoading
}
