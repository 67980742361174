var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 19 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", [
        _c("rect", {
          attrs: {
            width: "19",
            height: "19",
            rx: "9.5",
            transform: "matrix(-1 0 0 1 19 0)",
            fill: "currentColor",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }