<template>
  <div class="import-area">
    <div class="tokens-area">
      <div class="token-item">
        <div ref="fromListButton" class="token-select" @click="toggleFromList">
          <div v-if="fromToken === null" class="token-data">
            <div class="token-area">
              <div class="empty-area"></div>
              <p class="normal">
                {{ $t('liquidity.select_token') }}
              </p>
            </div>
            <div class="chevron-area">
              <ChevronDown />
            </div>
          </div>
          <div v-else class="token-data">
            <div class="token-area">
              <nuxt-img
                preset="tokenBigLogo"
                :src="fromToken.address !== $config.ZERO ? $getImageByAddress(fromToken.address) : $getImage(fromToken.logoURI)"
                :alt="fromToken.name"
                :title="fromToken.symbol"
                @error="$event.target.src = require('~/static/img/default_token.svg')"
              />
              <p>
                {{ fromToken.symbol }}
              </p>
            </div>
            <div class="chevron-area">
              <ChevronDown />
            </div>
          </div>
        </div>
        <div ref="fromList" class="token-select-list">
          <div class="h4">
            {{ $t('swap.select_token') }}
          </div>
          <form class="search-area" method="POST">
            <button type="button" class="search-button" :arira-label="$t('common.search')">
              <Search />
            </button>
            <input v-model="search" type="text" :placeholder="$t('common.search') + '...'">
          </form>
          <TokensList :current-token="pair.from_token" :pair-token="pair.to_token" :list="computedList" @pick="selectFromToken" />
          <div ref="manageFromButton" class="open-manage" @click="toggleManageFrom">
            {{ $t('swap.manage') }}
          </div>
          <ManageModal ref="manageFromArea" :active="activeManageFrom" @toggle="toggleManageFrom" />
        </div>
      </div>
      <div class="swap-area">
        <Plus />
      </div>
      <div class="token-item">
        <div ref="toListButton" class="token-select" @click="toggleToList">
          <div v-if="toToken === null" class="token-data">
            <div class="token-area">
              <div class="empty-area"></div>
              <p class="normal">
                {{ $t('liquidity.select_token') }}
              </p>
            </div>
            <div class="chevron-area">
              <ChevronDown />
            </div>
          </div>
          <div v-else class="token-data">
            <div class="token-area">
              <nuxt-img
                preset="tokenBigLogo"
                :src="toToken.address !== $config.ZERO ? $getImageByAddress(toToken.address) : $getImage(toToken.logoURI)"
                :alt="toToken.name"
                :title="toToken.symbol"
                @error="$event.target.src = require('~/static/img/default_token.svg')"
              />
              <p>
                {{ toToken.symbol }}
              </p>
            </div>
            <div class="chevron-area">
              <ChevronDown />
            </div>
          </div>
        </div>
        <div ref="toList" class="token-select-list">
          <div class="h4">
            {{ $t('swap.select_token') }}
          </div>
          <form class="search-area" method="POST">
            <button type="button" class="search-button" :arira-label="$t('common.search')">
              <Search />
            </button>
            <input v-model="search" type="text" :placeholder="$t('common.search') + '...'">
          </form>
          <TokensList :current-token="pair.to_token" :pair-token="pair.from_token" :list="computedList" @pick="selectToToken" />
          <div ref="manageToButton" class="open-manage" @click="toggleManageTo">
            {{ $t('swap.manage') }}
          </div>
          <ManageModal ref="manageToArea" :active="activeManageTo" @toggle="toggleManageTo" />
        </div>
      </div>
    </div>
    <div class="hint-area">
      <div v-if="!tokensPicked && errorText.length === 0 && infoText.length === 0" class="loading-area">
        <div class="icon-area">
        </div>
        <div class="text-area">
          {{ $t('pair.pick_tokens') }}
        </div>
      </div>
      <div v-else-if="tokensPicked && loading" class="loading-area">
        <div class="icon-area">
          <Loading />
        </div>
        <div class="text-area">
          {{ $t('common.loading') }}...
        </div>
      </div>
      <div v-else-if="errorText.length > 0" class="loading-area horizontal warning">
        <div class="icon-area">
          <WarnTriangle />
        </div>
        <div class="text-area">
          {{ errorText }}
        </div>
      </div>
      <div v-else-if="infoText.length > 0" class="loading-area horizontal info">
        <div class="icon-area">
          <CoinHold />
        </div>
        <div class="text-area">
          {{ infoText }}
        </div>
      </div>
      <div v-if="infoText.length > 0" class="buttons-area">
        <button type="button" class="btn cyan" @click="goToLiquidity">
          {{ $t('pools.add_liquidity') }}
        </button>
        <button v-if="tokensPicked" type="button" class="btn btn-outline cyan" @click="clearForm">
          <span class="text">
            {{ $t('pools.clear_form') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  hydrateWhenVisible,
  hydrateWhenIdle
} from 'vue-lazy-hydration'

export default {
  components: {
    ChevronDown: hydrateWhenVisible(() => import('~/components/svg/new/ChevronDown')),
    CoinHold: hydrateWhenVisible(() => import('~/components/svg/new/CoinHold')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    Plus: hydrateWhenVisible(() => import('~/components/svg/new/Plus')),
    Search: hydrateWhenVisible(() => import('~/components/svg/new/Search')),
    ManageModal: hydrateWhenIdle(() => import('~/components/modals/ManageModal')),
    TokensList: hydrateWhenIdle(() => import('~/components/app/interface/TokensList')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  data() {
    return {
      search: '',
      importManage: '',
      pair: {
        from_token: {
          symbol: '',
          address: '',
          decimals: 18
        },
        to_token: {
          symbol: '',
          address: '',
          decimals: 18
        }
      },
      walletConnected: false,
      loading: false,
      activeManageFrom: false,
      activeManageTo: false,
      errorText: '',
      infoText: ''
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('tokens', [
      'list',
      'source'
    ]),
    fromToken() {
      const token = this.list.length > 0 && this.pair.from_token.symbol.length > 0
        ? this.list.find(item => item.symbol.toLowerCase() === this.pair.from_token.symbol.toLowerCase())
        : null
      return token !== undefined ? token : this.list[0]
    },
    toToken() {
      const token = this.list.length > 1 && this.pair.to_token.symbol.length > 0
        ? this.list.find(item => item.symbol.toLowerCase() === this.pair.to_token.symbol.toLowerCase())
        : null
      return token !== undefined ? token : this.list[1]
    },
    computedList() {
      return this.list.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()) || item.symbol.toLowerCase().includes(this.search.toLowerCase()))
    },
    baseList() {
      return this.list.filter(item => this.baseTokens.includes(item.symbol.toLowerCase()))
    },
    tokensPicked() {
      return this.pair.from_token.address.length > 0 && this.pair.to_token.address.length > 0 &&
        this.walletConnected
    }
  },
  async mounted() {
    if (this.list.length <= 0) {
      await this.fetchList(this.source[0].uri)
    }
    if (this.user !== undefined && this.user !== null && this.user.address !== undefined && this.$isAddress(this.user.address)) {
      this.walletConnected = true
    }
    window.addEventListener('click', (evt) => {
      if (
        this.$refs.fromListButton !== undefined &&
        this.$refs.fromList !== undefined &&
        !this.$refs.fromList.contains(evt.target) &&
        !this.$refs.fromListButton.contains(evt.target) &&
        !this.$refs.manageFromArea.$el.contains(evt.target) &&
        !this.$refs.manageFromButton.contains(evt.target) &&
        this.$refs.fromList.classList.contains('active') &&
        !evt.target.classList.contains('remove-list')
      ) {
        this.toggleFromList()
      }
      if (
        this.$refs.manageFromButton !== undefined &&
        this.$refs.manageFromArea !== undefined &&
        !this.$refs.manageFromArea.$el.contains(evt.target) &&
        !this.$refs.manageFromButton.contains(evt.target) &&
        this.$refs.manageFromArea.$el.classList.contains('active') &&
        !evt.target.classList.contains('remove-list')
      ) {
        this.toggleManageFrom()
      }
      if (
        this.$refs.toListButton !== undefined &&
        this.$refs.toList !== undefined &&
        !this.$refs.toList.contains(evt.target) &&
        !this.$refs.toListButton.contains(evt.target) &&
        !this.$refs.manageToArea.$el.contains(evt.target) &&
        !this.$refs.manageToButton.contains(evt.target) &&
        this.$refs.toList.classList.contains('active') &&
        !evt.target.classList.contains('remove-list')
      ) {
        this.toggleToList()
      }
      if (
        this.$refs.manageToButton !== undefined &&
        this.$refs.manageToArea !== undefined &&
        !this.$refs.manageToArea.$el.contains(evt.target) &&
        !this.$refs.manageToButton.contains(evt.target) &&
        this.$refs.manageToArea.$el.classList.contains('active') &&
        !evt.target.classList.contains('remove-list')
      ) {
        this.toggleManageTo()
      }
    })
  },
  methods: {
    ...mapActions('tokens', [
      'fetchList'
    ]),
    ...mapActions('modal', [
      'changeModalGlobalState'
    ]),
    isTokenActive(symbol) {
      return this.pair.from_token.symbol.toLowerCase() === symbol.toLowerCase() ||
        this.pair.to_token.symbol.toLowerCase() === symbol.toLowerCase()
    },
    toggleFromList() {
      if (this.$refs.fromList !== undefined) {
        this.$refs.fromList.classList.toggle('active')
        this.search = ''
      }
    },
    selectFromToken(token) {
      if (this.pair.to_token.symbol.toLowerCase() !== token.symbol.toLowerCase()) {
        this.pair.from_token = token
        this.toggleFromList()
      }
      if (this.tokensPicked) {
        this.loadPair()
      }
    },
    toggleManageFrom() {
      this.activeManageFrom = !this.activeManageFrom
      this.importManage = ''
    },
    toggleToList() {
      if (this.$refs.toList !== undefined) {
        this.$refs.toList.classList.toggle('active')
        this.search = ''
      }
    },
    selectToToken(token) {
      if (this.pair.from_token.symbol.toLowerCase() !== token.symbol.toLowerCase()) {
        this.pair.to_token = token
        this.toggleToList()
      }
      if (this.tokensPicked) {
        this.loadPair()
      }
    },
    toggleManageTo() {
      this.activeManageTo = !this.activeManageTo
      this.importManage = ''
    },
    clearForm() {
      this.pair = {
        from_token: {
          symbol: '',
          address: '',
          decimals: 18
        },
        to_token: {
          symbol: '',
          address: '',
          decimals: 18
        }
      }
    },
    async loadPair() {
      if (!this.tokensPicked) {
        return
      }
      const fromAddress = this.$changeZeroToWrapped(this.pair.from_token.address)
      const toAddress = this.$changeZeroToWrapped(this.pair.to_token.address)
      this.loading = true
      this.infoText = ''
      this.errorText = ''
      const userAddress = await this.$getUserAddress()
      const PeriodFactoryfile = await import('~/assets/abis/PeriodFactory.json')
      const PeriodFactory = PeriodFactoryfile.default
      const factoryContract = new window.eth.eth.Contract(PeriodFactory, this.$config.periodFactory)
      try {
        const pairExists = await factoryContract.methods
          .getPair(
            fromAddress,
            toAddress
          )
          .call({ from: userAddress })
        if (pairExists !== this.$config.ZERO) {
          const pool = {
            token0: this.pair.from_token,
            token1: this.pair.to_token,
            balance: 0,
            totalSupply: 0,
            poolShare: 0,
            reserves: {},
            address: pairExists
          }
          const PeriodPairfile = await import('~/assets/abis/PeriodPair.json')
          const PeriodPair = PeriodPairfile.default
          const pairContract = new window.eth.eth.Contract(PeriodPair, pairExists)
          pool.balance = await pairContract.methods.balanceOf(userAddress).call({ from: userAddress })
          if (parseFloat(pool.balance) > 0) {
            pool.reserves = await pairContract.methods.getReserves().call({ from: userAddress })
            pool.totalSupply = await pairContract.methods.totalSupply().call({ from: userAddress })
            const token0 = await pairContract.methods.token0().call({ from: userAddress })

            let reserve0Decimal = this.$getDecimalsName(parseInt(this.pair.from_token.decimals, 10))
            let reserve1Decimal = this.$getDecimalsName(parseInt(this.pair.to_token.decimals, 10))
            if (this.pair.from_token.address.toLowerCase() !== token0.toLowerCase()) {
              pool.token0 = this.pair.to_token
              pool.token1 = this.pair.from_token
              reserve0Decimal = this.$getDecimalsName(parseInt(this.pair.to_token.decimals, 10))
              reserve1Decimal = this.$getDecimalsName(parseInt(this.pair.from_token.decimals, 10))
            }

            pool.balance = parseFloat(window.eth.utils.fromWei(pool.balance))

            let reserve0Balance = parseFloat(window.eth.utils.fromWei(pool.reserves.reserve0, reserve0Decimal.name))
            if (reserve0Decimal.multiply > 0) {
              reserve0Balance /= 10 ** reserve0Decimal.multiply
            }
            let reserve1Balance = parseFloat(window.eth.utils.fromWei(pool.reserves.reserve1, reserve1Decimal.name))
            if (reserve1Decimal.multiply > 0) {
              reserve1Balance /= 10 ** reserve1Decimal.multiply
            }

            pool.reserves.reserve0 = reserve0Balance
            pool.reserves.reserve1 = reserve1Balance
            pool.totalSupply = parseFloat(window.eth.utils.fromWei(pool.totalSupply))
            pool.poolShare = pool.balance / pool.totalSupply

            const networkId = window.ethereum.networkVersion
            let userPairs = this.$cookies.get(networkId + '/' + userAddress)
            const pairKey = this.$changeZeroToWrapped(this.pair.from_token.address) + '/' + this.$changeZeroToWrapped(this.pair.to_token.address)

            if (userPairs === undefined) {
              await this.$createUserActivity()
              userPairs = this.$cookies.get(networkId + '/' + userAddress)
            }
            if (userPairs.pairs !== undefined) {
              userPairs.pairs[pairKey] = pool
              this.$cookies.set(networkId + '/' + userAddress, userPairs)
              this.$emit('select')
            } else {
              userPairs.pairs = {}
              userPairs.pairs[pairKey] = pool
              this.$cookies.set(networkId + '/' + userAddress, userPairs)
              this.$emit('select')
            }
            this.loading = false
            this.closeModal()
          } else {
            this.loading = false
            this.infoText = this.$t('pair.no_balance_text')
          }
        } else {
          this.loading = false
          this.infoText = this.$t('pair.doesnt_exist_text')
        }
      } catch (err) {
        console.log(err)
        this.loading = false
        const errorMessage = err.message !== undefined && err.message.length > 0 ? err.message : ''
        this.errorText = errorMessage.length > 0 ? this.$t('pair.cannot_be_fetched_text_with_error', { error: this.$getRPCError(errorMessage) }) : this.$t('pair.cannot_be_fetched_text')
      }
    },
    goToLiquidity() {
      const fromAddress = this.pair.from_token.address
      const toAddress = this.pair.to_token.address
      this.closeModal()
      this.$router.push({
        path: '/liquidity/add',
        query: {
          from: fromAddress,
          to: toAddress
        }
      })
      this.$nuxt.$emit('tokensLoaded')
    },
    onModalClose() {
      this.clearForm()
      this.errorText = ''
      this.infoText = ''
    },
    closeModal() {
      this.onModalClose()
      this.changeModalGlobalState({
        state: false
      })
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/tokenSelector.css');
  .hint-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    z-index: 1;
  }
  .loading-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    border-radius: .75rem;
  }
  .loading-area .icon-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 5rem;
    height: 5rem;
    margin-bottom: 2rem;
  }
  .loading-area .icon-area svg {
    width: 5rem;
    height: 5rem;
    color: rgb(var(--main-text-color))
  }
  .loading-area .text-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.75rem;
    color: rgb(var(--main-text-color))
  }
  .loading-area.horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .loading-area.horizontal .icon-area {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0;
    margin-right: 1rem
  }
  .loading-area.horizontal .icon-area svg {
    width: 3rem;
    height: 3rem;
  }
  .loading-area.horizontal .text-area {
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1rem;
    line-height: 1.5rem
  }
  .loading-area.info {
    background: rgba(var(--cta-cyan-normal), .25);
  }
  .loading-area.warning {
    background: rgba(var(--main-danger-color), .25);
  }
  .buttons-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }
  .buttons-area .btn:not(:last-child) {
    margin-bottom: 1rem;
  }
</style>
