<template>
  <div v-if="!loadingScreen && !successScreen && !errorScreen" class="confirm-area">
    <div class="confirm-table">
      <div class="confirm-row">
        <div class="token-data">
          <nuxt-img
            v-if="inputMethod !== 'exchange'"
            :quality="80"
            placeholder
            :src="swap.from_token.address !== $config.ZERO ? $getImageByAddress(swap.from_token.address) : $getImage(swap.from_token.logoURI)"
            :alt="swap.from_token.name + ' ' + swap.from_token.symbol"
            :title="swap.from_token.name"
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <nuxt-img
            v-else
            :quality="80"
            placeholder
            :src="'/img/new/' + swap.from_token.name.toLowerCase() + '_item.svg'"
            :alt="swap.from_token.name + ' ' + swap.from_token.symbol"
            :title="swap.from_token.name"
            :lazy="true"
          />
          <p class="value">
            {{ swap.from_value }}
          </p>
        </div>
        <div class="token-symbol">
          <div class="h3">
            {{ swap.from_token.symbol.toUpperCase() }}
          </div>
        </div>
      </div>
      <div class="confirm-row">
        <div class="arrow-area">
          <ArrowDown />
        </div>
      </div>
      <div class="confirm-row">
        <div class="token-data">
          <nuxt-img
            v-if="inputMethod !== 'exchange'"
            :quality="80"
            placeholder
            :src="swap.to_token.address !== $config.ZERO ? $getImageByAddress(swap.to_token.address) : $getImage(swap.to_token.logoURI)"
            :alt="swap.to_token.name + ' ' + swap.to_token.symbol"
            :title="swap.to_token.name"
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <nuxt-img
            v-else
            :quality="80"
            placeholder
            :src="'/img/new/' + swap.to_token.name.toLowerCase() + '_item.svg'"
            :alt="swap.to_token.name + ' ' + swap.to_token.symbol"
            :title="swap.to_token.name"
            :lazy="true"
          />
          <p class="value">
            {{ swap.to_value }}
          </p>
        </div>
        <div class="token-symbol">
          <div class="h3">
            {{ swap.to_token.symbol.toUpperCase() }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="inputMethod !== 'exchange'" class="desc">
      <p v-if="inputMethod === 'from'">
        {{ $t('swap.swap_desc_at_least') }}
        <span class="value">
          {{ calculatedToSum + ' ' + swap.to_token.symbol.toUpperCase() }}
        </span>
        {{ $t('swap.or_transaction_revert') }}.
      </p>
      <p v-else>
        {{ $t('swap.swap_desc_at_most') }}
        <span class="value">
          {{ calculatedFromSum + ' ' + swap.from_token.symbol.toUpperCase() }}
        </span>
        {{ $t('swap.or_transaction_revert') }}
      </p>
    </div>
    <div :class="{'refresh-area': true, 'active': priceRefreshed}">
      <div class="icon-area">
        <CoinHold />
      </div>
      <div class="text-area">
        <p class="desc">
          {{ $t('swap.price_refreshed') }}
        </p>
        <button class="btn cyan" type="button" @click="approvePrive">
          {{ $t('pools.approve') }}
        </button>
      </div>
    </div>
    <div :class="{'refresh-area': true, 'error': true, 'active': error.length > 0}">
      <div class="icon-area">
        <WarnTriangle />
      </div>
      <div class="text-area">
        <p class="desc">
          {{ error }}
        </p>
      </div>
    </div>
    <hr>
    <div v-if="inputMethod !== 'exchange'" class="desc-table">
      <div class="desc-row">
        <div class="title">
          <p>
            {{ $t('swap.price') }}
          </p>
        </div>
        <div class="value">
          <p ref="priceEstimate" class="bold">
            {{ fromPrice + ' ' + swap.from_token.symbol.toUpperCase() + ' / ' + swap.to_token.symbol.toUpperCase() }}
          </p>
          <div class="change-icon" @click="turnOverPrice">
            <RefreshIcon />
          </div>
        </div>
      </div>
      <div class="desc-row">
        <div class="title">
          <p v-if="inputMethod === 'from'">
            {{ $t('swap.minimum_received') }}
          </p>
          <p v-else>
            {{ $t('swap.maximum_sold') }}
          </p>
        </div>
        <div class="value">
          <p v-if="inputMethod === 'from'">
            {{ calculatedToSum + ' ' + swap.to_token.symbol.toUpperCase() }}
          </p>
          <p v-else>
            {{ calculatedFromSum + ' ' + swap.from_token.symbol.toUpperCase() }}
          </p>
        </div>
      </div>
      <div class="desc-row">
        <div class="title">
          <p>
            {{ $t('swap.price_impact') }}
          </p>
        </div>
        <div :class="priceImpactClass">
          <p>
            {{ $priceFormat(priceImpact, 2, '.', ',') + '%' }}
          </p>
        </div>
      </div>
      <div class="desc-row">
        <div class="title">
          <p>
            {{ $t('swap.liquidity_provider_fee') }}
          </p>
        </div>
        <div class="value">
          <p>
            {{ $preciseTokenValue(swap.from_value * $config.fee, 2, '.', ',') + ' ' + swap.from_token.symbol.toUpperCase() }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="desc-table mb-0">
      <div class="desc-row">
        <div class="title">
          <p>
            {{ $t('swap.price') }}
          </p>
        </div>
        <div class="value">
          <p ref="priceEstimate" class="bold">
            {{ fromPriceExchange + ' ' + swap.from_token.symbol.toUpperCase() + ' / ' + swap.to_token.symbol.toUpperCase() }}
          </p>
          <div class="change-icon" @click="turnOverPrice">
            <RefreshIcon />
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div v-if="inputMethod !== 'exchange'" class="buttons-area">
      <button v-if="!fromApproved && (toApproved || (!fromApproved && !toApproved))" :class="{'btn btn-outline pink': true, 'disabled': loadingFromApprove}" :disabled="loadingFromApprove" type="button" @click="approveToken('from')">
        <span v-if="!loadingFromApprove" class="text">
          {{ $t('pools.approve') }} {{ swap.from_token.symbol }}
        </span>
        <span v-else class="text">
          <Loading />
        </span>
      </button>
      <button v-if="!toApproved && fromApproved" :class="{'btn btn-outline pink': true, 'disabled': loadingToApprove}" :disanled="loadingToApprove" type="button" @click="approveToken('to')">
        <span v-if="!loadingToApprove" class="text">
          {{ $t('pools.approve') }} {{ swap.to_token.symbol }}
        </span>
        <span v-else class="text">
          <Loading />
        </span>
      </button>
      <button :class="{ 'btn pink': true, 'disabled': loading || !approvedTokens || !priceApproved || error.length > 0 }" type="button" :disabled="loading || !approvedTokens || !priceApproved || error.length > 0" @click="confirmSwap">
        <span v-if="!loading">
          {{ $t('swap.confirm_swap') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
    <div v-else class="buttons-area">
      <button v-if="!fromApproved" :class="{'btn btn-outline pink': true, 'disabled': loadingFromApprove}" :disabled="loadingFromApprove" type="button" @click="approveToken('from')">
        <span v-if="!loadingFromApprove" class="text">
          {{ $t('pools.approve') }} {{ swap.from_token.symbol }}
        </span>
        <span v-else class="text">
          <Loading />
        </span>
      </button>
      <button :class="{ 'btn pink': true, 'disabled': loading || !approvedTokens || !priceApproved || error.length > 0 }" type="button" :disabled="loading || !approvedTokens || !priceApproved || error.length > 0" @click="confirmSwap">
        <span v-if="!loading">
          {{ $t('swap.confirm_swap') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
  </div>
  <div v-else-if="loadingScreen" class="loading-area">
    <ModalLoader />
    <div class="desc-area">
      <div class="h5">
        {{ $t('swap.swapping') }}
        {{ $preciseTokenValue(swap.from_value) + ' ' + swap.from_token.symbol.toUpperCase() }}
        {{ $t('common.for') }}
        {{ $preciseTokenValue(swap.to_value) + ' ' + swap.to_token.symbol.toUpperCase() }}
      </div>
      <p>
        {{ $t('swap.confirm_transaction') }}
      </p>
    </div>
  </div>
  <div v-else-if="successScreen" class="success-area">
    <div class="icon-area">
      <CheckIcon />
    </div>
    <div class="desc-area">
      <div class="h4">
        {{ $t('swap.success') }}
      </div>
      <p class="desc">
        {{ $t('swap.success_text') }}
      </p>
    </div>
    <hr>
    <div class="buttons-area">
      <a class="btn btn-outline pink" :href="transactionLink(transaction.tx)" rel="nofollow noopener noreferrer" target="_blank">
        <span class="text">
          {{ $t('swap.view_explorer') }}
          <span class="icon icon-right">
            <NewTabIcon />
          </span>
        </span>
      </a>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.close') }}
      </button>
    </div>
  </div>
  <div v-else-if="errorScreen" class="error-area">
    <WarnTriangle />
    <div class="desc-area">
      <div class="h4">
        {{ $t('swap.error') }}
      </div>
      <p class="desc">
        {{ errorText }}
      </p>
      <hr>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.dismiss') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  components: {
    ArrowDown: hydrateWhenVisible(() => import('~/components/svg/ArrowDown')),
    CoinHold: hydrateWhenVisible(() => import('~/components/svg/new/CoinHold')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    ModalLoader: hydrateWhenVisible(() => import('~/components/app/interface/ModalLoader')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    RefreshIcon: hydrateWhenVisible(() => import('~/components/svg/RefreshIcon')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  props: {
    swap: {
      type: Object,
      default: () => {
        return {
          from_value: null,
          from_token: {
            symbol: '',
            address: '',
            decimals: 18
          },
          to_value: null,
          to_token: {
            symbol: '',
            address: '',
            decimals: 18
          },
          method: 'swapExactETHForTokens'
        }
      }
    },
    transaction: {
      type: Object,
      default: () => {
        return {
          gasFee: 3000000,
          tx: '',
          loading: false,
          pending: false
        }
      }
    },
    pairData: {
      type: Object,
      default: () => {
        return null
      }
    },
    inputMethod: {
      type: String,
      default: () => {
        return 'from'
      }
    },
    type: {
      type: String,
      default: () => {
        return 'Global'
      }
    }
  },
  data() {
    return {
      errorText: this.$t('swap.error_text'),
      error: '',
      price: 'from-to',
      loading: false,
      loadingScreen: false,
      successScreen: false,
      errorScreen: false,
      fromApproved: false,
      toApproved: false,
      loadingFromApprove: false,
      loadingToApprove: false,
      priceInterval: null,
      priceRefreshed: false,
      priceApproved: true
    }
  },
  computed: {
    ...mapGetters('settings', [
      'slippageTolerance',
      'deadline',
      'expertMode',
      'multihops'
    ]),
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    },
    calculatedFromSum() {
      return this.$preciseTokenValue(this.$calculateWithSlippage(this.swap.from_value, this.slippageTolerance, 'max', false))
    },
    calculatedToSum() {
      return this.$preciseTokenValue(this.$calculateWithSlippage(this.swap.to_value, this.slippageTolerance, 'min', false))
    },
    fromPrice() {
      return this.pairData !== null && this.pairData.reserves.reserve1 !== 0 ? this.$preciseTokenValue(this.pairData.reserves.reserve0 / this.pairData.reserves.reserve1) : 0
    },
    fromPriceExchange() {
      return this.swap.from_token.address === this.$config.periodToken ? 1 / this.swap.contract.rate : this.swap.contract.rate
    },
    toPriceExchange() {
      return this.swap.to_token.address === this.$config.periodReset ? this.swap.contract.rate : 1 / this.swap.contract.rate
    },
    toPrice() {
      return this.pairData !== null && this.pairData.reserves.reserve0 !== 0 ? this.$preciseTokenValue(this.pairData.reserves.reserve1 / this.pairData.reserves.reserve0) : 0
    },
    priceImpact() {
      if (this.pairData !== null) {
        const initialMarketPrice = this.pairData.reserves.reserve0 / this.pairData.reserves.reserve1
        const conductProduct = this.pairData.reserves.reserve0 * this.pairData.reserves.reserve1
        const amountInWithFee = parseFloat(this.swap.from_value) + parseFloat(this.swap.from_value) * parseFloat(this.$config.fee)
        const newFromAmount = this.pairData.reserves.reserve0 + amountInWithFee
        const newToAmount = conductProduct / newFromAmount
        const toReceive = this.pairData.reserves.reserve1 - newToAmount
        const newPricePerTo = amountInWithFee / toReceive

        return (newPricePerTo - initialMarketPrice) / initialMarketPrice * 100
      } else {
        return 0
      }
    },
    priceImpactClass() {
      const classValue = {
        value: true
      }
      classValue[this.$ratePriceImpact(this.priceImpact)] = true
      return classValue
    },
    approvedTokens() {
      return this.inputMethod !== 'exchange' ? this.fromApproved && this.toApproved : this.fromApproved
    }
  },
  watch: {
    isModalOpened(modalOpen) {
      if (!modalOpen) {
        this.returnInitState()
        clearInterval(this.priceInterval)
        this.priceInterval = null
      } else {
        this.checkForApproval()
        this.priceInterval = setInterval(this.priceRefresh, 10000)
      }
    }
  },
  mounted() {
    this.checkForApproval()
  },
  methods: {
    turnOverPrice() {
      if (this.inputMethod !== 'exchange' && this.priceDirection === 'from-to') {
        this.priceDirection = 'to-from'
        this.$refs.priceEstimate.innerHTML = this.toPrice + ' ' + this.swap.to_token.symbol.toUpperCase() + ' / ' + this.swap.from_token.symbol.toUpperCase()
      } else if (this.inputMethod !== 'exchange') {
        this.priceDirection = 'from-to'
        this.$refs.priceEstimate.innerHTML = this.fromPrice + ' ' + this.swap.from_token.symbol.toUpperCase() + ' / ' + this.swap.to_token.symbol.toUpperCase()
      } else {
        this.priceDirection = this.priceDirection === 'from-to' ? 'to-from' : 'from-to'
        this.$refs.priceEstimate.innerHTML = this.priceDirection === 'from-to'
          ? this.fromPriceExchange + ' ' + this.swap.from_token.symbol.toUpperCase() + ' / ' + this.swap.to_token.symbol.toUpperCase()
          : this.toPriceExchange + ' ' + this.swap.to_token.symbol.toUpperCase() + ' / ' + this.swap.from_token.symbol.toUpperCase()
      }
    },
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    },
    closeModal() {
      this.$emit('closeGlobalModal')
      this.returnInitState()
    },
    returnInitState() {
      if (this.transaction.pending === false) {
        this.$emit('initConfirmState')
        this.loading = false
        this.loadingScreen = false
        this.successScreen = false
        this.errorScreen = false
      } else {
        this.initLoadingScreen()
      }
    },
    async approveToken(tokenType = 'from') {
      let token = this.swap.from_token
      if (tokenType === 'to') {
        token = this.swap.to_token
        this.loadingToApprove = true
      } else {
        this.loadingFromApprove = true
      }
      const approvalContract = this.inputMethod !== 'exchange' ? this.$config.periodRouter : this.$config.periodExchange
      const userAddress = (await window.eth.eth.requestAccounts())[0]
      const ERC20file = await import('~/assets/abis/ERC20.json')
      const ERC20 = ERC20file.default
      const erc20Contract = new window.eth.eth.Contract(ERC20, token.address)
      const gasPrice = await this.$calculateGasCost()
      const gasFeeEstimated = await erc20Contract.methods.approve(approvalContract, window.eth.utils.toWei(Number(2 ** 64 - 1).toString()))
        .estimateGas({ from: userAddress, gas: this.transaction.gasFee })

      erc20Contract.methods.approve(approvalContract, window.eth.utils.toWei(Number(2 ** 64 - 1).toString(), 'ether'))
        .send({ from: userAddress, gas: Math.round(gasFeeEstimated * 1.15).toString(), gasPrice })
        .then((result) => {
          const emitedApproval = result.events.Approval.returnValues[2]
          if (parseInt(emitedApproval, 10) > 0) {
            if (tokenType === 'from') {
              this.fromApproved = true
              this.loadingFromApprove = false
            } else {
              this.toApproved = true
              this.loadingToApprove = false
            }
          }
        })
        .catch(() => {
          this.loading = false
          this.loadingToApprove = false
          this.loadingFromApprove = false
          this.$denyWalletOperation()
        })
    },
    async confirmSwap() {
      this.loading = true
      if (!this.$isStableCurrency(this.swap.from_token.symbol)) {
        try {
          const userAddress = (await window.eth.eth.requestAccounts())[0]
          const ERC20file = await import('~/assets/abis/ERC20.json')
          const ERC20 = ERC20file.default
          const erc20Contract = new window.eth.eth.Contract(ERC20, this.swap.from_token.address)
          const contractAddress = this.inputMethod !== 'exchange' ? this.$config.periodRouter : this.$config.periodExchange
          const allowed = await erc20Contract.methods.allowance(userAddress, contractAddress).call({ from: userAddress })
          const decimals = await erc20Contract.methods.decimals().call({ from: userAddress })
          const remainingAllowance = window.eth.utils.toBN(allowed)
          const serviceFeeWei = window.eth.utils.toBN(this.$toWeiDecimal(this.swap.from_value, decimals))
          if (serviceFeeWei.gt(remainingAllowance)) {
            const gasPrice = await this.$calculateGasCost()
            const gasFeeEstimated = await erc20Contract.methods.approve(contractAddress, window.eth.utils.toWei(Number(2 ** 64 - 1).toString()))
              .estimateGas({ from: userAddress, gas: this.transaction.gasFee })

            erc20Contract.methods.approve(contractAddress, window.eth.utils.toWei(Number(2 ** 64 - 1).toString(), 'ether'))
              .send({ from: userAddress, gas: Math.round(gasFeeEstimated * 1.15).toString(), gasPrice })
              .then((result) => {
                const emitedApproval = result.events.Approval.returnValues[2]
                if (parseInt(emitedApproval, 10) > 0) {
                  this.initLoadingScreen()
                  this.$emit('swapTokens')
                }
              })
              .catch(() => {
                this.loading = false
                this.$denyWalletOperation()
              })
          } else {
            this.initLoadingScreen()
            this.$emit('swapTokens')
          }
        } catch (err) {
          this.loading = false
          this.rejectTransaction({
            type: 'error',
            message: this.$t('swap.token_not_erc20_text')
          })
        }
      } else {
        this.initLoadingScreen()
        this.$emit('swapTokens')
      }
    },
    async checkForApproval() {
      const chainId = await window.eth.eth.net.getId()
      this.error = ''
      if (!this.$isChainSupported(chainId)) {
        this.fromApproved = false
        this.toApproved = false
        return
      }
      try {
        const approvalContract = this.inputMethod !== 'exchange' ? this.$config.periodRouter : this.$config.periodExchange
        const userAddress = (await window.eth.eth.requestAccounts())[0]
        const ERC20file = await import('~/assets/abis/ERC20.json')
        const ERC20 = ERC20file.default
        if (!this.$isStableCurrency(this.swap.from_token.symbol) && this.swap.from_token.address.length > 0) {
          const erc20Contract = new window.eth.eth.Contract(ERC20, this.swap.from_token.address)
          const allowed = await erc20Contract.methods.allowance(userAddress, approvalContract).call({ from: userAddress })
          const decimals = await erc20Contract.methods.decimals().call({ from: userAddress })
          const remainingAllowance = window.eth.utils.toBN(allowed)
          const valueWei = this.$toWeiDecimal(this.swap.from_value, decimals)
          if (valueWei < 1) {
            this.error = this.$t('swap.low_from_value')
            return
          }
          const serviceFeeWei = window.eth.utils.toBN(valueWei)
          if (serviceFeeWei.gt(remainingAllowance)) {
            this.fromApproved = false
          } else {
            this.fromApproved = true
          }
        } else {
          this.fromApproved = true
        }
        if (!this.$isStableCurrency(this.swap.to_token.symbol) && this.swap.to_token.address.length > 0) {
          const erc20Contract = new window.eth.eth.Contract(ERC20, this.swap.to_token.address)
          const allowed = await erc20Contract.methods.allowance(userAddress, approvalContract).call({ from: userAddress })
          const decimals = await erc20Contract.methods.decimals().call({ from: userAddress })
          const remainingAllowance = window.eth.utils.toBN(allowed)
          const valueWei = this.$toWeiDecimal(this.swap.to_value, decimals)
          if (valueWei < 1) {
            this.error = this.$t('swap.low_to_value')
            return
          }
          const serviceFeeWei = window.eth.utils.toBN(valueWei)
          if (serviceFeeWei.gt(remainingAllowance)) {
            this.toApproved = false
          } else {
            this.toApproved = true
          }
        } else {
          this.toApproved = true
        }
      } catch (err) {
        console.log(err)
        this.fromApproved = false
        this.toApproved = false
      }
    },
    priceRefresh() {
      this.$emit('priceRefresh')
      this.priceApproved = false
      this.priceRefreshed = true
    },
    approvePrive() {
      this.priceApproved = true
      this.priceRefreshed = false
    },
    rejectTransaction(error) {
      if (error === null || typeof error !== 'object') {
        this.initErrorScreen()
      } else {
        switch (error.type.toLowerCase()) {
          case 'denied':
            this.errorText = this.$t('common.deined_operation')
            break
          case 'error':
            this.errorText = error.message !== undefined && error.message.length > 0 ? this.$t('swap.error_text_with_error', { error: error.message }) : this.$t('swap.error_text')
            break
          default:
            this.errorText = this.$t('swap.error_text')
        }
        this.initErrorScreen()
      }
    },
    initLoadingScreen() {
      this.$emit('initLoading')
      this.loading = false
      this.loadingScreen = true
      this.errorScreen = false
      this.successScreen = false
    },
    initSuccessScreen() {
      this.$emit('initSuccess')
      this.loading = false
      this.loadingScreen = false
      this.errorScreen = false
      this.successScreen = true
    },
    initErrorScreen() {
      this.$emit('initError')
      this.loading = false
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = true
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modalContent.css');
</style>
