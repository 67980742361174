var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 11 11",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.83252 8.18579L7.03308 5.38635C6.94214 5.29541 6.94214 5.14795 7.03308 5.05701L9.83252 2.25757C10.2872 1.80287 10.2872 1.06554 9.83252 0.61084C9.37782 0.15614 8.64049 0.15614 8.18579 0.61084L5.38635 3.41028C5.29541 3.50122 5.14795 3.50122 5.05701 3.41028L2.25757 0.61084C1.80287 0.15614 1.06554 0.15614 0.61084 0.61084C0.15614 1.06554 0.15614 1.80287 0.61084 2.25757L3.41028 5.05701C3.50122 5.14795 3.50122 5.29541 3.41028 5.38635L0.610839 8.18579C0.15614 8.64049 0.15614 9.37782 0.61084 9.83252C1.06554 10.2872 1.80287 10.2872 2.25757 9.83252L5.05701 7.03308C5.14795 6.94214 5.29541 6.94214 5.38635 7.03308L8.18579 9.83252C8.64049 10.2872 9.37782 10.2872 9.83252 9.83252C10.2872 9.37782 10.2872 8.64049 9.83252 8.18579Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }