export const state = () => ({
  modalWalletOpen: false,
  modalGlobalOpen: false,
  modalSettingsOpen: false,
  modalRemoveOpen: false,
  modalListOpen: false,
  modalProfileOpen: false,
  modalCommonOpen: false,
  modalRoiOpen: false,
  modalVoteOpen: false,
  innerState: null
})

export const mutations = {
  setModalWalletOpen(state, status) {
    state.modalWalletOpen = status
    checkOverflow(status)
  },
  setModalGlobalOpen(state, status) {
    state.modalGlobalOpen = status
    checkOverflow(status)
  },
  setModalSettingsOpen(state, status) {
    state.modalSettingsOpen = status
    checkOverflow(status)
  },
  setModalRemoveOpen(state, status) {
    state.modalRemoveOpen = status
    checkOverflow(status)
  },
  setModalListOpen(state, status) {
    state.modalListOpen = status
    checkOverflow(status)
  },
  setModalProfileOpen(state, status) {
    state.modalProfileOpen = status
    checkOverflow(status)
  },
  setModalCommonOpen(state, status) {
    state.modalCommonOpen = status
    checkOverflow(status)
  },
  setModalRoiOpen(state, status) {
    state.modalRoiOpen = status
    checkOverflow(status)
  },
  setModalVoteOpen(state, status) {
    state.modalVoteOpen = status
    checkOverflow(status)
  },
  closeAll(state) {
    state.modalWalletOpen = false
    state.modalGlobalOpen = false
    state.modalSettingsOpen = false
    state.modalRemoveOpen = false
    state.modalProfileOpen = false
    state.modalCommonOpen = false
    state.modalRoiOpen = false
    state.modalVoteOpen = false
    checkOverflow(false)
  },
  setInnerState(state, status) {
    state.innerState = status
  }
}

export const actions = {
  changeModalWalletState({
    commit
  }, payload) {
    commit('setModalWalletOpen', payload.state)
  },
  changeModalGlobalState({
    commit
  }, payload) {
    commit('setModalGlobalOpen', payload.state)
  },
  changeModalSettingsState({
    commit
  }, payload) {
    commit('setModalSettingsOpen', payload.state)
  },
  changeModalRemoveState({
    commit
  }, payload) {
    commit('setModalRemoveOpen', payload.state)
  },
  changeModalListState({
    commit
  }, payload) {
    commit('setModalListOpen', payload.state)
  },
  changeModalProfileState({
    commit
  }, payload) {
    commit('setModalProfileOpen', payload.state)
  },
  changeModalCommonState({
    commit
  }, payload) {
    commit('setModalCommonOpen', payload.state)
  },
  changeModalRoiState({
    commit
  }, payload) {
    commit('setModalRoiOpen', payload.state)
  },
  changeModalVoteState({
    commit
  }, payload) {
    commit('setModalVoteOpen', payload.state)
  },
  closeAllModals({
    commit
  }) {
    commit('closeAll')
  }
}

export const getters = {
  modalWalletOpen: state => state.modalWalletOpen,
  modalGlobalOpen: state => state.modalGlobalOpen,
  modalSettingsOpen: state => state.modalSettingsOpen,
  modalRemoveOpen: state => state.modalRemoveOpen,
  modalListOpen: state => state.modalListOpen,
  modalCommonOpen: state => state.modalCommonOpen,
  modalProfileOpen: state => state.modalProfileOpen,
  modalRoiOpen: state => state.modalRoiOpen,
  modalVoteOpen: state => state.modalVoteOpen,
  innerState: state => state.innerState
}

function checkOverflow(status) {
  if (status === true) {
    document.querySelector('body').style.overflow = 'hidden'
    document.querySelector('html').classList.add('modal-open')
  } else {
    document.querySelector('body').style.overflow = 'auto'
    document.querySelector('html').classList.remove('modal-open')
  }
}
