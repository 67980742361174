var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewbox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10.836 5.16419C10.1164 4.44461 9.10057 3.979 8.00005 3.979C6.89952 3.979 5.88364 4.42345 5.16407 5.16419C4.44449 5.88377 3.97888 6.89964 3.97888 8.00017C3.97888 9.1007 4.44449 10.1166 5.16407 10.8361C5.88364 11.5557 6.89952 12.0213 8.00005 12.0213C9.10057 12.0213 10.1164 11.5769 10.836 10.8361C11.5556 10.1166 12.0212 9.1007 12.0212 8.00017C12.0212 6.89964 11.5768 5.88377 10.836 5.16419Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M7.99997 2.73016C8.29627 2.73016 8.55024 2.47619 8.55024 2.17989V0.550264C8.55024 0.253968 8.29627 0 7.99997 0C7.70368 0 7.44971 0.253968 7.44971 0.550264V2.17989C7.44971 2.47619 7.70368 2.73016 7.99997 2.73016Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M12.5079 4.27509L13.6719 3.11107C13.8836 2.89942 13.8836 2.5608 13.6719 2.34916C13.4603 2.13752 13.1217 2.13752 12.91 2.34916L11.746 3.51318C11.5344 3.72482 11.5344 4.06345 11.746 4.27509C11.9365 4.48673 12.2751 4.48673 12.5079 4.27509Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M15.4497 7.44971H13.82C13.5237 7.44971 13.2698 7.70368 13.2698 7.99997C13.2698 8.29627 13.5237 8.55024 13.82 8.55024H15.4497C15.746 8.55024 15.9999 8.29627 15.9999 7.99997C15.9999 7.70368 15.746 7.44971 15.4497 7.44971Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M12.4868 11.7246C12.2752 11.513 11.9365 11.513 11.7249 11.7246C11.5133 11.9363 11.5133 12.2749 11.7249 12.4866L12.8889 13.6506C13.1006 13.8622 13.4392 13.8622 13.6508 13.6506C13.8625 13.4389 13.8625 13.1003 13.6508 12.8887L12.4868 11.7246Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M7.99997 13.27C7.70368 13.27 7.44971 13.524 7.44971 13.8203V15.4499C7.44971 15.7462 7.70368 16.0002 7.99997 16.0002C8.29627 16.0002 8.55024 15.7462 8.55024 15.4499V13.8203C8.55024 13.524 8.29627 13.27 7.99997 13.27Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M3.49206 11.7246L2.32804 12.8887C2.1164 13.1003 2.1164 13.4389 2.32804 13.6506C2.53968 13.8622 2.87831 13.8622 3.08995 13.6506L4.25397 12.4866C4.46561 12.2749 4.46561 11.9363 4.25397 11.7246C4.06349 11.513 3.72487 11.513 3.49206 11.7246Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M2.73016 7.99997C2.73016 7.70368 2.47619 7.44971 2.17989 7.44971H0.550264C0.253968 7.44971 0 7.70368 0 7.99997C0 8.29627 0.253968 8.55024 0.550264 8.55024H2.17989C2.47619 8.55024 2.73016 8.29627 2.73016 7.99997Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M3.49206 4.27509C3.7037 4.48673 4.04233 4.48673 4.25397 4.27509C4.46561 4.06345 4.46561 3.72482 4.25397 3.51318L3.08995 2.34916C2.87831 2.13752 2.53968 2.13752 2.32804 2.34916C2.1164 2.5608 2.1164 2.89942 2.32804 3.11107L3.49206 4.27509Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }