var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { staticClass: "spinner", attrs: { viewBox: "0 0 50 50" } },
    [
      _c("circle", {
        staticClass: "path",
        attrs: {
          cx: "25",
          cy: "25",
          r: "20",
          fill: "none",
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }