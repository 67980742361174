var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-area" }, [
    _vm.withSearch
      ? _c("div", { staticClass: "filter-area" }, [
          _c(
            "div",
            { staticClass: "input-area" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter.search,
                    expression: "filter.search",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("analytics.search_address_or_name"),
                },
                domProps: { value: _vm.filter.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.filter, "search", $event.target.value)
                    },
                    _vm.searchModel,
                  ],
                },
              }),
              _vm._v(" "),
              _c("Search"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.modelLoading && _vm.modelList.length > 0
      ? _c(
          "div",
          { staticClass: "table-item" },
          [
            _c("div", { staticClass: "header row" }, [
              _c(
                "div",
                {
                  staticClass: "col sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("name")
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.name")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.filter.orderField === "name"
                    ? _c(
                        "div",
                        {
                          class: {
                            "sort-icon": true,
                            active: _vm.filter.order === "ASC",
                          },
                        },
                        [_c("ChevronDown")],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.symbol")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("liquidity")
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.liquidity")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.filter.orderField === "liquidity"
                    ? _c(
                        "div",
                        {
                          class: {
                            "sort-icon": true,
                            active: _vm.filter.order === "ASC",
                          },
                        },
                        [_c("ChevronDown")],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("volume_24h")
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.volume_24h")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.filter.orderField === "volume_24h"
                    ? _c(
                        "div",
                        {
                          class: {
                            "sort-icon": true,
                            active: _vm.filter.order === "ASC",
                          },
                        },
                        [_c("ChevronDown")],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("price")
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.price")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.filter.orderField === "price"
                    ? _c(
                        "div",
                        {
                          class: {
                            "sort-icon": true,
                            active: _vm.filter.order === "ASC",
                          },
                        },
                        [_c("ChevronDown")],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("price_change")
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.price_change")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.filter.orderField === "price_change"
                    ? _c(
                        "div",
                        {
                          class: {
                            "sort-icon": true,
                            active: _vm.filter.order === "ASC",
                          },
                        },
                        [_c("ChevronDown")],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.modelList, function (token, index) {
              return _c("div", { key: index, staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col small-visible" },
                  [
                    _c("div", { staticClass: "number" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.filter.step * (_vm.filter.page - 1) + index + 1
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "logo",
                        attrs: {
                          to: _vm.localePath(
                            "/analytics/tokens/" + token.address
                          ),
                        },
                      },
                      [
                        _c("nuxt-img", {
                          attrs: {
                            fit: "inside",
                            src: _vm.$getImage(token.logoURI),
                            alt: token.name,
                            title: token.symbol,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "name",
                        attrs: {
                          to: _vm.localePath(
                            "/analytics/tokens/" + token.address
                          ),
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(token.name) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.symbol")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          token.symbol !== null && token.symbol !== undefined
                            ? token.symbol.toUpperCase()
                            : "..."
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.liquidity")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      "\n          $" +
                        _vm._s(_vm.$getBigNumber(token.liquidity)) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.volume_24h")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      "\n          $" +
                        _vm._s(_vm.$getBigNumber(token.volume)) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.price")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      "\n          $" +
                        _vm._s(_vm.$priceFormat(token.usd_price, 2, ",", ".")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col small-visible xs-hidden" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.price_change")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getNumberSign(token.price_change)) +
                        "\n          " +
                        _vm._s(
                          _vm.$priceFormat(token.price_change, 2, ",", ".")
                        ) +
                        "%\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col accordion small-visible",
                    on: {
                      click: function ($event) {
                        return _vm.openAccordion(index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          switcher: true,
                          active:
                            _vm.accordions[index] !== undefined &&
                            _vm.accordions[index] === true,
                        },
                      },
                      [
                        _vm.accordions[index] !== undefined &&
                        _vm.accordions[index] === true
                          ? _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.hide")) +
                                  "\n          "
                              ),
                            ])
                          : _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.show")) +
                                  "\n          "
                              ),
                            ]),
                        _vm._v(" "),
                        _c("ChevronDown"),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "accordionArea" + index,
                    refInFor: true,
                    staticClass: "accordion-area mobile-visible",
                  },
                  [
                    _c("div", { staticClass: "all-params" }, [
                      _c("div", { staticClass: "item" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.symbol")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                token.symbol !== null &&
                                  token.symbol !== undefined
                                  ? token.symbol.toUpperCase()
                                  : "..."
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.liquidity")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n              $" +
                              _vm._s(_vm.$getBigNumber(token.liquidity)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.volume_24h")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n              $" +
                              _vm._s(_vm.$getBigNumber(token.volume)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item no-border" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.price")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n              $" +
                              _vm._s(
                                _vm.$priceFormat(token.usd_price, 2, ",", ".")
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item xs-visible no-border" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.price_change")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getNumberSign(token.price_change)) +
                              "\n              " +
                              _vm._s(
                                _vm.$priceFormat(
                                  token.price_change,
                                  2,
                                  ",",
                                  "."
                                )
                              ) +
                              "%\n            "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ])
            }),
          ],
          2
        )
      : _vm.modelLoading
      ? _c(
          "div",
          { staticClass: "table-item" },
          [
            _c("div", { staticClass: "header row" }, [
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.name")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.symbol")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.liquidity")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.volume_24h")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.price")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.price_change")) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(3, function (index) {
              return _c("div", { key: index, staticClass: "row" }, [
                _c("div", { staticClass: "col small-visible" }, [
                  _c("div", { staticClass: "number" }, [
                    _vm._v("\n          " + _vm._s(index + 1) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "40px", height: "40px", circle: "" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "70px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "40px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "60px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "60px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "80px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col small-visible xs-hidden" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "80px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            }),
          ],
          2
        )
      : _c("div", { staticClass: "tablie-item" }, [
          _c("div", { staticClass: "not-found-area" }, [
            _c("div", { staticClass: "h3" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    !_vm.watchlistEnabled
                      ? _vm.$t("analytics.no_top_tokens")
                      : _vm.$t("analytics.saved_tokens")
                  ) +
                  "\n      "
              ),
            ]),
          ]),
        ]),
    _vm._v(" "),
    _vm.modelList.length > 0 && _vm.withPagination
      ? _c(
          "div",
          { class: { "pagination-area": true, disabled: _vm.modelLoading } },
          [
            _c("Pagination", {
              attrs: {
                page: _vm.filter.page,
                step: _vm.filter.step,
                total: _vm.modelTotal,
              },
              on: { change: _vm.setPage },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }