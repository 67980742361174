var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.6994 6.48559C13.9894 6.77551 13.9894 7.24546 13.6994 7.53523L8.72041 12.5144C8.4305 12.8042 7.96069 12.8042 7.67077 12.5144L5.30055 10.1441C5.01064 9.85428 5.01064 9.38432 5.30055 9.09455C5.59032 8.80463 6.06028 8.80463 6.35005 9.09455L8.19552 10.94L12.6498 6.48559C12.9397 6.19582 13.4097 6.19582 13.6994 6.48559ZM19 9.5C19 14.7511 14.7504 19 9.5 19C4.24888 19 0 14.7504 0 9.5C0 4.24888 4.2496 0 9.5 0C14.7511 0 19 4.2496 19 9.5ZM17.5156 9.5C17.5156 5.06934 13.9301 1.48438 9.5 1.48438C5.06934 1.48438 1.48438 5.06992 1.48438 9.5C1.48438 13.9307 5.06992 17.5156 9.5 17.5156C13.9307 17.5156 17.5156 13.9301 17.5156 9.5Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }