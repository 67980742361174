export const state = () => ({
  transactions: [],
  total: 0,
  transactionsLoading: false,
  transaction: null,
  transactionLoading: false,
  countTransactions: 0,
  countLoading: false
})

export const mutations = {
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setTotal(state, total) {
    state.total = total
  },
  setCountTransactions(state, total) {
    state.countTransactions = total
  },
  addTransaction(state, transactions) {
    state.transactions = state.transactions.concat(transactions)
  },
  removeTransaction(state, transactionIndex) {
    const itemIndex = state.transactions.findIndex((transaction, index) => index === transactionIndex)
    if (itemIndex !== -1) {
      state.transactions.splice(transactionIndex, 1)
    }
  },
  setLoadingTransactions(state, status) {
    state.transactionsLoading = status
  },
  setTransaction(state, transaction) {
    state.transaction = transaction
  },
  setLoadingTransaction(state, status) {
    state.transactionLoading = status
  },
  setLoadingCount(state, status) {
    state.countLoading = status
  }
}

export const actions = {
  async fetchTransactions({
    commit
  }, payload) {
    commit('setLoadingTransactions', true)
    const request = await this.$generateApiRequest()
    const txns = await request.$get('transactions', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        status: payload.status !== undefined && payload.status !== null ? payload.status : null,
        uid: payload.uid !== undefined && payload.uid !== null ? payload.uid : null,
        type: payload.type !== undefined && payload.type !== null ? payload.type : null,
        tokens: payload.tokens !== undefined && payload.tokens !== null ? payload.tokens : null,
        search: payload.search !== undefined && payload.search !== null ? payload.search : ''
      }
    })
    commit('setTransactions', txns.answer.transactions)
    commit('setTotal', txns.answer.total)
    commit('setLoadingTransactions', false)
    this.$refreshCookieExpires()
  },
  async addTransaction({
    commit
  }, payload) {
    commit('setLoadingTransaction', true)
    const request = await this.$generateApiRequest()
    await request.$post('transactions', {
      uid: payload.identity,
      bid: payload.chainId,
      tid0: payload.tid0,
      tid1: payload.tid1,
      tid0_token: payload.tid0_token !== undefined ? payload.tid0_token : null,
      tid1_token: payload.tid1_token !== undefined ? payload.tid1_token : null,
      tx: payload.hash !== undefined && payload.hash !== null ? payload.hash : '',
      t0_amount: payload.t0_amount !== undefined && payload.t0_amount !== null ? payload.t0_amount : '',
      t1_amount: payload.t1_amount !== undefined && payload.t1_amount !== null ? payload.t1_amount : null,
      title: payload.summary !== undefined && payload.summary !== null ? payload.summary : '',
      type: payload.type !== undefined && payload.type !== null ? payload.type : '',
      status: payload.status !== undefined && payload.status !== null ? (payload.status === 'success' ? 1 : 0) : 0
    })
    commit('addTransaction', payload)
    commit('setLoadingTransaction', false)
    this.$refreshCookieExpires()
  },
  async editTransaction({
    commit
  }, payload) {
    commit('setLoadingTransaction', true)
    const request = await this.$generateApiRequest()
    await request.$put('transactions/' + payload.hash + '/status', {
      status: payload.status !== undefined && payload.status !== null ? payload.status : 0
    })
    commit('setLoadingTransaction', false)
    this.$refreshCookieExpires()
  },
  async fetchTransaction({
    commit
  }, payload) {
    commit('setLoadingTransaction', true)
    const request = await this.$generateApiRequest()
    const tx = await request.$get('transactions/' + payload.tid)
    commit('setTransaction', tx.answer.tx)
    commit('setLoadingTransaction', false)
  },
  async fetchTransactionsCount({
    commit
  }, payload) {
    commit('setLoadingCount', true)
    const request = await this.$generateApiRequest()
    const tx = await request.$get('transactions/count', {
      params: {
        days: payload.days !== undefined ? payload.days : 0,
        model: payload.model !== undefined ? payload.model : null,
        address: payload.address !== undefined ? payload.address : null
      }
    })
    commit('setCountTransactions', tx.answer.count)
    commit('setLoadingCount', false)
  }
}

export const getters = {
  transactions: state => state.transactions,
  transaction: state => state.transaction,
  total: state => state.total,
  transactionsLoading: state => state.transactionsLoading,
  transactionLoading: state => state.transactionLoading,
  countTransactions: state => state.countTransactions,
  countLoading: state => state.countLoading
}
