var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 6.3499999 6.3500002",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:svg": "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { transform: "translate(0 -290.65)" } }, [
        _c("path", {
          attrs: {
            fill: "currentColor",
            d: "m.99803884 292.14575-.100769-.42219c-.027826-.12598-.1424437-.21353-.2713013-.20723-.1676123.008-.28569659.16771-.2439128.33022l.2635499 1.0914c.034213.14167.1765313.229.3183268.19534l1.09192296-.26148c.3438205-.0834.2187636-.5991-.1250569-.51573l-.5250325.1266c.3634816-.45901.8991221-.77976 1.5285887-.84697 1.2447641-.13292 2.3558162.76493 2.488737 2.00969.028588.36279.5765208.3037.5270998-.0568-.1530798-1.43356-1.3734172-2.50112-2.9277284-2.48641-.9124758.0902-1.5757793.48172-2.02442426 1.0436zm3.29178876 2.62465c-.3579755.0733-.2287844.6122.1235067.51521l.5245158-.1266c-.3634835.45886-.899056.7782-1.5285889.84542-1.244765.13292-2.3552993-.76286-2.48822036-2.00763-.012748-.13856-.1306388-.24358-.2697509-.24029-.15631.004-.2750712.14197-.2557984.29714.1632871 1.52913 1.54096696 2.64169 3.07009706 2.4784.7693279-.0821 1.4318755-.47491 1.8805054-1.03611l.1023194.42271c.064434.37006.6238184.23612.5136637-.12299l-.2619997-1.09192c-.029792-.12595-.1463125-.21186-.2979145-.19921z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }