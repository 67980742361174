var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 176 90",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M86.1963 1.70657L86.2018 1.70532C87.4042 1.43154 88.5983 1.43154 89.8007 1.70533L89.8055 1.70641L161.029 17.6749L161.029 17.675C169.31 19.5295 174.5 25.0523 174.5 30.885L174.5 87.4292C174.5 87.937 174.376 88.0627 174.354 88.0853C174.354 88.0856 174.353 88.0859 174.353 88.0862C174.299 88.1415 174.122 88.2717 173.597 88.3612C173.078 88.4499 172.402 88.4696 171.523 88.4572C171.22 88.4529 170.883 88.4445 170.525 88.4355C169.919 88.4203 169.255 88.4037 168.608 88.4037L7.39493 88.4037C6.61505 88.4037 5.85016 88.4323 5.16383 88.458C4.91583 88.4673 4.6781 88.4762 4.45361 88.4833C3.56549 88.5111 2.88635 88.5095 2.36078 88.4359C1.82747 88.3611 1.6648 88.2385 1.62741 88.2019C1.61484 88.1896 1.59145 88.1645 1.56606 88.0923C1.53738 88.0108 1.50247 87.8564 1.50247 87.5923L1.50248 30.7771L1.50248 30.7612L1.50214 30.7452C1.38125 25.0564 6.53149 19.5337 14.8309 17.675L86.1963 1.70657Z",
          stroke: "currentColor",
          "stroke-width": "3",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }