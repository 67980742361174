var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 511.752 511.752",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "m59.773 246.831h61.786c9.386 0 9.813-15 0-15h-61.786c-9.385 0-9.813 15 0 15z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "m151.56 269.831h-91.787c-9.386 0-9.813 15 0 15h91.786c9.386 0 9.814-15 .001-15z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "m452.227 231.831h-61.787c-9.386 0-9.813 15 0 15h61.786c9.386 0 9.813-15 .001-15z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "m452.227 269.831h-91.787c-9.386 0-9.813 15 0 15h91.786c9.386 0 9.813-15 .001-15z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "m511.752 138.654c0-21.292-18.405-35.596-35.596-35.596h-83.527c-.025-9.462-5.789-17.964-14.704-21.437-16.384-6.384-44.64-22.822-49.804-61.18-1.556-11.565-11.438-20.286-22.989-20.286h-98.266c-11.551 0-21.434 8.721-22.989 20.285-5.164 38.358-33.42 54.797-49.804 61.181-8.915 3.473-14.678 11.975-14.703 21.437h-83.774c-19.628.001-35.596 15.968-35.596 35.596v248.651c0 19.628 15.968 35.595 35.596 35.595h174.906l-8.305 73.695h-29.945c-9.386 0-9.813 15 0 15h167.247c9.386 0 9.813-15 0-15h-29.944l-8.305-73.695h87.75c9.386 0 9.813-15 0-15h-353.404c-11.357 0-20.596-9.238-20.596-20.594v-31.962h481.752v31.962c0 11.356-9.239 20.595-20.596 20.595h-45.156c-9.386 0-9.813 15 0 15h45.156c19.628 0 35.596-15.968 35.596-35.595zm-225.597 284.246 8.305 73.695h-77.168l8.305-73.695zm-146.635-327.301c31.605-12.314 54.657-39.223 59.224-73.156.568-4.222 3.984-7.286 8.124-7.286h98.266c4.14 0 7.556 3.064 8.124 7.286 4.722 33.874 27.594 60.832 59.224 73.156 3.23 1.259 5.348 4.551 5.149 8.005-2.938 51.104-24.759 98.083-64.858 139.636-24.088 24.961-47.916 40.35-56.713 45.632-8.807-5.282-32.658-20.671-56.771-45.635-40.139-41.552-61.979-88.53-64.917-139.633-.2-3.455 1.917-6.747 5.148-8.005zm-124.52 244.745v-201.69c0-11.356 9.239-20.596 20.596-20.596h85.038c6.051 49.208 29.445 95.962 68.212 135.959 31.819 32.829 62.379 49.434 63.664 50.124 1.109.596 2.33.894 3.551.894 1.222 0 2.444-.299 3.553-.895 1.284-.691 31.815-17.296 63.604-50.126 38.729-39.996 62.102-86.749 68.15-135.956h84.79c9.723 0 20.596 8.808 20.596 20.596v201.689h-481.754z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "m165.787 116.463c29.063-15.649 49.653-40.668 58.706-71.307h63.014c9.053 30.639 29.638 55.654 58.697 71.304-10.253 70.421-63.229 116.984-90.11 136.442-9.816-7.104-22.973-17.681-36.146-31.462-2.862-2.994-7.609-3.103-10.604-.239-2.994 2.862-3.101 7.61-.239 10.604 16.063 16.804 32.097 29.098 42.718 36.451 1.284.89 2.777 1.334 4.27 1.334 1.49 0 2.981-.443 4.265-1.33 27.323-18.885 91.901-71.813 101.416-155.217.359-3.156-1.309-6.198-4.165-7.591-29.37-14.323-49.638-39.03-57.067-69.569-.817-3.361-3.828-5.728-7.287-5.728h-74.506c-3.459 0-6.47 2.366-7.287 5.728-7.43 30.539-27.697 55.246-57.071 69.57-2.854 1.392-4.522 4.431-4.165 7.586 3.48 30.722 14.67 60.103 33.256 87.325 2.336 3.42 7.001 4.3 10.424 1.965 3.42-2.336 4.3-7.003 1.965-10.424-16.159-23.66-26.266-49.015-30.084-75.442z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "m256 202.266c10.954 0 19.865-8.912 19.865-19.866v-29.364c9.684-6.533 15.529-17.377 15.529-29.3 0-20.212-17.772-36.69-37.892-35.309-17.126 1.183-31.205 14.956-32.75 32.039-1.182 13.089 4.76 25.403 15.382 32.569v29.364c.001 10.955 8.912 19.867 19.866 19.867zm-20.308-80.45c.888-9.824 8.989-17.745 18.844-18.425 11.596-.805 21.858 8.638 21.858 20.344 0 7.807-4.353 14.813-11.358 18.283-2.555 1.266-4.171 3.87-4.171 6.721v33.66c0 6.29-9.73 6.29-9.73 0v-33.66c0-2.851-1.616-5.455-4.17-6.721-7.615-3.772-12.04-11.703-11.273-20.202z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }