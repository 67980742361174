var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594\n          -662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31\n          -565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220\n          412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 276 45\n          498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132\n          296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297 -180\n          476 -132 269 -269 460 -489 681 -221 220 -412 357 -681 489 -246 121 -474 193\n          -740 235 -147 23 -475 34 -615 20z m468 -401 c488 -53 934 -270 1288 -623 415\n          -415 634 -943 634 -1526 0 -583 -219 -1111 -634 -1526 -415 -415 -943 -634\n          -1526 -634 -583 0 -1111 219 -1526 634 -415 415 -634 943 -634 1526 0 583 219\n          1111 634 1526 349 349 800 569 1276 623 110 12 376 13 488 0z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3512 3478 c-30 -16 -224 -224 -653 -697 l-612 -673 -316 315 c-281\n          281 -321 317 -361 327 -91 24 -182 -17 -224 -102 -31 -62 -33 -108 -6 -166 25\n          -54 760 -806 822 -839 49 -27 111 -30 168 -7 32 13 170 159 736 782 473 520\n          703 781 717 811 72 162 -115 333 -271 249z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }