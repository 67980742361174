var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 1044 1056",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { filter: "url(#filter0_f_102_30)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "623.832",
            cy: "528.213",
            rx: "421.819",
            ry: "275.997",
            transform: "rotate(155.893 623.832 528.213)",
            fill: "url(#paint0_linear_102_30)",
            "fill-opacity": "0.6",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "filter",
            {
              attrs: {
                id: "filter0_f_102_30",
                x: "0.534546",
                y: "0.979736",
                width: "1246.59",
                height: "1054.47",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _vm._v(" "),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape",
                },
              }),
              _vm._v(" "),
              _c("feGaussianBlur", {
                attrs: {
                  stdDeviation: "111",
                  result: "effect1_foregroundBlur_102_30",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_102_30",
                x1: "797.39",
                y1: "799.225",
                x2: "87.7145",
                y2: "145.021",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#2523AA" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#2523AA",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }