<template>
  <div class="menu-area">
    <div :class="`switcher-area ${darkTheme ? 'dark' : 'light'}`" @click="changeTheme">
      <div class="switcher">
        <div class="light">
          <Sun />
        </div>
        <div class="dark">
          <Moon />
        </div>
        <div class="circle">
          <SwitchCircle />
        </div>
      </div>
    </div>
    <div class="menu-item">
      <nuxt-link :to="localePath('/swap')">
        {{ $t('common.swap') }}
      </nuxt-link>
    </div>
    <div class="menu-item">
      <nuxt-link :to="localePath('/liquidity')">
        {{ $t('common.liquidity') }}
      </nuxt-link>
    </div>
    <div class="menu-item">
      <nuxt-link :to="localePath('/farm')">
        {{ $t('common.farm') }}
      </nuxt-link>
    </div>
    <div class="menu-item">
      <nuxt-link :to="localePath('/stake')">
        {{ $t('common.stake') }}
      </nuxt-link>
    </div>
    <div class="menu-item">
      <nuxt-link :to="localePath('/rewards')">
        {{ $t('common.rewards') }}
      </nuxt-link>
    </div>
    <div class="menu-item mr-0">
      <nuxt-link :to="localePath('/account')">
        {{ $t('common.invest') }}
      </nuxt-link>
    </div>
    <div class="divider-line"></div>
    <div class="dropdown">
      <p class="dropdown-header">
        <DropsLine />
      </p>
      <div class="dropdown-area">
        <div class="dropdown-list">
          <nuxt-link :to="localePath('/listing')">
            {{ $t('common.listing') }}
          </nuxt-link>
          <!-- <nuxt-link :to="localePath('/limit')">
            {{ $t('common.limit') }}
          </nuxt-link> -->
          <nuxt-link :to="localePath('/lottery')">
            {{ $t('common.lottery') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/analytics')">
            {{ $t('common.analytics') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/faq')">
            {{ $t('common.faq') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/voting')">
            {{ $t('common.voting') }}
          </nuxt-link>
          <a href="https://medium.com/@info_19215/meeting-with-defipool-5ab219f3be2e" target="_blank" rel="nofollow noreferrer noopener">
            {{ $t('common.blog') }}
          </a>
          <nuxt-link :to="localePath('/docs')">
            {{ $t('common.documentation') }}
          </nuxt-link>
          <a :href="contacts.github" rel="nofollow noopener" target="_blank">
            GitHub
          </a>
          <nuxt-link :to="localePath('/report')">
            {{ $t('common.report') }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

export default {
  components: {
    Moon: hydrateWhenIdle(() => import('~/components/svg/new/Moon')),
    Sun: hydrateWhenIdle(() => import('~/components/svg/new/Sun')),
    SwitchCircle: hydrateWhenIdle(() => import('~/components/svg/new/SwitchCircle')),
    DropsLine: hydrateWhenIdle(() => import('~/components/svg/new/DropsLine'))
  },
  computed: {
    ...mapGetters(['darkTheme']),
    ...mapGetters('seos', [
      'contacts'
    ])
  },
  methods: {
    changeTheme() {
      if (!this.darkTheme) {
        this.$onTheme()
      } else {
        this.$offTheme()
      }
    }
  }
}
</script>

<style>
  @import url('~/assets/css/new/nav.css');
</style>
