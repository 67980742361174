<template>
  <div class="table-area">
    <div v-if="withSearch" class="filter-area">
      <div class="input-area">
        <input v-model="filter.search" type="text" :placeholder="$t('analytics.search_address_or_hash')" @input="searchModel">
        <Search />
      </div>
    </div>
    <div v-if="!transactionsLoading && transactions.length > 0" class="table-item">
      <div class="header row">
        <div class="col wide">
          <div class="range-switch-area">
            <div :class="{'switch-item': true, 'active': filter.type === 'all'}" @click="changeType('all')">
              <span>
                {{ $t('common.all') }}
              </span>
            </div>
            <div :class="{'switch-item': true, 'active': filter.type === 'swap'}" @click="changeType('swap')">
              <span>
                {{ $t('analytics.swaps') }}
              </span>
            </div>
            <div :class="{'switch-item': true, 'active': filter.type === 'add'}" @click="changeType('add')">
              <span>
                {{ $t('analytics.adds') }}
              </span>
            </div>
            <div :class="{'switch-item': true, 'active': filter.type === 'remove'}" @click="changeType('remove')">
              <span>
                {{ $t('analytics.removes') }}
              </span>
            </div>
          </div>
        </div>
        <div class="col sortable">
          <p>
            {{ $t('analytics.total_value') }}
          </p>
          <div
            v-if="filter.orderField === 'total_value'"
            :class="{'sort-icon': true, 'active': filter.order === 'ASC'}"
          >
            <ChevronDown />
          </div>
        </div>
        <div class="col sortable" @click="changeSort('total_amount')">
          <p>
            {{ $t('analytics.total_amount') }}
          </p>
          <div
            v-if="filter.orderField === 'total_amount'"
            :class="{'sort-icon': true, 'active': filter.order === 'ASC'}"
          >
            <ChevronDown />
          </div>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.account') }}
          </p>
        </div>
        <div class="col sortable" @click="changeSort('time')">
          <p>
            {{ $t('analytics.time') }}
          </p>
          <div
            v-if="filter.orderField === 'time'"
            :class="{'sort-icon': true, 'active': filter.order === 'ASC'}"
          >
            <ChevronDown />
          </div>
        </div>
      </div>
      <div v-for="(transaction, index) of transactions" :key="index" class="row">
        <div class="col wide small-visible">
          <div class="number">
            {{ (filter.step * (filter.page - 1)) + index + 1 }}
          </div>
          <div class="logo">
            <nuxt-img
              fit="inside"
              :src="$getImage(transaction.t1_image || transaction.t0_image)"
              :alt="transaction.t1_name || transaction.t1_name"
              :title="transaction.t0_symbol || transaction.t1_symbol"
            />
          </div>
          <div class="name">
            {{ getTxTitle(transaction) }}
          </div>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('analytics.total_value') }}
          </p>
          <div class="name">
            ${{ $getBigNumber(transaction.total) }}
          </div>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('analytics.total_amount') }}
          </p>
          <div class="name">
            <span v-if="transaction.t0_amount !== null">
              {{ $formatCryptoAmount(transaction.t0_amount) }} {{ transaction.t0_symbol !== undefined && transaction.t0_symbol !== null ? transaction.t0_symbol.toUpperCase() : '...' }}
            </span>
            <span v-if="transaction.t1_amount !== null">
              &nbsp;+&nbsp;{{ $formatCryptoAmount(transaction.t1_amount) }} {{ transaction.t1_symbol !== undefined && transaction.t1_symbol !== null ? transaction.t1_symbol.toUpperCase() : '...' }}
            </span>
          </div>
        </div>
        <div class="col">
          <p class="hint">
            {{ $t('analytics.account') }}
          </p>
          <div class="name">
            <a v-if="transaction.user_address !== undefined && transaction.user_address !== null && transaction.user_address.length > 0" class="link" :href="$getExplorerUserLink(transaction.user_address)" rel="nofollow noopener noreferrer" target="_blank">
              {{ $shortenAddress(transaction.user_address) }}
            </a>
            <p v-else class="link">
              {{ $shortenAddress(transaction.user_address) }}
            </p>
          </div>
        </div>
        <div class="col small-visible xs-hidden">
          <p class="hint">
            {{ $t('analytics.time') }}
          </p>
          <a :href="$getExplorerLinkByTx(transaction)" rel="nofollow noopener noreferrer" target="_blank" class="value">
            {{ $timeSince(transaction.created_at) }}
            <NewTabIcon />
          </a>
        </div>
        <div class="col accordion small-visible" @click="openAccordion(index)">
          <div :class="{'switcher': true, 'active': accordions[index] !== undefined && accordions[index] === true}">
            <p v-if="accordions[index] !== undefined && accordions[index] === true">
              {{ $t('common.hide') }}
            </p>
            <p v-else>
              {{ $t('common.show') }}
            </p>
            <ChevronDown />
          </div>
        </div>
        <div :ref="'accordionArea' + index" class="accordion-area mobile-visible">
          <div class="all-params">
            <div class="item">
              <p class="hint">
                {{ $t('analytics.total_value') }}
              </p>
              <div class="name">
                ${{ $getBigNumber(transaction.total) }}
              </div>
            </div>
            <div class="item">
              <p class="hint">
                {{ $t('analytics.total_amount') }}
              </p>
              <div class="name">
                <span v-if="transaction.t0_amount !== null">
                  {{ $formatCryptoAmount(transaction.t0_amount) }} {{ transaction.t0_symbol !== undefined && transaction.t0_symbol !== null ? transaction.t0_symbol.toUpperCase() : '...' }}
                </span>
                <span v-if="transaction.t1_amount !== null">
                  &nbsp;+&nbsp;{{ $formatCryptoAmount(transaction.t1_amount) }} {{ transaction.t1_symbol !== undefined && transaction.t1_symbol !== null ? transaction.t1_symbol.toUpperCase() : '...' }}
                </span>
              </div>
            </div>
            <div class="item no-border">
              <p class="hint">
                {{ $t('analytics.account') }}
              </p>
              <div class="name">
                <a v-if="transaction.user_address !== null && transaction.user_address.length > 0" class="link" :href="$getExplorerUserLink(transaction.user_address)" rel="nofollow noopener noreferrer" target="_blank">
                  {{ $shortenAddress(transaction.user_address) }}
                </a>
                <p v-else class="link">
                  {{ $shortenAddress(transaction.user_address) }}
                </p>
              </div>
            </div>
            <div class="item xs-visible no-border">
              <p class="hint">
                {{ $t('analytics.time') }}
              </p>
              <a :href="$getExplorerLinkByTx(transaction)" rel="nofollow noopener noreferrer" target="_blank" class="name">
                {{ $timeSince(transaction.created_at) }}
                <NewTabIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="transactionsLoading" class="table-item">
      <div class="header row">
        <div class="col wide">
          <Skeleton width="50px" height="35px" style="margin-right: .5rem;" />
          <Skeleton width="50px" height="35px" style="margin-right: .5rem;" />
          <Skeleton width="50px" height="35px" style="margin-right: .5rem;" />
          <Skeleton width="50px" height="35px" />
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.total_value') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.total_amount') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.account') }}
          </p>
        </div>
        <div class="col">
          <p>
            {{ $t('analytics.time') }}
          </p>
        </div>
      </div>
      <div v-for="index in 3" :key="index" class="row">
        <div class="col wide small-visible">
          <div class="number">
            {{ index + 1 }}
          </div>
          <div class="logo">
            <Skeleton width="40px" height="40px" circle />
          </div>
          <div class="name">
            <Skeleton width="70px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="40px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="60px" height="25px" />
          </div>
        </div>
        <div class="col">
          <div class="name">
            <Skeleton width="60px" height="25px" />
          </div>
        </div>
        <div class="col small-visible xs-hidden">
          <div class="name">
            <Skeleton width="80px" height="25px" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tablie-item">
      <div class="not-found-area">
        <div class="h3">
          {{ $t('analytics.no_transactions') }}
        </div>
      </div>
    </div>
    <div v-if="transactions.length > 0 && withPagination" :class="{'pagination-area': true, 'disabled': transactionsLoading}">
      <Pagination :page="filter.page" :step="filter.step" :total="total" @change="setPage" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

import { Skeleton } from 'vue-loading-skeleton'

import Search from '~/components/svg/new/Search'
import NewTabIcon from '~/components/svg/NewTabIcon'

export default {
  components: {
    ChevronDown: hydrateWhenIdle(() => import('~/components/svg/new/ChevronDown')),
    NewTabIcon,
    Search,
    Skeleton,
    Pagination: hydrateWhenIdle(() => import('~/components/analytics/parts/Pagination'))
  },
  props: {
    withSearch: {
      type: Boolean,
      default: () => false
    },
    withPagination: {
      type: Boolean,
      default: () => true
    },
    tokens: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      accordions: [],
      filter: {
        page: 1,
        step: 10,
        orderField: 'created_at_desc',
        search: '',
        type: 'all',
        tokens: this.tokens
      }
    }
  },
  computed: {
    ...mapGetters('transactions', [
      'transactions',
      'total',
      'transactionsLoading'
    ])
  },
  mounted() {
    this.fetchTransactions(this.filter)
  },
  methods: {
    ...mapActions('transactions', [
      'fetchTransactions'
    ]),
    setPage(newPage) {
      this.filter.page = parseInt(newPage, 10)
      this.loadModels()
    },
    loadModels() {
      this.fetchTransactions(this.filter)
    },
    changeSort(orderField) {
      if (!this.filter.orderField.includes(orderField)) {
        this.filter.orderField = orderField + '_desc'
      } else {
        this.filter.orderField = this.filter.orderField.includes('_desc')
          ? this.filter.orderField.replace('_desc', '_asc')
          : this.filter.orderField.replace('_asc', '_desc')
      }
    },
    getNumberSign(number) {
      return number > 0 ? '+' : (number < 0 ? '-' : '')
    },
    changeType(newType) {
      if (this.filter.type !== newType) {
        this.filter.type = newType
      }
    },
    openAccordion(indexClicked) {
      const ind = parseInt(indexClicked, 10)
      this.accordions.forEach((item, index) => {
        this.accordions[index] = false
        if (this.$refs['accordionArea' + index][0] !== undefined) {
          this.$refs['accordionArea' + index][0].style.maxHeight = null
        }
      })
      this.accordions[ind] = true
      if (this.$refs['accordionArea' + ind][0] !== undefined) {
        this.$refs['accordionArea' + ind][0].style.maxHeight = this.$refs['accordionArea' + ind][0].scrollHeight + 'px'
      }
    },
    getTxTitle(tx) {
      if (tx === undefined || tx === null || tx.type === undefined || tx.type === null) {
        return '...'
      }
      switch (tx.type) {
        case 'swap':
          return this.$t('common.swap') + ' ' + tx.t0_symbol + ' ' + this.$t('common.for') + ' ' + tx.t1_symbol
        case 'add':
          return this.$t('pools.add') + ' ' + tx.t0_symbol + ' ' + this.$t('common.and') + ' ' + tx.t1_symbol
        case 'remove':
          return this.$t('pools.remove') + ' ' + tx.t0_symbol + ' ' + this.$t('common.and') + ' ' + tx.t1_symbol
        case 'stake':
          return this.$t('common.stake_action') + ' ' + tx.t0_symbol
        case 'unstake':
          return this.$t('common.unstake_action') + ' ' + tx.t0_symbol
        case 'other':
          return tx.t1_symbol !== null ? this.$t('analytics.txn_with') + ' ' + tx.t0_symbol + ' ' + tx.t1_symbol : this.$t('analytics.txn_with') + ' ' + tx.t0_symbol
      }
    },
    searchModel() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(async () => {
        await this.loadModels()
      }, this.timeOutTime)
    }
  }
}
</script>

<style scoped>
  @import url('../../../assets/css/new/table.css');
</style>
