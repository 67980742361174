<template>
  <div class="pagination-row">
    <div v-if="renderFirstPages" :class="{'item': true, 'active': page === 1}" data-first="1" @click="goToPage(1)">
      <p class="value">
        1
      </p>
    </div>
    <div v-if="renderFirstPages" class="item" data-firsts="firsts...">
      <p class="value">
        ...
      </p>
    </div>
    <div
      v-for="index in renderNumber"
      :key="index"
      :class="{'item': true, 'active': page === getRenderIndex(index - 1)}"
      data-main="main"
      @click="goToPage(getRenderIndex(index - 1))"
    >
      <p class="value">
        {{ getRenderIndex(index - 1) }}
      </p>
    </div>
    <div v-if="renderLastPages" class="item" data-lasts="lasts">
      <p class="value">
        ...
      </p>
    </div>
    <div v-if="renderLastPages || renderLastPage" :class="{'item': true, 'active': page === lastPage}" data-last="last" @click="goToPage(lastPage)">
      <p class="value">
        {{ lastPage }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TablePaginationComponent',
  props: {
    page: {
      type: Number,
      default: () => 1
    },
    step: {
      type: Number,
      default: () => 10
    },
    total: {
      type: Number,
      default: () => 1
    },
    maxNumbers: {
      type: Number,
      default: () => 3
    }
  },
  data() {
    return {
    }
  },
  computed: {
    renderFirstPages() {
      return this.page > Math.round(this.maxNumbers / 2) + 1
    },
    renderLastPages() {
      return (this.lastPage - this.page) > Math.round(this.maxNumbers / 2)
    },
    renderLastPage() {
      return this.lastPage - this.page === Math.round(this.maxNumbers / 2) && this.lastPage > this.maxNumbers
    },
    startPageRender() {
      return this.page > (Math.round(this.maxNumbers / 2) + 1) ? this.page - Math.floor(this.maxNumbers / 2) : 1
    },
    lastPage() {
      return Math.round(this.total / this.step)
    },
    renderNumber() {
      return this.maxNumbers < this.lastPage ? this.maxNumbers : this.lastPage
    }
  },
  methods: {
    goToPage(page) {
      const newPage = parseInt(page, 10)
      if (newPage > 0 && newPage * this.step <= this.total) {
        this.$emit('change', newPage)
      }
    },
    goNextPage() {
      const newPage = this.page + 1
      if (newPage * this.step <= this.total) {
        this.$emit('change', newPage)
      }
    },
    goPreviousPage() {
      const newPage = this.page - 1
      if (newPage > 0) {
        this.$emit('change', newPage)
      }
    },
    getRenderIndex(index) {
      if (this.startPageRender + index <= this.lastPage) {
        return this.startPageRender + index
      } else if (this.startPageRender - 1 > 0 && this.startPageRender + index < this.lastPage) {
        return this.startPageRender - 1
      } else {
        return '-'
      }
    }
  }
}
</script>

<style scoped>
  @import url('../../../assets/css/new/pagination.css');
</style>
