var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "confirm-area" }, [
    !_vm.ticketsLoading && _vm.tickets.length > 0
      ? _c(
          "div",
          { staticClass: "transactions-list" },
          [
            _c("div", { staticClass: "header" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("lottery.your_tickets")) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.tickets, function (ticket, index) {
              return _c(
                "div",
                { key: index, staticClass: "transaction-item" },
                [
                  _c("div", { staticClass: "label" }, [
                    _c("span", [
                      _vm._v(
                        "\n          #" + _vm._s(index + 1) + "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    ticket.tx !== null
                      ? _c("span", { staticClass: "smaller" }, [
                          _vm._v("\n           | \n        "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    ticket.tx !== null
                      ? _c(
                          "a",
                          {
                            staticClass: "link smaller",
                            attrs: {
                              href: ticket.tx,
                              rel: "nofollow noopener noreferrer",
                              target: "_blank",
                            },
                          },
                          [
                            _c("i", { staticClass: "bx bx-link-external" }),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("lottery.scan_link")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "number" },
                    _vm._l(
                      ticket.roll.toString().split(""),
                      function (num, ind) {
                        return _c("div", { key: ind, staticClass: "num" }, [
                          _vm._v("\n          " + _vm._s(num) + "\n        "),
                        ])
                      }
                    ),
                    0
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "buttons-area" }, [
              _c(
                "button",
                {
                  staticClass: "btn cyan btn-outline",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal(false)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("common.close")) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn pink",
                  attrs: { type: "button" },
                  on: { click: _vm.openBuyTickets },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("lottery.buy_tickets_cta")) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          2
        )
      : _vm.ticketsLoading
      ? _c("div", { staticClass: "transactions-list empty-area" }, [
          _c("div", { staticClass: "icon-area" }, [_c("Loading")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "h4" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("common.loading")) + "\n    "),
          ]),
        ])
      : _c("div", { staticClass: "transactions-list" }, [
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("lottery.no_tickets")) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-area" }, [
            _c(
              "button",
              {
                staticClass: "btn cyan btn-outline",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("common.close")) +
                      "\n        "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: { click: _vm.openBuyTickets },
              },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("lottery.buy_tickets_cta")) +
                      "\n        "
                  ),
                ]),
              ]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }