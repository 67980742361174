var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingScreen &&
    !_vm.successScreen &&
    !_vm.errorScreen &&
    _vm.action === "stake"
    ? _c("div", { staticClass: "confirm-area" }, [
        _c("div", { staticClass: "settings-area" }, [
          _c("div", { staticClass: "inputs-area" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", { staticClass: "label-area" }, [
                _c("p", { staticClass: "label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$config.floatieSymbol +
                          " " +
                          _vm.$t("stake.to_stake")
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "balance" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.balance")) +
                      ": " +
                      _vm._s(
                        _vm.pool !== null
                          ? _vm.$formatCryptoAmount(_vm.pool.balance, 6)
                          : "..."
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field-area" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.stakeAmount,
                      expression: "stakeAmount",
                    },
                  ],
                  attrs: { type: "number", step: "0.0000001", min: "0" },
                  domProps: { value: _vm.stakeAmount },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.stakeAmount = $event.target.value
                      },
                      _vm.checkStakeAmount,
                    ],
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "max-button active",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setMax()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("swap.max")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "stake-percent percent-area" }, [
            _c(
              "div",
              { staticClass: "handler" },
              [
                _c("veeno", {
                  ref: "percentSlider",
                  attrs: {
                    connect: "",
                    handles: [0, 0],
                    range: {
                      min: 0,
                      max: 100,
                    },
                    set: [0, parseFloat(_vm.percent)],
                  },
                  on: { change: _vm.updateVeeno, slide: _vm.setSlide },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "range-switch-area" }, [
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 25 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(25)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            25%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 50 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(50)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            50%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 75 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(75)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            75%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 100 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(100)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            100%\n          ")])]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.pool.type !== undefined &&
          _vm.pool.type === "locked" &&
          _vm.pool.ctaType === "stake"
            ? _c("div", { staticClass: "inputs-area" }, [
                _c("div", { staticClass: "input" }, [
                  _c("div", { staticClass: "label-area" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("stake.add_duration")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "balance" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("stake.max_duration")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-area" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.weeksExtend,
                          expression: "weeksExtend",
                        },
                      ],
                      attrs: { type: "number", step: "0.0000001", min: "0" },
                      domProps: { value: _vm.weeksExtend },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.weeksExtend = $event.target.value
                          },
                          _vm.checkweeks,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "max-button active",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.setWeeks(52)
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("swap.max")) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pool.type !== undefined &&
          _vm.pool.type === "locked" &&
          _vm.pool.ctaType === "stake"
            ? _c("div", { staticClass: "stake-percent percent-area m-0 p-0" }, [
                _c("div", { staticClass: "range-switch-area" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.weeksExtend === 1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setWeeks(1)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            1 " +
                            _vm._s(_vm.$t("common.w")) +
                            "\n          "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.weeksExtend === 5,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setWeeks(5)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            5 " +
                            _vm._s(_vm.$t("common.w")) +
                            "\n          "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.weeksExtend === 10,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setWeeks(10)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            10 " +
                            _vm._s(_vm.$t("common.w")) +
                            "\n          "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.weeksExtend === 25,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setWeeks(25)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            25 " +
                            _vm._s(_vm.$t("common.w")) +
                            "\n          "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pool.type !== undefined &&
          _vm.pool.type === "locked" &&
          _vm.pool.ctaType === "add_floatie"
            ? _c("div", { staticClass: "extending-area" }, [
                _c(
                  "div",
                  { class: { "warning-area": true, active: !_vm.extendLock } },
                  [
                    _c(
                      "div",
                      { staticClass: "icon-area" },
                      [_c("WarnCircleIcon")],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-area" }, [
                      _c("p", { staticClass: "desc" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("stake.stake_more_floatie")) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-area" }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.extendLock,
                          expression: "extendLock",
                        },
                      ],
                      attrs: { id: "extendAndRenewLock", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.extendLock)
                          ? _vm._i(_vm.extendLock, null) > -1
                          : _vm.extendLock,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.extendLock,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.extendLock = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.extendLock = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.extendLock = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "extendAndRenewLock" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("stake.renew_and_receive")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pool.type !== undefined && _vm.pool.type === "locked"
            ? _c("hr")
            : _vm._e(),
          _vm._v(" "),
          _vm.pool.type !== undefined && _vm.pool.type === "locked"
            ? _c("div", { staticClass: "modal-table" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "text-area" }, [
                    _c("p", { staticClass: "hint" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("stake.to_be_staked")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value-area" }, [
                    _vm.extendLock
                      ? _c("div", { staticClass: "h6 highlighted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.pool.info.lockedAmount + _vm.stakeAmount >
                                  1000
                                  ? _vm.$priceFormat(
                                      _vm.pool.info.lockedAmount,
                                      2
                                    ) +
                                      " -> " +
                                      _vm.$priceFormat(
                                        _vm.pool.info.lockedAmount +
                                          _vm.stakeAmount,
                                        2
                                      )
                                  : _vm.$formatCryptoAmount(
                                      _vm.pool.info.lockedAmount
                                    ) +
                                      " -> " +
                                      _vm.$formatCryptoAmount(
                                        _vm.pool.info.lockedAmount +
                                          _vm.stakeAmount
                                      )
                              ) +
                              " " +
                              _vm._s(_vm.pool.token.symbol) +
                              "\n          "
                          ),
                        ])
                      : _c("div", { staticClass: "h6" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.stakeAmount > 1000
                                  ? _vm.$priceFormat(_vm.stakeAmount, 2)
                                  : _vm.$formatCryptoAmount(_vm.stakeAmount)
                              ) +
                              " " +
                              _vm._s(_vm.pool.token.symbol) +
                              "\n          "
                          ),
                        ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "text-area" }, [
                    _c("p", { staticClass: "hint" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("stake.duration")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value-area" }, [
                    _c(
                      "div",
                      { class: { h6: true, highlighted: _vm.extendLock } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.extendLock
                                ? "-> " + _vm.getDepositPeriod()
                                : "-"
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "text-area" }, [
                    _c("p", { staticClass: "hint" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("stake.unlock_on")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value-area" }, [
                    !_vm.extendLock
                      ? _c("div", { staticClass: "h6" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getUnlockDate()) +
                              "\n          "
                          ),
                        ])
                      : _c("div", { staticClass: "h6 highlighted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getNewUnlockDate()) +
                              "\n          "
                          ),
                        ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "button",
            {
              class: {
                "btn cyan": true,
                disabled: _vm.loading || _vm.stakeAmount < 0.00001,
              },
              attrs: {
                type: "button",
                disabled: _vm.loading || _vm.stakeAmount < 0.00001,
              },
              on: { click: _vm.confirmStake },
            },
            [
              !_vm.loading
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("stake.confirm_stake")) +
                        "\n      "
                    ),
                  ])
                : _c("span", [_c("Loading")], 1),
            ]
          ),
        ]),
      ])
    : !_vm.loadingScreen &&
      !_vm.successScreen &&
      !_vm.errorScreen &&
      _vm.action === "unstake"
    ? _c("div", { staticClass: "confirm-area" }, [
        _c("div", { staticClass: "staking-area" }, [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "token-area" }, [
              _c("img", {
                attrs: {
                  src:
                    _vm.pool.token.address !== _vm.$config.ZERO
                      ? _vm.$getImageByAddress(_vm.pool.token.address)
                      : _vm.$getImage(_vm.pool.token.logoURI),
                  alt: "$FLOATIE stake pool for earning auto flexible rewards",
                  title: "$FLOATIE stake pool",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [
                _c("div", { staticClass: "h2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$formatCryptoAmount(parseFloat(_vm.stakeAmount))
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "h4" }, [
                  _vm._v(
                    "\n            / " +
                      _vm._s(_vm.convertToUsd(_vm.stakeAmount)) +
                      " USD\n          "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "symbol" }, [
              _vm._v("\n        " + _vm._s(_vm.pool.token.symbol) + "\n      "),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "settings-area" }, [
          _c("div", { staticClass: "inputs-area" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", { staticClass: "label-area" }, [
                _c("p", { staticClass: "label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$config.floatieSymbol +
                          " " +
                          _vm.$t("stake.to_unstake")
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "balance" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.balance")) +
                      ": " +
                      _vm._s(
                        _vm.pool !== null
                          ? _vm.$formatCryptoAmount(
                              _vm.pool.info.lockedAmount + _vm.pool.earned,
                              6
                            )
                          : "..."
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field-area" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.stakeAmount,
                      expression: "stakeAmount",
                    },
                  ],
                  attrs: { type: "number", step: "0.0000001", min: "0" },
                  domProps: { value: _vm.stakeAmount },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.stakeAmount = $event.target.value
                      },
                      _vm.checkStakeAmount,
                    ],
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "max-button active",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setMax()
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "text" }, [
                      _vm._v("\n              MAX\n            "),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "stake-percent percent-area" }, [
            _c(
              "div",
              { staticClass: "handler" },
              [
                _c("veeno", {
                  ref: "percentSlider",
                  attrs: {
                    connect: "",
                    handles: [0, 0],
                    range: {
                      min: 0,
                      max: 100,
                    },
                    set: [0, parseFloat(_vm.percent)],
                  },
                  on: { change: _vm.updateVeeno, slide: _vm.setSlide },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "range-switch-area" }, [
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 25 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(25)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            25%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 50 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(50)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            50%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 75 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(75)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            75%\n          ")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.percent === 100 },
                  on: {
                    click: function ($event) {
                      return _vm.setPercent(100)
                    },
                  },
                },
                [_c("span", [_vm._v("\n            100%\n          ")])]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: {
              "warning-area": true,
              active: _vm.pool.info.unstakeFee !== 0,
            },
          },
          [
            _c("div", { staticClass: "icon-area" }, [_c("WarnCircleIcon")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("stake.unstaking_fee_active", {
                        fee: _vm.pool.info.unstakeFee,
                      })
                    ) +
                    "\n      "
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "button",
            {
              class: { "btn cyan": true, disabled: _vm.loading },
              attrs: { type: "button", disabled: _vm.loading },
              on: { click: _vm.confirmUnStake },
            },
            [
              !_vm.loading
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("stake.confirm_unstake")) +
                        "\n      "
                    ),
                  ])
                : _c("span", [_c("Loading")], 1),
            ]
          ),
        ]),
      ])
    : !_vm.loadingScreen &&
      !_vm.successScreen &&
      !_vm.errorScreen &&
      _vm.action === "extend"
    ? _c("div", { staticClass: "confirm-area" }, [
        _c("div", { staticClass: "settings-area" }, [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "token-area" }, [
              _c("img", {
                attrs: {
                  src: "/img/new/floatie_item.svg",
                  alt: "$FLOATIE stake pool for earning auto flexible rewards",
                  title: "$FLOATIE stake pool",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [
                _c("div", { staticClass: "h2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$formatCryptoAmount(
                          parseFloat(_vm.pool.info.lockedAmount),
                          6
                        )
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "h4" }, [
                  _vm._v(
                    "\n            / " +
                      _vm._s(
                        _vm.$priceFormat(
                          _vm.convertToUsd(_vm.pool.info.lockedAmount),
                          2
                        )
                      ) +
                      " USD\n          "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "symbol" }, [
              _vm._v("\n        " + _vm._s(_vm.pool.token.symbol) + "\n      "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputs-area" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", { staticClass: "label-area" }, [
                _c("p", { staticClass: "label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("stake.add_duration")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "balance" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("stake.max_duration")) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field-area" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.weeksExtend,
                      expression: "weeksExtend",
                    },
                  ],
                  attrs: { type: "number", step: "0.0000001", min: "0" },
                  domProps: { value: _vm.weeksExtend },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.weeksExtend = $event.target.value
                      },
                      _vm.checkweeks,
                    ],
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "max-button active",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setWeeks(52)
                      },
                    },
                  },
                  [
                    _c("p", { staticClass: "text" }, [
                      _vm._v("\n              MAX\n            "),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "stake-percent percent-area m-0 p-0" }, [
            _c("div", { staticClass: "range-switch-area" }, [
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.weeksExtend === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.setWeeks(1)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n            1 " +
                        _vm._s(_vm.$t("common.w")) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { "switch-item": true, active: _vm.weeksExtend === 5 },
                  on: {
                    click: function ($event) {
                      return _vm.setWeeks(5)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n            5 " +
                        _vm._s(_vm.$t("common.w")) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "switch-item": true,
                    active: _vm.weeksExtend === 10,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setWeeks(10)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n            10 " +
                        _vm._s(_vm.$t("common.w")) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "switch-item": true,
                    active: _vm.weeksExtend === 25,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setWeeks(25)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n            25 " +
                        _vm._s(_vm.$t("common.w")) +
                        "\n          "
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "modal-table" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "text-area" }, [
              _c("p", { staticClass: "hint" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("stake.to_be_staked")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value-area" }, [
              _c("div", { staticClass: "h6" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.pool.info.lockedAmount > 1000
                        ? _vm.$priceFormat(_vm.pool.info.lockedAmount, 2)
                        : _vm.$formatCryptoAmount(_vm.pool.info.lockedAmount)
                    ) +
                    " " +
                    _vm._s(_vm.pool.token.symbol) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "text-area" }, [
              _c("p", { staticClass: "hint" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("stake.duration")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value-area" }, [
              _c("div", { staticClass: "h6 highlighted" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.extendedWeeksPeriod) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "text-area" }, [
              _c("p", { staticClass: "hint" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("stake.unlock_on")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value-area" }, [
              _c("div", { staticClass: "h6 highlighted" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.extendedWeeksDate) + "\n        "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "button",
            {
              class: {
                "btn cyan": true,
                disabled: _vm.loading || _vm.weeksExtend <= 0,
              },
              attrs: {
                type: "button",
                disabled: _vm.loading || _vm.weeksExtend <= 0,
              },
              on: { click: _vm.confirmExtend },
            },
            [
              !_vm.loading
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("stake.confirm_extend")) +
                        "\n      "
                    ),
                  ])
                : _c("span", [_c("Loading")], 1),
            ]
          ),
        ]),
      ])
    : _vm.loadingScreen
    ? _c(
        "div",
        { staticClass: "loading-area" },
        [
          _c("ModalLoader"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _vm.action === "stake"
              ? _c("div", { staticClass: "h5" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("stake.staking")) +
                      "\n      " +
                      _vm._s(
                        _vm.$formatCryptoAmount(parseFloat(_vm.stakeAmount)) +
                          " " +
                          _vm.pool.token.symbol
                      ) +
                      "\n      " +
                      _vm._s(_vm.$t("common.to_the_pool")) +
                      "\n    "
                  ),
                ])
              : _vm.action === "unstake"
              ? _c("div", { staticClass: "h5" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("stake.unstaking")) +
                      "\n      " +
                      _vm._s(
                        (parseFloat(_vm.stakeAmount) > 1000
                          ? _vm.$priceFormat(parseFloat(_vm.stakeAmount), 2)
                          : _vm.$formatCryptoAmount(
                              parseFloat(_vm.stakeAmount)
                            )) +
                          " " +
                          _vm.pool.token.symbol
                      ) +
                      "\n      " +
                      _vm._s(_vm.$t("common.from_the_pool")) +
                      "\n    "
                  ),
                ])
              : _vm.action === "extend"
              ? _c("div", { staticClass: "h5" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("stake.extending_for") + " " + _vm.weeksExtend
                      ) +
                      " \n      " +
                      _vm._s(
                        _vm.$num2str(
                          [
                            _vm.$t("common.week"),
                            _vm.$t("common.weeks"),
                            _vm.$t("common.many_weeks"),
                          ],
                          _vm.weeksExtend
                        )
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("swap.confirm_transaction")) +
                  "\n    "
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm.successScreen
    ? _c("div", { staticClass: "success-area" }, [
        _c("div", { staticClass: "icon-area" }, [_c("CheckIcon")], 1),
        _vm._v(" "),
        _vm.action === "stake"
          ? _c("div", { staticClass: "desc-area" }, [
              _c("div", { staticClass: "h4" }, [
                _vm._v("\n      " + _vm._s(_vm.$t("stake.success")) + "\n    "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("stake.success_text")) + "\n    "
                ),
              ]),
            ])
          : _vm.action === "unstake"
          ? _c("div", { staticClass: "desc-area" }, [
              _c("div", { staticClass: "h4" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("stake.success_unstake")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("stake.success_unstake_text")) +
                    "\n    "
                ),
              ]),
            ])
          : _vm.action === "extend"
          ? _c("div", { staticClass: "desc-area" }, [
              _c("div", { staticClass: "h4" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("stake.success_extend")) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("stake.success_extend_text")) +
                    "\n    "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-outline pink",
              attrs: {
                href: _vm.transactionLink(_vm.transaction.tx),
                rel: "nofollow noopener noreferrer",
                target: "_blank",
              },
            },
            [
              _c("span", { staticClass: "text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("swap.view_explorer")) +
                    "\n        "
                ),
                _c(
                  "span",
                  { staticClass: "icon icon-right" },
                  [_c("NewTabIcon")],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn pink",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeModal(false)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("common.close")) + "\n    ")]
          ),
        ]),
      ])
    : _vm.errorScreen
    ? _c(
        "div",
        { staticClass: "error-area" },
        [
          _c("WarnTriangle"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h4" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("swap.error")) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.errorText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("common.dismiss")) + "\n    ")]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }