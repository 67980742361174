<template>
  <div class="clock-loader"></div>
</template>

<script>
export default {
  name: 'LoaderIcon'
}
</script>

<style scoped>
.clock-loader {
  --clock-color: var(--color-dark-main);
  --clock-width: 2rem;
  --clock-radius: calc(var(--clock-width) / 2);
  --clock-minute-length: calc(var(--clock-width) * 0.4);
  --clock-hour-length: calc(var(--clock-width) * 0.2);
  --clock-thickness: 0.1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--clock-width);
  height: var(--clock-width);
  border: 2px solid var(--clock-color);
  border-radius: 50%;
}
.dark .clock-loader {
  --clock-color: #fff;
}
.clock-loader::before, .clock-loader::after {
  position: absolute;
  content: "";
  top: calc(var(--clock-radius) * 0.25);
  width: var(--clock-thickness);
  background: var(--clock-color);
  border-radius: 10px;
  transform-origin: center calc(100% - calc(var(--clock-thickness) / 2));
  animation: spin infinite linear;
}
.clock-loader::before {
  height: var(--clock-minute-length);
  animation-duration: 2s;
}
.clock-loader::after {
  top: calc(var(--clock-radius) * 0.25 + var(--clock-hour-length));
  height: var(--clock-hour-length);
  animation-duration: 15s;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
</style>
