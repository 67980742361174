export const state = () => ({
  list: [
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
      logoURI: 'tokens/bnb.webp',
      balance: 0
    }
  ],
  tokens: [],
  tokensTotal: 1,
  total: 1,
  imported: 0,
  tokensLoading: false,
  listLoading: false,
  listUpdating: false,
  token: null,
  tokenLoading: false,
  source: [
    {
      id: 'defipool',
      name: 'DeFi Pool Popular',
      logoURI: 'tokens/floatie_item.svg',
      uri: 'https://defipool.finance/src/json/mainnet.json',
      version: {
        major: 1,
        minor: 0,
        patch: 3
      },
      tokens: 123,
      included: false
    },
    {
      id: 'pancakeswap',
      name: 'PancakeSwap Extended',
      logoURI: 'tokens/pancake.png',
      uri: 'https://tokens.pancakeswap.finance/pancakeswap-extended.json',
      version: {
        major: 2,
        minor: 16,
        patch: 200
      },
      tokens: 387,
      included: false
    },
    {
      id: 'pancakeswap_top',
      name: 'PancakeSwap Top 100',
      logoURI: 'tokens/pancake.png',
      uri: 'https://tokens.pancakeswap.finance/pancakeswap-top-100.json',
      version: {
        major: 2,
        minor: 16,
        patch: 23
      },
      tokens: 100,
      included: false
    },
    {
      id: 'coingecko',
      name: 'Coingecko',
      logoURI: 'https://www.coingecko.com/assets/thumbnail-007177f3eca19695592f0b8b0eabbdae282b54154e1be912285c9034ea6cbaf2.png',
      uri: 'https://tokens.pancakeswap.finance/coingecko.json',
      version: {
        major: 1,
        minor: 0,
        patch: 10
      },
      tokens: 5163,
      included: false
    },
    {
      id: 'coinmarketcap',
      name: 'CoinMarketCap',
      logoURI: 'https://ipfs.io/ipfs/QmQAGtNJ2rSGpnP6dh6PPKNSmZL8RTZXmgFwgTdy5Nz5mx',
      uri: 'https://tokens.pancakeswap.finance/cmc.json',
      version: {
        major: 2,
        minor: 0,
        patch: 4
      },
      tokens: 6658,
      included: false
    }
  ]
})

export const mutations = {
  setList(state, list) {
    state.list = list
  },
  setTokens(state, tokens) {
    state.tokens = tokens
  },
  setToken(state, tokens) {
    state.token = tokens
  },
  addList(state, list) {
    const newId = list.name.split(' ')[0].toLowerCase()
    for (let itr = 0; itr < list.tokens.length; ++itr) {
      list.tokens[itr].id = newId
    }
    const listID = new Set(state.list.map(item => item.address.toLowerCase()))
    state.list = [...new Set([...state.list, ...list.tokens.filter(token => !listID.has(token.address.toLowerCase()))])]
  },
  addListItem(state, item) {
    const toPush = [item]
    const listID = new Set(state.list.map(itm => itm.address.toLowerCase()))
    state.list = [...new Set([...state.list, ...toPush.filter(token => !listID.has(token.address.toLowerCase()))])]
  },
  editListItem(state, item) {
    const index = state.list.findIndex(tkn => tkn.address === item.address)
    state.list[index] = item
  },
  removeListItem(state, item) {
    state.list = state.list.filter(function(token) {
      return item.address !== token.address
    })
  },
  sortList(state, sortKey) {
    const list = state.list
    list.sort((token0, token1) => {
      let compare = 0
      if (token0[sortKey] === undefined || token0[sortKey] === null) {
        compare = 1
      } else if (token1[sortKey] === undefined || token1[sortKey] === null) {
        compare = -1
      } else if (parseFloat(token0[sortKey]) === parseFloat(token1[sortKey])) {
        compare = 0
      } else {
        compare = parseFloat(token0[sortKey]) < parseFloat(token1[sortKey]) ? 1 : -1
      }
      return compare
    })
    state.list = list
  },
  changeBalance(state, payload) {
    const item = state.list.findIndex(item => item.address === payload.address)
    if (item !== -1) {
      state.list[item].balance = payload.balance
    }
  },
  addSource(state, source) {
    const newId = source.data.name.split(' ')[0].toLowerCase()
    if (state.source.find(item => item.uri === source.uri) === undefined) {
      state.source = state.source.concat({
        id: newId,
        name: source.data.name,
        logoURI: source.data.logoURI,
        uri: source.data.uri,
        version: source.data.version,
        tokens: source.data.tokens.length,
        included: false
      })
    }
  },
  enableImportSource(state, uri) {
    const listIndex = state.source.findIndex(item => item.uri === uri)
    if (listIndex !== undefined) {
      state.source[listIndex].included = true
    }
  },
  disableImportSource(state, source) {
    const listIndex = state.source.findIndex(item => item.id === source.id)
    if (listIndex !== undefined) {
      state.source[listIndex].included = true
    }
  },
  removeList(state, list) {
    state.list = state.list.filter(el => !list.includes(el))
  },
  removeListById(state, identifier) {
    state.list = state.list.filter(el => el.id !== identifier)
  },
  removeSource(state, source) {
    state.source = state.source.filter(el => el.id !== source.id)
  },
  setImported(state, imported) {
    if (imported < 0) {
      imported = 0
    }
    state.imported = imported
  },
  addImported(state) {
    state.imported += 1
  },
  removeImported(state) {
    state.imported -= 1
    if (state.imported < 0) {
      state.imported = 0
    }
  },
  setTotal(state, total) {
    state.total = total
  },
  setTokensTotal(state, total) {
    state.tokensTotal = total
  },
  setLoadingTokens(state, status) {
    state.tokensLoading = status
  },
  setLoadingList(state, status) {
    state.listLoading = status
  },
  setLoadingToken(state, status) {
    state.tokenLoading = status
  },
  setUpdatingList(state, status) {
    state.listUpdating = status
  }
}

export const actions = {
  fetchList({
    state, commit
  }, payload) {
    commit('setLoadingList', true)
    commit('enableImportSource', payload)
    commit('setLoadingList', false)
    // await this.$axios.get(payload)
    //   .then((result) => {
    //     commit('addSource', {
    //       data: result.data,
    //       uri: payload
    //     })
    //     commit('enableImportSource', payload)
    //     commit('addList', result.data)
    //     commit('setTotal', state.list.length)
    //     commit('setLoadingList', false)

    //     const identifier = result.data.name.split(' ')[0].toLowerCase()
    //     saveListToCookie(this, {
    //       id: identifier,
    //       uri: payload
    //     })
    //   })
    //   .catch(() => {
    //     commit('setLoadingList', false)
    //   })
  },
  removeList({
    state, commit
  }, payload) {
    commit('setUpdatingList', true)

    commit('disableImportSource', payload)
    commit('removeListById', payload.id)
    commit('setTotal', state.list.length)
    removeListFromCookie(this, payload.id)

    commit('setUpdatingList', false)
  },
  async addList({
    state, commit
  }, payload) {
    commit('setLoadingList', true)
    await this.$axios.get(payload.uri)
      .then((result) => {
        commit('addSource', {
          data: result.data,
          uri: payload.uri
        })
        commit('enableImportSource', payload.uri)
        commit('addList', result.data)
        commit('setTotal', state.list.length)
        commit('setLoadingList', false)
      })
      .catch(() => {
        commit('setLoadingList', false)
      })
  },
  addToken({
    state, commit
  }, payload) {
    commit('setUpdatingList', true)
    const prevLength = state.list.length
    commit('addListItem', payload)
    commit('setTotal', state.list.length)
    const currentLength = state.list.length
    if (currentLength > prevLength) {
      commit('addImported')
      saveToCookie(this, payload)
    }

    commit('setUpdatingList', false)
  },
  removeToken({
    state, commit
  }, payload) {
    commit('setUpdatingList', true)
    const prevLength = state.list.length
    commit('removeListItem', payload)
    commit('setTotal', state.list.length)
    const currentLength = state.list.length
    if (currentLength < prevLength) {
      commit('removeImported')
      removeFromCookie(this, payload)
    }

    commit('setUpdatingList', false)
  },
  async fetchTokens({
    commit
  }, payload) {
    commit('setLoadingTokens', true)
    const request = await this.$generateApiRequest()
    const pools = await request.$get('tokens', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        search: payload.search !== undefined && payload.search !== null ? payload.search : ''
      }
    })
    commit('setTokens', pools.answer.tokens)
    commit('setTokensTotal', pools.answer.total)
    commit('setLoadingTokens', false)
    this.$refreshCookieExpires()
  },
  async fetchToken({
    commit
  }, payload) {
    commit('setLoadingToken', true)
    const request = await this.$generateApiRequest()
    const token = await request.$get('tokens/' + payload.address)
    commit('setToken', token.answer.token)
    commit('addListItem', token.answer.token)
    commit('setLoadingToken', false)
    this.$refreshCookieExpires()
  },
  async createToken({
    commit
  }, payload) {
    commit('setLoadingToken', true)
    const request = await this.$generateApiRequest()
    await request.$post('tokens/list', payload)
    commit('addListItem', payload)
    commit('setLoadingToken', false)
    this.$refreshCookieExpires()
  },
  async uploadTokenImage({
    commit
  }, payload) {
    commit('setLoadingToken', true)
    const request = await this.$generateUploadRequest()
    return await new Promise((resolve, reject) => {
      request.$post('tokens/list/upload', payload) // payload shoud be FormData()
        .then((response) => {
          commit('setLoadingToken', false)
          resolve(response)
        }).catch((error) => {
          commit('setLoadingToken', false)
          if (error.response && error.response.status < 500) {
            reject(error)
          } else {
            reject(error.message)
          }
          reject(error.config)
        })
    })
  }
}

export const getters = {
  list: state => state.list,
  tokens: state => state.tokens,
  source: state => state.source,
  total: state => state.total,
  tokensTotal: state => state.tokensTotal,
  imported: state => state.imported,
  tokensLoading: state => state.tokensLoading,
  listLoading: state => state.listLoading,
  listUpdating: state => state.listUpdating,
  token: state => state.token,
  tokenLoading: state => state.tokenLoading
}

async function saveToCookie(that, item) {
  const tokensStorage = that.$cookies.get(that.$config.tokensStorageName)
  const networkId = await window.eth.eth.net.getId()
  if (tokensStorage !== undefined && Array.isArray(tokensStorage)) {
    if (tokensStorage[networkId] !== undefined) {
      const toPush = [item]
      const listID = new Set(tokensStorage[networkId].map(item => item.symbol))
      tokensStorage[networkId] = [...new Set([...tokensStorage[networkId], ...toPush.filter(token => !listID.has(token.symbol))])]
    } else {
      tokensStorage[networkId] = [item]
    }
    that.$cookies.set(that.$config.tokensStorageName, tokensStorage)
  } else {
    const newTokensStorage = {}
    newTokensStorage[networkId] = [item]
    that.$cookies.set(that.$config.tokensStorageName, newTokensStorage)
  }
}

async function removeFromCookie(that, item) {
  const tokensStorage = that.$cookies.get(that.$config.tokensStorageName)
  const networkId = await window.eth.eth.net.getId()
  if (tokensStorage !== undefined) {
    if (tokensStorage[networkId] !== undefined) {
      tokensStorage[networkId] = tokensStorage[networkId].filter((token) => {
        return token.id !== item.id
      })
    }
    that.$cookies.set(that.$config.tokensStorageName, tokensStorage)
  }
}

// async function saveListToCookie(that, item) {
//   const listsStorage = that.$cookies.get(that.$config.listsStorageName)
//   const networkId = await window.eth.eth.net.getId()
//   if (listsStorage !== undefined) {
//     if (listsStorage[networkId] !== undefined) {
//       const toPush = [item]
//       const listID = new Set(listsStorage[networkId].map(list => list.id))
//       listsStorage[networkId] = [...new Set([...listsStorage[networkId], ...toPush.filter(list => !listID.has(list.id))])]
//     } else {
//       listsStorage[networkId] = [item]
//     }
//     that.$cookies.set(that.$config.listsStorageName, listsStorage)
//   } else {
//     const newTokensStorage = {}
//     newTokensStorage[networkId] = [item]
//     that.$cookies.set(that.$config.listsStorageName, newTokensStorage)
//   }
// }

async function removeListFromCookie(that, itemId) {
  const listsStorage = that.$cookies.get(that.$config.listsStorageName)
  const networkId = await window.eth.eth.net.getId()
  if (listsStorage !== undefined) {
    if (listsStorage[networkId] !== undefined) {
      listsStorage[networkId] = listsStorage[networkId].filter((list) => {
        return list.id !== itemId
      })
    }
    that.$cookies.set(that.$config.listsStorageName, listsStorage)
  }
}
