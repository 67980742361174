export const state = () => ({
  farms: [],
  total: 0,
  farmsLoading: false
})

export const mutations = {
  setFarms(state, farms) {
    state.farms = farms
  },
  setFarmsTotal(state, total) {
    state.total = total
  },
  addFarms(state, farms) {
    state.farms = state.farms.concat(farms)
  },
  setLoadingFarms(state, status) {
    state.farmsLoading = status
  }
}

export const actions = {
  async fetchFarms({
    commit
  }, payload) {
    commit('setLoadingFarms', true)
    const request = await this.$generateApiRequest()
    const farms = await request.$get('farms', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        search: payload.search !== undefined && payload.search !== null ? payload.search : '',
        tokens: payload.tokens !== undefined && payload.tokens !== null ? payload.tokens : null
      }
    })
    commit('setFarms', farms.answer.farms)
    commit('setFarmsTotal', farms.answer.total)
    commit('setLoadingFarms', false)
    this.$refreshCookieExpires()
  },
  async fetchShares({
    commit
  }, payload) {
    commit('setLoadingPairs', true)
    const request = await this.$generateApiRequest()
    const pools = await request.$get('shares/' + payload.address)
    commit('setShares', pools.answer.shares)
    commit('setLoadingPairs', false)
    this.$refreshCookieExpires()
  }
}

export const getters = {
  farms: state => state.farms,
  total: state => state.total,
  farmsLoading: state => state.farmsLoading
}
