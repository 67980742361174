var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "37",
        height: "32",
        viewbox: "0 0 37 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.39645 24.6154C2.64295 24.6157 2.88709 24.5681 3.11483 24.4753C3.34258 24.3825 3.54944 24.2464 3.72353 24.0747L14.2824 13.6878L18.5855 17.9208C18.7598 18.0923 18.9667 18.2283 19.1944 18.3211C19.4221 18.4138 19.6661 18.4616 19.9126 18.4616C20.159 18.4616 20.4031 18.4138 20.6308 18.3211C20.8585 18.2283 21.0654 18.0923 21.2397 17.9208L33.049 6.30392L33.0483 10.4615C33.0483 10.9512 33.246 11.4207 33.5979 11.767C33.9499 12.1132 34.4272 12.3077 34.925 12.3077C35.4227 12.3077 35.9001 12.1132 36.252 11.767C36.604 11.4207 36.8017 10.9512 36.8017 10.4615L36.8031 1.84615C36.8031 1.35652 36.6054 0.886947 36.2534 0.540726C35.9015 0.194505 35.4241 0 34.9264 0H26.1683C25.6706 0 25.1932 0.194505 24.8413 0.540726C24.4893 0.886947 24.2916 1.35652 24.2916 1.84615C24.2916 2.33578 24.4893 2.80536 24.8413 3.15158C25.1932 3.4978 25.6706 3.69231 26.1683 3.69231H30.3956L19.9126 14.0045L15.6095 9.77146C15.4352 9.60002 15.2283 9.46402 15.0006 9.37124C14.7729 9.27846 14.5289 9.2307 14.2824 9.2307C14.0359 9.2307 13.7919 9.27846 13.5642 9.37124C13.3365 9.46402 13.1296 9.60002 12.9553 9.77146L1.06937 21.4638C0.80689 21.722 0.628138 22.0509 0.555719 22.409C0.483299 22.7672 0.520465 23.1384 0.662515 23.4757C0.804566 23.8131 1.04512 24.1014 1.35376 24.3043C1.6624 24.5071 2.02526 24.6154 2.39645 24.6154Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M34.9264 28.3076H2.39644C1.8987 28.3076 1.42135 28.5021 1.0694 28.8483C0.71744 29.1946 0.519714 29.6641 0.519714 30.1538C0.519714 30.6434 0.71744 31.113 1.0694 31.4592C1.42135 31.8054 1.8987 31.9999 2.39644 31.9999H34.9264C35.4241 31.9999 35.9015 31.8054 36.2534 31.4592C36.6054 31.113 36.8031 30.6434 36.8031 30.1538C36.8031 29.6641 36.6054 29.1946 36.2534 28.8483C35.9015 28.5021 35.4241 28.3076 34.9264 28.3076Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }