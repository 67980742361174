<template>
  <div class="select-item">
    <div ref="selectedHeader" :class="{'select-header': true, 'active': isOpen}" @click.prevent="toggleSelect">
      <p class="current">
        {{ currentOption }}
      </p>
      <ChevronDown />
    </div>
    <div ref="selectedArea" class="select-area">
      <div v-if="options.length > 0" class="options-list">
        <div v-for="(item, index) in options" :key="index" class="item" @click.prevent="pickOption(item.value)">
          <p>
            {{ item.name }}
          </p>
        </div>
      </div>
      <div v-else class="empty-options">
        {{ $t('common.list_empty') }}
      </div>
    </div>
  </div>
</template>

<script>
import ChevronDown from '~/components/svg/new/ChevronDown'

export default {
  name: 'SelectComponent',
  components: {
    ChevronDown
  },
  model: {
    prop: 'value',
    event: 'select'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      isOpen: false,
      currentValue: this.value
    }
  },
  computed: {
    currentOption() {
      const option = this.options.find(opt => opt.value === this.currentValue)
      return option !== undefined && option !== null ? option.name : this.$t('common.choose_one') + '...'
    }
  },
  mounted() {
    window.addEventListener('click', this.shouldToggleSelect)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.shouldToggleSelect)
  },
  methods: {
    shouldToggleSelect(evt) {
      if (
        this.isOpen &&
        this.$refs.selectedArea !== undefined &&
        !this.$refs.selectedHeader.contains(evt.target) &&
        !this.$refs.selectedArea.contains(evt.target)
      ) {
        this.toggleSelect()
      }
    },
    toggleSelect() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$refs.selectedArea.classList.add('active')
      } else {
        this.$refs.selectedArea.classList.remove('active')
      }
    },
    pickOption(newValue) {
      this.currentValue = newValue
      this.$emit('select', this.currentValue)
      this.toggleSelect()
    }
  }
}
</script>

<style scoped>
    .select-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        min-width: 10rem;
        z-index: 5;
    }
    .select-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: .5rem;
        border: 1px solid rgb(var(--cta-cyan-normal));
        padding: .5rem 1rem;
        width: 100%;
        height: 2.75rem;
        transition: background .3s ease-out;
        will-change: background;
    }
    .dark .select-header {
        border: 1px solid rgb(var(--cta-cyan-light));
    }
    .select-header.active {
      background: rgba(var(--main-text-color), .1)
    }
    .select-header .current {
      font-size: 1rem;
      font-weight: 300;
      color: rgb(var(--main-text-color));
      white-space: nowrap;
    }
    .select-header svg {
        width: .75rem;
        height: .75rem;
        color: rgb(var(--cta-pink-light));
        margin-left: 1rem;
        transition: transform .3s ease-out;
        will-change: transform;
    }
    .select-header.active svg {
        transform: rotate(180deg);
    }
    .select-area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        position: absolute;
        top: 2.75rem;;
        left: 0;
        border-radius: .75rem;
        opacity: 0;
        visibility: hidden;
        padding: .5rem 0;
        background: rgba(var(--cta-cyan-normal), .9);
        backdrop-filter: blur(5px);
        transition: opacity .3s ease-out, transform .3s ease-out;
        will-change: opacity, transform;
    }
    .dark .select-area {
      background: rgba(var(--cta-cyan-dark), .9);
    }
    .select-area.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(.5rem)
    }
    .select-area .options-list {
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       justify-content: flex-start;
       width: 100%;
    }
    .select-area .options-list .item {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: flex-start;
       width: 100%;
       padding: .75rem 1rem;
       font-size: 1rem;
       font-weight: 500;
       color: rgb(var(--main-text-color));
       background: transparent;
       cursor: pointer;
       transition: background .3s ease-out;
       will-change: background;
    }
    .select-area .options-list .item:hover,
    .select-area .options-list .item:active {
      background: rgba(var(--cta-cyan-light), .85);
    }
    .dark .select-area .options-list .item:hover,
    .dark .select-area .options-list .item:active {
      background: rgba(var(--cta-cyan-normal), .85);
    }
    .select-area .empty-options {
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       width: 100%;
       padding: 0 .5rem;
       font-size: 1rem;
       font-weight: 300;
       color: rgb(var(--main-text-color));
       font-style: italic;
    }
</style>
