
export default ({ app, store }, inject) => {
  inject('onTheme', function() {
    const currentUserData = app.$cookies.get(app.$config.userStorageName)
    if (currentUserData !== undefined) {
      app.$cookies.set(app.$config.userStorageName, {
        ...currentUserData,
        darkTheme: 'on'
      })
    } else {
      app.$cookies.set(app.$config.userStorageName, {
        darkTheme: 'on'
      })
    }
    store.commit('setDarkTheme', true)
  })
  inject('offTheme', function() {
    const currentUserData = app.$cookies.get(app.$config.userStorageName)
    if (currentUserData !== undefined) {
      app.$cookies.set(app.$config.userStorageName, {
        ...currentUserData,
        darkTheme: 'off'
      })
    } else {
      app.$cookies.set(app.$config.userStorageName, {
        darkTheme: 'off'
      })
    }
    store.commit('setDarkTheme', false)
  })
}
