<template>
  <div v-if="!transactionsLoading && transactions.length > 0" class="transactions-list">
    <div v-for="(transaction, index) in transactions" :key="index" class="transaction-item">
      <div class="transaction-data">
        <div v-if="transaction.status === 1" class="icon success">
          <CheckIcon />
        </div>
        <div v-if="transaction.status === 0" class="icon primary">
          <ClockIcon />
        </div>
        <div v-if="transaction.status === 2" class="icon danger">
          <WarnSign />
        </div>
        <div class="transaction-text">
          <a :href="transactionLink(transaction.hash)" class="summary" target="_blank" rel="nofollow noopener">
            {{ transaction.title }}
            <NewTabIcon />
          </a>
          <div v-if="transaction.gasUsed !== undefined" class="stats">
            {{ $t('common.gas_used') + ': ' }}
            <span class="value">
              {{ $priceFormat(parseFloat(transaction.gasUsed), 0, '.', ',') }}
            </span>
            Gwei
          </div>
          <div class="stats">
            {{ $t('common.transaction_completed') + ': ' }}
            <span class="value">
              {{ $getFormatDate(transaction.created_at) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="transactionsLoading" class="transactions-list empty-area">
    <div class="icon-area">
      <Loading />
    </div>
    <div class="h4">
      {{ $t('common.loading') }}
    </div>
  </div>
  <div v-else class="transactions-list">
    <p class="desc">
      {{ $t('common.no_transactions') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  components: {
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    ClockIcon: hydrateWhenVisible(() => import('~/components/svg/ClockIcon')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    WarnSign: hydrateWhenVisible(() => import('~/components/svg/WarnSign'))
  },
  computed: {
    ...mapGetters('transactions', [
      'transactions',
      'transactionsLoading'
    ])
  },
  methods: {
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/profile.css');
</style>
