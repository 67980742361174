var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2465 4309 c-172 -23 -368 -92 -519 -183 -98 -59 -264 -193 -308\n      -249 -25 -32 -32 -35 -100 -42 -149 -15 -276 -59 -390 -136 -175 -119 -296\n      -309 -332 -519 l-12 -74 -69 -40 c-97 -57 -231 -192 -289 -291 -210 -360 -143\n      -841 158 -1122 160 -150 359 -219 617 -211 127 3 148 11 193 72 29 39 29 133\n      0 172 -46 62 -63 68 -222 75 -166 8 -226 24 -317 85 -97 64 -168 163 -207 289\n      -29 91 -31 251 -4 340 49 164 142 271 308 353 66 33 113 64 124 80 11 17 20\n      60 26 126 4 55 13 118 19 140 30 113 132 237 238 291 72 36 182 58 251 51 91\n      -10 109 0 253 147 102 103 151 145 217 184 156 92 308 140 480 150 481 27 910\n      -273 1055 -737 50 -161 100 -203 264 -225 184 -24 296 -78 401 -192 231 -250\n      226 -667 -10 -903 -106 -106 -233 -160 -424 -179 -93 -9 -121 -22 -160 -75\n      -29 -39 -29 -133 0 -172 46 -62 66 -69 182 -67 381 7 712 243 842 601 134 369\n      38 803 -233 1059 -116 110 -279 194 -439 227 -42 9 -85 16 -95 16 -13 0 -24\n      18 -41 65 -162 450 -554 788 -1025 881 -98 19 -335 27 -432 13z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M1695 2546 c-41 -18 -83 -69 -90 -109 -3 -18 -5 -154 -3 -304 3 -256\n      4 -273 24 -299 11 -15 33 -37 48 -48 26 -20 40 -21 326 -21 286 0 300 1 326\n      21 53 39 69 71 69 134 0 44 -5 66 -21 86 -41 55 -70 69 -156 73 l-80 3 49 35\n      c432 314 1041 -19 1010 -552 -10 -167 -70 -298 -193 -420 -202 -202 -516 -242\n      -764 -98 -96 56 -196 161 -250 263 -39 73 -51 88 -90 108 -78 40 -155 19 -207\n      -58 -44 -64 -26 -144 63 -283 51 -79 183 -216 255 -264 210 -143 469 -201 712\n      -158 203 35 366 122 517 275 132 133 211 274 257 456 24 95 24 333 0 428 -46\n      182 -128 328 -261 462 -134 133 -280 215 -462 261 -108 27 -334 24 -444 -5\n      -104 -28 -264 -105 -345 -167 l-60 -45 -6 70 c-6 74 -22 106 -71 143 -33 25\n      -113 32 -153 13z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }