var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "33",
        height: "24",
        viewbox: "0 0 33 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M28.1072 2.8083C28.1072 2.8083 25.79 0.355057 20.5287 0V0.77462C20.5287 0.77462 23.4144 0.606974 26.9865 4.12323C24.2902 2.18335 20.4437 0.968142 16.1711 0.968142C11.8986 0.968142 8.05204 2.18317 5.35569 4.12323C8.92783 0.606974 11.8136 0.77462 11.8136 0.77462V0C6.55221 0.355057 4.235 2.8083 4.235 2.8083C0.225864 7.97244 0 19.2698 0 19.2698C3.77644 23.8208 8.52138 23.2401 8.52138 23.2401L10.2643 20.7225C8.01665 20.1145 6.41152 18.7185 5.44679 17.6314C8.1383 19.5337 11.9462 20.7225 16.1711 20.7225C20.396 20.7225 24.2039 19.5339 26.8954 17.6314C25.9307 18.7183 24.3256 20.1145 22.0779 20.7225L23.8208 23.2401C23.8208 23.2401 28.5658 23.8212 32.3422 19.2698C32.3426 19.2696 32.1165 7.97244 28.1072 2.8083ZM11.136 16.0743C9.62069 16.0743 8.39237 14.7692 8.39237 13.159C8.39237 11.5491 9.62069 10.2438 11.136 10.2438C12.6513 10.2438 13.8796 11.5491 13.8796 13.159C13.8796 14.769 12.6513 16.0743 11.136 16.0743ZM21.2066 16.0743C19.6913 16.0743 18.463 14.7692 18.463 13.159C18.463 11.5491 19.6913 10.2438 21.2066 10.2438C22.7219 10.2438 23.9502 11.5491 23.9502 13.159C23.9502 14.769 22.7221 16.0743 21.2066 16.0743Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }