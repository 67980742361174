var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 166 153",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_254_319)" } }, [
        _c("path", {
          attrs: {
            d: "M97.9531 10.619C94.0523 3.90085 88.8513 0 83.0001 0C77.1489 0 71.9479 3.90085 68.0471 10.619L3.25076 126.344C-0.650025 133.279 -1.08345 139.78 1.9505 144.982C4.98444 150.183 11.0523 153 18.8539 153H146.93C154.948 153 160.799 150.183 163.833 144.982C166.867 139.78 166.434 133.279 162.533 126.344L97.9531 10.619Z",
            fill: "url(#paint0_linear_254_319)",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M83 116.809C78.2324 116.809 74.5483 120.71 74.5483 125.261C74.5483 130.028 78.4491 133.712 83 133.712C87.7677 133.712 91.4517 129.812 91.4517 125.261C91.4517 120.71 87.7677 116.809 83 116.809Z",
            fill: "currentColor",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M83 106.84C86.034 106.84 88.8512 102.939 89.2846 98.3881L92.5353 42.9094C92.752 38.1417 89.2846 34.4575 84.517 34.4575H81.6998C76.9322 34.4575 73.4648 38.3584 73.6815 42.9094L76.9322 98.3881C77.1489 102.939 79.9661 106.84 83 106.84Z",
            fill: "currentColor",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_254_319",
                x1: "82.8918",
                y1: "0",
                x2: "202.262",
                y2: "98.8697",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#FF3C9F" } }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-color": "#7E1B57" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("clipPath", { attrs: { id: "clip0_254_319" } }, [
            _c("rect", {
              attrs: { width: "166", height: "153", fill: "white" },
            }),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }