var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingScreen && !_vm.successScreen && !_vm.errorScreen
    ? _c("div", { staticClass: "confirm-area" }, [
        _vm.tab === "buy"
          ? _c("div", { staticClass: "buy-area" }, [
              _c("div", { staticClass: "inputs-area" }, [
                _c("div", { staticClass: "input" }, [
                  _c("div", { staticClass: "label-area" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("lottery.buy")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "balance" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("common.balance")) +
                          ": " +
                          _vm._s(
                            _vm.floatieBalance !== null && !_vm.loadingBalance
                              ? _vm.$formatCryptoAmount(_vm.floatieBalance, 6)
                              : "..."
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-area" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.tickets,
                          expression: "form.tickets",
                        },
                      ],
                      attrs: { type: "number", step: "1", min: "0" },
                      domProps: { value: _vm.form.tickets },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "tickets", $event.target.value)
                          },
                          _vm.checkTicketsAmount,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "max-button active",
                        attrs: { type: "button" },
                        on: { click: _vm.setMax },
                      },
                      [
                        _c("p", { staticClass: "text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("swap.max")) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.tickets.length > 0,
                            expression: "errors.tickets.length > 0",
                          },
                        ],
                        staticClass: "hint error",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.tickets) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc-table" }, [
                _c("div", { staticClass: "desc-row" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("lottery.price")) +
                          " ($FLE)\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$formatCryptoAmount(
                            _vm.lottery.priceTicketInFloatie,
                            6
                          )
                        ) +
                        "\n          $FLE\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc-row" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("p", [
                      _vm.discountAmount > 0
                        ? _c("strong", { staticClass: "bold" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$priceFormat(_vm.discountAmount, 2)
                                ) +
                                "%\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("lottery.discount")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "hint-icon wide-tooltip",
                        attrs: { tooltip: _vm.$t("lottery.discount_hint") },
                      },
                      [_c("Question")],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          ~" +
                        _vm._s(_vm.$formatCryptoAmount(_vm.totalDiscount, 6)) +
                        "\n          $FLE\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "desc-table" }, [
                _c("div", { staticClass: "desc-row" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("lottery.you_pay")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _c("p", { staticClass: "bold" }, [
                      _vm._v(
                        "\n            ~" +
                          _vm._s(_vm.$formatCryptoAmount(_vm.total, 6)) +
                          "\n            $FLE\n          "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.tab === "edit"
          ? _c("div", { staticClass: "buy-area" }, [
              _c("div", { staticClass: "desc-table" }, [
                _c("div", { staticClass: "desc-row" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("lottery.you_pay")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _c("p", { staticClass: "bold" }, [
                      _vm._v(
                        "\n            ~" +
                          _vm._s(_vm.$formatCryptoAmount(_vm.total, 6)) +
                          "\n            $FLE\n          "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("lottery.edit_desc")) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons-area" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn cyan btn-outline",
                    attrs: { type: "button" },
                    on: { click: _vm.randomizeTickets },
                  },
                  [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("lottery.randomize")) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tickets-list" },
                _vm._l(_vm.form.numbers, function (number, ind) {
                  return _c("div", { key: ind, staticClass: "ticket" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v("\n          #" + _vm._s(ind + 1) + "\n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "numbers-area" },
                      _vm._l(number.split(""), function (num, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "number" },
                          [
                            _c("input", {
                              ref: "tnum" + ind + index,
                              refInFor: true,
                              attrs: { type: "number" },
                              domProps: { value: num },
                              on: {
                                input: function ($event) {
                                  return _vm.changeTicketNumber(
                                    $event,
                                    ind,
                                    index
                                  )
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          !_vm.floatieApproved
            ? _c(
                "button",
                {
                  class: {
                    "btn btn-outline pink": true,
                    disabled: _vm.loadingFloatieApproved,
                  },
                  attrs: {
                    disabled: _vm.loadingFloatieApproved,
                    type: "button",
                  },
                  on: { click: _vm.approveToken },
                },
                [
                  !_vm.loadingFloatieApproved
                    ? _c("span", { staticClass: "text" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("pools.approve")) +
                            " $FLE\n      "
                        ),
                      ])
                    : _c("span", { staticClass: "text" }, [_c("Loading")], 1),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tab === "buy"
            ? _c(
                "button",
                {
                  class: {
                    "btn cyan btn-outline": true,
                    disabled:
                      _vm.loading ||
                      _vm.errorsExist ||
                      _vm.loadingBalance ||
                      _vm.total === 0,
                  },
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.loading ||
                      _vm.errorsExist ||
                      _vm.loadingBalance ||
                      _vm.total === 0,
                  },
                  on: { click: _vm.changeTabToEdit },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lottery.view_edit_numbers")) +
                        "\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.floatieApproved && _vm.tab === "buy"
            ? _c(
                "button",
                {
                  class: {
                    "btn pink": true,
                    disabled:
                      _vm.loading ||
                      _vm.errorsExist ||
                      _vm.loadingBalance ||
                      _vm.total === 0,
                  },
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.loading ||
                      _vm.errorsExist ||
                      _vm.loadingBalance ||
                      _vm.total === 0,
                  },
                  on: { click: _vm.buyTickets },
                },
                [
                  !_vm.loading
                    ? _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("lottery.buy_now")) +
                            "\n      "
                        ),
                      ])
                    : _c("span", [_c("Loading")], 1),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tab === "edit"
            ? _c(
                "button",
                {
                  class: {
                    "btn cyan btn-outline": true,
                    disabled:
                      _vm.loading ||
                      _vm.errorsExist ||
                      _vm.loadingBalance ||
                      _vm.total === 0,
                  },
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.loading ||
                      _vm.errorsExist ||
                      _vm.loadingBalance ||
                      _vm.total === 0,
                  },
                  on: { click: _vm.changeTabToBuy },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lottery.return_back")) +
                        "\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.floatieApproved && _vm.tab === "edit"
            ? _c(
                "button",
                {
                  class: {
                    "btn pink": true,
                    disabled:
                      _vm.loading ||
                      _vm.errorsExist ||
                      _vm.loadingBalance ||
                      _vm.total === 0,
                  },
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.loading ||
                      _vm.errorsExist ||
                      _vm.loadingBalance ||
                      _vm.total === 0,
                  },
                  on: { click: _vm.buyTickets },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("lottery.confirm_buy")) +
                        "\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "other-desc" }, [
          _c("p", { staticClass: "hint" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("lottery.buy_now_desc", {
                    price: _vm.$formatCryptoAmount(
                      _vm.lottery.priceTicketInFloatie,
                      6
                    ),
                  })
                ) +
                "\n    "
            ),
          ]),
        ]),
      ])
    : _vm.loadingScreen
    ? _c(
        "div",
        { staticClass: "loading-area" },
        [
          _c("ModalLoader"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("lottery.confirming", {
                      tickets: _vm.form.tickets,
                      tickets_text: _vm.$numberToString(_vm.form.tickets, [
                        _vm.$t("lottery.ticket"),
                        _vm.$t("lottery.tickets"),
                        _vm.$t("lottery.many_tickets"),
                      ]),
                      lottery: _vm.lottery.id,
                    })
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("swap.confirm_transaction")) +
                  "\n    "
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm.successScreen
    ? _c("div", { staticClass: "success-area" }, [
        _c("div", { staticClass: "icon-area" }, [_c("CheckIcon")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "desc-area" }, [
          _c("div", { staticClass: "h4" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("lottery.success")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("lottery.success_text")) + "\n    "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-outline pink",
              attrs: {
                href: _vm.transactionLink(_vm.transaction.tx),
                rel: "nofollow noopener noreferrer",
                target: "_blank",
              },
            },
            [
              _c("span", { staticClass: "text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("swap.view_explorer")) +
                    "\n        "
                ),
                _c(
                  "span",
                  { staticClass: "icon icon-right" },
                  [_c("NewTabIcon")],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn pink",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeModal(false)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("common.close")) + "\n    ")]
          ),
        ]),
      ])
    : _vm.errorScreen
    ? _c(
        "div",
        { staticClass: "error-area" },
        [
          _c("WarnTriangle"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h4" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("limit.error")) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.errorText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("common.dismiss")) + "\n    ")]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }