<template>
  <div class="layout">
    <Header />
    <ConnectWallet />
    <ProfileModal />
    <SettingsModal />
    <main class="content main-wrapper">
      <Nuxt />
      <div class="bg-texture">
        <picture>
          <source srcset="/img/new/texture.webp" type="image/webp">
          <nuxt-img
            v-if="!darkTheme"
            width="1920"
            sizes="xs:320px sm:640px md:768px lg:1024px xl:1280px xxl:1536px"
            src="/img/new/texture.png"
            alt="Defi Pool crypto finances wallet"
            title="Defi Pool stake"
            loading="lazy"
          />
        </picture>
      </div>
    </main>
    <Footer />
    <div ref="cookies" class="cookies-alert">
      <Cookie />
      <p>
        {{ $t('common.we') }}
        <span class="line-through">
          {{ $t('common.eat') }}
        </span>
        {{ $t('common.cookies_text') }}
        <nuxt-link :to="localePath('/policy')">
          {{ $t('common.here') }}
        </nuxt-link>
      </p>
      <button type="button" :aria-label="$t('common.ok')" @click="acceptCookies">
        {{ $t('common.ok') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

import Header from '~/components/common/Header'
import Footer from '~/components/common/Footer'

const Web3 = require('web3')

export default {
  name: 'NewDefaultLayout',
  components: {
    ConnectWallet: hydrateWhenIdle(() => import('~/components/modals/ConnectWallet')),
    Cookie: hydrateWhenIdle(() => import('~/components/svg/new/Cookie')),
    Header,
    Footer,
    ProfileModal: hydrateWhenIdle(() => import('~/components/modals/ProfileModal')),
    SettingsModal: hydrateWhenIdle(() => import('~/components/modals/SettingsModal'))
  },
  data() {
    return {
      reloadingCount: 0,
      reloadingAccount: false
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    ...mapGetters('seos', [
      'contacts'
    ])
  },
  created() {
    const userData = this.$cookies.get(this.$config.userStorageName)
    if (userData !== undefined && userData.darkTheme !== undefined && userData.darkTheme === 'on') {
      this.$onTheme()
    } else {
      this.$offTheme()
    }
    if (process.browser && localStorage.getItem(this.$config.userStorageName) !== null) {
      const userObject = JSON.parse(localStorage.getItem(this.$config.userStorageName))
      this.$store.commit('pairs/setPairs', userObject.pairs)
    }
  },
  async beforeMount() {
    try {
      if (window !== undefined && window.ethereum !== undefined) {
        window.eth = new Web3(window.ethereum)
      }
      this.$loadSettings()
      await this.fetchContacts()
      const userAuth = this.$cookies.get('auth.user')
      if (userAuth !== undefined) {
        this.checkUserAuth()
      }
      const userData = this.$cookies.get(this.$config.userStorageName)
      if (userData !== undefined && userData.darkTheme !== undefined && this.$isAddress(userData.darkTheme)) {
        this.setDarkTheme(userData.darkTheme === 'on')
      }
      if (this.$store.getters.blockchains.length === 0) {
        await this.fetchBlockchains({
          step: 15
        })
      }
    } catch (err) {
      console.error(err)
    }
  },
  async mounted() {
    if (this.$cookies.get('cookiesAccept') === undefined) {
      setTimeout(() => {
        this.$refs.cookies.classList.add('active')
      }, 4000)
    }
    if (window.ethereum !== undefined) {
      const userAuth = this.$cookies.get('auth.user')
      if (window.ethereum.isConnected()) {
        const networkId = window.ethereum.networkVersion
        this.$loadTokensAndLists(networkId)
        this.$loadTransactions(networkId)
        this.$loadPairs(networkId)
        await this.$loadBalances()
        this.$nuxt.$emit('tokenListToggle')
        if (userAuth !== undefined) {
          this.$checkNetwork()
        }
      } else {
        window.ethereum.on('connect', async (connectInfo) => {
          const networkId = window.eth.utils.hexToNumber(connectInfo.chainId)
          this.$loadTokensAndLists(networkId)
          this.$loadTransactions(networkId)
          this.$loadPairs(networkId)
          await this.$loadBalances()
          this.$nuxt.$emit('tokenListToggle')
          if (userAuth !== undefined) {
            this.$checkNetwork()
          }
        })
      }
      window.ethereum.on('chainChanged', () => {
        const userAuth = this.$cookies.get('auth.user')
        if (userAuth !== undefined) {
          this.$checkNetwork()
        }
      })
      window.ethereum.on('accountsChanged', this.reloadAccount)
    }
    document.querySelector('.main-wrapper').classList.remove('active')
    document.querySelector('body').style.overflow = ''
  },
  methods: {
    ...mapMutations(['setDarkTheme']),
    ...mapActions(['fetchBlockchains']),
    ...mapActions('seos', ['fetchContacts']),
    ...mapActions('auth', [
      'checkAuth'
    ]),
    acceptCookies() {
      this.$refs.cookies.classList.remove('active')
      this.$cookies.set('cookiesAccept', 'true')
    },
    async reloadAccount() {
      const currentToken = this.$cookies.get('auth.token')
      const currentUser = this.$cookies.get('auth.user')
      if (currentToken !== undefined && currentUser !== undefined) {
        this.$nuxt.$emit('openLoading', this.$t('common.reloading_wallet'), this.$t('common.reloading_wallet_text'))
        this.$clearCookies()
        let xsrfToken = this.$cookies.get('XSRF-TOKEN')
        if (this.$cookies.get('XSRF-TOKEN') === undefined) {
          this.$cookies.set('XSRF-TOKEN', xsrfToken)
        }
        this.$nextTick(() => {
          this.$store.dispatch('auth/logout').then(() => {
            xsrfToken = this.$cookies.get('XSRF-TOKEN')
            this.$nextTick(async () => {
              const address = await this.$getUserAddress()
              document.querySelector('body').style.overflow = 'auto'
              if (this.$cookies.get('XSRF-TOKEN') === undefined) {
                this.$cookies.set('XSRF-TOKEN', xsrfToken)
              }
              await this.$api.$post('users/nonce', {
                address
              }).then((newUserData) => {
                xsrfToken = this.$cookies.get('XSRF-TOKEN')
                const response = newUserData
                this.$nextTick(async () => {
                  if (response.answer.token !== undefined && response.answer.token.length !== 0) {
                    this.$store.commit('auth/setUser', response.answer.user)
                    this.$store.commit('auth/setUserToken', response.answer.token)
                    this.reloadingAccount = false
                    this.reloadingCount = 0
                    const nowDate = new Date()
                    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)
                    this.$cookies.set('auth.user', response.answer.user, {
                      expires: nowDate
                    })
                    this.$cookies.set('auth.token', response.answer.token, {
                      expires: nowDate
                    })
                    this.$nuxt.$emit('walletReconnected')
                    this.$nextTick(() => {
                      this.$nuxt.refresh()
                      this.$store.commit('modal/closeAll')
                    })
                  } else {
                    let message = this.$t('auth.login_metamask_message')
                    message += this.$t('auth.current_wallet_address') + ': ' + address + '\n\n'
                    message += this.$t('auth.nonce') + ': ' + response.answer.nonce + '\n\n'
                    this.$nuxt.$emit('openLoading', this.$t('common.enter_new_account'), this.$t('common.sign_transaction'))
                    try {
                      const signature = await window.eth.eth.personal.sign(message, address, (err, result) => {
                        if (err) {
                          this.$toast.fire({
                            icon: 'warning',
                            title: err.code + ': ' + err.message
                          })
                        } else if (result.error) {
                          this.$toast.fire({
                            icon: 'warning',
                            title: result.error.message
                          })
                        }
                      })
                      if (this.$cookies.get('XSRF-TOKEN') === undefined) {
                        this.$cookies.set('XSRF-TOKEN', xsrfToken)
                      }
                      this.$store.dispatch('auth/metamask', {
                        message,
                        address,
                        signature
                      }).then(() => {
                        xsrfToken = this.$cookies.get('XSRF-TOKEN')
                        this.reloadingAccount = false
                        this.reloadingCount = 0
                        this.$cookies.set('recent', 'metamask')
                        window.location.reload()
                      }).catch((error) => {
                        console.log(error)
                        this.$swal.hideLoading()
                        this.$nuxt.$emit('showError', null, this.$t('auth.metamask_wrong'))
                      })
                    } catch (err) {
                      console.log(err)
                      this.stopReloadingAccount()
                    }
                  }
                })
              })
                .catch((err) => {
                  console.log(err)
                  this.stopReloadingAccount()
                })
            })
          }).catch((err) => {
            console.log(err)
            this.stopReloadingAccount()
          })
        })
      } else {
        const currentChain = parseInt(window.ethereum.networkVersion)
        if (currentChain !== this.$config.chainId) {
          const replaced = await this.$replaceChain()
          if (replaced) {
            const userData = this.$cookies.get(this.$config.userStorageName)
            userData.chainId = currentChain
            this.$cookies.set(this.$config.userStorageName, userData)
            await this.$metamaskNonce()
            this.loadingWallet = ''
          } else {
            this.loadingWallet = ''
          }
        } else {
          const userData = this.$cookies.get(this.$config.userStorageName)
          userData.chainId = currentChain
          this.$cookies.set(this.$config.userStorageName, userData)
          await this.$metamaskNonce()
          this.loadingWallet = ''
        }
      }
    },
    stopReloadingAccount(resultMessage = null) {
      this.reloadingAccount = false
      this.reloadingCount = 0
      const errText = resultMessage !== null ? resultMessage : this.$t('auth.metamask_wrong')
      this.$nuxt.$emit('showError', null, errText)
    },
    async checkUserAuth() {
      try {
        await this.checkAuth()
      } catch (err) {
        this.$cookies.remove('auth.token')
        this.$cookies.remove('auth.user')
        this.reloginUser()
      }
    },
    reloginUser() {
      if (window.ethereum !== undefined) {
        this.$metamaskNonce(true)
      }
    }
  }
}
</script>

<style>
  @import url('~/assets/css/new.css');
</style>
