var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 14 11",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.1973 4.2044C12.9888 4.00563 12.7107 3.8966 12.4227 3.90069L4.18531 3.90676L6.20057 1.8915C6.3133 1.78277 6.40156 1.65116 6.45934 1.50559C6.51711 1.36003 6.54313 1.20381 6.53565 1.04737C6.52817 0.890938 6.48735 0.73792 6.41595 0.598528C6.34456 0.459137 6.24423 0.336586 6.12165 0.239108C5.90728 0.0713242 5.63826 -0.0109528 5.36671 0.00812124C5.09516 0.0271952 4.84031 0.146325 4.6515 0.342428L0.765199 4.22873C0.558189 4.43416 0.44128 4.71338 0.440139 5.00502C0.438998 5.29666 0.553706 5.57685 0.759102 5.7839L4.64544 9.67024C4.74672 9.77605 4.86815 9.86054 5.00253 9.91881C5.13691 9.97707 5.28153 10.008 5.428 10.0096C5.57446 10.0112 5.71982 9.98345 5.85545 9.92814C5.99108 9.87283 6.11425 9.79103 6.21782 9.68745C6.3214 9.58388 6.40319 9.46071 6.45851 9.32508C6.51382 9.18946 6.54154 9.04409 6.53993 8.89763C6.53833 8.75116 6.50744 8.60654 6.44917 8.47216C6.39091 8.33778 6.30642 8.21635 6.20061 8.11507L4.18986 6.10432L12.4227 6.10587C12.645 6.11029 12.8634 6.04721 13.0491 5.92498C13.2349 5.80275 13.3792 5.62712 13.463 5.42121C13.5469 5.2153 13.5665 4.98874 13.519 4.77153C13.4716 4.55431 13.3594 4.35656 13.1973 4.2044Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }