<template>
  <div v-if="!loadingScreen && !successScreen && !errorScreen && !infoScreen && !questionScreen">
    Common modal for site states.
  </div>
  <div v-else-if="loadingScreen" class="loading-area">
    <ModalLoader />
    <div class="desc-area">
      <div class="h5">
        {{ header !== $t('common.loading') ? header : loadingText }}
      </div>
      <p v-if="header !== $t('common.loading')" class="desc">
        {{ loadingText }}
      </p>
    </div>
  </div>
  <div v-else-if="successScreen" class="success-area">
    <div class="icon-area">
      <CheckIcon />
    </div>
    <div class="desc-area">
      <div class="h5">
        {{ header }}
      </div>
      <p class="desc">
        {{ successText }}
      </p>
    </div>
    <hr>
    <div class="buttons-area">
      <a v-if="externalLink !== null" class="btn btn-outline pink" :href="externalLink" rel="nofollow noopener noreferrer" target="_blank">
        <span class="text">
          {{ $t('common.see_details') }}
          <span class="icon icon-right">
            <NewTabIcon />
          </span>
        </span>
      </a>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.close') }}
      </button>
    </div>
  </div>
  <div v-else-if="errorScreen" class="error-area">
    <WarnTriangle />
    <div class="desc-area">
      <div class="h5">
        {{ header }}
      </div>
      <p class="desc">
        {{ errorText }}
      </p>
      <hr>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.dismiss') }}
      </button>
    </div>
  </div>
  <div v-else-if="infoScreen" class="info-area">
    <Info />
    <div class="desc-area">
      <div class="h5">
        {{ header }}
      </div>
      <p class="desc">
        {{ infoText }}
      </p>
      <hr>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.dismiss') }}
      </button>
    </div>
  </div>
  <div v-else-if="questionScreen" class="info-area">
    <QuestionCircle />
    <div class="desc-area">
      <div class="h5">
        {{ header }}
      </div>
      <p class="desc">
        {{ questionText }}
      </p>
      <hr>
      <div class="buttons-area">
        <button type="button" class="btn btn-outline cyan" @click="dismissQuestion">
          <span class="text">
            {{ $t('common.dismiss') }}
          </span>
        </button>
        <button type="button" class="btn pink" @click="approveQuestion">
          {{ $t('common.confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  components: {
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    Info: hydrateWhenVisible(() => import('~/components/svg/new/Info')),
    ModalLoader: hydrateWhenVisible(() => import('~/components/app/interface/ModalLoader')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    QuestionCircle: hydrateWhenVisible(() => import('~/components/svg/new/QuestionCircle')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  props: {
    type: {
      type: String,
      default: () => {
        return 'Common'
      }
    }
  },
  data() {
    return {
      header: '',
      infoText: '',
      errorText: '',
      loadingText: '',
      successText: '',
      questionText: '',
      externalLink: null,
      externalMethod: null,
      loadingScreen: true,
      successScreen: false,
      errorScreen: false,
      infoScreen: false,
      questionScreen: false,
      toggleClosableEvent: true
    }
  },
  computed: {
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    }
  },
  created() {
    this.$nuxt.$on('openLoading', (header = null, text) => this.showLoading(header, text))
    this.$nuxt.$on('openSuccess', (header = null, text, externalLink = null) => this.showSuccess(header, text, externalLink))
    this.$nuxt.$on('openError', (header = null, text) => this.showError(header, text))
    this.$nuxt.$on('openInfo', (header = null, text) => this.showInfo(header, text))
    this.$nuxt.$on('openQuestion', (header = null, text = null, method = null) => this.showQuestion(header, text, method))
    this.$nuxt.$on('closeCommonModals', () => this.closeModal())
  },
  methods: {
    ...mapActions('modal', [
      'changeModalCommonState'
    ]),
    closeModal() {
      this.changeModalCommonState({
        state: false
      })
      if (this.questionScreen && this.toggleClosableEvent) {
        this.$nuxt.$emit('modalQuestionClosed', {
          isConfirmed: false,
          isClosed: true,
          isCanceled: false,
          method: this.externalMethod
        })
      }
      this.toggleClosableEvent = true
    },
    showError(header = null, text) {
      this.changeModalCommonState({
        state: true
      })
      this.header = header !== null ? header : this.$t('common.smth_happen')
      this.errorText = text !== null ? text : '...'
      this.initErrorScreen()
    },
    showInfo(header = null, text) {
      this.changeModalCommonState({
        state: true
      })
      this.header = header !== null ? header : this.$t('common.notice')
      this.infoText = text !== null ? text : '...'
      this.initInfoScreen()
    },
    showSuccess(header = null, text, externalLink = null) {
      this.changeModalCommonState({
        state: true
      })
      this.header = header !== null ? header : this.$t('common.success')
      this.successText = text !== null ? text : '...'
      this.externalLink = externalLink
      this.initSuccessScreen()
    },
    showLoading(header = null, text) {
      this.changeModalCommonState({
        state: true
      })
      this.header = header !== null ? header : this.$t('common.loading')
      this.loadingText = text !== null ? text : '...'
      this.initLoadingScreen()
    },
    showQuestion(header = null, text = null, method = null) {
      this.changeModalCommonState({
        state: true
      })
      this.header = header !== null ? header : this.$t('common.question')
      this.questionText = text !== null ? text : '...'
      this.externalMethod = method
      this.initQuestionScreen()
    },
    dismissQuestion() {
      this.toggleClosableEvent = false
      this.$nuxt.$emit('modalQuestionClosed', {
        isConfirmed: false,
        isClosed: true,
        isCanceled: true,
        method: this.externalMethod
      })
      this.closeModal(false)
    },
    approveQuestion() {
      this.toggleClosableEvent = false
      this.$nuxt.$emit('modalQuestionClosed', {
        isConfirmed: true,
        isClosed: true,
        isCanceled: false,
        method: this.externalMethod
      })
      this.closeModal(false)
    },
    initLoadingScreen() {
      this.$emit('initLoading')
      this.loadingScreen = true
      this.errorScreen = false
      this.successScreen = false
    },
    initSuccessScreen() {
      this.$emit('initSuccess')
      this.loadingScreen = false
      this.successScreen = true
      this.errorScreen = false
      this.infoScreen = false
      this.questionScreen = false
    },
    initErrorScreen() {
      this.$emit('initError')
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = true
      this.infoScreen = false
      this.questionScreen = false
    },
    initInfoScreen() {
      this.$emit('initInfo')
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = false
      this.infoScreen = true
      this.questionScreen = false
    },
    initQuestionScreen() {
      this.$emit('initQuestion')
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = false
      this.infoScreen = false
      this.questionScreen = true
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modalContent.css');
</style>
