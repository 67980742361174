var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "icon-header",
      attrs: {
        viewBox: "0 0 512 512",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M256.001,0c-142.285,0-256,115.36-256,256c0,142.024,115.362,256.007,255.941,256.002\n        C397.082,512,512.001,397.669,512.001,256C512.001,115.524,398.38,0,256.001,0z M198.975,281.887l-18.859,81.73\n        c-4.726,20.488-33.203,23.705-42.039,4.139l-42.428-94.133l28.331-83.156c2.434-7.141-0.815-14.977-7.587-18.302l-56.874-27.926\n        c22.098-38.88,54.954-70.092,94.732-90.106V91.39c0,7.085,4.958,13.205,11.89,14.674l76.806,16.281\n        c10.44,2.208,18.607,9.998,21.313,20.326l7.259,27.712c2.225,8.511,0.284,17.697-5.191,24.572l-64.471,80.96\n        C200.463,277.665,199.478,279.706,198.975,281.887z M239.383,481.401l8.607-18.167c1.601-3.384,3.379-4.98,6.528-6.886\n        c3.323-1.99,8.365-4.304,16.655-8.465c7.415-3.712,15.373-1.359,19.711,4.341c0.001,0.001,0.003,0.003,0.004,0.004l17.942,23.558\n        C286.188,481.201,262.695,483.1,239.383,481.401z M401.654,428.797l-19.655-12.749l-8.815-81.699\n        c-0.868-8.038,1.218-16.118,5.871-22.746l44.27-63.01c1.774-2.525,2.727-5.537,2.727-8.623v-57.875l28.732-33.717\n        c17.858,32.899,27.219,69.724,27.219,107.622C482.001,324.983,451.135,386.993,401.654,428.797z",
            fill: "currentColor",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }