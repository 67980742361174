export const state = () => ({
  lotteries: [],
  total: 0,
  tickets: [],
  totalTickets: 0,
  lotteriesLoading: false,
  ticketsLoading: false,
  ticketLoading: false
})

export const mutations = {
  setTickets(state, tickets) {
    state.tickets = tickets
  },
  setTicketsTotal(state, total) {
    state.totalTickets = total
  },
  setLotteries(state, tickets) {
    state.lotteries = tickets
  },
  setLotteriesTotal(state, total) {
    state.total = total
  },
  addLotteries(state, lotteries) {
    state.lotteries = state.lotteries.concat(lotteries)
  },
  addTickets(state, tickets) {
    state.tickets = state.tickets.concat(tickets)
  },
  unshiftTicket(state, tickets) {
    state.tickets = tickets.concat(state.tickets)
  },
  setLoadingLotteries(state, status) {
    state.lotteriesLoading = status
  },
  setLoadingTicket(state, status) {
    state.ticketLoading = status
  },
  setLoadingTickets(state, status) {
    state.ticketsLoading = status
  }
}

export const actions = {
  async fetchLotteries({
    commit
  }, payload) {
    commit('setLoadingLotteries', true)
    const request = await this.$generateApiRequest()
    const orders = await request.$get('lottery', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        status: payload.status !== undefined && payload.status !== null ? payload.status : null,
        starts: payload.starts !== undefined && payload.starts !== null ? payload.starts : null,
        ends: payload.ends !== undefined && payload.ends !== null ? payload.ends : null
      }
    })
    commit('setLotteries', orders.answer.lotteries)
    commit('setLotteriesTotal', orders.answer.total)
    commit('setLoadingLotteries', false)
    this.$refreshCookieExpires()
  },
  async addTicket({
    commit
  }, payload) {
    commit('setLoadingTicket', true)
    const request = await this.$generateApiRequest()
    const order = await request.$post('lottery/tickets', payload)
    commit('unshiftTickets', order.answer.tickets)
    commit('setLoadingTicket', false)
    this.$refreshCookieExpires()
  },
  async fetchTickets({
    commit
  }, payload) {
    commit('setLoadingTickets', true)
    const request = await this.$generateApiRequest()
    const orders = await request.$get('lottery/tickets', {
      params: {
        page: payload.page !== undefined && payload.page !== null ? payload.page : 1,
        step: payload.step !== undefined && payload.step !== null ? payload.step : 20,
        orderField: payload.orderField !== undefined && payload.orderField !== null ? payload.orderField : 'created_at_desc',
        lid: payload.lid !== undefined && payload.lid !== null ? payload.lid : null,
        uid: payload.uid !== undefined && payload.uid !== null ? payload.uid : null,
        search: payload.search !== undefined && payload.search !== null ? payload.search : null
      }
    })
    commit('setTickets', orders.answer.tickets)
    commit('setTicketsTotal', orders.answer.total)
    commit('setLoadingTickets', false)
    this.$refreshCookieExpires()
  }
}

export const getters = {
  lotteries: state => state.lotteries,
  total: state => state.total,
  tickets: state => state.tickets,
  totalTickets: state => state.totalTickets,
  lotteriesLoading: state => state.lotteriesLoading,
  ticketsLoading: state => state.ticketsLoading,
  ticketLoading: state => state.ticketLoading
}
