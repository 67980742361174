var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 20 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M15.8464 5.26974L14.4321 2.77364C14.2129 2.38211 13.8637 2.09252 13.4537 1.96216C13.0436 1.83181 12.6026 1.87019 12.2179 2.06971C12.1679 2.09254 7.35357 5.26974 7.35357 5.26974H15.8464Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M6 5.26974C6 5.26974 11.7571 1.45457 11.8821 1.39622L11.05 0.521061C10.7409 0.190024 10.3215 0.00264021 9.8833 -0.000211121C9.44506 -0.00306245 9.02355 0.17885 8.71071 0.505841L4.075 5.26974H6Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M19.0429 11.1181H14.4464C13.8677 11.1181 13.3127 11.363 12.9034 11.799C12.4942 12.235 12.2643 12.8264 12.2643 13.443C12.2643 14.0596 12.4942 14.6509 12.9034 15.0869C13.3127 15.5229 13.8677 15.7678 14.4464 15.7678H19.0429C19.2967 15.7678 19.5401 15.6604 19.7196 15.4691C19.8991 15.2779 20 15.0185 20 14.7481V12.1416C20.0001 11.8708 19.8994 11.6109 19.72 11.4191C19.5406 11.2272 19.2971 11.119 19.0429 11.1181ZM15.4071 13.1652C15.5006 13.1672 15.5896 13.2081 15.6551 13.2793C15.7206 13.3504 15.7572 13.4461 15.7572 13.5457C15.7572 13.6453 15.7206 13.741 15.6551 13.8121C15.5896 13.8832 15.5006 13.9242 15.4071 13.9262C15.3137 13.9242 15.2246 13.8832 15.1592 13.8121C15.0937 13.741 15.0571 13.6453 15.0571 13.5457C15.0571 13.4461 15.0937 13.3504 15.1592 13.2793C15.2246 13.2081 15.3137 13.1672 15.4071 13.1652Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M14.4464 16.5288C10.6126 16.3763 10.6156 10.5105 14.4465 10.3571H18.1536V7.93327C18.1534 7.42875 17.9652 6.94494 17.6304 6.58819C17.2955 6.23144 16.8414 6.03093 16.3679 6.03075H1.78571C1.31217 6.03093 0.858062 6.23144 0.523213 6.58819C0.188364 6.94494 0.000171331 7.42875 0 7.93327V19.0972C0.000171331 19.6018 0.188364 20.0856 0.523213 20.4423C0.858062 20.7991 1.31217 20.9996 1.78571 20.9998H16.3679C16.8414 20.9996 17.2955 20.7991 17.6304 20.4423C17.9652 20.0856 18.1534 19.6018 18.1536 19.0972V16.5288C17.7214 16.5256 14.9466 16.5312 14.4464 16.5288Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }