var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M340 5109 c-160 -31 -299 -173 -330 -339 -14 -73 -14 -4347 0 -4420\n          32 -170 170 -308 339 -340 74 -14 4348 -14 4422 0 169 32 307 170 339 340 6\n          33 10 449 10 1131 l0 1079 -215 0 -215 0 0 -1065 0 -1065 -2130 0 -2130 0 0\n          2130 0 2130 1065 0 1065 0 0 215 0 215 -1087 -1 c-599 -1 -1108 -5 -1133 -10z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3412 4908 l3 -213 485 -3 485 -2 -913 -913 -912 -912 153 -153 152\n          -152 910 910 910 910 5 -482 5 -483 213 -3 212 -2 0 767 0 768 -24 50 c-27 59\n          -46 77 -106 104 -44 20 -62 21 -813 21 l-767 0 2 -212z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }