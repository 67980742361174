<template>
  <header ref="header" :class="{ 'fixed': fixedData }">
    <WindowModal :title="modalCommonTitle" type="Common">
      <template #modal-content>
        <CommonModal
          ref="commonModal"
          @initLoading="changeModalTitle('loading')"
          @initSuccess="changeModalTitle('success')"
          @initError="changeModalTitle('error')"
          @initInfo="changeModalTitle('info')"
          @initQuestion="changeModalTitle('question')"
          @closeGlobalModal="closeModal"
        />
      </template>
    </WindowModal>
    <div ref="mainMenu" class="mobile-menu">
      <HeaderNavMobile @close="activateMenu" />
    </div>
    <div class="top_panel">
      <div class="panel__container">
        <div class="links">
          <p class="label">
            {{ $t('common.useful_links') }}:
          </p>
          <a href="/docs/Whitepaper_DefiPool_ENG.pdf" class="link" rel="nofoloow noopener noreferrer" target="_blank">
            {{ $t('common.whitepaper') }}
            <i class="bx bx-link-external"></i>
          </a>
          <a href="/docs/OnePager_DefiPool_ENG.pdf" class="link" rel="nofoloow noopener noreferrer" target="_blank">
            {{ $t('common.one_pager') }}
            <i class="bx bx-link-external"></i>
          </a>
          <a href="/docs/Pitchdeck_DeFiPool_ENG.pdf" class="link" rel="nofoloow noopener noreferrer" target="_blank">
            {{ $t('common.pitch_deck') }}
            <i class="bx bx-link-external"></i>
          </a>
          <a href="/docs/RoadMap_DefiPool_ENG.pdf" class="link" rel="nofoloow noopener noreferrer" target="_blank">
            {{ $t('common.roadmap') }}
            <i class="bx bx-link-external"></i>
          </a>
          <a href="/docs/Tokenomics_DefiPool_ENG.pdf" class="link" rel="nofoloow noopener noreferrer" target="_blank">
            {{ $t('common.tokenomics') }}
            <i class="bx bx-link-external"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="header-inner">
      <div class="left-assets">
        <div class="logo-area">
          <nuxt-link class="logo" :to="localePath('/')" :aria-label="$t('common.go_to_home_page')">
            <div v-if="!darkTheme" class="logo-picture">
              <nuxt-picture
                fit="inside"
                src="/img/df_wide_light.svg"
                alt="Defi Pool Finance eran and exchange cryptocurrency"
                :img-attrs="{'class': 'desktop', 'title': 'Defi Pool home'}"
              />
              <nuxt-picture
                fit="inside"
                src="/img/df_small_light.svg"
                alt="Defi Pool Finance eran and exchange cryptocurrency"
                :img-attrs="{'class': 'mobile', 'title': 'Defi Pool home'}"
              />
            </div>
            <div v-else class="logo-picture">
              <nuxt-picture
                fit="inside"
                src="/img/df_wide_dark.svg"
                alt="Defi Pool Finance eran and exchange cryptocurrency"
                :img-attrs="{'class': 'desktop', 'title': 'Defi Pool home'}"
              />
              <nuxt-picture
                fit="inside"
                src="/img/df_small_dark.svg"
                alt="Defi Pool Finance eran and exchange cryptocurrency"
                :img-attrs="{'class': 'mobile', 'title': 'Defi Pool home'}"
              />
            </div>
          </nuxt-link>
        </div>
        <div class="menu-items">
          <HeaderNav @close="activateMenu" />
        </div>
      </div>
      <div class="right-assets">
        <div class="lang-switcher-area dropdown">
          <div class="dropdown-header">
            <Planet />
            <ChevronDown />
          </div>
          <div class="dropdown-area">
            <nuxt-link
              v-for="locale in availableLocales"
              :key="locale.code"
              :to="switchLocalePath(locale.code)"
              rel="nofollow noreferrer"
            >
              {{ locale.name }}
            </nuxt-link>
          </div>
        </div>
        <div class="settings-area">
          <button type="button" class="settings-button" :aria-label="$t('common.settings')" @click="openSettingsModal">
            <Cog />
          </button>
        </div>
        <button type="button" class="connect-wallet" :aria-label="$t('common.connect_wallet')" @click="openModalWallets">
          <Wallet />
          <span>
            {{ currentAccount }}
          </span>
        </button>
        <button rel="menuButton" type="button" :class="{'menu-btn': true, 'active': hamburgerActive}" :aria-label="$t('common.open_menu')" @click.prevent="activateMenu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

export default {
  name: 'HeaderItem',
  components: {
    ChevronDown: hydrateWhenIdle(() => import('~/components/svg/new/ChevronDown')),
    CommonModal: hydrateWhenIdle(() => import('~/components/app/interface/CommonModal')),
    Planet: hydrateWhenIdle(() => import('~/components/svg/new/Planet')),
    Cog: hydrateWhenIdle(() => import('~/components/svg/new/Cog')),
    Wallet: hydrateWhenIdle(() => import('~/components/svg/new/Wallet')),
    HeaderNav: hydrateWhenIdle(() => import('~/components/common/HeaderNav')),
    HeaderNavMobile: hydrateWhenIdle(() => import('~/components/common/HeaderNavMobile')),
    WindowModal: hydrateWhenIdle(() => import('~/components/modals/WindowModal'))
  },
  data() {
    return {
      previousHeight: 0,
      fixedData: false,
      hamburgerActive: false,
      menuActive: false,
      modalCommonTitle: ''
    }
  },
  head() {
    return {
      htmlAttrs: {
        class: this.darkTheme ? 'dark' : ''
      },
      meta: [
        {
          hid: 'og-image',
          name: 'og:image',
          content: 'seo/default.jpg'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    ...mapGetters('auth', [
      'user'
    ]),
    currentAccount() {
      return this.user !== undefined && this.user !== null && this.user.address.length > 0
        ? this.$shortenAddress(this.user.address)
        : this.$t('common.connect_wallet')
    },
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  },
  watch: {
    $route(to, from) {
      if (this.hamburgerActive) {
        this.activateMenu()
      }
      if (this.menuActive) {
        this.toggleMenu()
      }
    }
  },
  beforeMount() {
    if (document.querySelector('nav') !== null && document.querySelector('nav').classList
      .contains('opened')) {
      document.querySelector('nav').classList.remove('opened')
      document.querySelector('body').style.overflow = 'auto'
    }
  },
  mounted() {
    window.addEventListener('click', (evt) => {
      if (!evt.target.closest('.mobile-menu') &&
          !evt.target.closest('.menu-btn') &&
          this.$refs.mainMenu !== undefined &&
          this.$refs.mainMenu !== null &&
          this.$refs.mainMenu.classList.contains('opened')) {
        this.activateMenu(evt)
      }
    })
    window.addEventListener('optimizedScroll', this.stickyScroll)
  },
  beforeDestroy() {
    window.removeEventListener('optimizedScroll', this.stickyScroll)
  },
  methods: {
    ...mapActions('modal', [
      'changeModalWalletState',
      'changeModalSettingsState',
      'changeModalProfileState',
      'changeModalCommonState'
    ]),
    async openModalWallets() {
      if (this.user !== undefined && this.user !== null && this.user.address.length > 0) {
        await this.changeModalProfileState({
          state: true
        })
      } else {
        await this.changeModalWalletState({
          state: true
        })
      }
    },
    async openSettingsModal() {
      await this.changeModalSettingsState({
        state: true
      })
    },
    activateMenu() {
      this.hamburgerActive = !this.hamburgerActive
      if (!this.hamburgerActive) {
        this.$refs.mainMenu?.classList.remove('opened')
        document.querySelector('body').style.overflow = 'auto'
      } else {
        this.$refs.mainMenu?.classList.add('opened')
        document.querySelector('body').style.overflow = 'hidden'
      }
    },
    closesMenu() {
      if (this.hamburgerActive) {
        this.activateMenu()
      }
    },
    stickyScroll() {
      const headerHeight = this.$refs.header
      if (document.documentElement.scrollTop > headerHeight.offsetHeight * 0.5) {
        this.fixedData = true
      } else {
        this.fixedData = false
      }
      this.previousHeight = document.documentElement.scrollTop
    },
    closeModal() {
      this.changeModalCommonState({
        state: false
      })
    },
    changeModalTitle(titleType) {
      switch (titleType) {
        case 'loading':
          this.modalCommonTitle = this.$t('common.loading')
          break
        case 'success':
          this.modalCommonTitle = this.$t('common.success')
          break
        case 'error':
          this.modalCommonTitle = this.$t('common.smth_happen')
          break
        case 'info':
          this.modalCommonTitle = this.$t('common.notice')
          break
        case 'question':
          this.modalCommonTitle = this.$t('common.question')
          break
        default:
          this.modalCommonTitle = this.$t('common.smth_happen')
      }
    }
  }
}
</script>

<style>
  @import url('~/assets/css/new/header.css');
</style>
