var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-area" }, [
    _c(
      "picture",
      [
        _c("source", {
          attrs: { srcset: "/img/new/404.webp", type: "image/webp" },
        }),
        _vm._v(" "),
        _c("nuxt-img", {
          attrs: {
            fit: "inside",
            width: "975",
            sizes: "sm:300px md:400px lg:500px xl:500px xxl:600px",
            src: "/img/new/404.png",
            alt: "Page not found on Defi Pool Finance",
            title: "Page not found",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("h1", [
      _vm._v("\n    " + _vm._s(_vm.$t("errors.not_found_header")) + "\n  "),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "desc" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("errors.not_found_desc")) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }