var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout" },
    [
      _c("Header"),
      _vm._v(" "),
      _c("ConnectWallet"),
      _vm._v(" "),
      _c("ProfileModal"),
      _vm._v(" "),
      _c("SettingsModal"),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "content main-wrapper" },
        [
          _c("Nuxt"),
          _vm._v(" "),
          _c("div", { staticClass: "bg-texture" }, [
            _c(
              "picture",
              [
                _c("source", {
                  attrs: {
                    srcset: "/img/new/texture.webp",
                    type: "image/webp",
                  },
                }),
                _vm._v(" "),
                !_vm.darkTheme
                  ? _c("nuxt-img", {
                      attrs: {
                        width: "1920",
                        sizes:
                          "xs:320px sm:640px md:768px lg:1024px xl:1280px xxl:1536px",
                        src: "/img/new/texture.png",
                        alt: "Defi Pool crypto finances wallet",
                        title: "Defi Pool stake",
                        loading: "lazy",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer"),
      _vm._v(" "),
      _c(
        "div",
        { ref: "cookies", staticClass: "cookies-alert" },
        [
          _c("Cookie"),
          _vm._v(" "),
          _c(
            "p",
            [
              _vm._v("\n      " + _vm._s(_vm.$t("common.we")) + "\n      "),
              _c("span", { staticClass: "line-through" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.eat")) + "\n      "
                ),
              ]),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("common.cookies_text")) + "\n      "
              ),
              _c("nuxt-link", { attrs: { to: _vm.localePath("/policy") } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.here")) + "\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { type: "button", "aria-label": _vm.$t("common.ok") },
              on: { click: _vm.acceptCookies },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("common.ok")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }