var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu-area" }, [
    _c(
      "div",
      {
        class: `switcher-area ${_vm.darkTheme ? "dark" : "light"}`,
        on: { click: _vm.changeTheme },
      },
      [
        _c("div", { staticClass: "switcher" }, [
          _c("div", { staticClass: "light" }, [_c("Sun")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "dark" }, [_c("Moon")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "circle" }, [_c("SwitchCircle")], 1),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menu-item" },
      [
        _c("nuxt-link", { attrs: { to: _vm.localePath("/swap") } }, [
          _vm._v("\n      " + _vm._s(_vm.$t("common.swap")) + "\n    "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menu-item" },
      [
        _c("nuxt-link", { attrs: { to: _vm.localePath("/liquidity") } }, [
          _vm._v("\n      " + _vm._s(_vm.$t("common.liquidity")) + "\n    "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menu-item" },
      [
        _c("nuxt-link", { attrs: { to: _vm.localePath("/farm") } }, [
          _vm._v("\n      " + _vm._s(_vm.$t("common.farm")) + "\n    "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menu-item" },
      [
        _c("nuxt-link", { attrs: { to: _vm.localePath("/stake") } }, [
          _vm._v("\n      " + _vm._s(_vm.$t("common.stake")) + "\n    "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menu-item" },
      [
        _c("nuxt-link", { attrs: { to: _vm.localePath("/rewards") } }, [
          _vm._v("\n      " + _vm._s(_vm.$t("common.rewards")) + "\n    "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menu-item mr-0" },
      [
        _c("nuxt-link", { attrs: { to: _vm.localePath("/account") } }, [
          _vm._v("\n      " + _vm._s(_vm.$t("common.invest")) + "\n    "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "divider-line" }),
    _vm._v(" "),
    _c("div", { staticClass: "dropdown" }, [
      _c("p", { staticClass: "dropdown-header" }, [_c("DropsLine")], 1),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-area" }, [
        _c(
          "div",
          { staticClass: "dropdown-list" },
          [
            _c("nuxt-link", { attrs: { to: _vm.localePath("/listing") } }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("common.listing")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("nuxt-link", { attrs: { to: _vm.localePath("/lottery") } }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("common.lottery")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("nuxt-link", { attrs: { to: _vm.localePath("/analytics") } }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("common.analytics")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("nuxt-link", { attrs: { to: _vm.localePath("/faq") } }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("common.faq")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("nuxt-link", { attrs: { to: _vm.localePath("/voting") } }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("common.voting")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://medium.com/@info_19215/meeting-with-defipool-5ab219f3be2e",
                  target: "_blank",
                  rel: "nofollow noreferrer noopener",
                },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("common.blog")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("nuxt-link", { attrs: { to: _vm.localePath("/docs") } }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("common.documentation")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.contacts.github,
                  rel: "nofollow noopener",
                  target: "_blank",
                },
              },
              [_vm._v("\n          GitHub\n        ")]
            ),
            _vm._v(" "),
            _c("nuxt-link", { attrs: { to: _vm.localePath("/report") } }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("common.report")) + "\n        "
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }