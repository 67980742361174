var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "31",
        height: "32",
        viewbox: "0 0 31 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.8614 7.47616L14.4413 6.45984L15.0214 7.47616L16.0378 8.056L15.0214 8.63616L14.4413 9.65248L13.8614 8.63616L12.8451 8.056L13.8614 7.47616ZM24.0189 16.0374L23.4387 17.0538L22.4224 17.6339L23.4387 18.2141L24.0189 19.2304L24.599 18.2141L25.6154 17.6339L24.599 17.0538L24.0189 16.0374ZM14.4038 3.99072C8.66272 3.99072 3.99072 8.66208 3.99072 14.4038H5.58688C5.58688 9.54272 9.54176 5.58688 14.4038 5.58688V3.99072ZM24.0189 25.127V27.2112C24.9014 27.2112 25.615 27.9248 25.615 28.8074V32H3.26752V28.8074C3.26752 27.9248 3.98144 27.2112 4.86368 27.2112V25.1917C1.8816 22.5533 0 18.6989 0 14.4038C0 6.44896 6.44896 0 14.4038 0C17.7002 0 20.7363 1.10752 23.1632 2.9696L24.817 0.07488L26.8483 3.63072L30.4038 5.66208L27.08 7.56096C28.1811 9.59648 28.8074 11.927 28.8074 14.4042C28.8077 18.6634 26.9578 22.4899 24.0189 25.127ZM22.4477 5.66176L23.9565 6.52288L24.817 8.03008L25.6774 6.52288L27.1862 5.66176L25.6774 4.80032L24.817 3.29312L23.9565 4.80032L22.4477 5.66176ZM24.0189 28.8074H4.86368V30.4035H24.0189V28.8074ZM22.4224 27.2112V25.615H6.45984V27.2112H22.4224ZM27.2112 14.4038C27.2112 12.5075 26.7955 10.7066 26.0525 9.08608L24.817 11.249L22.7856 7.69312L19.2301 5.66208L21.9843 4.08864C19.8602 2.5232 17.2384 1.59648 14.4038 1.59648C7.3424 1.59648 1.59648 7.3424 1.59648 14.4038C1.59648 18.2307 3.28512 21.6701 5.9552 24.0189H22.8528C25.5226 21.6698 27.2112 18.2307 27.2112 14.4038ZM24.0189 13.6432L20.4634 15.6742L18.432 19.2301L16.4006 15.6742L12.8448 13.6432L16.4006 11.6118L18.432 8.056L20.4634 11.6118L24.0189 13.6432ZM20.8013 13.6432L19.2925 12.7821L18.432 11.2746L17.5715 12.7821L16.0627 13.6432L17.5715 14.5043L18.432 16.0115L19.2925 14.5043L20.8013 13.6432Z",
          fill: "#89D8FC",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }