var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2121 5104 c-83 -22 -165 -71 -224 -133 -99 -104 -137 -210 -137\n          -383 l0 -107 -487 -3 -488 -3 -67 -32 c-93 -44 -151 -100 -196 -191 l-37 -76\n          0 -237 c0 -224 1 -239 21 -265 11 -15 33 -37 48 -48 l27 -21 1979 0 1979 0 27\n          21 c15 11 37 33 48 48 20 26 21 41 21 265 l0 237 -37 76 c-45 91 -103 147\n          -196 191 l-67 32 -487 3 -488 3 0 107 c0 173 -38 279 -137 383 -61 64 -141\n          111 -228 134 -84 21 -793 21 -874 -1z m845 -330 c60 -44 69 -67 72 -185 l4\n          -109 -481 0 -481 0 0 94 c0 102 9 137 43 175 46 50 56 51 449 48 353 -2 368\n          -3 394 -23z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M746 3258 c-12 -20 -5 -208 54 -1433 38 -775 73 -1432 80 -1460 39\n          -169 193 -317 368 -354 75 -15 2549 -15 2624 0 172 36 318 173 365 343 10 36\n          34 463 82 1466 60 1230 67 1418 55 1438 l-14 22 -1800 0 -1800 0 -14 -22z\n          m1102 -245 c14 -10 35 -32 46 -47 21 -27 21 -32 21 -1126 0 -1094 0 -1099 -21\n          -1126 -39 -53 -71 -69 -134 -69 -63 0 -95 16 -134 69 -21 27 -21 35 -24 1099\n          -1 590 0 1086 3 1104 7 40 49 91 90 109 40 19 120 12 153 -13z m800 0 c14 -10\n          35 -32 46 -47 21 -27 21 -32 21 -1126 0 -1094 0 -1099 -21 -1126 -39 -53 -71\n          -69 -134 -69 -63 0 -95 16 -134 69 -21 27 -21 35 -24 1099 -1 590 0 1086 3\n          1104 7 40 49 91 90 109 40 19 120 12 153 -13z m800 0 c14 -10 35 -32 46 -47\n          21 -27 21 -32 21 -1126 0 -1094 0 -1099 -21 -1126 -39 -53 -71 -69 -134 -69\n          -63 0 -95 16 -134 69 -21 27 -21 35 -24 1099 -1 590 0 1086 3 1104 7 40 49 91\n          90 109 40 19 120 12 153 -13z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }