var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pagination-row" },
    [
      _vm.renderFirstPages
        ? _c(
            "div",
            {
              class: { item: true, active: _vm.page === 1 },
              attrs: { "data-first": "1" },
              on: {
                click: function ($event) {
                  return _vm.goToPage(1)
                },
              },
            },
            [_c("p", { staticClass: "value" }, [_vm._v("\n      1\n    ")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.renderFirstPages
        ? _c(
            "div",
            { staticClass: "item", attrs: { "data-firsts": "firsts..." } },
            [_c("p", { staticClass: "value" }, [_vm._v("\n      ...\n    ")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.renderNumber, function (index) {
        return _c(
          "div",
          {
            key: index,
            class: {
              item: true,
              active: _vm.page === _vm.getRenderIndex(index - 1),
            },
            attrs: { "data-main": "main" },
            on: {
              click: function ($event) {
                _vm.goToPage(_vm.getRenderIndex(index - 1))
              },
            },
          },
          [
            _c("p", { staticClass: "value" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.getRenderIndex(index - 1)) + "\n    "
              ),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _vm.renderLastPages
        ? _c("div", { staticClass: "item", attrs: { "data-lasts": "lasts" } }, [
            _c("p", { staticClass: "value" }, [_vm._v("\n      ...\n    ")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.renderLastPages || _vm.renderLastPage
        ? _c(
            "div",
            {
              class: { item: true, active: _vm.page === _vm.lastPage },
              attrs: { "data-last": "last" },
              on: {
                click: function ($event) {
                  return _vm.goToPage(_vm.lastPage)
                },
              },
            },
            [
              _c("p", { staticClass: "value" }, [
                _vm._v("\n      " + _vm._s(_vm.lastPage) + "\n    "),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }