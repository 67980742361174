<template>
  <svg viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor">
      <path d="M0.15856 8.32914C-0.0630018 8.08944 -0.051019 7.71325 0.18605 7.48901C0.422648 7.26477 0.794815 7.27714 1.01614 7.51683C1.40671 7.93916 2.06945 7.95401 2.47853 7.54959L3.15775 6.87812C3.38988 6.64841 3.76205 6.6534 3.98831 6.88787C4.21504 7.12281 4.21058 7.499 3.97868 7.72847L2.14157 9.54474C2.02738 9.65793 1.87913 9.71405 1.73111 9.71405C1.72547 9.71405 1.72006 9.71262 1.71442 9.71238C1.70761 9.71262 1.70103 9.71429 1.69422 9.71429C1.53727 9.71429 1.38079 9.65103 1.26519 9.52595L0.15856 8.32914ZM0.15856 1.62025C-0.0630018 1.38056 -0.051019 1.00437 0.18605 0.780127C0.422648 0.55565 0.794815 0.568253 1.01614 0.807949C1.40671 1.23028 2.06945 1.24513 2.47853 0.840707L3.15775 0.169234C3.38988 -0.0604747 3.76205 -0.055481 3.98831 0.178984C4.21504 0.413924 4.21058 0.790353 3.97868 1.01959L2.14157 2.83562C2.02738 2.94881 1.87913 3.00493 1.73111 3.00493C1.72547 3.00493 1.72006 3.0035 1.71442 3.00326C1.70761 3.0035 1.70103 3.00517 1.69422 3.00517C1.53727 3.00517 1.38079 2.94191 1.26519 2.81683L0.15856 1.62025ZM5.97461 1.29899C6.03875 1.02886 6.29791 0.851941 6.57257 0.851941H16.4127C16.7846 0.851941 17.0757 1.2015 16.9824 1.59386C16.9183 1.86399 16.6591 2.04091 16.3845 2.04091H6.54437C6.17244 2.04091 5.88133 1.69112 5.97461 1.29899ZM16.9824 8.25209C16.9183 8.52222 16.6591 8.69914 16.3845 8.69914H6.54437C6.17244 8.69914 5.88133 8.34935 5.97461 7.95723C6.03875 7.68709 6.29791 7.51017 6.57257 7.51017H16.4127C16.7844 7.51017 17.0755 7.85973 16.9824 8.25209Z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ListIcon'
}
</script>
