var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 1224 891",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { filter: "url(#filter0_f_563_3)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "680.669",
            cy: "368.913",
            rx: "337",
            ry: "220.5",
            transform: "rotate(-24.1067 680.669 368.913)",
            fill: "url(#paint0_linear_563_3)",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { filter: "url(#filter1_f_563_3)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "818.291",
            cy: "369.107",
            rx: "173.124",
            ry: "113.276",
            transform: "rotate(-24.1067 818.291 369.107)",
            fill: "url(#paint1_linear_563_3)",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { filter: "url(#filter2_f_563_3)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "542.669",
            cy: "424.913",
            rx: "337",
            ry: "220.5",
            transform: "rotate(-24.1067 542.669 424.913)",
            fill: "url(#paint2_linear_563_3)",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "filter",
            {
              attrs: {
                id: "filter0_f_563_3",
                x: "138.064",
                y: "-96.9442",
                width: "1085.21",
                height: "931.715",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _vm._v(" "),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape",
                },
              }),
              _vm._v(" "),
              _c("feGaussianBlur", {
                attrs: {
                  stdDeviation: "111",
                  result: "effect1_foregroundBlur_563_3",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "filter",
            {
              attrs: {
                id: "filter1_f_563_3",
                x: "431.59",
                y: "21.832",
                width: "773.403",
                height: "694.55",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _vm._v(" "),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape",
                },
              }),
              _vm._v(" "),
              _c("feGaussianBlur", {
                attrs: {
                  stdDeviation: "111",
                  result: "effect1_foregroundBlur_563_3",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "filter",
            {
              attrs: {
                id: "filter2_f_563_3",
                x: "0.0644531",
                y: "-40.9445",
                width: "1085.21",
                height: "931.715",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _vm._v(" "),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape",
                },
              }),
              _vm._v(" "),
              _c("feGaussianBlur", {
                attrs: {
                  stdDeviation: "111",
                  result: "effect1_foregroundBlur_563_3",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_563_3",
                x1: "819.329",
                y1: "585.43",
                x2: "252.354",
                y2: "62.773",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#0D0C48" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#100F56",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint1_linear_563_3",
                x1: "889.523",
                y1: "480.336",
                x2: "598.256",
                y2: "211.836",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#0590F4" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#0F0CFD",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint2_linear_563_3",
                x1: "681.329",
                y1: "641.43",
                x2: "114.354",
                y2: "118.773",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#2523AA" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#2523AA",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }