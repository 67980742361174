var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "manageFromArea",
      class: { "manage-area": true, active: _vm.active },
    },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "button",
          { staticClass: "back", on: { click: _vm.toggleManage } },
          [_c("ArrowBack")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "h6" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("swap.manage")) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "range-switch-area" }, [
        _c(
          "div",
          {
            class: { "switch-item": true, active: _vm.tab === "list" },
            on: {
              click: function ($event) {
                return _vm.changeTabFrom("list")
              },
            },
          },
          [
            _c("span", [
              _vm._v("\n        " + _vm._s(_vm.$t("swap.list")) + "\n      "),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: { "switch-item": true, active: _vm.tab === "tokens" },
            on: {
              click: function ($event) {
                return _vm.changeTabFrom("tokens")
              },
            },
          },
          [
            _c("span", [
              _vm._v("\n        " + _vm._s(_vm.$t("swap.tokens")) + "\n      "),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search-container" }, [
        _c(
          "div",
          { staticClass: "search-input with-loading" },
          [
            _c("Search"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.importManage,
                  expression: "importManage",
                },
              ],
              attrs: {
                type: "text",
                сlass: "input",
                placeholder: _vm.searchPlaceholder,
              },
              domProps: { value: _vm.importManage },
              on: {
                change: _vm.importTokensList,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.importManage = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.loading
              ? _c("div", { staticClass: "loading-icon" }, [_c("Loading")], 1)
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "listFromContainer",
          class: { "content-area left": true, active: _vm.tab === "list" },
        },
        [
          _c(
            "div",
            { staticClass: "list-area" },
            _vm._l(_vm.computedLists, function (importList, index) {
              return _c("div", { key: index, staticClass: "list-item" }, [
                _c("div", { staticClass: "info-area" }, [
                  _c("div", { staticClass: "image-area" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.$getImage(importList.logoURI),
                        alt: importList.name + " " + _vm.$config.appName,
                        title: importList.name,
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-area" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(importList.name) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "importListFromButton" + index,
                        refInFor: true,
                        staticClass: "settings",
                        on: {
                          click: function ($event) {
                            return _vm.toggleImportListSettingsFromonClick(
                              index
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(importList.tokens) +
                            " " +
                            _vm._s(
                              _vm.$tc("common.tokens_plural", importList.tokens)
                            ) +
                            "\n              "
                        ),
                        _c("Cog"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "importListFromArea" + index,
                            refInFor: true,
                            staticClass: "settings_window",
                          },
                          [
                            _c("div", { staticClass: "version" }, [
                              _vm._v(
                                "\n                  v\n                  " +
                                  _vm._s(
                                    importList.version.major +
                                      "." +
                                      importList.version.minor +
                                      "." +
                                      importList.version.patch
                                  ) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "actions" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: importList.uri,
                                    target: "_blank",
                                    rel: "nofollow noopener",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("swap.watch_list")) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "remove-list",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeSource(importList)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$t("swap.remove_list")) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "switch-area" },
                  [
                    _vm.loadingList[importList.id] !== undefined &&
                    _vm.loadingList[importList.id] === true
                      ? _c(
                          "div",
                          { staticClass: "loading-area" },
                          [_c("Loading")],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("Switcher", {
                      attrs: {
                        id: "importList" + index,
                        value: importList.included,
                      },
                      on: {
                        select: function ($event) {
                          return _vm.toggleImportList(importList)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "tokensFromContainer",
          class: { "content-area right": true, active: _vm.tab === "tokens" },
        },
        [
          _c(
            "div",
            { class: { "error-area": true, active: _vm.errorText.length > 0 } },
            [
              _c("div", { staticClass: "icon-area" }, [_c("Warn")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "text-area" }, [
                _c("p", [
                  _vm._v("\n          " + _vm._s(_vm.errorText) + "\n        "),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tokens-area" },
            [
              _vm._l(_vm.importedList, function (importToken, index) {
                return _c(
                  "div",
                  { key: index, class: { "token-item": true } },
                  [
                    _c("div", { staticClass: "name-area" }, [
                      _c("div", { staticClass: "image" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.$getImage(importToken.logoURI),
                            alt: importToken.name + " " + _vm.$config.appName,
                            title: importToken.name,
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(importToken.name) +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "action-area" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn action-btn delete",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.removeImportedToken(importToken)
                            },
                          },
                        },
                        [_c("TrashCan")],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "footer-area" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.importedTotal) +
                      " " +
                      _vm._s(_vm.$t("common.custom")) +
                      " " +
                      _vm._s(
                        _vm.$tc("common.tokens_plural", _vm.importedTotal)
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("p", { staticClass: "tip" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("swap.custom_tokens_tip")) + "\n    "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }