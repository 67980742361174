var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 25 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10.8641 20.379C10.8246 20.379 10.7855 20.3725 10.7481 20.3597C9.31601 19.9039 8.01402 19.1114 6.95129 18.0486C5.88856 16.9859 5.09605 15.6839 4.64019 14.2518C4.62185 14.1985 4.61639 14.1416 4.62426 14.0858C4.63214 14.03 4.65312 13.9768 4.6855 13.9307L9.9904 6.36114C13.3795 1.96275 18.8428 -0.391456 24.6172 0.0534139C24.7022 0.0598893 24.782 0.0965382 24.8422 0.156735C24.9025 0.216931 24.9392 0.296702 24.9458 0.381626C25.3896 6.1553 23.0365 11.6193 18.6509 14.9992L18.6381 15.0088L11.0685 20.313C11.0088 20.3556 10.9374 20.3787 10.8641 20.379ZM5.3751 14.1904C5.80312 15.4526 6.51593 16.5992 7.45832 17.5416C8.4007 18.484 9.54737 19.1968 10.8095 19.6248L18.2217 14.4298C22.334 11.2576 24.5755 6.16065 24.2555 0.744799C18.8329 0.423722 13.737 2.66805 10.5651 6.78461L5.3751 14.1904Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M23.7482 7.60977C23.7256 7.60985 23.703 7.60782 23.6808 7.60371C22.1241 7.30713 20.6932 6.54767 19.5753 5.42467C18.4522 4.30668 17.6928 2.87579 17.3962 1.31917C17.3785 1.22621 17.3984 1.13001 17.4516 1.05173C17.5048 0.973453 17.5869 0.91951 17.6798 0.90177C17.7728 0.884029 17.869 0.903944 17.9473 0.957132C18.0255 1.01032 18.0795 1.09243 18.0972 1.18539C18.3671 2.6015 19.058 3.9032 20.0797 4.92022C21.0967 5.94191 22.3984 6.63286 23.8145 6.90269C23.9016 6.91944 23.9793 6.96801 24.0325 7.03894C24.0857 7.10987 24.1106 7.19808 24.1023 7.28636C24.0941 7.37464 24.0532 7.45667 23.9877 7.51646C23.9222 7.57626 23.8368 7.60952 23.7482 7.60977Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M15.5982 12.1955C14.9518 12.1951 14.3256 11.9707 13.8262 11.5604C13.3268 11.1501 12.9851 10.5793 12.8594 9.94531C12.7336 9.31133 12.8316 8.65336 13.1366 8.0835C13.4415 7.51364 13.9347 7.06716 14.5319 6.82013C15.1292 6.57309 15.7936 6.54079 16.412 6.72872C17.0304 6.91666 17.5646 7.3132 17.9234 7.85078C18.2822 8.38837 18.4435 9.03373 18.3799 9.67693C18.3162 10.3201 18.0315 10.9213 17.5742 11.3781C17.3149 11.6378 17.0068 11.8437 16.6677 11.9839C16.3286 12.1242 15.9652 12.1961 15.5982 12.1955ZM15.5982 7.32045C15.1166 7.32037 14.6498 7.4873 14.2775 7.79278C13.9052 8.09826 13.6503 8.52339 13.5562 8.99574C13.4622 9.46809 13.5349 9.95843 13.7619 10.3832C13.9889 10.808 14.3562 11.1409 14.8011 11.3252C15.246 11.5096 15.7411 11.5339 16.202 11.3941C16.6629 11.2544 17.0611 10.9591 17.3286 10.5587C17.5962 10.1582 17.7167 9.67738 17.6695 9.19809C17.6223 8.71879 17.4103 8.27069 17.0698 7.93014C16.8769 7.73633 16.6476 7.58266 16.395 7.47801C16.1424 7.37336 15.8716 7.31981 15.5982 7.32045Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M13.6717 24.9729H13.6503C13.5929 24.9694 13.5373 24.9521 13.488 24.9225C13.4387 24.8929 13.3974 24.8518 13.3674 24.8027L10.5601 20.2092C10.5108 20.1284 10.4956 20.0314 10.5178 19.9394C10.54 19.8474 10.5978 19.768 10.6786 19.7186C10.7593 19.6693 10.8564 19.6541 10.9484 19.6763C11.0404 19.6985 11.1198 19.7563 11.1691 19.8371L13.7088 23.9929L15.6314 21.5581C16.0552 21.0184 16.3676 20.3999 16.5503 19.7384C16.7331 19.077 16.7826 18.3859 16.6959 17.7051L16.4612 15.9C16.4543 15.8531 16.4568 15.8054 16.4685 15.7595C16.4802 15.7136 16.501 15.6705 16.5296 15.6328C16.5581 15.595 16.5939 15.5633 16.6349 15.5395C16.6758 15.5157 16.7211 15.5003 16.7681 15.4942C16.815 15.4881 16.8627 15.4914 16.9084 15.5039C16.9541 15.5164 16.9968 15.5379 17.0341 15.5671C17.0714 15.5963 17.1025 15.6327 17.1256 15.674C17.1487 15.7153 17.1633 15.7609 17.1686 15.8079L17.4033 17.6135C17.5021 18.3888 17.4458 19.176 17.2375 19.9293C17.0293 20.6826 16.6734 21.387 16.1904 22.0015L13.9503 24.8387C13.9169 24.8806 13.8746 24.9144 13.8263 24.9376C13.7781 24.9608 13.7253 24.9729 13.6717 24.9729Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M4.97661 14.4919C4.91105 14.4919 4.84674 14.4739 4.79074 14.4398L0.197921 11.6325C0.148899 11.6026 0.107832 11.5612 0.0782127 11.512C0.0485932 11.4628 0.0312968 11.4071 0.0277942 11.3498C0.0242917 11.2925 0.0346865 11.2351 0.0580949 11.1827C0.0815033 11.1302 0.117233 11.0842 0.162246 11.0485L2.99949 8.80846C3.61406 8.32562 4.31846 7.96975 5.07176 7.76152C5.82506 7.55329 6.61224 7.49686 7.38754 7.5955L9.19306 7.83025C9.24012 7.83556 9.28565 7.85019 9.32699 7.87328C9.36834 7.89638 9.40467 7.92747 9.43388 7.96475C9.46308 8.00204 9.48457 8.04476 9.49709 8.09043C9.50961 8.1361 9.51291 8.18381 9.5068 8.23077C9.50069 8.27774 9.48529 8.32301 9.4615 8.36396C9.43771 8.40491 9.40601 8.44071 9.36824 8.46928C9.33047 8.49785 9.28738 8.51861 9.24151 8.53035C9.19563 8.5421 9.14787 8.54459 9.10102 8.53769L7.29586 8.30294C6.61514 8.2164 5.92401 8.26594 5.2626 8.44869C4.60119 8.63143 3.98266 8.94375 3.44294 9.36749L1.00703 11.2911L5.16284 13.8308C5.22916 13.8714 5.28042 13.9325 5.30879 14.0049C5.33717 14.0773 5.34111 14.157 5.32003 14.2319C5.29895 14.3067 5.25399 14.3726 5.19199 14.4195C5.13 14.4665 5.05437 14.4919 4.97661 14.4919Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M4.73117 25C4.66062 25 4.59166 24.9791 4.53301 24.9398C4.47435 24.9006 4.42864 24.8449 4.40164 24.7798C4.37465 24.7146 4.36758 24.6429 4.38134 24.5737C4.3951 24.5045 4.42907 24.4409 4.47894 24.391L7.66509 21.2049C7.698 21.1708 7.73737 21.1436 7.78089 21.1249C7.82442 21.1062 7.87123 21.0964 7.9186 21.096C7.96597 21.0956 8.01295 21.1046 8.05679 21.1225C8.10063 21.1405 8.14047 21.167 8.17396 21.2005C8.20746 21.2339 8.23395 21.2738 8.25189 21.3176C8.26982 21.3615 8.27885 21.4084 8.27844 21.4558C8.27803 21.5032 8.26819 21.55 8.24949 21.5935C8.23079 21.637 8.20361 21.6764 8.16954 21.7093L4.98339 24.8955C4.9165 24.9624 4.82578 25 4.73117 25Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M0.356676 20.6255C0.286128 20.6255 0.217169 20.6046 0.158515 20.5654C0.0998615 20.5262 0.0541474 20.4704 0.027152 20.4053C0.000156564 20.3401 -0.00690816 20.2684 0.00685089 20.1992C0.0206099 20.13 0.054575 20.0664 0.104452 20.0165L3.2906 16.8304C3.32351 16.7963 3.36288 16.7691 3.4064 16.7504C3.44993 16.7317 3.49674 16.7219 3.54411 16.7215C3.59148 16.7211 3.63846 16.7301 3.6823 16.748C3.72614 16.766 3.76598 16.7925 3.79947 16.826C3.83297 16.8595 3.85946 16.8993 3.8774 16.9431C3.89533 16.987 3.90436 17.034 3.90395 17.0813C3.90354 17.1287 3.89369 17.1755 3.875 17.219C3.8563 17.2626 3.82912 17.3019 3.79505 17.3348L0.608899 20.521C0.542011 20.5879 0.451284 20.6255 0.356676 20.6255Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M0.572867 24.7838C0.50232 24.7838 0.43336 24.7629 0.374707 24.7237C0.316053 24.6845 0.270339 24.6287 0.243344 24.5636C0.216348 24.4984 0.209283 24.4267 0.223043 24.3575C0.236802 24.2883 0.270767 24.2247 0.320644 24.1748L3.74974 20.7457C3.81703 20.6807 3.90714 20.6448 4.00068 20.6456C4.09422 20.6464 4.1837 20.6839 4.24984 20.7501C4.31599 20.8162 4.35351 20.9057 4.35432 20.9992C4.35513 21.0928 4.31917 21.1829 4.25419 21.2502L0.825091 24.6793C0.758203 24.7462 0.667476 24.7838 0.572867 24.7838Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }