var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2477 5106 c-83 -31 -147 -103 -166 -186 -7 -30 -11 -716 -11 -2045\n          l0 -2000 -702 701 c-765 763 -720 724 -837 724 -92 0 -169 -46 -214 -128 -37\n          -68 -43 -125 -22 -198 l16 -59 927 -928 c532 -533 946 -940 972 -955 38 -23\n          56 -27 120 -27 64 0 82 4 120 27 26 15 440 422 972 955 925 926 927 928 943\n          986 45 157 -59 314 -216 325 -132 10 -75 58 -856 -722 l-703 -700 -2 2024 c-3\n          2228 2 2051 -63 2128 -64 76 -189 111 -278 78z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }