var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 13 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.86603 13.9996C9.49335 14.0278 11.8933 12.4845 13 10.0553C12.3035 10.3651 11.5492 10.5168 10.7895 10.5C7.81145 10.4966 5.39809 8.03635 5.39474 5.00052C5.42455 2.94701 6.53604 1.06866 8.30391 0.0837702C7.82713 0.0218867 7.34664 -0.00582048 6.86603 0.00101477C3.07393 0.00101477 0 3.13474 0 7.00032C0 10.866 3.07393 13.9996 6.86603 13.9996Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }