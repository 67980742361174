<template>
  <div class="loader-area">
    <div class="loading-spinner">
      <svg class="loading-spinner__circle-svg" viewBox="25 25 50 50">
        <circle
          class="loading-spinner__circle-stroke"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIcon'
}
</script>

<style>
    .loader-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        stroke: rgb(var(--cta-pink-light))
    }
    .dark .loader-area {
       stroke: rgb(var(--cta-cyan-light))
    }
    .loading-spinner {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
    }
    .loading-spinner:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
    .loading-spinner__circle-svg {
        animation: loading-spinner-rotate 1.28973s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .loading-spinner__circle-stroke {
        stroke: inherit;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: loading-spinner-dash 2s ease-in-out infinite;
        stroke-linecap: round;
        stroke-width: 4px;
        stroke: inherit
    }
    @keyframes loading-spinner-rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes loading-spinner-dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
    @keyframes loading-spinner-color {
        100%,
        0% {
            /* stroke: #7d0fe6; */
            stroke: rgb(var(--cta-cyan-normal))
        }
        40% {
            /* stroke: #28dcd7; */
            stroke: rgb(var(--cta-cyan-light))
        }
        66% {
            /* stroke: #ff6100; */
            stroke: rgb(var(--cta-pink-light))
        }
        80%,
        90% {
            /* stroke: #7d0fe6; */
            stroke: rgb(var(--cta-pink-normal))
        }
    }
</style>
