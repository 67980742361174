<template>
  <div ref="modalArea" :class="{ 'modal-container': true, 'connect-wallet': true, 'active': modalOpen }">
    <div class="modal-wrap">
      <div ref="modalInner" class="modal-content-wrap">
        <div class="modal-content">
          <div class="header">
            <div class="h2">
              {{ $t('common.roi_calculator') }}
            </div>
            <div class="close-area" @click="closeModal">
              <Plus />
            </div>
          </div>
          <div class="staked-calculator">
            <p class="subheader">
              {{ $t('stake.fle_staked') }}:
            </p>
            <div class="input-container">
              <div class="upper-row">
                <input v-model="amount" type="number" class="roi-input" @input="calculateRoiAmount">
                <span>
                  {{ currency }}
                </span>
              </div>
              <div class="lower-row">
                {{ currency === $config.floatieSymbol ? $formatCryptoAmount(amountSecond, 15) : $priceFormat(amountSecond, 2) }}
                {{ currencySecond }}
              </div>
              <div class="swap-area" @click="swapRoiInputs">
                <Swap />
              </div>
            </div>
            <div class="amount-pips">
              <div class="pip" @click="setAmount(100)">
                $100
              </div>
              <div class="pip" @click="setAmount(100)">
                $1000
              </div>
              <div class="pip grow" @click="setAmount(0)">
                {{ $t('stake.my_balance') }}
              </div>
              <div class="hint-icon wide-tooltip" flow="left" :tooltip="$t('stake.my_balance_desc')">
                <Question />
              </div>
            </div>
            <p class="subheader">
              {{ $t('stake.stake_for') }}:
            </p>
            <div v-if="type === 'flexible'" class="duration-pips">
              <div v-for="(dur, ind) in daysFlexible" :key="ind" :class="{'pip': true, 'active': dur === days}" @click="changeDays(dur)">
                {{ daysDisplay(dur) }}
              </div>
              <div class="pip" @click="changeWeeks(daysFlexible[daysFlexible.length - 1])">
                {{ $t('swap.max') }}
              </div>
            </div>
            <div v-else class="duration-pips">
              <div v-for="(dur, ind) in daysFixed" :key="ind" :class="{'pip': true, 'active': dur === days}" @click="changeDays(dur)">
                {{ daysDisplay(dur, 'w') }}
              </div>
              <div class="pip" @click="changeDays(daysFixed[daysFixed.length - 1])">
                {{ $t('swap.max') }}
              </div>
            </div>
            <div v-if="type === 'fixed'" class="duration-input">
              <div class="field-area">
                <input v-model="days" type="number" step="0.0000001" min="0" @input="changeDays">
                <p class="text hint">
                  {{ $num2str([$t('common.week'), $t('common.weeks'), $t('common.many_weeks')], days) }}
                </p>
              </div>
            </div>
            <div class="arrow-down">
              <ArrowDown />
            </div>
            <div class="result-area">
              <p class="subheader">
                {{ $t('stake.current_roi') }}:
              </p>
              <div class="h4">
                ${{ $priceFormat(roi, 2) }}
              </div>
              <div class="second">
                ~{{ $formatCryptoAmount(roiSecond, 15) + ' ' + token.symbol }} ({{ $priceFormat(roiPercent, 2) }}%)
              </div>
            </div>
            <div class="spoiler-area">
              <div :class="{'spoiler': true, 'active': spoilerActive}" @click="toggleSpoiler">
                <p>
                  {{ !spoilerActive ? $t('lottery.details') : $t('lottery.hide') }}
                </p>
                <ChevronDown />
              </div>
              <div :class="{'spoiler-content': true, 'active': spoilerActive}">
                <div class="table-data">
                  <div class="table-data-row">
                    <div class="text">
                      APR
                    </div>
                    <div class="value">
                      {{ $priceFormat(apr, 2) }}%
                    </div>
                  </div>
                </div>
                <ul class="list">
                  <li class="item">
                    {{ $t('stake.current_rates') }}
                  </li>
                  <li class="item">
                    {{ $t('stake.no_guarantee') }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

export default {
  name: 'ConnectWalletComponent',
  components: {
    ArrowDown: hydrateWhenIdle(() => import('~/components/svg/ArrowDown')),
    ChevronDown: hydrateWhenIdle(() => import('~/components/svg/new/ChevronDown')),
    Question: hydrateWhenIdle(() => import('~/components/svg/new/Question')),
    Plus: hydrateWhenIdle(() => import('~/components/svg/new/Plus')),
    Swap: hydrateWhenIdle(() => import('~/components/svg/new/Swap'))
  },
  props: {
    token: {
      type: Object,
      default: () => {
        return {
          name: 'Floatie',
          symbol: 'FLE',
          decimals: 18,
          balance: 0,
          price: 0
        }
      }
    },
    apr: {
      type: Number,
      default: () => 0
    },
    performanceFee: {
      type: Number,
      default: () => 2
    },
    deposited: {
      type: Number,
      default: () => 0
    },
    balance: {
      type: Number,
      default: () => 0
    },
    type: {
      type: String,
      default: () => 'flexible'
    }
  },
  data() {
    return {
      amount: this.deposited,
      currency: 'USD',
      amountSecond: 0,
      currencySecond: this.$config.floatieSymbol,
      days: 7,
      compound: -1,
      daysFixed: [7, 35, 70, 175, 364],
      daysFlexible: [1, 7, 30, 364, 1825],
      compounds: [1, 7, 14, 30],
      compoundIndex: [1, 0.142857142, 0.071428571, 0.033333333],
      roi: 0,
      roiSecond: 0,
      roiPercent: 0,
      spoilerActive: false
    }
  },
  computed: {
    ...mapGetters(['floatieToUsd']),
    ...mapGetters('modal', [
      'modalRoiOpen'
    ]),
    modalOpen() {
      return this.modalRoiOpen !== undefined ? this.modalRoiOpen : false
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClickEvents)
    this.calculateRoi()
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapActions('modal', [
      'changeModalRoiState'
    ]),
    toggleMinified() {
      this.isMinified = !this.isMinified
    },
    async closeModal() {
      this.cleareForm()
      await this.changeModalRoiState({
        state: false
      })
    },
    cleareForm() {
      if (this.currency !== this.$config.floatieSymbol) {
        this.swapRoiInputs()
      }
      this.setAmount()
      this.days = 7
      this.compound = 0
      this.spoilerActive = false
    },
    changeDays(newDays) {
      if (this.type === 'fixed') {
        this.days = newDays < 364 ? newDays : 364
      } else {
        this.days = newDays
      }
    },
    daysDisplay(days, onlyTimePeriod = '') {
      let timeString = Math.floor(days / 364)
      if ((timeString >= 1 && onlyTimePeriod.length === 0) || onlyTimePeriod === 'y') {
        return timeString + this.$t('common.y')
      }
      timeString = Math.floor(days / 30)
      if ((timeString >= 1 && onlyTimePeriod.length === 0) || onlyTimePeriod === 'm') {
        return timeString + this.$t('common.m')
      }
      timeString = Math.floor(days / 7)
      if ((timeString > 1 && onlyTimePeriod.length === 0) || onlyTimePeriod === 'w') {
        return timeString + this.$t('common.w')
      } else {
        return days + this.$t('common.d')
      }
    },
    calculateRoiAmount() {
      if (this.currency === this.$config.floatieSymbol) {
        this.amountSecond = this.amount * this.floatieToUsd
      } else {
        this.amountSecond = this.amount / this.floatieToUsd
      }
      this.calculateRoi()
    },
    swapRoiInputs() {
      const currency = this.currency
      const amount = this.amount
      this.currency = this.currencySecond
      this.amount = this.amountSecond
      this.currencySecond = currency
      this.amountSecond = amount
    },
    setAmount(amount = 0) {
      if (amount === 0) {
        const fleBalance = this.balance + this.deposited
        amount = fleBalance * this.floatieToUsd
      }
      if (this.currency === this.$config.floatieSymbol) {
        this.amount = amount / this.floatieToUsd
        this.amountSecond = amount
      } else {
        this.amount = amount
        this.amountSecond = amount / this.floatieToUsd
      }
      this.calculateRoi()
    },
    toggleSpoiler() {
      this.spoilerActive = !this.spoilerActive
    },
    calculateRoi() {
      const usdAmount = this.currency === this.$config.floatieSymbol ? parseFloat(this.amountSecond) : parseFloat(this.amount)
      const compoundFrequency = this.compound !== -1 ? this.compoundIndex[this.compound] : 0
      const interestBreakdown = this.$getInterestBreakdown(
        this.type,
        usdAmount,
        this.apr,
        this.floatieToUsd, // todo should be token price from db (after adding more staking options)
        compoundFrequency,
        this.performanceFee
      )
      const daysArray = this.type === 'flexible' ? this.daysFlexible : this.daysFixed
      const hasInterest = !Number.isNaN(interestBreakdown[daysArray.indexOf(this.days)])
      this.roiSecond = hasInterest ? interestBreakdown[daysArray.indexOf(this.days)] : 0
      this.roi = hasInterest ? this.roiSecond * this.floatieToUsd : 0
      this.roiPercent = hasInterest
        ? this.$priceFormat(this.roi / usdAmount * 100, 2)
        : 0
    },
    handleClickEvents(evt) {
      if (
        this.$refs.modalArea !== undefined &&
          this.$refs.modalArea.contains(evt.target) &&
          !this.$refs.modalInner.contains(evt.target)
      ) {
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped>
  @import url('../../assets/css/new/modal.css');
  @import url('../../assets/css/new/interfaces/roi.css');
</style>
