var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 32 31",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.61538 17.36C10.3193 17.36 11.985 16.8509 13.4018 15.8972C14.8186 14.9434 15.9229 13.5878 16.575 12.0017C17.227 10.4156 17.3977 8.67037 17.0652 6.98662C16.7328 5.30286 15.9123 3.75624 14.7074 2.54232C13.5025 1.3284 11.9674 0.501707 10.2962 0.166788C8.62494 -0.168132 6.89267 0.0037609 5.31842 0.660729C3.74416 1.3177 2.39862 2.43024 1.45195 3.85765C0.505284 5.28507 0 6.96326 0 8.68C0 10.9821 0.90769 13.1899 2.52339 14.8177C4.13909 16.4455 6.33044 17.36 8.61538 17.36ZM8.61538 1.24C10.0759 1.24 11.5037 1.67635 12.7181 2.49387C13.9325 3.31139 14.879 4.47336 15.4379 5.83284C15.9968 7.19232 16.143 8.68826 15.8581 10.1315C15.5732 11.5747 14.8699 12.9004 13.8371 13.9409C12.8043 14.9814 11.4885 15.69 10.0561 15.977C8.62358 16.2641 7.13878 16.1168 5.78941 15.5537C4.44005 14.9905 3.28673 14.0369 2.4753 12.8134C1.66387 11.5899 1.23077 10.1515 1.23077 8.68C1.23077 6.70679 2.00879 4.8144 3.39367 3.41913C4.77856 2.02386 6.65686 1.24 8.61538 1.24Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M8.17846 12.8929C8.29334 13.0093 8.44936 13.0751 8.61231 13.0758C8.69351 13.0759 8.77392 13.0597 8.84891 13.0283C8.9239 12.997 8.99198 12.9509 9.04923 12.8929L12.7969 9.1171C12.9115 9.00094 12.9759 8.8438 12.9759 8.68C12.9759 8.51621 12.9115 8.35907 12.7969 8.2429L9.04923 4.4671C8.99208 4.40946 8.92421 4.36373 8.8495 4.33253C8.77479 4.30132 8.69472 4.28527 8.61384 4.28527C8.53297 4.28527 8.4529 4.30132 8.37819 4.33253C8.30348 4.36373 8.23561 4.40946 8.17846 4.4671L4.43077 8.2429C4.31615 8.35907 4.25182 8.51621 4.25182 8.68C4.25182 8.8438 4.31615 9.00094 4.43077 9.1171L8.17846 12.8929ZM8.61538 5.7815L11.4892 8.68L8.61538 11.5785L5.73538 8.68L8.61538 5.7815Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M25.8462 17.36C27.0633 17.36 28.2531 16.9964 29.265 16.3151C30.277 15.6338 31.0658 14.6655 31.5316 13.5326C31.9973 12.3997 32.1192 11.1531 31.8818 9.95044C31.6443 8.74776 31.0582 7.64303 30.1976 6.77594C29.3369 5.90886 28.2404 5.31836 27.0467 5.07913C25.853 4.83991 24.6156 4.96269 23.4912 5.43195C22.3667 5.90121 21.4056 6.69588 20.7294 7.71547C20.0532 8.73505 19.6923 9.93376 19.6923 11.16C19.6923 12.8043 20.3407 14.3813 21.4947 15.5441C22.6488 16.7068 24.2141 17.36 25.8462 17.36ZM25.8462 6.2C26.8198 6.2 27.7717 6.4909 28.5813 7.03591C29.3909 7.58093 30.0219 8.35557 30.3945 9.26189C30.7671 10.1682 30.8646 11.1655 30.6746 12.1277C30.4847 13.0898 30.0158 13.9736 29.3273 14.6673C28.6388 15.3609 27.7616 15.8333 26.8066 16.0247C25.8516 16.2161 24.8617 16.1179 23.9622 15.7424C23.0626 15.367 22.2937 14.7313 21.7528 13.9156C21.2118 13.1 20.9231 12.141 20.9231 11.16C20.9231 9.84453 21.4418 8.58293 22.365 7.65275C23.2883 6.72257 24.5405 6.2 25.8462 6.2Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M30.7692 18.6H1.23077C1.06756 18.6 0.911034 18.6653 0.795627 18.7816C0.68022 18.8979 0.615385 19.0556 0.615385 19.22C0.615385 19.3844 0.68022 19.5421 0.795627 19.6584C0.911034 19.7747 1.06756 19.84 1.23077 19.84H14.6615L9.99384 25.327C9.89852 25.4393 9.84614 25.5822 9.84615 25.73V30.38C9.84615 30.5444 9.91099 30.7021 10.0264 30.8184C10.1418 30.9347 10.2983 31 10.4615 31H21.5385C21.7017 31 21.8582 30.9347 21.9736 30.8184C22.089 30.7021 22.1538 30.5444 22.1538 30.38V25.73C22.1539 25.5822 22.1015 25.4393 22.0062 25.327L17.3385 19.84H30.7692C30.9324 19.84 31.089 19.7747 31.2044 19.6584C31.3198 19.5421 31.3846 19.3844 31.3846 19.22C31.3846 19.0556 31.3198 18.8979 31.2044 18.7816C31.089 18.6653 30.9324 18.6 30.7692 18.6ZM20.9231 29.76H11.0769V25.9594L16 20.1717L20.9231 25.9594V29.76Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }