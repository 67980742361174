var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingScreen &&
    !_vm.successScreen &&
    !_vm.errorScreen &&
    !_vm.infoScreen &&
    !_vm.questionScreen
    ? _c("div", [_vm._v("\n  Common modal for site states.\n")])
    : _vm.loadingScreen
    ? _c(
        "div",
        { staticClass: "loading-area" },
        [
          _c("ModalLoader"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.header !== _vm.$t("common.loading")
                      ? _vm.header
                      : _vm.loadingText
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _vm.header !== _vm.$t("common.loading")
              ? _c("p", { staticClass: "desc" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadingText) + "\n    "),
                ])
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm.successScreen
    ? _c("div", { staticClass: "success-area" }, [
        _c("div", { staticClass: "icon-area" }, [_c("CheckIcon")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "desc-area" }, [
          _c("div", { staticClass: "h5" }, [
            _vm._v("\n      " + _vm._s(_vm.header) + "\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v("\n      " + _vm._s(_vm.successText) + "\n    "),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _vm.externalLink !== null
            ? _c(
                "a",
                {
                  staticClass: "btn btn-outline pink",
                  attrs: {
                    href: _vm.externalLink,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("common.see_details")) +
                        "\n        "
                    ),
                    _c(
                      "span",
                      { staticClass: "icon icon-right" },
                      [_c("NewTabIcon")],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn pink",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeModal(false)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("common.close")) + "\n    ")]
          ),
        ]),
      ])
    : _vm.errorScreen
    ? _c(
        "div",
        { staticClass: "error-area" },
        [
          _c("WarnTriangle"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v("\n      " + _vm._s(_vm.header) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.errorText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("common.dismiss")) + "\n    ")]
            ),
          ]),
        ],
        1
      )
    : _vm.infoScreen
    ? _c(
        "div",
        { staticClass: "info-area" },
        [
          _c("Info"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v("\n      " + _vm._s(_vm.header) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.infoText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("common.dismiss")) + "\n    ")]
            ),
          ]),
        ],
        1
      )
    : _vm.questionScreen
    ? _c(
        "div",
        { staticClass: "info-area" },
        [
          _c("QuestionCircle"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v("\n      " + _vm._s(_vm.header) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.questionText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "buttons-area" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline cyan",
                  attrs: { type: "button" },
                  on: { click: _vm.dismissQuestion },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("common.dismiss")) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn pink",
                  attrs: { type: "button" },
                  on: { click: _vm.approveQuestion },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.confirm")) + "\n      "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }