<template>
  <div v-if="!loadingScreen && !successScreen && !errorScreen" class="confirm-area">
    <div class="lp-area">
      <div class="tokens-pair">
        <div class="logos">
          <nuxt-img
            :quality="80"
            placeholder
            :lazy="true"
            :src="pair.from_token.address !== $config.ZERO ? $getImageByAddress(pair.from_token.address) : $getImage(pair.from_token.logoURI)"
            :alt="pair.from_token.name + ' ' + pair.from_token.symbol"
            :title="pair.from_token.name"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          <nuxt-img
            :quality="80"
            placeholder
            :lazy="true"
            :src="pair.to_token.address !== $config.ZERO ? $getImageByAddress(pair.to_token.address) : $getImage(pair.to_token.logoURI)"
            :alt="pair.to_token.name + ' ' + pair.to_token.symbol"
            :title="pair.to_token.name"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
        </div>
      </div>
      <div class="h2">
        {{ lpTokensToReceive }}
      </div>
    </div>
    <div class="lp-tokens-header">
      {{ pair.from_token.symbol.toUpperCase() + ' / ' + pair.to_token.symbol.toUpperCase() + ' ' + $t('liquidity.pool_tokens') }}
    </div>
    <div class="desc">
      <p>
        {{ $t('liquidity.estimated_output', {percent: slippageTolerance}) }}
      </p>
    </div>
    <hr>
    <div class="desc-table">
      <div class="desc-row">
        <div class="title">
          {{ pair.from_token.symbol.toUpperCase() + ' ' + $t('liquidity.deposited') }}
        </div>
        <div class="value">
          <nuxt-img
            :src="pair.from_token.address !== $config.ZERO ? $getImageByAddress(pair.from_token.address) : $getImage(pair.from_token.logoURI)"
            :alt="pair.from_token.name + ' ' + pair.from_token.symbol"
            :title="pair.from_token.name"
            :quality="80"
            placeholder
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          {{ $preciseTokenValue(pair.from_value) }}
        </div>
      </div>
      <div class="desc-row">
        <div class="title">
          {{ pair.to_token.symbol.toUpperCase() + ' ' + $t('liquidity.deposited') }}
        </div>
        <div class="value">
          <nuxt-img
            :src="pair.to_token.address !== $config.ZERO ? $getImageByAddress(pair.to_token.address) : $getImage(pair.to_token.logoURI)"
            :alt="pair.to_token.name + ' ' + pair.to_token.symbol"
            :title="pair.to_token.name"
            :quality="80"
            placeholder
            :lazy="true"
            @error="$event.target.src = require('~/static/img/default_token.svg')"
          />
          {{ $preciseTokenValue(pair.to_value) }}
        </div>
      </div>
      <div class="desc-row">
        <div class="title">
          {{ $t('liquidity.rates') }}
        </div>
        <div class="value column">
          <div class="value-row">
            1 {{ pair.from_token.symbol.toUpperCase() }} = {{ toPrice }}
          </div>
          <div class="value-row">
            1 {{ pair.to_token.symbol.toUpperCase() }} = {{ fromPrice }}
          </div>
        </div>
      </div>
      <div class="desc-row">
        <div class="title">
          {{ $t('liquidity.pool_share') }}
        </div>
        <div class="value">
          {{ $preciseTokenValue(shareOfPool) + '%' }}
        </div>
      </div>
    </div>
    <hr>
    <div class="buttons-area">
      <button :class="{ 'btn pink': true, 'disabled': loading }" type="button" :disabled="loading" @click="confirmSupply">
        <span v-if="!loading">
          {{ $t('liquidity.confirm_supply') }}
        </span>
        <span v-else>
          <Loading />
        </span>
      </button>
    </div>
  </div>
  <div v-else-if="loadingScreen" class="loading-area">
    <ModalLoader />
    <div class="desc-area">
      <div v-if="loadingHeader.length === 0" class="h5">
        {{ $t('liquidity.supplying') }}
        {{ $preciseTokenValue(pair.from_value) + ' ' + pair.from_token.symbol.toUpperCase() }}
        {{ $t('common.and') }}
        {{ $preciseTokenValue(pair.to_value) + ' ' + pair.to_token.symbol.toUpperCase() }}
      </div>
      <div v-else class="h5">
        {{ loadingHeader }}
      </div>
      <p v-if="loadingText.length === 0">
        {{ $t('swap.confirm_transaction') }}
      </p>
      <p v-else>
        {{ loadingText }}
      </p>
    </div>
  </div>
  <div v-else-if="successScreen" class="success-area">
    <div class="icon-area">
      <CheckIcon />
    </div>
    <div class="desc-area">
      <div class="h4">
        {{ $t('swap.success') }}
      </div>
      <p class="desc">
        {{ $t('swap.success_text') }}
      </p>
      <hr>
      <div class="buttons-area">
        <a class="btn btn-outline pink" :href="transactionLink(transaction.tx)" rel="nofollow noopener noreferrer" target="_blank">
          <span class="text">
            {{ $t('swap.view_explorer') }}
            <span class="icon icon-right">
              <NewTabIcon />
            </span>
          </span>
        </a>
        <button type="button" class="btn pink" @click="closeModal(true)">
          {{ $t('common.close') }}
        </button>
      </div>
    </div>
  </div>
  <div v-else-if="errorScreen" class="error-area">
    <WarnTriangle />
    <div class="desc-area">
      <div class="h4">
        {{ $t('swap.error') }}
      </div>
      <p class="desc">
        {{ errorText }}
      </p>
      <hr>
      <button type="button" class="btn pink" @click="closeModal(false)">
        {{ $t('common.dismiss') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  components: {
    CheckIcon: hydrateWhenVisible(() => import('~/components/svg/CheckIcon')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    ModalLoader: hydrateWhenVisible(() => import('~/components/app/interface/ModalLoader')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  props: {
    pair: {
      type: Object,
      default: () => {
        return {
          from_value: null,
          from_value_usd: 0,
          from_token: {
            symbol: '',
            address: '',
            decimals: 18
          },
          to_value: null,
          to_value_usd: 0,
          to_token: {
            symbol: '',
            address: '',
            decimals: 18
          },
          method: 'swapExactETHForTokens'
        }
      }
    },
    transaction: {
      type: Object,
      default: () => {
        return {
          gasFee: 3000000,
          tx: '',
          loading: false,
          pending: false
        }
      }
    },
    pairData: {
      type: Object,
      default: () => {
        return null
      }
    },
    type: {
      type: String,
      default: () => {
        return 'Global'
      }
    }
  },
  data() {
    return {
      errorText: this.$t('liquidity.error_text'),
      loading: false,
      loadingScreen: false,
      successScreen: false,
      errorScreen: false,
      loadingHeader: '',
      loadingText: ''
    }
  },
  computed: {
    ...mapGetters('settings', [
      'slippageTolerance',
      'deadline',
      'expertMode',
      'multihops'
    ]),
    isModalOpened() {
      return this.$store.getters['modal/modal' + this.type + 'Open'] !== undefined ? this.$store.getters['modal/modal' + this.type + 'Open'] : false
    },
    fromPrice() {
      return this.pairData !== null && this.pairData.reserves.reserve1 !== 0
        ? this.$preciseTokenValue(this.pairData.reserves.reserve0 / this.pairData.reserves.reserve1) + ' ' + this.pair.from_token.symbol.toUpperCase()
        : (this.pairData !== null ? 0 : this.$t('liquidity.tbd'))
    },
    toPrice() {
      return this.pairData !== null && this.pairData.reserves.reserve0 !== 0
        ? this.$preciseTokenValue(this.pairData.reserves.reserve1 / this.pairData.reserves.reserve0) + ' ' + this.pair.to_token.symbol.toUpperCase()
        : (this.pairData !== null ? 0 : this.$t('liquidity.tbd'))
    },
    shareOfPool() {
      if (this.pairData !== null && this.pairData.reserves.reserve0 !== 0) {
        let fromValue = 0
        if (this.pair.from_value) {
          fromValue = parseFloat(this.pair.from_value)
        }
        return fromValue / (this.pairData.reserves.reserve0 + fromValue) * 100
      } else {
        return '100%'
      }
    },
    lpTokensToReceive() {
      if (this.pairData !== null && this.pairData.reserves.reserve0 !== 0 && this.pairData.reserves.reserve1 !== 0) {
        let fromValue = 0
        let toValue = 0
        if (this.pair.from_value) {
          fromValue = parseFloat(this.pair.from_value)
        }
        if (this.pair.to_value) {
          toValue = parseFloat(this.pair.to_value)
        }
        const fromLpValue = fromValue * this.pairData.totalSupply / this.pairData.reserves.reserve0
        const toLpValue = toValue * this.pairData.totalSupply / this.pairData.reserves.reserve1
        return Math.min(fromLpValue, toLpValue)
      } else {
        return this.$t('liquidity.tbd')
      }
    }
  },
  watch: {
    isModalOpened(modalOpen) {
      if (!modalOpen) {
        this.returnInitState()
      }
    }
  },
  methods: {
    confirmSupply() {
      this.loading = true
      this.initLoadingScreen()
      this.$emit('provideLiquidity')
    },
    transactionLink(hash) {
      return this.$config.chainExplorer + '/tx/' + hash
    },
    closeModal(redirect = false) {
      this.$emit('closeGlobalModal')
      this.returnInitState()
      if (redirect) {
        this.$router.push({
          path: this.localePath('/liquidity')
        })
      }
    },
    returnInitState() {
      if (this.transaction.pending === false) {
        this.$emit('initConfirmState')
        this.loading = false
        this.loadingScreen = false
        this.successScreen = false
        this.errorScreen = false
        this.loadingHeader = ''
        this.loadingText = ''
      } else {
        this.initLoadingScreen()
      }
    },
    rejectTransaction(error) {
      if (error === null || typeof error !== 'object') {
        this.initErrorScreen()
      } else {
        switch (error.type.toLowerCase()) {
          case 'denied':
            this.errorText = this.$t('common.deined_operation')
            break
          case 'error':
            this.errorText = error.message !== undefined && error.message.length > 0 ? this.$t('swap.error_text_with_error', { error: error.message }) : this.$t('liquidity.error_text')
            break
          default:
            this.errorText = this.$t('liquidity.error_text')
        }
        this.initErrorScreen()
      }
    },
    initLoadingScreen(header = '', text = '') {
      this.$emit('initLoading')
      this.loading = false
      this.loadingScreen = true
      this.errorScreen = false
      this.successScreen = false
      this.loadingHeader = header
      this.loadingText = text
    },
    initSuccessScreen() {
      this.$emit('initSuccess')
      this.loading = false
      this.loadingScreen = false
      this.errorScreen = false
      this.successScreen = true
    },
    initErrorScreen() {
      this.$emit('initError')
      this.loading = false
      this.loadingScreen = false
      this.successScreen = false
      this.errorScreen = true
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modalContent.css');
</style>
