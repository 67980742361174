var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingScreen && !_vm.successScreen && !_vm.errorScreen
    ? _c("div", { staticClass: "confirm-area" }, [
        _vm.method === "buy"
          ? _c("div", { staticClass: "buy-area" }, [
              _c("div", { staticClass: "inputs-area" }, [
                _c("div", { staticClass: "input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.from,
                        expression: "form.from",
                      },
                    ],
                    attrs: { type: "number", placeholder: "100..." },
                    domProps: { value: _vm.form.from },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "from", $event.target.value)
                        },
                        _vm.changeFrom,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "token-info" }, [
                    _c(
                      "picture",
                      [
                        _c("source", {
                          attrs: {
                            srcset: "/img/new/floatie-token.webp",
                            type: "image/webp",
                          },
                        }),
                        _vm._v(" "),
                        _c("nuxt-img", {
                          attrs: {
                            fit: "inside",
                            src: "/img/new/floatie-token.png",
                            alt: "Buy DFP to become DEX platform investor",
                            title: "Buy $DFP tokens",
                            loading: "lazy",
                            placeholder: "",
                            quality: 80,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "token-symbol" }, [
                      _vm._v("\n            DFP\n          "),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "hr" }),
                _vm._v(" "),
                _c("div", { staticClass: "input usdt" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.to,
                        expression: "form.to",
                      },
                    ],
                    attrs: { type: "number", placeholder: "10..." },
                    domProps: { value: _vm.form.to },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "to", $event.target.value)
                        },
                        _vm.changeTo,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "token-info" }, [
                    _c(
                      "picture",
                      [
                        _c("source", {
                          attrs: {
                            srcset: `/icons/${_vm.form.currency.toLowerCase()}.webp`,
                            type: "image/webp",
                          },
                        }),
                        _vm._v(" "),
                        _c("nuxt-img", {
                          attrs: {
                            fit: "inside",
                            src: `/icons/${_vm.form.currency.toLowerCase()}.png`,
                            alt: `Sell ${_vm.form.currency.toUpperCase()} for purchasing Defi Pool investment tokens`,
                            title: `${_vm.form.currency.toUpperCase()} for sell`,
                            loading: "lazy",
                            placeholder: "",
                            quality: 80,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "token-symbol" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.currentCurrency) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "inputs-area" }, [
                _c("div", { staticClass: "input column" }, [
                  _c("div", { staticClass: "label-area" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invest.currency")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "description" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invest.currency_desc")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field-area" },
                    [
                      _c("Select", {
                        attrs: { options: _vm.currenciesList },
                        on: { select: _vm.changeCurrency },
                        model: {
                          value: _vm.form.currency,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "currency", $$v)
                          },
                          expression: "form.currency",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc-table" }, [
                _c("div", { staticClass: "desc-row" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("invest.price")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          1 DFP = " +
                        _vm._s(_vm.price) +
                        " " +
                        _vm._s(_vm.currentCurrency) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.form.currency === "inner"
                  ? _c("div", { staticClass: "desc-row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("common.account_balance")) +
                            "\n          "
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "hint-icon wide-tooltip",
                            attrs: {
                              tooltip: _vm.$t("invest.topup_account_tip"),
                            },
                          },
                          [_c("Question")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "hint-icon cta wide-tooltip",
                            attrs: {
                              tooltip: _vm.$t("invest.topup_account_cta"),
                            },
                            on: { click: _vm.openTopup },
                          },
                          [_c("PlusIcon")],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.user !== null && _vm.user !== undefined
                        ? _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$priceFormat(_vm.user.usdt_balance, 2)
                                ) +
                                " USDT\n        "
                            ),
                          ])
                        : _c("div", { staticClass: "value" }, [
                            _vm._v("\n          0 USDT\n        "),
                          ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: { "error-info": true, active: _vm.error !== null } },
                [
                  _c(
                    "div",
                    { staticClass: "icon-item" },
                    [_c("WarnTriangle")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-area" }, [
                    _vm._v("\n        " + _vm._s(_vm.error) + "\n      "),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.method === "transfer"
          ? _c("div", { staticClass: "buy-area" }, [
              _c("div", { staticClass: "modal-description" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("invest.transfer_description")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "inputs-area" }, [
                _c("div", { staticClass: "input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.to,
                        expression: "form.to",
                      },
                    ],
                    attrs: { type: "number", placeholder: "10..." },
                    domProps: { value: _vm.form.to },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "to", $event.target.value)
                        },
                        _vm.checkTo,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "token-info" }, [
                    _c(
                      "picture",
                      [
                        _c("source", {
                          attrs: {
                            srcset: "/img/new/floatie-token.webp",
                            type: "image/webp",
                          },
                        }),
                        _vm._v(" "),
                        _c("nuxt-img", {
                          attrs: {
                            fit: "inside",
                            src: "/img/new/floatie-token.png",
                            alt: "Buy DFP to become DEX platform investor",
                            title: "Buy $DFP tokens",
                            loading: "lazy",
                            placeholder: "",
                            quality: 80,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "token-symbol" }, [
                      _vm._v("\n            DFP\n          "),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "inputs-area" }, [
                _c("div", { staticClass: "input column" }, [
                  _c("div", { staticClass: "label-area" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invest.wallet_for_transfer")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "description" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invest.wallet_for_transfer_desc")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-area" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.address,
                          expression: "form.address",
                        },
                      ],
                      staticClass: "smaller",
                      attrs: { type: "text", placeholder: "0xA11ce..." },
                      domProps: { value: _vm.form.address },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "address", $event.target.value)
                          },
                          _vm.checkAddress,
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc-table" }, [
                _c("div", { staticClass: "desc-row" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("invest.dfp_balance")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.balance !== null
                            ? _vm.$priceFormat(_vm.balance, 0)
                            : "..."
                        ) +
                        " DFP\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: { "error-info": true, active: _vm.error !== null } },
                [
                  _c(
                    "div",
                    { staticClass: "icon-item" },
                    [_c("WarnTriangle")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-area" }, [
                    _vm._v("\n        " + _vm._s(_vm.error) + "\n      "),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.method === "buy" && _vm.error === null
          ? _c("div", { staticClass: "buttons-area" }, [
              _c(
                "button",
                {
                  class: {
                    "btn pink": true,
                    disabled: _vm.loading || _vm.form.from === null,
                  },
                  attrs: {
                    type: "button",
                    disabled: _vm.loading || _vm.form.from === null,
                  },
                  on: { click: _vm.buyTokens },
                },
                [
                  !_vm.loading
                    ? _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("invest.buy_now")) +
                            "\n      "
                        ),
                      ])
                    : _c("span", [_c("Loading")], 1),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.method === "transfer" && _vm.error === null
          ? _c("div", { staticClass: "buttons-area" }, [
              _c(
                "button",
                {
                  class: {
                    "btn pink": true,
                    disabled:
                      _vm.loading ||
                      _vm.form.to === null ||
                      _vm.form.address === null,
                  },
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.loading ||
                      _vm.form.to === null ||
                      _vm.form.address === null,
                  },
                  on: { click: _vm.transferTokens },
                },
                [
                  !_vm.loading
                    ? _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("invest.send_dfp")) +
                            "\n      "
                        ),
                      ])
                    : _c("span", [_c("Loading")], 1),
                ]
              ),
            ])
          : _vm._e(),
      ])
    : _vm.loadingScreen
    ? _c(
        "div",
        { staticClass: "loading-area" },
        [
          _c("ModalLoader"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.method === "buy"
                      ? _vm.$t("invest.buying", {
                          amount: _vm.form.from,
                          tokens: _vm.$numberToString(_vm.form.from, [
                            _vm.$t("invest.token"),
                            _vm.$t("invest.tokens"),
                            _vm.$t("invest.many_tokens"),
                          ]),
                        })
                      : _vm.$t("invest.transfering", {
                          amount: _vm.form.to,
                          tokens: _vm.$numberToString(_vm.form.to, [
                            _vm.$t("invest.token"),
                            _vm.$t("invest.tokens"),
                            _vm.$t("invest.many_tokens"),
                          ]),
                        })
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("invest.confirm_transaction")) +
                  "\n    "
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm.successScreen
    ? _c("div", { staticClass: "success-area" }, [
        _c("div", { staticClass: "icon-area" }, [_c("CheckIcon")], 1),
        _vm._v(" "),
        _vm.method === "buy"
          ? _c("div", { staticClass: "desc-area" }, [
              _c("div", { staticClass: "h4" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("invest.bought_title")) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("invest.bought_text")) + "\n    "
                ),
              ]),
            ])
          : _c("div", { staticClass: "desc-area" }, [
              _c("div", { staticClass: "h4" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("invest.transfered_title")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("invest.transfered_text")) +
                    "\n    "
                ),
              ]),
            ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _vm.form.hash !== null
            ? _c(
                "a",
                {
                  staticClass: "btn btn-outline pink",
                  attrs: {
                    href: _vm.form.hash,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("swap.view_explorer")) +
                        "\n        "
                    ),
                    _c(
                      "span",
                      { staticClass: "icon icon-right" },
                      [_c("NewTabIcon")],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn pink",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeModal(false)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("common.close")) + "\n    ")]
          ),
        ]),
      ])
    : _vm.errorScreen
    ? _c(
        "div",
        { staticClass: "error-area" },
        [
          _c("WarnTriangle"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h4" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("invest.error")) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.errorText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.returnInitState()
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("common.dismiss")) + "\n    ")]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }