var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalArea",
      class: { "modal-container": true, active: _vm.isModalOpened },
    },
    [
      _c("div", { staticClass: "modal-wrap" }, [
        _c(
          "div",
          { ref: "modalInner", staticClass: "modal-content-wrap no-scroll" },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "h2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.your_wallet")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "close-area", on: { click: _vm.closeModal } },
                  [_c("Plus")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "range-switch-area" }, [
                _c(
                  "div",
                  {
                    class: {
                      "switch-item": true,
                      active: _vm.tab === "wallet",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeTab("wallet")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.my_wallet")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "switch-item": true,
                      active: _vm.tab === "transactions",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeTab("transactions")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.transactions")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: { "switch-item": true, active: _vm.tab === "topup" },
                    on: {
                      click: function ($event) {
                        return _vm.changeTab("topup")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.topup")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content-area" }, [
                _c(
                  "div",
                  {
                    class: {
                      "info wallet": true,
                      active: _vm.tab === "wallet",
                    },
                  },
                  [
                    _c("div", { staticClass: "account" }, [
                      _c("div", { staticClass: "h4" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("common.connected_address")) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "desc-table" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "name address" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.user !== null && _vm.user !== undefined
                                    ? _vm.user.address
                                    : "..."
                                ) +
                                "\n                  "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon-area",
                                attrs: { tooltip: _vm.copyText, flow: "left" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyAddress($event)
                                  },
                                },
                              },
                              [_c("Copy")],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "desc-table" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("common.account_balance")) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.user !== null && _vm.user !== undefined
                          ? _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$priceFormat(_vm.user.usdt_balance, 2)
                                  ) +
                                  " USDT\n                "
                              ),
                            ])
                          : _c("div", { staticClass: "value" }, [
                              _c(
                                "div",
                                { staticClass: "icon-area" },
                                [_c("Loading")],
                                1
                              ),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n                  BNB " +
                              _vm._s(_vm.$t("common.balance")) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadingBnb,
                                expression: "!loadingBnb",
                              },
                            ],
                            staticClass: "value",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.bnbBalance !== 0
                                    ? _vm.bnbBalance
                                    : _vm.$t("common.computing")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loadingBnb,
                                expression: "loadingBnb",
                              },
                            ],
                            staticClass: "value",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "icon-area" },
                              [_c("Loading")],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n                  $FLOATIE " +
                              _vm._s(_vm.$t("common.balance")) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadingFloatie,
                                expression: "!loadingFloatie",
                              },
                            ],
                            staticClass: "value",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.floatieBalance !== null
                                    ? _vm.floatieBalance
                                    : _vm.$t("common.computing")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loadingFloatie,
                                expression: "loadingFloatie",
                              },
                            ],
                            staticClass: "value",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "icon-area" },
                              [_c("Loading")],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.user !== undefined && _vm.user !== null,
                            expression: "user !== undefined && user !== null",
                          },
                        ],
                        staticClass: "buttons-area",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-outline cyan",
                            attrs: {
                              href:
                                _vm.$config.chainExplorer +
                                "/address/" +
                                (_vm.user !== undefined && _vm.user !== null
                                  ? _vm.user.address
                                  : ""),
                              rel: "nofollow noopener noreferrer",
                              target: "_blank",
                            },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("common.view_on_scan")) +
                                  "\n                  "
                              ),
                              _c(
                                "span",
                                { staticClass: "icon icon-right" },
                                [_c("NewTabIcon")],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            class: {
                              "btn btn-outline pink": true,
                              disabled: _vm.logoutLoading,
                            },
                            attrs: { type: "button" },
                            on: { click: _vm.logoutWallet },
                          },
                          [
                            !_vm.logoutLoading
                              ? _c("span", { staticClass: "text" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("common.logout")) +
                                      "\n                "
                                  ),
                                ])
                              : _c("span", [_c("Loading")], 1),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "info transactions": true,
                      active: _vm.tab === "transactions",
                    },
                  },
                  [
                    _c("div", { staticClass: "h4" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("common.recent_transactions")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("TransactionsList"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "info transactions": true,
                      active: _vm.tab === "topup",
                    },
                  },
                  [
                    _c("div", { staticClass: "h4" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("common.topup_header")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "desc" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("common.topup_desc")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "inputs-area" }, [
                      _c("div", { staticClass: "label-area" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("common.topup_send")) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.topup.amount,
                              expression: "topup.amount",
                            },
                          ],
                          attrs: { type: "number", placeholder: "10..." },
                          domProps: { value: _vm.topup.amount },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.topup,
                                  "amount",
                                  $event.target.value
                                )
                              },
                              _vm.changeAmount,
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "token-info" }, [
                          _c(
                            "picture",
                            [
                              _c("source", {
                                attrs: {
                                  srcset: "/icons/usdt.webp",
                                  type: "image/webp",
                                },
                              }),
                              _vm._v(" "),
                              _c("nuxt-img", {
                                attrs: {
                                  fit: "inside",
                                  src: "/icons/usdt.png",
                                  alt: "Transfer USDT for top up your Defi Pool inner Account balance for web3 operations",
                                  title: "USDT for top up Defi Pool account",
                                  loading: "lazy",
                                  placeholder: "",
                                  quality: 80,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "token-symbol" }, [
                            _vm._v(
                              "\n                    USDT\n                  "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "desc-table" }, [
                        _c("div", { staticClass: "desc-table" }, [
                          _c("div", { staticClass: "desc-row" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.$t("common.current_account_balance")
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.user !== null && _vm.user !== undefined
                              ? _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm.$priceFormat(
                                          _vm.user.usdt_balance,
                                          2
                                        )
                                      ) +
                                      " USDT\n                    "
                                  ),
                                ])
                              : _c("div", { staticClass: "value" }, [
                                  _c(
                                    "div",
                                    { staticClass: "icon-area" },
                                    [_c("Loading")],
                                    1
                                  ),
                                ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc-row" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.$t("invest.usdt_balance")) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.topup.usdtBalance !== null
                                      ? _vm.$priceFormat(
                                          _vm.topup.usdtBalance,
                                          2
                                        )
                                      : "..."
                                  ) +
                                  " USDT\n                    "
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "error-info": true,
                              active: _vm.topup.error !== null,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "icon-item" },
                              [_c("WarnTriangle")],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-area" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.topup.error) +
                                  "\n                  "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "button-area" }, [
                          _c(
                            "button",
                            {
                              class: {
                                "btn pink": true,
                                disabled:
                                  _vm.topup.loading ||
                                  _vm.topup.amount === null ||
                                  _vm.topup.error !== null,
                              },
                              attrs: {
                                type: "button",
                                disabled:
                                  _vm.topup.loading ||
                                  _vm.topup.amount === null ||
                                  _vm.topup.error !== null,
                              },
                              on: { click: _vm.topupUsdtBalance },
                            },
                            [
                              !_vm.topup.loading
                                ? _c("span", [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.$t("common.topup")) +
                                        "\n                    "
                                    ),
                                  ])
                                : _c("span", [_c("Loading")], 1),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }