var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "26",
        height: "20",
        viewbox: "0 0 26 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.6915 0.375293C6.31019 -1.41118 0 3.41255 0 9.99986C0 16.5998 6.32169 21.4077 12.6915 19.6244C19.0816 21.4148 25.3781 16.5739 25.3781 9.99986C25.3781 3.41848 19.0738 -1.41292 12.6915 0.375293ZM1.49283 9.99986C1.49283 5.26655 5.38115 1.42396 10.1339 1.50049C7.28959 3.26402 5.39096 6.41419 5.39096 9.99986C5.39096 13.5855 7.28959 16.7357 10.1339 18.4992C5.38125 18.5758 1.49283 14.7333 1.49283 9.99986ZM15.3846 18.5006C10.6972 18.5006 6.88384 14.6871 6.88384 9.99986C6.88384 5.31258 10.6972 1.49914 15.3846 1.49914C20.0719 1.49914 23.8853 5.31258 23.8853 9.99986C23.8853 14.6871 20.0719 18.5006 15.3846 18.5006Z",
          fill: "#0C275C",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M16.4426 9.25386H14.3264C13.6901 9.25386 13.1725 8.73625 13.1725 8.09996C13.1725 7.46152 13.6892 6.946 14.3262 6.946C14.3309 6.94605 14.3356 6.946 14.3405 6.94605H16.3682C16.7951 6.94998 17.2158 7.13997 17.5228 7.46754C17.8047 7.76835 18.2771 7.78373 18.5779 7.50173C18.8786 7.21983 18.894 6.7475 18.6121 6.4467C17.906 5.69337 17.0938 5.45322 16.131 5.45322V4.55891C16.131 4.14669 15.7968 3.8125 15.3845 3.8125C14.9723 3.8125 14.6381 4.14669 14.6381 4.55891V5.45322C12.6563 5.45322 11.6797 6.74243 11.6797 8.09996C11.6797 9.5594 12.867 10.7467 14.3265 10.7467H16.4426C17.0789 10.7467 17.5965 11.2643 17.5965 11.9006C17.5965 12.539 17.0798 13.0546 16.4428 13.0546C16.4381 13.0546 16.4334 13.0545 16.4285 13.0545H14.2163C13.9089 13.0516 13.598 12.9472 13.3172 12.7523C12.9784 12.5173 12.5134 12.6014 12.2784 12.9401C12.0434 13.2788 12.1275 13.7438 12.4662 13.9788C13.3788 14.612 14.1581 14.5473 14.638 14.5473V15.4416C14.638 15.8539 14.9722 16.1881 15.3844 16.1881C15.7967 16.1881 16.1309 15.8539 16.1309 15.4416V14.5473C18.1362 14.5473 19.0893 13.2335 19.0893 11.9006C19.0893 10.4412 17.902 9.25386 16.4426 9.25386Z",
          fill: "#0C275C",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }