var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M1622 5109 c-47 -14 -109 -79 -123 -131 -23 -89 12 -182 88 -229 l38\n  -24 1191 -5 1190 -5 76 -37 c91 -45 147 -103 191 -196 l32 -67 5 -1590 5\n  -1590 24 -38 c13 -21 42 -50 64 -65 34 -23 52 -27 107 -27 55 0 73 4 107 27\n  22 15 51 44 64 65 l24 38 0 1605 c0 1468 -1 1610 -17 1665 -43 157 -109 273\n  -213 378 -68 70 -184 148 -263 177 -156 59 -61 55 -1362 57 -740 1 -1208 -2\n  -1228 -8z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M1050 4295 c-337 -69 -587 -329 -639 -665 -9 -55 -11 -452 -9 -1520\n  3 -1575 -1 -1455 58 -1612 66 -177 261 -372 438 -438 154 -58 82 -55 1257 -55\n  1175 0 1103 -3 1257 55 178 66 372 260 438 438 59 156 55 43 55 1657 0 1351\n  -1 1485 -17 1540 -43 157 -109 273 -213 378 -68 70 -184 148 -263 177 -155 58\n  -74 55 -1232 57 -851 1 -1078 -1 -1130 -12z m2222 -422 c93 -44 151 -100 196\n  -191 l37 -76 0 -1451 0 -1451 -37 -76 c-45 -91 -103 -147 -196 -191 l-67 -32\n  -1050 0 -1050 0 -67 32 c-93 44 -151 100 -196 191 l-37 76 0 1450 0 1451 32\n  67 c17 37 50 87 72 111 44 48 135 101 197 116 23 5 468 9 1069 8 l1030 -2 67\n  -32z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }