<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">

    <g
      transform="translate(0,512) scale(0.1,-0.1)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M2330 4869 c-696 -70 -1337 -461 -1716 -1049 -498 -771 -497 -1763 4
        -2525 101 -154 175 -245 303 -374 129 -128 220 -202 374 -303 912 -599 2136
        -470 2907 305 352 354 576 801 655 1308 24 159 24 500 0 659 -81 513 -298 944
        -661 1306 -194 193 -361 316 -581 429 -393 200 -851 287 -1285 244z m-675
        -414 c50 -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0 41 39 80 80 80 19 0 40
        -9 55 -25z m-269 -158 c26 -23 36 -70 19 -97 -3 -5 -48 -43 -99 -84 -260 -209
        -453 -468 -596 -798 -22 -50 -48 -97 -57 -104 -45 -34 -123 5 -123 61 0 51 93
        255 187 409 127 208 335 443 511 574 91 68 117 74 158 39z m2029 -82 c16 -15
        25 -36 25 -55 0 -19 -9 -40 -25 -55 -15 -16 -36 -25 -55 -25 -19 0 -40 9 -55
        25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16 36 25 55 25 19 0 40 -9 55 -25z
        m-858 -250 c98 -29 146 -116 123 -222 -31 -136 -185 -336 -312 -403 -116 -62
        -226 -37 -303 68 -129 178 -59 402 159 507 128 61 239 78 333 50z m-1222 -550
        c50 -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0 41 39 80 80 80 19 0 40 -9 55
        -25z m2499 13 c86 -26 166 -136 166 -228 0 -124 -116 -240 -240 -240 -63 0
        -114 23 -165 75 -102 101 -102 229 0 330 70 71 145 90 239 63z m-979 -573 c16
        -15 25 -36 25 -55 0 -19 -9 -40 -25 -55 -15 -16 -36 -25 -55 -25 -19 0 -40 9
        -55 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16 36 25 55 25 19 0 40 -9 55
        -25z m-1440 -86 c63 -30 123 -90 155 -154 20 -41 25 -65 25 -135 -1 -77 -4
        -91 -34 -148 -38 -70 -70 -101 -145 -140 -43 -22 -64 -26 -136 -26 -70 -1 -94
        4 -135 24 -260 128 -238 495 35 594 63 23 170 16 235 -15z m2156 -478 c140
        -66 215 -221 179 -372 -39 -167 -166 -303 -292 -315 -140 -14 -240 123 -255
        348 -12 185 43 313 153 354 52 19 158 12 215 -15z m-1196 -76 c50 -49 15 -135
        -55 -135 -19 0 -40 9 -55 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16 36
        25 55 25 19 0 40 -9 55 -25z m-880 -800 c50 -49 15 -135 -55 -135 -19 0 -40 9
        -55 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16 36 25 55 25 19 0 40 -9 55
        -25z m819 13 c86 -26 166 -136 166 -228 0 -124 -116 -240 -240 -240 -92 0
        -202 80 -228 166 -28 94 -8 169 63 239 70 71 145 90 239 63z m1101 -253 c50
        -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0 41 39 80 80 80 19 0 40 -9 55 -25z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CookieIcon'
}
</script>
