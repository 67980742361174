var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loader-area" }, [
    _c("div", { staticClass: "loading-spinner" }, [
      _c(
        "svg",
        {
          staticClass: "loading-spinner__circle-svg",
          attrs: { viewBox: "25 25 50 50" },
        },
        [
          _c("circle", {
            staticClass: "loading-spinner__circle-stroke",
            attrs: {
              cx: "50",
              cy: "50",
              r: "20",
              fill: "none",
              "stroke-width": "2",
              "stroke-miterlimit": "10",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }