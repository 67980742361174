var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingScreen &&
    !_vm.successScreen &&
    !_vm.errorScreen &&
    !_vm.confirmScreen
    ? _c("div", { staticClass: "settings-area" }, [
        _c("div", { staticClass: "setting-item amount-area" }, [
          _c("div", { staticClass: "header-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("liquidity.amount")) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "h5 switcher", on: { click: _vm.changeView } },
              [
                _c("SwapArrows"),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.simpleView
                        ? _vm.$t("pool.expert_view")
                        : _vm.$t("pool.simple_view")
                    ) +
                    "\n      "
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h3" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$priceFormat(_vm.percent, 0, ".", ",")) +
                "%\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.simpleView
            ? _c("div", { staticClass: "percent-area" }, [
                _c(
                  "div",
                  { staticClass: "handler" },
                  [
                    _c("veeno", {
                      ref: "percentSlider",
                      attrs: {
                        connect: "",
                        handles: [0, 0],
                        range: {
                          min: 0,
                          max: 100,
                        },
                        set: [0, parseFloat(_vm.percent)],
                      },
                      on: { change: _vm.updateVeeno, set: _vm.setPercentValue },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "range-switch-area" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.percent === 25,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setPercent(25)
                        },
                      },
                    },
                    [_c("span", [_vm._v("\n            25%\n          ")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.percent === 50,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setPercent(50)
                        },
                      },
                    },
                    [_c("span", [_vm._v("\n            50%\n          ")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.percent === 75,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setPercent(75)
                        },
                      },
                    },
                    [_c("span", [_vm._v("\n            75%\n          ")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.percent === 100,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setPercent(100)
                        },
                      },
                    },
                    [_c("span", [_vm._v("\n            100%\n          ")])]
                  ),
                ]),
              ])
            : _c("div", { staticClass: "inputs-area" }, [
                _c("div", { staticClass: "input" }, [
                  _c("div", { staticClass: "label-area" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("pool.input")) +
                          " LP\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "balance" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("common.balance")) +
                          ": " +
                          _vm._s(
                            _vm.pool !== null
                              ? _vm.$formatCryptoAmount(_vm.pool.balance, 6)
                              : "..."
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-area" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.outputs.lpToken,
                          expression: "outputs.lpToken",
                        },
                      ],
                      attrs: { type: "number", step: "0.0000001", min: "0" },
                      domProps: { value: _vm.outputs.lpToken },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.outputs,
                              "lpToken",
                              $event.target.value
                            )
                          },
                          _vm.calculateLpToken,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "max-button active",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.setMax()
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "text" }, [
                          _vm._v("\n              MAX\n            "),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "icon-area" }, [_c("ArrowDown")], 1),
                _vm._v(" "),
                _c("div", { staticClass: "input" }, [
                  _c("div", { staticClass: "label-area" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("pool.output")) +
                          " " +
                          _vm._s(_vm.pool.token0.symbol) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-area" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.outputs.token0,
                          expression: "outputs.token0",
                        },
                      ],
                      attrs: { type: "number", step: "0.0000001", min: "0" },
                      domProps: { value: _vm.outputs.token0 },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.outputs, "token0", $event.target.value)
                          },
                          _vm.calculateReserve0,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "max-button active",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.setMax()
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "text" }, [
                          _vm._v("\n              MAX\n            "),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "icon-area rotated" },
                  [_c("Plus")],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "input" }, [
                  _c("div", { staticClass: "label-area" }, [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("pool.output")) +
                          " " +
                          _vm._s(_vm.pool.token1.symbol) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field-area" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.outputs.token1,
                          expression: "outputs.token1",
                        },
                      ],
                      attrs: { type: "number", step: "0.0000001", min: "0" },
                      domProps: { value: _vm.outputs.token1 },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.outputs, "token1", $event.target.value)
                          },
                          _vm.calculateReserve1,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "max-button active",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.setMax()
                          },
                        },
                      },
                      [
                        _c("p", { staticClass: "text" }, [
                          _vm._v("\n              MAX\n            "),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow-area" }, [_c("ArrowDown")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "setting-item results-area" }, [
          _c("div", { staticClass: "result-item" }, [
            _c("div", { staticClass: "value" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.pool !== null
                      ? _vm.$formatCryptoAmount(
                          _vm.lpRatio * _vm.pool.reserves.reserve0,
                          6
                        )
                      : "..."
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "token" },
              [
                _vm.pool !== null
                  ? _c("nuxt-img", {
                      attrs: {
                        src:
                          _vm.pool.token0.address !== _vm.$config.ZERO
                            ? _vm.$getImageByAddress(_vm.pool.token0.address)
                            : _vm.$getImage(_vm.pool.token0.logoURI),
                        alt:
                          _vm.pool.token0.name + " " + _vm.pool.token0.symbol,
                        title: _vm.pool.token0.name,
                        placeholder: "",
                        lazy: true,
                      },
                      on: {
                        error: function ($event) {
                          $event.target.src = require("~/static/img/default_token.svg")
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.pool !== null
                          ? _vm.getTokenSymbol(_vm.pool.token0)
                          : "..."
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.pool !== null &&
                (_vm.pool.token0.address === _vm.$config.ZERO ||
                  _vm.pool.token0.address === _vm.$config.wbnbAddress)
                  ? _c(
                      "div",
                      { staticClass: "swap", on: { click: _vm.swapBnb } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              !_vm.receiveBnb
                                ? _vm.$t("liquidity.receive_bnb")
                                : _vm.$t("liquidity.receive_wbnb")
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "result-item" }, [
            _c("div", { staticClass: "value" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.pool !== null
                      ? _vm.$formatCryptoAmount(
                          _vm.lpRatio * _vm.pool.reserves.reserve1,
                          6
                        )
                      : "..."
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "token" },
              [
                _vm.pool !== null
                  ? _c("nuxt-img", {
                      attrs: {
                        src:
                          _vm.pool.token1.address !== _vm.$config.ZERO
                            ? _vm.$getImageByAddress(_vm.pool.token1.address)
                            : _vm.$getImage(_vm.pool.token1.logoURI),
                        alt:
                          _vm.pool.token1.name + " " + _vm.pool.token1.symbol,
                        title: _vm.pool.token1.name,
                        placeholder: "",
                        lazy: true,
                      },
                      on: {
                        error: function ($event) {
                          $event.target.src = require("~/static/img/default_token.svg")
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.pool !== null
                          ? _vm.getTokenSymbol(_vm.pool.token1)
                          : "..."
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.pool !== null &&
                (_vm.pool.token1.address === _vm.$config.ZERO ||
                  _vm.pool.token1.address === _vm.$config.wbnbAddress)
                  ? _c(
                      "div",
                      { staticClass: "swap", on: { click: _vm.swapBnb } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              !_vm.receiveBnb
                                ? _vm.$t("liquidity.receive_bnb")
                                : _vm.$t("liquidity.receive_wbnb")
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc-table" }, [
          _c("div", { staticClass: "desc-row" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("liquidity.prices")) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value column" }, [
              _c("div", { staticClass: "value-row" }, [
                _vm._v(
                  "\n          1 " +
                    _vm._s(
                      _vm.pool !== null
                        ? _vm.pool.token0.symbol.toUpperCase()
                        : "..."
                    ) +
                    " = " +
                    _vm._s(
                      _vm.toPrice +
                        " " +
                        (_vm.pool !== null
                          ? _vm.pool.token1.symbol.toUpperCase()
                          : "...")
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value-row" }, [
                _vm._v(
                  "\n          1 " +
                    _vm._s(
                      _vm.pool !== null
                        ? _vm.pool.token1.symbol.toUpperCase()
                        : "..."
                    ) +
                    " = " +
                    _vm._s(
                      _vm.fromPrice +
                        " " +
                        (_vm.pool !== null
                          ? _vm.pool.token0.symbol.toUpperCase()
                          : "...")
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-area" }, [
          _c(
            "button",
            {
              class: {
                "btn btn-outline pink": true,
                disabled: _vm.approved || _vm.loadingAllowance,
              },
              attrs: {
                type: "button",
                disabled: _vm.approved || _vm.loadingAllowance,
              },
              on: { click: _vm.approveTokens },
            },
            [
              !_vm.loadingAllowance
                ? _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("common.approve_action")) +
                        "\n      "
                    ),
                  ])
                : _c("span", { staticClass: "text" }, [_c("Loading")], 1),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: {
                "btn cyam": true,
                disabled: !_vm.approved || _vm.loadingAllowance,
              },
              attrs: {
                type: "button",
                disabled: !_vm.approved || _vm.loadingAllowance,
              },
              on: { click: _vm.initConfirmScreen },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("common.remove_action")) + "\n    "
              ),
            ]
          ),
        ]),
      ])
    : _vm.confirmScreen
    ? _c("div", { staticClass: "confirm-area" }, [
        _c("div", { staticClass: "confirm-table" }, [
          _c("div", { staticClass: "confirm-row" }, [
            _c(
              "div",
              { staticClass: "token-data" },
              [
                _c("nuxt-img", {
                  attrs: {
                    src:
                      _vm.pool.token0.address !== _vm.$config.ZERO
                        ? _vm.$getImageByAddress(_vm.pool.token0.address)
                        : _vm.$getImage(_vm.pool.token0.logoURI),
                    alt: _vm.pool.token0.name + " " + _vm.pool.token0.symbol,
                    title: _vm.pool.token0.name,
                    placeholder: "",
                    lazy: true,
                  },
                  on: {
                    error: function ($event) {
                      $event.target.src = require("~/static/img/default_token.svg")
                    },
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.pool !== null
                          ? _vm.$formatCryptoAmount(
                              _vm.lpRatio * _vm.pool.reserves.reserve0,
                              6
                            )
                          : "..."
                      ) +
                      "\n        "
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "token-symbol" }, [
              _c("div", { staticClass: "h3" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.pool.token0.symbol.toUpperCase()) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "confirm-row" }, [
            _c("div", { staticClass: "arrow-area plus" }, [_c("Plus")], 1),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "confirm-row" }, [
            _c(
              "div",
              { staticClass: "token-data" },
              [
                _c("nuxt-img", {
                  attrs: {
                    src:
                      _vm.pool.token1.address !== _vm.$config.ZERO
                        ? _vm.$getImageByAddress(_vm.pool.token1.address)
                        : _vm.$getImage(_vm.pool.token1.logoURI),
                    alt: _vm.pool.token1.name + " " + _vm.pool.token1.symbol,
                    title: _vm.pool.token1.name,
                    placeholder: "",
                    lazy: true,
                  },
                  on: {
                    error: function ($event) {
                      $event.target.src = require("~/static/img/default_token.svg")
                    },
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.pool !== null
                          ? _vm.$formatCryptoAmount(
                              _vm.lpRatio * _vm.pool.reserves.reserve1,
                              6
                            )
                          : "..."
                      ) +
                      "\n        "
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "token-symbol" }, [
              _c("div", { staticClass: "h3" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.pool.token1.symbol.toUpperCase()) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "desc" }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("liquidity.estimated_output", {
                  percent: _vm.slippageTolerance,
                })
              ) +
              "\n  "
          ),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "desc-table" }, [
          _c("div", { staticClass: "desc-row" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("liquidity.lp_burned", {
                      tokens:
                        _vm.pool.token0.symbol.toUpperCase() +
                        "/" +
                        _vm.pool.token1.symbol.toUpperCase(),
                    })
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value" }, [
              _c(
                "div",
                { staticClass: "logos" },
                [
                  _c("nuxt-img", {
                    attrs: {
                      src:
                        _vm.pool.token0.address !== _vm.$config.ZERO
                          ? _vm.$getImageByAddress(_vm.pool.token0.address)
                          : _vm.$getImage(_vm.pool.token0.logoURI),
                      alt: _vm.pool.token0.name + " " + _vm.pool.token0.symbol,
                      title: _vm.pool.token0.name,
                      placeholder: "",
                      lazy: true,
                    },
                    on: {
                      error: function ($event) {
                        $event.target.src = require("~/static/img/default_token.svg")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("nuxt-img", {
                    attrs: {
                      src:
                        _vm.pool.token1.address !== _vm.$config.ZERO
                          ? _vm.$getImageByAddress(_vm.pool.token1.address)
                          : _vm.$getImage(_vm.pool.token1.logoURI),
                      alt: _vm.pool.token1.name + " " + _vm.pool.token1.symbol,
                      title: _vm.pool.token1.name,
                      placeholder: "",
                      lazy: true,
                    },
                    on: {
                      error: function ($event) {
                        $event.target.src = require("~/static/img/default_token.svg")
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "value" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$formatCryptoAmount(_vm.lpTokensToRemove, 6)) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc-row" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("liquidity.prices")) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value column" }, [
              _c("div", { staticClass: "value-row" }, [
                _vm._v(
                  "\n          1 " +
                    _vm._s(
                      _vm.pool !== null
                        ? _vm.pool.token0.symbol.toUpperCase()
                        : "..."
                    ) +
                    " = " +
                    _vm._s(
                      _vm.toPrice +
                        " " +
                        (_vm.pool !== null
                          ? _vm.pool.token1.symbol.toUpperCase()
                          : "...")
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value-row" }, [
                _vm._v(
                  "\n          1 " +
                    _vm._s(
                      _vm.pool !== null
                        ? _vm.pool.token1.symbol.toUpperCase()
                        : "..."
                    ) +
                    " = " +
                    _vm._s(
                      _vm.fromPrice +
                        " " +
                        (_vm.pool !== null
                          ? _vm.pool.token0.symbol.toUpperCase()
                          : "...")
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "button",
            {
              class: { "btn pink": true, disabled: _vm.loading },
              attrs: { type: "button", disabled: _vm.loading },
              on: { click: _vm.confirmRemove },
            },
            [
              !_vm.loading
                ? _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("common.confirm")) +
                        "\n      "
                    ),
                  ])
                : _c("span", { staticClass: "text" }, [_c("Loading")], 1),
            ]
          ),
        ]),
      ])
    : _vm.loadingScreen
    ? _c(
        "div",
        { staticClass: "loading-area" },
        [
          _c("ModalLoader"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("liquidity.removing")) +
                  "\n      " +
                  _vm._s(
                    _vm.pool !== null
                      ? _vm.$formatCryptoAmount(
                          _vm.lpRatio * _vm.pool.reserves.reserve0,
                          6
                        ) +
                          " " +
                          _vm.pool.token0.symbol.toUpperCase()
                      : "..."
                  ) +
                  "\n      " +
                  _vm._s(_vm.$t("common.and")) +
                  "\n      " +
                  _vm._s(
                    _vm.pool !== null
                      ? _vm.$formatCryptoAmount(
                          _vm.lpRatio * _vm.pool.reserves.reserve1,
                          6
                        ) +
                          " " +
                          _vm.pool.token1.symbol.toUpperCase()
                      : "..."
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("swap.confirm_transaction")) +
                  "\n    "
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm.successScreen
    ? _c("div", { staticClass: "success-area" }, [
        _c("div", { staticClass: "icon-area" }, [_c("CheckIcon")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "desc-area" }, [
          _c("div", { staticClass: "h4" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("pools.success")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("pools.success_text")) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-area" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-outline pink",
                attrs: {
                  href: _vm.transactionLink(_vm.transaction.tx),
                  rel: "nofollow noopener",
                  target: "_blank",
                },
              },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("swap.view_explorer")) +
                      "\n          "
                  ),
                  _c(
                    "span",
                    { staticClass: "icon icon-right" },
                    [_c("NewTabIcon")],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(true)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.close")) + "\n      "
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm.errorScreen
    ? _c(
        "div",
        { staticClass: "error-area" },
        [
          _c("WarnTriangle"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h4" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("pools.error")) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.errorText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "buttons-area" }, [
              _c(
                "button",
                {
                  staticClass: "btn pink",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal(false)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.dismiss")) + "\n      "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }