<template>
  <div class="switcher-pick">
    <div class="switcher">
      <input
        :id="'switcher' + id"
        :v-model="currentState"
        :checked="currentState"
        type="checkbox"
        :true-value="true"
        :false-value="false"
        @change="selectHandler"
      >
      <label :for="'switcher' + id">Toggle</label>
    </div>
    <p v-if="label.length > 0" class="label">
      {{ label }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SwitcherComponent',
  model: {
    prop: 'value',
    event: 'select'
  },
  props: {
    id: {
      type: String,
      default: () => Math.floor(Math.random() * 10000).toString()
    },
    label: {
      type: String,
      default: () => ''
    },
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      currentState: this.value
    }
  },
  watch: {
    value(newValue) {
      this.currentState = newValue
    }
  },
  methods: {
    selectHandler() {
      this.currentState = !this.currentState
      this.$emit('select', this.currentState)
    }
  }
}
</script>

<style scoped>
.switcher-pick {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.switcher-pick .switcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.switcher-pick .label {
    margin-left: .75rem;
    font-size: 1rem;
    font-weight: 300;
    color: rgb(var(--main-text-color))
}
.switcher-pick .switcher input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}
.switcher-pick .switcher label {
    cursor: pointer;
    text-indent: -9999px;
    width: 48px;
    height: 30px;
    background: transparent;
    display: flex;
    border-radius: 5rem;
    border: 1px solid rgb(var(--main-text-color));
    position: relative;
    overflow: hidden;
}
.switcher-pick .switcher label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 20px;
    background: rgb(var(--main-text-color));
    border-radius: 5rem;
    transition: 0.3s
}
.switcher-pick .switcher input:checked + label {
    background: rgb(var(--cta-cyan-normal))
}
.switcher-pick .switcher input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}
.switcher-pick .switcher label:active:after {
    width: 24px;
}
</style>
