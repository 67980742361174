var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 851 754",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { filter: "url(#filter0_f_102_326)" } }, [
        _c("ellipse", {
          attrs: {
            cx: "425.341",
            cy: "376.713",
            rx: "213.501",
            ry: "139.694",
            transform: "rotate(155.893 425.341 376.713)",
            fill: "url(#paint0_linear_102_326)",
            "fill-opacity": "0.6",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "filter",
            {
              attrs: {
                id: "filter0_f_102_326",
                x: "0.22644",
                y: "0.22113",
                width: "850.229",
                height: "752.984",
                filterUnits: "userSpaceOnUse",
                "color-interpolation-filters": "sRGB",
              },
            },
            [
              _c("feFlood", {
                attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
              }),
              _vm._v(" "),
              _c("feBlend", {
                attrs: {
                  mode: "normal",
                  in: "SourceGraphic",
                  in2: "BackgroundImageFix",
                  result: "shape",
                },
              }),
              _vm._v(" "),
              _c("feGaussianBlur", {
                attrs: {
                  stdDeviation: "111",
                  result: "effect1_foregroundBlur_102_326",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear_102_326",
                x1: "513.186",
                y1: "513.884",
                x2: "153.988",
                y2: "182.763",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "#2523AA" } }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#2523AA",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }