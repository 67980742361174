var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2419 4280 c-428 -40 -802 -219 -1105 -532 -168 -174 -289 -366 -368\n          -587 -17 -48 -35 -88 -41 -89 -5 -1 -35 33 -65 75 -67 94 -115 123 -200 123\n          -117 0 -201 -78 -208 -194 -3 -45 1 -73 12 -97 22 -46 419 -606 458 -646 49\n          -50 91 -66 164 -61 73 5 56 -6 487 300 243 173 267 196 288 273 24 86 -27 192\n          -114 236 -32 17 -54 20 -107 17 -64 -3 -69 -6 -183 -85 -64 -45 -117 -78 -117\n          -73 0 5 11 39 25 75 159 423 527 734 973 822 108 21 355 23 462 4 177 -31 380\n          -117 530 -222 41 -29 119 -97 173 -151 141 -143 208 -166 318 -110 112 57 149\n          186 84 297 -32 55 -222 236 -322 308 -177 126 -368 214 -579 268 -181 45 -397\n          64 -565 49z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M3985 2836 c-60 -27 -650 -453 -673 -486 -72 -107 -36 -252 79 -310\n          41 -21 130 -27 171 -11 13 5 71 43 131 84 59 42 107 72 107 68 0 -5 -12 -40\n          -26 -77 -159 -423 -525 -732 -972 -821 -108 -21 -355 -23 -462 -4 -177 32\n          -381 117 -530 222 -41 29 -119 97 -173 152 -142 142 -208 165 -318 110 -106\n          -54 -147 -180 -93 -285 9 -17 55 -71 102 -121 493 -513 1266 -664 1922 -377\n          425 186 772 554 924 980 18 50 36 91 41 92 4 0 33 -35 64 -78 68 -95 116 -124\n          201 -124 118 0 201 78 208 195 5 82 6 81 -239 428 -205 290 -244 337 -295 362\n          -39 18 -128 19 -169 1z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }