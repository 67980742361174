<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
    <g
      transform="translate(0,512) scale(0.1,-0.1)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M2330 5109 c-305 -29 -646 -126 -910 -259 -273 -138 -559 -356 -755
        -576 -384 -432 -602 -931 -655 -1499 -41 -446 55 -949 260 -1355 138 -273 356
        -559 576 -755 432 -384 931 -602 1499 -655 446 -41 949 55 1355 260 273 138
        559 356 755 576 384 432 602 931 655 1499 41 446 -55 949 -260 1355 -138 273
        -356 559 -576 755 -432 384 -931 602 -1499 655 -125 11 -320 11 -445 -1z m352
        -1077 c59 -26 124 -91 151 -151 30 -64 30 -178 0 -242 -27 -60 -92 -125 -152
        -152 -64 -30 -178 -30 -242 0 -129 59 -202 206 -169 342 43 180 242 278 412
        203z m0 -900 c58 -25 124 -91 152 -151 21 -46 21 -48 21 -871 0 -823 0 -825
        -21 -871 -28 -60 -93 -125 -153 -152 -64 -30 -178 -30 -242 0 -60 27 -125 92
        -153 152 -21 46 -21 52 -24 841 -2 557 0 810 8 847 39 180 241 281 412 205z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon'
}
</script>
