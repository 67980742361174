var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 219 215",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M70.284 100.653C69.626 94.959 70.0527 88.6266 73.744 84.1078C87.3382 67.0495 92.2065 95.4611 93.4262 103.496C95.1724 115.571 99.3921 127.267 105.512 137.666C110.3 145.836 116.878 158.515 125.938 162.371C100.021 151.275 73.4361 130.757 70.284 100.653Z",
          fill: "#2FC7D3",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M74.3298 91.4893C74.898 90.1347 75.7901 88.7605 77.1878 88.1671C82.3876 85.8882 79.9087 92.829 79.1723 94.774C78.0463 97.6869 77.5237 100.797 77.591 103.834C77.6397 106.217 77.5233 109.818 79.0743 111.72C74.6459 106.264 71.2525 98.6142 74.3298 91.4893Z",
          fill: "#D1F8FB",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M100.778 189.826C98.4655 188.296 96.1193 185.888 96.7227 183.242C97.1998 180.806 99.9719 179.46 102.405 178.914C111.679 177.025 120.922 181.277 130.166 182.951C140.426 184.804 150.922 183.219 161.051 180.84C151.46 188.41 147.074 194.088 134.121 196.493C122.168 198.637 110.416 196.462 100.778 189.826Z",
          fill: "#2FC7D3",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M30.4342 124.745C30.0851 121.815 30.2353 118.754 31.5847 116.268C32.934 113.783 35.7121 111.725 38.6156 112.033C43.0742 112.557 45.1499 117.525 46.8586 121.7C54.5246 140.761 69.9565 154.594 88.7646 161.858C65.7412 164.671 33.5359 150.952 30.4342 124.745Z",
          fill: "#FD3B90",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M47.14 50.9743C48.2367 48.9104 49.4999 46.8032 51.5661 45.4637C53.466 44.1678 56.209 43.807 58.108 45.0885C60.5635 46.8468 60.8034 50.4279 60.4466 53.3649C59.9516 57.9377 58.8771 62.3064 57.2632 66.6381C55.7355 70.5919 53.7315 74.4034 52.5768 78.4376C50.6462 85.8736 52.0152 93.4257 53.5737 100.662C42.6696 86.5347 38.3603 68.1973 47.14 50.9743Z",
          fill: "#2FC7D3",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M103.674 17.5481C106.004 16.9396 108.799 17.8985 109.423 20.1348C110.071 22.0986 108.911 24.2677 107.384 25.644C105.858 27.0203 103.941 27.8764 102.167 28.9616C96.1348 32.9364 89.3317 42.1781 91.6532 50.0146C88.73 39.6691 90.8594 20.8945 103.674 17.5481Z",
          fill: "#FE3B93",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }