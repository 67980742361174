<template>
  <div ref="modalArea" :class="{'modal-container': true, 'active': isModalOpened}">
    <div class="modal-wrap">
      <div ref="modalInner" class="modal-content-wrap no-scroll">
        <div class="modal-content">
          <div class="header">
            <div class="h2">
              {{ $t('common.your_wallet') }}
            </div>
            <div class="close-area" @click="closeModal">
              <Plus />
            </div>
          </div>
          <hr>
          <div class="range-switch-area">
            <div :class="{'switch-item': true, 'active': tab === 'wallet'}" @click="changeTab('wallet')">
              {{ $t('common.my_wallet') }}
            </div>
            <div :class="{'switch-item': true, 'active': tab === 'transactions'}" @click="changeTab('transactions')">
              {{ $t('common.transactions') }}
            </div>
            <div :class="{'switch-item': true, 'active': tab === 'topup'}" @click="changeTab('topup')">
              {{ $t('common.topup') }}
            </div>
          </div>
          <div class="content-area">
            <div :class="{'info wallet': true, 'active': tab === 'wallet'}">
              <div class="account">
                <div class="h4">
                  {{ $t('common.connected_address') }}
                </div>
                <div class="desc-table">
                  <div class="row">
                    <div class="name address">
                      {{ user !== null && user !== undefined ? user.address : '...' }}
                    </div>
                    <div class="value">
                      <div class="icon-area" :tooltip="copyText" flow="left" @click="copyAddress($event)">
                        <Copy />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="desc-table">
                <div class="row">
                  <div class="name">
                    {{ $t('common.account_balance') }}
                  </div>
                  <div v-if="user !== null && user !== undefined" class="value">
                    {{ $priceFormat(user.usdt_balance, 2) }} USDT
                  </div>
                  <div v-else class="value">
                    <div class="icon-area">
                      <Loading />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="name">
                    BNB {{ $t('common.balance') }}
                  </div>
                  <div v-show="!loadingBnb" class="value">
                    {{ bnbBalance !== 0 ? bnbBalance : $t('common.computing') }}
                  </div>
                  <div v-show="loadingBnb" class="value">
                    <div class="icon-area">
                      <Loading />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="name">
                    $FLOATIE {{ $t('common.balance') }}
                  </div>
                  <div v-show="!loadingFloatie" class="value">
                    {{ floatieBalance !== null ? floatieBalance : $t('common.computing') }}
                  </div>
                  <div v-show="loadingFloatie" class="value">
                    <div class="icon-area">
                      <Loading />
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="user !== undefined && user !== null" class="buttons-area">
                <a :href="$config.chainExplorer + '/address/' + (user !== undefined && user !== null ? user.address : '')" class="btn btn-outline cyan" rel="nofollow noopener noreferrer" target="_blank">
                  <span class="text">
                    {{ $t('common.view_on_scan') }}
                    <span class="icon icon-right">
                      <NewTabIcon />
                    </span>
                  </span>
                </a>
                <button type="button" :class="{'btn btn-outline pink': true, 'disabled': logoutLoading}" @click="logoutWallet">
                  <span v-if="!logoutLoading" class="text">
                    {{ $t('common.logout') }}
                  </span>
                  <span v-else>
                    <Loading />
                  </span>
                </button>
              </div>
            </div>
            <div :class="{'info transactions': true, 'active': tab === 'transactions'}">
              <div class="h4">
                {{ $t('common.recent_transactions') }}
              </div>
              <TransactionsList />
            </div>
            <div :class="{'info transactions': true, 'active': tab === 'topup'}">
              <div class="h4">
                {{ $t('common.topup_header') }}
              </div>
              <div class="desc">
                {{ $t('common.topup_desc') }}
              </div>
              <div class="inputs-area">
                <div class="label-area">
                  {{ $t('common.topup_send') }}
                </div>
                <div class="input">
                  <input v-model="topup.amount" type="number" placeholder="10..." @input="changeAmount">
                  <div class="token-info">
                    <picture>
                      <source srcset="/icons/usdt.webp" type="image/webp">
                      <nuxt-img
                        fit="inside"
                        src="/icons/usdt.png"
                        alt="Transfer USDT for top up your Defi Pool inner Account balance for web3 operations"
                        title="USDT for top up Defi Pool account"
                        loading="lazy"
                        placeholder
                        :quality="80"
                      />
                    </picture>
                    <div class="token-symbol">
                      USDT
                    </div>
                  </div>
                </div>
                <div class="desc-table">
                  <div class="desc-table">
                    <div class="desc-row">
                      <div class="title">
                        {{ $t('common.current_account_balance') }}
                      </div>
                      <div v-if="user !== null && user !== undefined" class="value">
                        {{ $priceFormat(user.usdt_balance, 2) }} USDT
                      </div>
                      <div v-else class="value">
                        <div class="icon-area">
                          <Loading />
                        </div>
                      </div>
                    </div>
                    <div class="desc-row">
                      <div class="title">
                        {{ $t('invest.usdt_balance') }}
                      </div>
                      <div class="value">
                        {{ topup.usdtBalance !== null ? $priceFormat(topup.usdtBalance, 2) : '...' }} USDT
                      </div>
                    </div>
                  </div>

                  <div :class="{'error-info': true, 'active': topup.error !== null}">
                    <div class="icon-item">
                      <WarnTriangle />
                    </div>
                    <div class="text-area">
                      {{ topup.error }}
                    </div>
                  </div>
                  <div class="button-area">
                    <button :class="{'btn pink': true, 'disabled': topup.loading || topup.amount === null || topup.error !== null }" type="button" :disabled="topup.loading || topup.amount === null || topup.error !== null" @click="topupUsdtBalance">
                      <span v-if="!topup.loading">
                        {{ $t('common.topup') }}
                      </span>
                      <span v-else>
                        <Loading />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
  hydrateWhenIdle,
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  components: {
    Copy: hydrateWhenVisible(() => import('~/components/svg/new/Copy')),
    Loading: hydrateWhenVisible(() => import('~/components/svg/new/Loading')),
    NewTabIcon: hydrateWhenVisible(() => import('~/components/svg/NewTabIcon')),
    Plus: hydrateWhenVisible(() => import('~/components/svg/new/Plus')),
    TransactionsList: hydrateWhenIdle(() => import('~/components/app/interface/TransactionsList')),
    WarnTriangle: hydrateWhenVisible(() => import('~/components/svg/new/WarnTriangle'))
  },
  data() {
    return {
      tab: 'wallet',
      copyText: this.$t('common.copy'),
      bnbBalance: 0,
      floatieBalance: 0,
      loadingBnb: true,
      loadingFloatie: true,
      logoutLoading: false,
      updateBalanceInterval: null,
      topup: {
        error: null,
        amount: null,
        hash: null,
        usdtBalance: null,
        checking: true,
        loading: false
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('modal', [
      'modalProfileOpen',
      'innerState'
    ]),
    ...mapGetters('transactions', [
      'transactions',
      'transactionsLoading'
    ]),
    isModalOpened() {
      return this.modalProfileOpen !== undefined ? this.modalProfileOpen : false
    }
  },
  watch: {
    isModalOpened(modalOpen) {
      if (modalOpen) {
        if (this.innerState !== null && ['wallet', 'transactions', 'topup'].includes(this.innerState)) {
          this.changeTab(this.innerState)
        }
        this.loadBalances()
        this.updateBalanceInterval = setInterval(() => {
          this.loadBalances()
        }, 3500)
      } else {
        clearInterval(this.updateBalanceInterval)
        this.updateBalanceInterval = null
      }
    }
  },
  mounted() {
    if (this.user !== undefined && this.user !== null) {
      this.loadBalances()
    } else {
      this.loadingBnb = false
      this.loadingFloatie = false
    }
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapActions('modal', [
      'changeModalProfileState'
    ]),
    ...mapActions('transactions', [
      'fetchTransactions'
    ]),
    ...mapActions('auth', [
      'topupAccountBalance'
    ]),
    ...mapMutations('modal', [
      'setInnerState'
    ]),
    handleClickEvents(evt) {
      if (
        this.$refs.modalArea !== undefined &&
        this.$refs.modalArea.contains(evt.target) &&
        !this.$refs.modalInner.contains(evt.target)
      ) {
        this.closeModal()
        this.modaTitle = this.title
      }
    },
    async logoutWallet() {
      this.logoutLoading = true
      await this.$logoutFromWallet()
      this.logoutLoading = false
      this.closeModal()
    },
    closeModal() {
      this.$emit('closeModalProfileState')
      this.setInnerState(null)
      this.changeModalProfileState({
        state: false
      })
    },
    changeTab(newTab) {
      if (this.tab.toLowerCase() !== newTab.toLowerCase()) {
        this.tab = newTab
      }
      if (this.tab === 'transactions') {
        this.fetchTransactions({
          page: 1,
          step: 15,
          uid: this.user.address
        })
      }
    },
    copyAddress(evt) {
      this.$copy(evt, this.user !== null && this.user !== undefined ? this.user.address : '')
      this.copyText = this.$t('common.copied')
      setTimeout(() => {
        this.copyText = this.$t('common.copy')
      }, 1200)
    },
    async loadBalances() {
      if (this.user !== null && this.user !== undefined) {
        try {
          const bnb = await window.eth.eth.getBalance(this.user.address)
          this.bnbBalance = this.$formatCryptoAmount(parseFloat(window.eth.utils.fromWei(bnb)), 6)
          this.loadingBnb = false

          const ERC20file = await import('~/assets/abis/ERC20.json')
          const ERC20 = ERC20file.default
          const contract = new window.eth.eth.Contract(ERC20, this.$config.periodToken)
          const balance = await contract.methods.balanceOf(this.user.address).call({ from: this.user.address })
          this.floatieBalance = this.$formatCryptoAmount(parseFloat(window.eth.utils.fromWei(balance)), 6)
          this.loadingFloatie = false

          await this.checkUsdtBalance()
        } catch (err) {
          console.log(err)
        }
      }
    },
    async checkUsdtBalance() {
      try {
        this.topup.checking = true
        const usdtFile = await import('~/assets/abis/ERC20.json')
        const usdt = usdtFile.default
        const usdtContract = new window.eth.eth.Contract(usdt, this.$config.usdtToken)
        const usdtBalance = await usdtContract.methods.balanceOf(this.user.address).call({ from: this.user.address })
        this.topup.usdtBalance = parseFloat(window.eth.utils.fromWei(usdtBalance))
        this.topup.checking = false
      } catch (err) {
        console.log(err)
        this.topup.checking = false
      }
    },
    changeAmount() {
      this.topup.error = null
      if (parseFloat(this.topup.amount) < 0) {
        this.topup.amount = 0
      }
      if ((this.topup.usdtBalance !== null && parseFloat(this.topup.amount) > this.topup.usdtBalance) || parseFloat(this.topup.amount) === 0) {
        this.topup.error = this.$t('common.topup_low_balance_or_min_amount')
      }
    },
    async topupUsdtBalance() {
      if (this.topup.loading) {
        return
      }
      this.topup.loading = true
      await this.checkUsdtBalance()
      if (this.topup.error === null && this.topup.amount !== null && this.topup.amount > 0 && this.topup.amount <= this.topup.usdtBalance) {
        try {
          const userAddress = await this.$getUserAddress()
          if (userAddress.length === 0) {
            this.topup.loading = false
            return
          }
          const usdtFile = await import('~/assets/abis/ERC20.json')
          const usdt = usdtFile.default
          const usdtContract = new window.eth.eth.Contract(usdt, this.$config.usdtToken)
          const gasPrice = await this.$calculateGasCost()
          const amount = window.eth.utils.toWei(this.topup.amount.toString(), 'ether')
          const gasFeeEstimated = await usdtContract.methods.transfer(this.$config.usdtReceiverAddress, amount)
            .estimateGas({ from: userAddress, gas: 3000000 })
          usdtContract.methods.transfer(this.$config.usdtReceiverAddress, amount)
            .send({ from: userAddress, gas: Math.round(gasFeeEstimated * 1.1), gasPrice })
            .then(async (result) => {
              this.topup.hash = result.transactionHash
              this.topup.loading = false
              await this.saveTransaction(userAddress)
              this.topup = {
                error: null,
                amount: null,
                hash: null
              }
              this.tab = 'wallet'
              this.closeModal()
              this.$nuxt.$emit('openSuccess', this.$t('common.topup_success_header'), this.$t('common.topup_success'))
            })
            .catch((err) => {
              this.topup.loading = false
              this.topup.error = err.message !== undefined ? err.message : err
              setTimeout(() => {
                this.topup.error = null
              }, 2000)
              console.log(err)
            })
        } catch (err) {
          this.topup.loading = false
          console.log(err)
          this.topup.error = this.$t('common.cant_topup_inner_accout')
        }
      } else {
        this.topup.loading = false
        this.topup.error = this.$t('common.topup_error')
      }
    },
    saveTransaction(address) {
      const amount = parseFloat(this.topup.amount)
      this.topupAccountBalance({
        amount,
        hash: this.topup.hash,
        address
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/new/modal.css');
  @import url('~/assets/css/new/profile.css');
</style>
