var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { card: true, active: _vm.card.active } }, [
    _c("div", { staticClass: "header" }, [
      !_vm.card.active
        ? _c(
            "div",
            { staticClass: "status" },
            [
              _c("Ban"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("prediction.expired")) +
                    "\n      "
                ),
              ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "status" },
            [
              _c("Play"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("prediction.live")) + "\n      "
                ),
              ]),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "div",
        { class: { hat: true, active: _vm.isPriceHigher } },
        [
          _c("CardHat"),
          _vm._v(" "),
          _c("div", { staticClass: "h2" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("prediction.up")) + "\n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h6" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.bullX) +
                "\n        x\n        " +
                _vm._s(_vm.$t("prediction.payout")) +
                "\n      "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "status" }, [
        _c("div", { staticClass: "h6" }, [_vm._v("\n        #\n      ")]),
        _vm._v(" "),
        _c("p", [_vm._v("\n        " + _vm._s(_vm.card.id) + "\n      ")]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          body: true,
          higher: _vm.isPriceHigher,
          lower: _vm.isPriceLower,
        },
      },
      [
        !_vm.card.active
          ? _c("div", { staticClass: "h4" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("prediction.closed_price")) +
                  "\n    "
              ),
            ])
          : _c("div", { staticClass: "h4" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("prediction.last_price")) + "\n    "
              ),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "chart-area" }),
        _vm._v(" "),
        !_vm.card.active
          ? _c("div", { staticClass: "price" }, [
              _vm._v(
                "\n      $" +
                  _vm._s(_vm.$priceFormat(_vm.card.closedPrice)) +
                  "\n    "
              ),
            ])
          : _c("div", { staticClass: "price" }, [
              _vm._v(
                "\n      $" +
                  _vm._s(_vm.$priceFormat(_vm.card.price)) +
                  "\n    "
              ),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "change-area" }, [
          _c("p", { staticClass: "price" }, [
            _vm._v("\n        $ " + _vm._s(_vm.priceChange) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "details" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "icon-area" }, [_c("Lock")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _c("p", { staticClass: "hint" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("prediction.locked_price")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "value" }, [
                _vm._v(
                  "\n            $" +
                    _vm._s(_vm.$priceFormat(_vm.card.lockPrice)) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "icon-area" }, [_c("Bnb")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _c("p", { staticClass: "hint" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("prediction.prize_pool")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "value" }, [
                _vm._v(
                  "\n            $" +
                    _vm._s(_vm.$priceFormat(_vm.card.totalAmount)) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "div",
        { class: { hat: true, active: _vm.isPriceLower } },
        [
          _c("CardHat"),
          _vm._v(" "),
          _c("div", { staticClass: "h2" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("prediction.down")) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h6" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.bearX) +
                "\n        x\n        " +
                _vm._s(_vm.$t("prediction.payout")) +
                "\n      "
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }