var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-area" }, [
    _vm.withSearch
      ? _c("div", { staticClass: "filter-area" }, [
          _c(
            "div",
            { staticClass: "input-area" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter.search,
                    expression: "filter.search",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("analytics.search_address_or_hash"),
                },
                domProps: { value: _vm.filter.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.filter, "search", $event.target.value)
                    },
                    _vm.searchModel,
                  ],
                },
              }),
              _vm._v(" "),
              _c("Search"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.transactionsLoading && _vm.transactions.length > 0
      ? _c(
          "div",
          { staticClass: "table-item" },
          [
            _c("div", { staticClass: "header row" }, [
              _c("div", { staticClass: "col wide" }, [
                _c("div", { staticClass: "range-switch-area" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.filter.type === "all",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeType("all")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("common.all")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.filter.type === "swap",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeType("swap")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("analytics.swaps")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.filter.type === "add",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeType("add")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("analytics.adds")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "switch-item": true,
                        active: _vm.filter.type === "remove",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeType("remove")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("analytics.removes")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col sortable" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.total_value")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.filter.orderField === "total_value"
                  ? _c(
                      "div",
                      {
                        class: {
                          "sort-icon": true,
                          active: _vm.filter.order === "ASC",
                        },
                      },
                      [_c("ChevronDown")],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("total_amount")
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.total_amount")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.filter.orderField === "total_amount"
                    ? _c(
                        "div",
                        {
                          class: {
                            "sort-icon": true,
                            active: _vm.filter.order === "ASC",
                          },
                        },
                        [_c("ChevronDown")],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.account")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col sortable",
                  on: {
                    click: function ($event) {
                      return _vm.changeSort("time")
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.time")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.filter.orderField === "time"
                    ? _c(
                        "div",
                        {
                          class: {
                            "sort-icon": true,
                            active: _vm.filter.order === "ASC",
                          },
                        },
                        [_c("ChevronDown")],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.transactions, function (transaction, index) {
              return _c("div", { key: index, staticClass: "row" }, [
                _c("div", { staticClass: "col wide small-visible" }, [
                  _c("div", { staticClass: "number" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.filter.step * (_vm.filter.page - 1) + index + 1
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo" },
                    [
                      _c("nuxt-img", {
                        attrs: {
                          fit: "inside",
                          src: _vm.$getImage(
                            transaction.t1_image || transaction.t0_image
                          ),
                          alt: transaction.t1_name || transaction.t1_name,
                          title: transaction.t0_symbol || transaction.t1_symbol,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getTxTitle(transaction)) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.total_value")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      "\n          $" +
                        _vm._s(_vm.$getBigNumber(transaction.total)) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.total_amount")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    transaction.t0_amount !== null
                      ? _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$formatCryptoAmount(transaction.t0_amount)
                              ) +
                              " " +
                              _vm._s(
                                transaction.t0_symbol !== undefined &&
                                  transaction.t0_symbol !== null
                                  ? transaction.t0_symbol.toUpperCase()
                                  : "..."
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    transaction.t1_amount !== null
                      ? _c("span", [
                          _vm._v(
                            "\n             + " +
                              _vm._s(
                                _vm.$formatCryptoAmount(transaction.t1_amount)
                              ) +
                              " " +
                              _vm._s(
                                transaction.t1_symbol !== undefined &&
                                  transaction.t1_symbol !== null
                                  ? transaction.t1_symbol.toUpperCase()
                                  : "..."
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.account")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    transaction.user_address !== undefined &&
                    transaction.user_address !== null &&
                    transaction.user_address.length > 0
                      ? _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: {
                              href: _vm.$getExplorerUserLink(
                                transaction.user_address
                              ),
                              rel: "nofollow noopener noreferrer",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$shortenAddress(transaction.user_address)
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c("p", { staticClass: "link" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$shortenAddress(transaction.user_address)
                              ) +
                              "\n          "
                          ),
                        ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col small-visible xs-hidden" }, [
                  _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("analytics.time")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "value",
                      attrs: {
                        href: _vm.$getExplorerLinkByTx(transaction),
                        rel: "nofollow noopener noreferrer",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$timeSince(transaction.created_at)) +
                          "\n          "
                      ),
                      _c("NewTabIcon"),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col accordion small-visible",
                    on: {
                      click: function ($event) {
                        return _vm.openAccordion(index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          switcher: true,
                          active:
                            _vm.accordions[index] !== undefined &&
                            _vm.accordions[index] === true,
                        },
                      },
                      [
                        _vm.accordions[index] !== undefined &&
                        _vm.accordions[index] === true
                          ? _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.hide")) +
                                  "\n          "
                              ),
                            ])
                          : _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.show")) +
                                  "\n          "
                              ),
                            ]),
                        _vm._v(" "),
                        _c("ChevronDown"),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "accordionArea" + index,
                    refInFor: true,
                    staticClass: "accordion-area mobile-visible",
                  },
                  [
                    _c("div", { staticClass: "all-params" }, [
                      _c("div", { staticClass: "item" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.total_value")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(
                            "\n              $" +
                              _vm._s(_vm.$getBigNumber(transaction.total)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.total_amount")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          transaction.t0_amount !== null
                            ? _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$formatCryptoAmount(
                                        transaction.t0_amount
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      transaction.t0_symbol !== undefined &&
                                        transaction.t0_symbol !== null
                                        ? transaction.t0_symbol.toUpperCase()
                                        : "..."
                                    ) +
                                    "\n              "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          transaction.t1_amount !== null
                            ? _c("span", [
                                _vm._v(
                                  "\n                 + " +
                                    _vm._s(
                                      _vm.$formatCryptoAmount(
                                        transaction.t1_amount
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      transaction.t1_symbol !== undefined &&
                                        transaction.t1_symbol !== null
                                        ? transaction.t1_symbol.toUpperCase()
                                        : "..."
                                    ) +
                                    "\n              "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item no-border" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.account")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          transaction.user_address !== null &&
                          transaction.user_address.length > 0
                            ? _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    href: _vm.$getExplorerUserLink(
                                      transaction.user_address
                                    ),
                                    rel: "nofollow noopener noreferrer",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$shortenAddress(
                                          transaction.user_address
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _c("p", { staticClass: "link" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$shortenAddress(
                                        transaction.user_address
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item xs-visible no-border" }, [
                        _c("p", { staticClass: "hint" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("analytics.time")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "name",
                            attrs: {
                              href: _vm.$getExplorerLinkByTx(transaction),
                              rel: "nofollow noopener noreferrer",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$timeSince(transaction.created_at)) +
                                "\n              "
                            ),
                            _c("NewTabIcon"),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ])
            }),
          ],
          2
        )
      : _vm.transactionsLoading
      ? _c(
          "div",
          { staticClass: "table-item" },
          [
            _c("div", { staticClass: "header row" }, [
              _c(
                "div",
                { staticClass: "col wide" },
                [
                  _c("Skeleton", {
                    staticStyle: { "margin-right": ".5rem" },
                    attrs: { width: "50px", height: "35px" },
                  }),
                  _vm._v(" "),
                  _c("Skeleton", {
                    staticStyle: { "margin-right": ".5rem" },
                    attrs: { width: "50px", height: "35px" },
                  }),
                  _vm._v(" "),
                  _c("Skeleton", {
                    staticStyle: { "margin-right": ".5rem" },
                    attrs: { width: "50px", height: "35px" },
                  }),
                  _vm._v(" "),
                  _c("Skeleton", { attrs: { width: "50px", height: "35px" } }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.total_value")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.total_amount")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.account")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("analytics.time")) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(3, function (index) {
              return _c("div", { key: index, staticClass: "row" }, [
                _c("div", { staticClass: "col wide small-visible" }, [
                  _c("div", { staticClass: "number" }, [
                    _vm._v("\n          " + _vm._s(index + 1) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "logo" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "40px", height: "40px", circle: "" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "70px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "40px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "60px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "60px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col small-visible xs-hidden" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("Skeleton", {
                        attrs: { width: "80px", height: "25px" },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            }),
          ],
          2
        )
      : _c("div", { staticClass: "tablie-item" }, [
          _c("div", { staticClass: "not-found-area" }, [
            _c("div", { staticClass: "h3" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("analytics.no_transactions")) +
                  "\n      "
              ),
            ]),
          ]),
        ]),
    _vm._v(" "),
    _vm.transactions.length > 0 && _vm.withPagination
      ? _c(
          "div",
          {
            class: {
              "pagination-area": true,
              disabled: _vm.transactionsLoading,
            },
          },
          [
            _c("Pagination", {
              attrs: {
                page: _vm.filter.page,
                step: _vm.filter.step,
                total: _vm.total,
              },
              on: { change: _vm.setPage },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }