<template>
  <section class="error-screen">
    <component :is="errorType" :error="error" />
    <div class="buttons-area">
      <button type="button" class="btn cyan btn-outline" @click="loadPreviousPage">
        <span class="text">
          {{ $t('errors.go_back') }}
        </span>
      </button>
      <button type="button" class="btn cyan" @click="loadHomePage">
        {{ $t('errors.go_home') }}
      </button>
    </div>
    <div class="cyan-cloud">
      <CloudCyanError />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

import error404 from '~/pages/errors/error404.vue'
import error500 from '~/pages/errors/error500.vue'

export default {
  name: 'ErrorLayout',
  components: {
    CloudCyanError: hydrateWhenIdle(() => import('~/components/svg/new/clouds/CloudCyanError')),
    error404,
    error500
  },
  layout: 'new',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    const title = this.error.statusCode === 404 ? this.$t('errors.not_found_title') : this.$t('errors.internal_title')
    const description = this.error.statusCode === 404 ? this.$t('errors.not_found_description') : this.$t('errors.internal_description')
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description
        },
        {
          hid: 'og-title',
          name: 'og:title',
          content: title
        },
        {
          hid: 'og-image',
          name: 'og:image',
          content: 'seo/default.jpg'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        },
        ...i18nHead.meta
      ],
      link: [
        ...i18nHead.link
      ]
    }
  },
  computed: {
    ...mapGetters(['darkTheme']),
    errorType() {
      if (this.error.statusCode === 404) {
        return error404
      }
      return error500
    }
  },
  methods: {
    loadHomePage() {
      if (this.$router.path === '/') {
        location.reload()
      }
      if (this.$nuxt.$route.path.includes('/app')) {
        if (this.$router.path === '/app') {
          location.reload()
        }
        this.$router.push({
          path: this.localePath('/app')
        })
      } else if (this.$nuxt.$route.path.includes('/analytics')) {
        if (this.$router.path === '/analytics') {
          location.reload()
        }
        this.$router.push({
          path: this.localePath('/analytics')
        })
      } else {
        this.$router.push({
          path: this.localePath('/')
        })
      }
    },
    loadPreviousPage() {
      if (window !== undefined) {
        window.history.go(-1)
      }
    }
  }
}
</script>

<style scoped>
  @import url('../assets/css/new/errors.css');
</style>
