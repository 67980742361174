var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 26 26",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", { attrs: { cx: "13", cy: "13", r: "13", fill: "#ff3c9f" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M17.8608 15.8465L14.9096 12.8953C14.8137 12.7994 14.8137 12.6439 14.9096 12.5481L17.8608 9.59685C18.3402 9.1175 18.3402 8.34019 17.8608 7.86084C17.3815 7.38149 16.6042 7.38149 16.1248 7.86084L13.1736 10.8121C13.0777 10.9079 12.9223 10.9079 12.8264 10.8121L9.87517 7.86084C9.39582 7.38149 8.61851 7.38149 8.13916 7.86084C7.65981 8.34019 7.65981 9.1175 8.13916 9.59685L11.0904 12.5481C11.1863 12.6439 11.1863 12.7994 11.0904 12.8953L8.13916 15.8465C7.65981 16.3259 7.65981 17.1032 8.13916 17.5825C8.61851 18.0619 9.39582 18.0619 9.87517 17.5825L12.8264 14.6313C12.9223 14.5354 13.0777 14.5354 13.1736 14.6313L16.1248 17.5825C16.6042 18.0619 17.3815 18.0619 17.8608 17.5825C18.3402 17.1032 18.3402 16.3259 17.8608 15.8465Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }