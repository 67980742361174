var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { ref: "header", class: { fixed: _vm.fixedData } },
    [
      _c("WindowModal", {
        attrs: { title: _vm.modalCommonTitle, type: "Common" },
        scopedSlots: _vm._u([
          {
            key: "modal-content",
            fn: function () {
              return [
                _c("CommonModal", {
                  ref: "commonModal",
                  on: {
                    initLoading: function ($event) {
                      return _vm.changeModalTitle("loading")
                    },
                    initSuccess: function ($event) {
                      return _vm.changeModalTitle("success")
                    },
                    initError: function ($event) {
                      return _vm.changeModalTitle("error")
                    },
                    initInfo: function ($event) {
                      return _vm.changeModalTitle("info")
                    },
                    initQuestion: function ($event) {
                      return _vm.changeModalTitle("question")
                    },
                    closeGlobalModal: _vm.closeModal,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "mainMenu", staticClass: "mobile-menu" },
        [_c("HeaderNavMobile", { on: { close: _vm.activateMenu } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "top_panel" }, [
        _c("div", { staticClass: "panel__container" }, [
          _c("div", { staticClass: "links" }, [
            _c("p", { staticClass: "label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("common.useful_links")) +
                  ":\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link",
                attrs: {
                  href: "/docs/Whitepaper_DefiPool_ENG.pdf",
                  rel: "nofoloow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.whitepaper")) +
                    "\n          "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link",
                attrs: {
                  href: "/docs/OnePager_DefiPool_ENG.pdf",
                  rel: "nofoloow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.one_pager")) +
                    "\n          "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link",
                attrs: {
                  href: "/docs/Pitchdeck_DeFiPool_ENG.pdf",
                  rel: "nofoloow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.pitch_deck")) +
                    "\n          "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link",
                attrs: {
                  href: "/docs/RoadMap_DefiPool_ENG.pdf",
                  rel: "nofoloow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.roadmap")) +
                    "\n          "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "link",
                attrs: {
                  href: "/docs/Tokenomics_DefiPool_ENG.pdf",
                  rel: "nofoloow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.tokenomics")) +
                    "\n          "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header-inner" }, [
        _c("div", { staticClass: "left-assets" }, [
          _c(
            "div",
            { staticClass: "logo-area" },
            [
              _c(
                "nuxt-link",
                {
                  staticClass: "logo",
                  attrs: {
                    to: _vm.localePath("/"),
                    "aria-label": _vm.$t("common.go_to_home_page"),
                  },
                },
                [
                  !_vm.darkTheme
                    ? _c(
                        "div",
                        { staticClass: "logo-picture" },
                        [
                          _c("nuxt-picture", {
                            attrs: {
                              fit: "inside",
                              src: "/img/df_wide_light.svg",
                              alt: "Defi Pool Finance eran and exchange cryptocurrency",
                              "img-attrs": {
                                class: "desktop",
                                title: "Defi Pool home",
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("nuxt-picture", {
                            attrs: {
                              fit: "inside",
                              src: "/img/df_small_light.svg",
                              alt: "Defi Pool Finance eran and exchange cryptocurrency",
                              "img-attrs": {
                                class: "mobile",
                                title: "Defi Pool home",
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "logo-picture" },
                        [
                          _c("nuxt-picture", {
                            attrs: {
                              fit: "inside",
                              src: "/img/df_wide_dark.svg",
                              alt: "Defi Pool Finance eran and exchange cryptocurrency",
                              "img-attrs": {
                                class: "desktop",
                                title: "Defi Pool home",
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("nuxt-picture", {
                            attrs: {
                              fit: "inside",
                              src: "/img/df_small_dark.svg",
                              alt: "Defi Pool Finance eran and exchange cryptocurrency",
                              "img-attrs": {
                                class: "mobile",
                                title: "Defi Pool home",
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "menu-items" },
            [_c("HeaderNav", { on: { close: _vm.activateMenu } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right-assets" }, [
          _c("div", { staticClass: "lang-switcher-area dropdown" }, [
            _c(
              "div",
              { staticClass: "dropdown-header" },
              [_c("Planet"), _vm._v(" "), _c("ChevronDown")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-area" },
              _vm._l(_vm.availableLocales, function (locale) {
                return _c(
                  "nuxt-link",
                  {
                    key: locale.code,
                    attrs: {
                      to: _vm.switchLocalePath(locale.code),
                      rel: "nofollow noreferrer",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(locale.name) + "\n          "
                    ),
                  ]
                )
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "settings-area" }, [
            _c(
              "button",
              {
                staticClass: "settings-button",
                attrs: {
                  type: "button",
                  "aria-label": _vm.$t("common.settings"),
                },
                on: { click: _vm.openSettingsModal },
              },
              [_c("Cog")],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "connect-wallet",
              attrs: {
                type: "button",
                "aria-label": _vm.$t("common.connect_wallet"),
              },
              on: { click: _vm.openModalWallets },
            },
            [
              _c("Wallet"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n          " + _vm._s(_vm.currentAccount) + "\n        "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: { "menu-btn": true, active: _vm.hamburgerActive },
              attrs: {
                rel: "menuButton",
                type: "button",
                "aria-label": _vm.$t("common.open_menu"),
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.activateMenu.apply(null, arguments)
                },
              },
            },
            [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }