var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 49 33",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M18.1335 30.3306C18.9431 30.6695 19.7895 30.9328 20.6601 31.14V21.451H18.1335V30.3306Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M25.7151 15.8813H23.1886V32.0296H25.7151V15.8813Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M28.2436 31.3065C29.1141 31.0956 29.9624 30.8297 30.7701 30.4831V10.3136H28.2436V31.3065Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M24.4528 -4.57764e-05C15.6072 -4.57764e-05 8.43799 7.10152 8.43799 15.8595C8.43799 21.3779 11.2898 26.2264 15.6072 29.0669V25.9207C12.7684 23.4717 10.9645 19.8733 10.9645 15.8595C10.9645 8.49433 17.0156 2.50198 24.4528 2.50198C31.8899 2.50198 37.9411 8.49433 37.9411 15.8595C37.9411 19.8733 36.1372 23.4699 33.2983 25.9207V29.0669C37.6158 26.2264 40.4676 21.3779 40.4676 15.8595C40.4676 7.10152 33.2983 -4.57764e-05 24.4528 -4.57764e-05Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }