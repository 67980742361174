var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingScreen && !_vm.successScreen && !_vm.errorScreen
    ? _c("div", { staticClass: "confirm-area" }, [
        _c("div", { staticClass: "lp-area" }, [
          _c("div", { staticClass: "tokens-pair" }, [
            _c(
              "div",
              { staticClass: "logos" },
              [
                _c("nuxt-img", {
                  attrs: {
                    quality: 80,
                    placeholder: "",
                    lazy: true,
                    src:
                      _vm.pair.from_token.address !== _vm.$config.ZERO
                        ? _vm.$getImageByAddress(_vm.pair.from_token.address)
                        : _vm.$getImage(_vm.pair.from_token.logoURI),
                    alt:
                      _vm.pair.from_token.name +
                      " " +
                      _vm.pair.from_token.symbol,
                    title: _vm.pair.from_token.name,
                  },
                  on: {
                    error: function ($event) {
                      $event.target.src = require("~/static/img/default_token.svg")
                    },
                  },
                }),
                _vm._v(" "),
                _c("nuxt-img", {
                  attrs: {
                    quality: 80,
                    placeholder: "",
                    lazy: true,
                    src:
                      _vm.pair.to_token.address !== _vm.$config.ZERO
                        ? _vm.$getImageByAddress(_vm.pair.to_token.address)
                        : _vm.$getImage(_vm.pair.to_token.logoURI),
                    alt:
                      _vm.pair.to_token.name + " " + _vm.pair.to_token.symbol,
                    title: _vm.pair.to_token.name,
                  },
                  on: {
                    error: function ($event) {
                      $event.target.src = require("~/static/img/default_token.svg")
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h2" }, [
            _vm._v("\n      " + _vm._s(_vm.lpTokensToReceive) + "\n    "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "lp-tokens-header" }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.pair.from_token.symbol.toUpperCase() +
                  " / " +
                  _vm.pair.to_token.symbol.toUpperCase() +
                  " " +
                  _vm.$t("liquidity.pool_tokens")
              ) +
              "\n  "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("liquidity.estimated_output", {
                    percent: _vm.slippageTolerance,
                  })
                ) +
                "\n    "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "desc-table" }, [
          _c("div", { staticClass: "desc-row" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.pair.from_token.symbol.toUpperCase() +
                      " " +
                      _vm.$t("liquidity.deposited")
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "value" },
              [
                _c("nuxt-img", {
                  attrs: {
                    src:
                      _vm.pair.from_token.address !== _vm.$config.ZERO
                        ? _vm.$getImageByAddress(_vm.pair.from_token.address)
                        : _vm.$getImage(_vm.pair.from_token.logoURI),
                    alt:
                      _vm.pair.from_token.name +
                      " " +
                      _vm.pair.from_token.symbol,
                    title: _vm.pair.from_token.name,
                    quality: 80,
                    placeholder: "",
                    lazy: true,
                  },
                  on: {
                    error: function ($event) {
                      $event.target.src = require("~/static/img/default_token.svg")
                    },
                  },
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$preciseTokenValue(_vm.pair.from_value)) +
                    "\n      "
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc-row" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.pair.to_token.symbol.toUpperCase() +
                      " " +
                      _vm.$t("liquidity.deposited")
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "value" },
              [
                _c("nuxt-img", {
                  attrs: {
                    src:
                      _vm.pair.to_token.address !== _vm.$config.ZERO
                        ? _vm.$getImageByAddress(_vm.pair.to_token.address)
                        : _vm.$getImage(_vm.pair.to_token.logoURI),
                    alt:
                      _vm.pair.to_token.name + " " + _vm.pair.to_token.symbol,
                    title: _vm.pair.to_token.name,
                    quality: 80,
                    placeholder: "",
                    lazy: true,
                  },
                  on: {
                    error: function ($event) {
                      $event.target.src = require("~/static/img/default_token.svg")
                    },
                  },
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$preciseTokenValue(_vm.pair.to_value)) +
                    "\n      "
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc-row" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("liquidity.rates")) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value column" }, [
              _c("div", { staticClass: "value-row" }, [
                _vm._v(
                  "\n          1 " +
                    _vm._s(_vm.pair.from_token.symbol.toUpperCase()) +
                    " = " +
                    _vm._s(_vm.toPrice) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value-row" }, [
                _vm._v(
                  "\n          1 " +
                    _vm._s(_vm.pair.to_token.symbol.toUpperCase()) +
                    " = " +
                    _vm._s(_vm.fromPrice) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc-row" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("liquidity.pool_share")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$preciseTokenValue(_vm.shareOfPool) + "%") +
                  "\n      "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "button",
            {
              class: { "btn pink": true, disabled: _vm.loading },
              attrs: { type: "button", disabled: _vm.loading },
              on: { click: _vm.confirmSupply },
            },
            [
              !_vm.loading
                ? _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("liquidity.confirm_supply")) +
                        "\n      "
                    ),
                  ])
                : _c("span", [_c("Loading")], 1),
            ]
          ),
        ]),
      ])
    : _vm.loadingScreen
    ? _c(
        "div",
        { staticClass: "loading-area" },
        [
          _c("ModalLoader"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _vm.loadingHeader.length === 0
              ? _c("div", { staticClass: "h5" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("liquidity.supplying")) +
                      "\n      " +
                      _vm._s(
                        _vm.$preciseTokenValue(_vm.pair.from_value) +
                          " " +
                          _vm.pair.from_token.symbol.toUpperCase()
                      ) +
                      "\n      " +
                      _vm._s(_vm.$t("common.and")) +
                      "\n      " +
                      _vm._s(
                        _vm.$preciseTokenValue(_vm.pair.to_value) +
                          " " +
                          _vm.pair.to_token.symbol.toUpperCase()
                      ) +
                      "\n    "
                  ),
                ])
              : _c("div", { staticClass: "h5" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadingHeader) + "\n    "),
                ]),
            _vm._v(" "),
            _vm.loadingText.length === 0
              ? _c("p", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("swap.confirm_transaction")) +
                      "\n    "
                  ),
                ])
              : _c("p", [
                  _vm._v("\n      " + _vm._s(_vm.loadingText) + "\n    "),
                ]),
          ]),
        ],
        1
      )
    : _vm.successScreen
    ? _c("div", { staticClass: "success-area" }, [
        _c("div", { staticClass: "icon-area" }, [_c("CheckIcon")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "desc-area" }, [
          _c("div", { staticClass: "h4" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("swap.success")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("swap.success_text")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-area" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-outline pink",
                attrs: {
                  href: _vm.transactionLink(_vm.transaction.tx),
                  rel: "nofollow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("swap.view_explorer")) +
                      "\n          "
                  ),
                  _c(
                    "span",
                    { staticClass: "icon icon-right" },
                    [_c("NewTabIcon")],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(true)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.close")) + "\n      "
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm.errorScreen
    ? _c(
        "div",
        { staticClass: "error-area" },
        [
          _c("WarnTriangle"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h4" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("swap.error")) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.errorText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("common.dismiss")) + "\n    ")]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }