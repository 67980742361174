var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingScreen && !_vm.successScreen && !_vm.errorScreen
    ? _c("div", { staticClass: "confirm-area" }, [
        _c("div", { staticClass: "confirm-table" }, [
          _c("div", { staticClass: "confirm-row" }, [
            _c(
              "div",
              { staticClass: "token-data" },
              [
                _vm.inputMethod !== "exchange"
                  ? _c("nuxt-img", {
                      attrs: {
                        quality: 80,
                        placeholder: "",
                        src:
                          _vm.swap.from_token.address !== _vm.$config.ZERO
                            ? _vm.$getImageByAddress(
                                _vm.swap.from_token.address
                              )
                            : _vm.$getImage(_vm.swap.from_token.logoURI),
                        alt:
                          _vm.swap.from_token.name +
                          " " +
                          _vm.swap.from_token.symbol,
                        title: _vm.swap.from_token.name,
                        lazy: true,
                      },
                      on: {
                        error: function ($event) {
                          $event.target.src = require("~/static/img/default_token.svg")
                        },
                      },
                    })
                  : _c("nuxt-img", {
                      attrs: {
                        quality: 80,
                        placeholder: "",
                        src:
                          "/img/new/" +
                          _vm.swap.from_token.name.toLowerCase() +
                          "_item.svg",
                        alt:
                          _vm.swap.from_token.name +
                          " " +
                          _vm.swap.from_token.symbol,
                        title: _vm.swap.from_token.name,
                        lazy: true,
                      },
                    }),
                _vm._v(" "),
                _c("p", { staticClass: "value" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.swap.from_value) + "\n        "
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "token-symbol" }, [
              _c("div", { staticClass: "h3" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.swap.from_token.symbol.toUpperCase()) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "confirm-row" }, [
            _c("div", { staticClass: "arrow-area" }, [_c("ArrowDown")], 1),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "confirm-row" }, [
            _c(
              "div",
              { staticClass: "token-data" },
              [
                _vm.inputMethod !== "exchange"
                  ? _c("nuxt-img", {
                      attrs: {
                        quality: 80,
                        placeholder: "",
                        src:
                          _vm.swap.to_token.address !== _vm.$config.ZERO
                            ? _vm.$getImageByAddress(_vm.swap.to_token.address)
                            : _vm.$getImage(_vm.swap.to_token.logoURI),
                        alt:
                          _vm.swap.to_token.name +
                          " " +
                          _vm.swap.to_token.symbol,
                        title: _vm.swap.to_token.name,
                        lazy: true,
                      },
                      on: {
                        error: function ($event) {
                          $event.target.src = require("~/static/img/default_token.svg")
                        },
                      },
                    })
                  : _c("nuxt-img", {
                      attrs: {
                        quality: 80,
                        placeholder: "",
                        src:
                          "/img/new/" +
                          _vm.swap.to_token.name.toLowerCase() +
                          "_item.svg",
                        alt:
                          _vm.swap.to_token.name +
                          " " +
                          _vm.swap.to_token.symbol,
                        title: _vm.swap.to_token.name,
                        lazy: true,
                      },
                    }),
                _vm._v(" "),
                _c("p", { staticClass: "value" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.swap.to_value) + "\n        "
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "token-symbol" }, [
              _c("div", { staticClass: "h3" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.swap.to_token.symbol.toUpperCase()) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.inputMethod !== "exchange"
          ? _c("div", { staticClass: "desc" }, [
              _vm.inputMethod === "from"
                ? _c("p", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("swap.swap_desc_at_least")) +
                        "\n      "
                    ),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.calculatedToSum +
                              " " +
                              _vm.swap.to_token.symbol.toUpperCase()
                          ) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("swap.or_transaction_revert")) +
                        ".\n    "
                    ),
                  ])
                : _c("p", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("swap.swap_desc_at_most")) +
                        "\n      "
                    ),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.calculatedFromSum +
                              " " +
                              _vm.swap.from_token.symbol.toUpperCase()
                          ) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("swap.or_transaction_revert")) +
                        "\n    "
                    ),
                  ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: { "refresh-area": true, active: _vm.priceRefreshed } },
          [
            _c("div", { staticClass: "icon-area" }, [_c("CoinHold")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("swap.price_refreshed")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn cyan",
                  attrs: { type: "button" },
                  on: { click: _vm.approvePrive },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("pools.approve")) + "\n      "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: {
              "refresh-area": true,
              error: true,
              active: _vm.error.length > 0,
            },
          },
          [
            _c("div", { staticClass: "icon-area" }, [_c("WarnTriangle")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _c("p", { staticClass: "desc" }, [
                _vm._v("\n        " + _vm._s(_vm.error) + "\n      "),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.inputMethod !== "exchange"
          ? _c("div", { staticClass: "desc-table" }, [
              _c("div", { staticClass: "desc-row" }, [
                _c("div", { staticClass: "title" }, [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("swap.price")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _c("p", { ref: "priceEstimate", staticClass: "bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.fromPrice +
                            " " +
                            _vm.swap.from_token.symbol.toUpperCase() +
                            " / " +
                            _vm.swap.to_token.symbol.toUpperCase()
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "change-icon",
                      on: { click: _vm.turnOverPrice },
                    },
                    [_c("RefreshIcon")],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc-row" }, [
                _c("div", { staticClass: "title" }, [
                  _vm.inputMethod === "from"
                    ? _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("swap.minimum_received")) +
                            "\n        "
                        ),
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("swap.maximum_sold")) +
                            "\n        "
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _vm.inputMethod === "from"
                    ? _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.calculatedToSum +
                                " " +
                                _vm.swap.to_token.symbol.toUpperCase()
                            ) +
                            "\n        "
                        ),
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.calculatedFromSum +
                                " " +
                                _vm.swap.from_token.symbol.toUpperCase()
                            ) +
                            "\n        "
                        ),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc-row" }, [
                _c("div", { staticClass: "title" }, [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("swap.price_impact")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.priceImpactClass }, [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$priceFormat(_vm.priceImpact, 2, ".", ",") + "%"
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc-row" }, [
                _c("div", { staticClass: "title" }, [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("swap.liquidity_provider_fee")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$preciseTokenValue(
                            _vm.swap.from_value * _vm.$config.fee,
                            2,
                            ".",
                            ","
                          ) +
                            " " +
                            _vm.swap.from_token.symbol.toUpperCase()
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
            ])
          : _c("div", { staticClass: "desc-table mb-0" }, [
              _c("div", { staticClass: "desc-row" }, [
                _c("div", { staticClass: "title" }, [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("swap.price")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _c("p", { ref: "priceEstimate", staticClass: "bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.fromPriceExchange +
                            " " +
                            _vm.swap.from_token.symbol.toUpperCase() +
                            " / " +
                            _vm.swap.to_token.symbol.toUpperCase()
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "change-icon",
                      on: { click: _vm.turnOverPrice },
                    },
                    [_c("RefreshIcon")],
                    1
                  ),
                ]),
              ]),
            ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.inputMethod !== "exchange"
          ? _c("div", { staticClass: "buttons-area" }, [
              !_vm.fromApproved &&
              (_vm.toApproved || (!_vm.fromApproved && !_vm.toApproved))
                ? _c(
                    "button",
                    {
                      class: {
                        "btn btn-outline pink": true,
                        disabled: _vm.loadingFromApprove,
                      },
                      attrs: {
                        disabled: _vm.loadingFromApprove,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.approveToken("from")
                        },
                      },
                    },
                    [
                      !_vm.loadingFromApprove
                        ? _c("span", { staticClass: "text" }, [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("pools.approve")) +
                                " " +
                                _vm._s(_vm.swap.from_token.symbol) +
                                "\n      "
                            ),
                          ])
                        : _c(
                            "span",
                            { staticClass: "text" },
                            [_c("Loading")],
                            1
                          ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.toApproved && _vm.fromApproved
                ? _c(
                    "button",
                    {
                      class: {
                        "btn btn-outline pink": true,
                        disabled: _vm.loadingToApprove,
                      },
                      attrs: { disanled: _vm.loadingToApprove, type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.approveToken("to")
                        },
                      },
                    },
                    [
                      !_vm.loadingToApprove
                        ? _c("span", { staticClass: "text" }, [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("pools.approve")) +
                                " " +
                                _vm._s(_vm.swap.to_token.symbol) +
                                "\n      "
                            ),
                          ])
                        : _c(
                            "span",
                            { staticClass: "text" },
                            [_c("Loading")],
                            1
                          ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: {
                    "btn pink": true,
                    disabled:
                      _vm.loading ||
                      !_vm.approvedTokens ||
                      !_vm.priceApproved ||
                      _vm.error.length > 0,
                  },
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.loading ||
                      !_vm.approvedTokens ||
                      !_vm.priceApproved ||
                      _vm.error.length > 0,
                  },
                  on: { click: _vm.confirmSwap },
                },
                [
                  !_vm.loading
                    ? _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("swap.confirm_swap")) +
                            "\n      "
                        ),
                      ])
                    : _c("span", [_c("Loading")], 1),
                ]
              ),
            ])
          : _c("div", { staticClass: "buttons-area" }, [
              !_vm.fromApproved
                ? _c(
                    "button",
                    {
                      class: {
                        "btn btn-outline pink": true,
                        disabled: _vm.loadingFromApprove,
                      },
                      attrs: {
                        disabled: _vm.loadingFromApprove,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.approveToken("from")
                        },
                      },
                    },
                    [
                      !_vm.loadingFromApprove
                        ? _c("span", { staticClass: "text" }, [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("pools.approve")) +
                                " " +
                                _vm._s(_vm.swap.from_token.symbol) +
                                "\n      "
                            ),
                          ])
                        : _c(
                            "span",
                            { staticClass: "text" },
                            [_c("Loading")],
                            1
                          ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: {
                    "btn pink": true,
                    disabled:
                      _vm.loading ||
                      !_vm.approvedTokens ||
                      !_vm.priceApproved ||
                      _vm.error.length > 0,
                  },
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.loading ||
                      !_vm.approvedTokens ||
                      !_vm.priceApproved ||
                      _vm.error.length > 0,
                  },
                  on: { click: _vm.confirmSwap },
                },
                [
                  !_vm.loading
                    ? _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("swap.confirm_swap")) +
                            "\n      "
                        ),
                      ])
                    : _c("span", [_c("Loading")], 1),
                ]
              ),
            ]),
      ])
    : _vm.loadingScreen
    ? _c(
        "div",
        { staticClass: "loading-area" },
        [
          _c("ModalLoader"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h5" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("swap.swapping")) +
                  "\n      " +
                  _vm._s(
                    _vm.$preciseTokenValue(_vm.swap.from_value) +
                      " " +
                      _vm.swap.from_token.symbol.toUpperCase()
                  ) +
                  "\n      " +
                  _vm._s(_vm.$t("common.for")) +
                  "\n      " +
                  _vm._s(
                    _vm.$preciseTokenValue(_vm.swap.to_value) +
                      " " +
                      _vm.swap.to_token.symbol.toUpperCase()
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("swap.confirm_transaction")) +
                  "\n    "
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm.successScreen
    ? _c("div", { staticClass: "success-area" }, [
        _c("div", { staticClass: "icon-area" }, [_c("CheckIcon")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "desc-area" }, [
          _c("div", { staticClass: "h4" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("swap.success")) + "\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("swap.success_text")) + "\n    "),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-area" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-outline pink",
              attrs: {
                href: _vm.transactionLink(_vm.transaction.tx),
                rel: "nofollow noopener noreferrer",
                target: "_blank",
              },
            },
            [
              _c("span", { staticClass: "text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("swap.view_explorer")) +
                    "\n        "
                ),
                _c(
                  "span",
                  { staticClass: "icon icon-right" },
                  [_c("NewTabIcon")],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn pink",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeModal(false)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("common.close")) + "\n    ")]
          ),
        ]),
      ])
    : _vm.errorScreen
    ? _c(
        "div",
        { staticClass: "error-area" },
        [
          _c("WarnTriangle"),
          _vm._v(" "),
          _c("div", { staticClass: "desc-area" }, [
            _c("div", { staticClass: "h4" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("swap.error")) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v("\n      " + _vm._s(_vm.errorText) + "\n    "),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn pink",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.closeModal(false)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("common.dismiss")) + "\n    ")]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }