var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "12",
        viewbox: "0 0 14 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.99975 8.43262C6.60836 8.43262 6.28027 8.74024 6.28027 9.10723C6.28027 9.47422 6.60836 9.78184 6.99975 9.78184C7.37676 9.78184 7.71923 9.47422 7.70196 9.12342C7.71923 8.73754 7.39403 8.43262 6.99975 8.43262Z",
          fill: "#FF3030",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M13.6593 10.8235C14.1112 10.0922 14.114 9.2206 13.6651 8.49202L9.15826 1.17383C8.71219 0.437149 7.90637 0 7.0027 0C6.09904 0 5.29322 0.439847 4.84714 1.17113L0.334571 8.49741C-0.114383 9.23409 -0.111505 10.1111 0.343205 10.8424C0.79216 11.5655 1.5951 12 2.49301 12H11.4951C12.3959 12 13.2046 11.5602 13.6593 10.8235ZM12.6808 10.2946C12.4305 10.6993 11.9873 10.9395 11.4923 10.9395H2.49013C2.00089 10.9395 1.56056 10.7047 1.31594 10.3081C1.06844 9.906 1.06556 9.42568 1.31306 9.02091L5.82564 1.69732C6.07026 1.29526 6.5077 1.05779 7.0027 1.05779C7.49483 1.05779 7.93515 1.29795 8.17977 1.70002L12.6895 9.02361C12.9312 9.41759 12.9283 9.89251 12.6808 10.2946Z",
          fill: "#FF3030",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M6.82106 3.69678C6.47859 3.78853 6.26562 4.07996 6.26562 4.43346C6.28289 4.64663 6.29728 4.86251 6.31455 5.07569C6.36347 5.88792 6.4124 6.68396 6.46132 7.49619C6.47859 7.77144 6.70595 7.97112 6.9995 7.97112C7.29304 7.97112 7.52328 7.75794 7.53767 7.48C7.53767 7.3127 7.53767 7.15889 7.55493 6.98889C7.58659 6.46809 7.62113 5.94728 7.65278 5.42648C7.67005 5.08918 7.70171 4.75187 7.71898 4.41457C7.71898 4.29314 7.70171 4.1852 7.65278 4.07726C7.50601 3.77503 7.16354 3.62122 6.82106 3.69678Z",
          fill: "#FF3030",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }