var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 6 9",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M-3.39362e-07 1.23606L3.41373 4.49976L-5.40406e-08 7.76345L1.29314 8.99976L6 4.49976L1.29314 -0.000244197L-3.39362e-07 1.23606Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }