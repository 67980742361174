<template>
  <div :class="{'card': true, 'active': card.active}">
    <div class="header">
      <div v-if="!card.active" class="status">
        <Ban />
        <p>
          {{ $t('prediction.expired') }}
        </p>
      </div>
      <div v-else class="status">
        <Play />
        <p>
          {{ $t('prediction.live') }}
        </p>
      </div>
      <div :class="{'hat': true, 'active': isPriceHigher}">
        <CardHat />
        <div class="h2">
          {{ $t('prediction.up') }}
        </div>
        <div class="h6">
          {{ bullX }}
          x
          {{ $t('prediction.payout') }}
        </div>
      </div>
      <div class="status">
        <div class="h6">
          #
        </div>
        <p>
          {{ card.id }}
        </p>
      </div>
    </div>
    <div :class="{'body': true, 'higher': isPriceHigher, 'lower': isPriceLower}">
      <div v-if="!card.active" class="h4">
        {{ $t('prediction.closed_price') }}
      </div>
      <div v-else class="h4">
        {{ $t('prediction.last_price') }}
      </div>
      <div class="chart-area"></div>
      <div v-if="!card.active" class="price">
        ${{ $priceFormat(card.closedPrice) }}
      </div>
      <div v-else class="price">
        ${{ $priceFormat(card.price) }}
      </div>
      <div class="change-area">
        <p class="price">
          $ {{ priceChange }}
        </p>
      </div>
      <hr>
      <div class="details">
        <div class="item">
          <div class="icon-area">
            <Lock />
          </div>
          <div class="text-area">
            <p class="hint">
              {{ $t('prediction.locked_price') }}
            </p>
            <p class="value">
              ${{ $priceFormat(card.lockPrice) }}
            </p>
          </div>
        </div>
        <div class="item">
          <div class="icon-area">
            <Bnb />
          </div>
          <div class="text-area">
            <p class="hint">
              {{ $t('prediction.prize_pool') }}
            </p>
            <p class="value">
              ${{ $priceFormat(card.totalAmount) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div :class="{'hat': true, 'active': isPriceLower}">
        <CardHat />
        <div class="h2">
          {{ $t('prediction.down') }}
        </div>
        <div class="h6">
          {{ bearX }}
          x
          {{ $t('prediction.payout') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ban from '~/components/svg/new/Ban'
import Bnb from '~/components/svg/new/Bnb'
import CardHat from '~/components/svg/new/CardHat'
import Lock from '~/components/svg/new/Lock'
import Play from '~/components/svg/new/Play'

export default {
  name: 'PredictionCard',
  components: {
    Ban,
    Bnb,
    CardHat,
    Lock,
    Play
  },
  layout: 'new',
  props: {
    card: {
      type: Object,
      default: () => {
        return {
          id: 1,
          price: 1,
          lockPrice: 1,
          closedPrice: 1,
          totalAmount: 2,
          bullAmount: 1,
          bearAmount: 1,
          active: false
        }
      }
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    bullX() {
      return this.$priceFormat(this.card.bullAmount / this.card.totalAmount * 100, 2)
    },
    bearX() {
      return this.$priceFormat(this.card.bearAmount / this.card.totalAmount * 100, 2)
    },
    isPriceHigher() {
      if (this.card.active) {
        return this.card.lockPrice < this.card.price
      } else {
        return this.card.lockPrice < this.card.closedPrice
      }
    },
    isPriceLower() {
      if (this.card.active) {
        return this.card.lockPrice > this.card.price
      } else {
        return this.card.lockPrice > this.card.closedPrice
      }
    },
    priceChange() {
      if (!this.card.active) {
        const price = this.card.closedPrice - this.card.lockPrice
        return (price > 0 ? '+' : (price < 0 ? '-' : '')) + this.$priceFormat(Math.abs(price), 4)
      } else {
        const price = this.card.price - this.card.lockPrice
        return (price > 0 ? '+' : (price < 0 ? '-' : '')) + this.$priceFormat(Math.abs(price), 4)
      }
    }
  }
}
</script>

<style scoped>
    @import url('../../../assets/css/new/predictionCard.css');
</style>
