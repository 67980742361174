var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "19",
        viewbox: "0 0 20 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M19.7786 2.24339C19.4829 1.94694 19.0038 1.94616 18.7071 2.24136L12.9548 7.97037L11.2924 6.30799C10.9965 6.01229 10.5172 6.01202 10.2212 6.30799L3.47882 13.0504C3.18284 13.3461 3.18284 13.8259 3.47882 14.1217C3.62655 14.2696 3.8205 14.3436 4.01444 14.3436C4.20811 14.3436 4.40233 14.2696 4.55003 14.1217L10.7569 7.9151L12.4182 9.57619C12.7139 9.87189 13.192 9.87217 13.4884 9.57744L19.7763 3.31483C20.0727 3.01964 20.0738 2.53983 19.7786 2.24339Z",
          fill: "#0C275C",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M19.2171 16.6666H1.51515V0.757577C1.51515 0.33914 1.17601 0 0.757577 0C0.33914 0 0 0.33914 0 0.757577V17.4242C0 17.8427 0.33914 18.1818 0.757577 18.1818H19.2171C19.6356 18.1818 19.9747 17.8427 19.9747 17.4242C19.9747 17.0058 19.6356 16.6666 19.2171 16.6666Z",
          fill: "#0C275C",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M19.2421 1.99512H13.5603C13.1419 1.99512 12.8027 2.33426 12.8027 2.75269C12.8027 3.17113 13.1419 3.51027 13.5603 3.51027H18.4846V8.43475C18.4846 8.85292 18.8237 9.19233 19.2421 9.19233C19.6606 9.19233 19.9997 8.85292 19.9997 8.43448V2.75269C19.9997 2.33426 19.6605 1.99512 19.2421 1.99512Z",
          fill: "#0C275C",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }