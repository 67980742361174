var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalArea",
      class: {
        "modal-container": true,
        "connect-wallet": true,
        active: _vm.modalOpen,
      },
    },
    [
      _c("div", { staticClass: "modal-wrap" }, [
        _c("div", { ref: "modalInner", staticClass: "modal-content-wrap" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "h2" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.roi_calculator")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "close-area", on: { click: _vm.closeModal } },
                [_c("Plus")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "staked-calculator" }, [
              _c("p", { staticClass: "subheader" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("stake.fle_staked")) +
                    ":\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "upper-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.amount,
                        expression: "amount",
                      },
                    ],
                    staticClass: "roi-input",
                    attrs: { type: "number" },
                    domProps: { value: _vm.amount },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.amount = $event.target.value
                        },
                        _vm.calculateRoiAmount,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.currency) +
                        "\n              "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "lower-row" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.currency === _vm.$config.floatieSymbol
                          ? _vm.$formatCryptoAmount(_vm.amountSecond, 15)
                          : _vm.$priceFormat(_vm.amountSecond, 2)
                      ) +
                      "\n              " +
                      _vm._s(_vm.currencySecond) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "swap-area",
                    on: { click: _vm.swapRoiInputs },
                  },
                  [_c("Swap")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "amount-pips" }, [
                _c(
                  "div",
                  {
                    staticClass: "pip",
                    on: {
                      click: function ($event) {
                        return _vm.setAmount(100)
                      },
                    },
                  },
                  [_vm._v("\n              $100\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "pip",
                    on: {
                      click: function ($event) {
                        return _vm.setAmount(100)
                      },
                    },
                  },
                  [_vm._v("\n              $1000\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "pip grow",
                    on: {
                      click: function ($event) {
                        return _vm.setAmount(0)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("stake.my_balance")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "hint-icon wide-tooltip",
                    attrs: {
                      flow: "left",
                      tooltip: _vm.$t("stake.my_balance_desc"),
                    },
                  },
                  [_c("Question")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "subheader" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("stake.stake_for")) +
                    ":\n          "
                ),
              ]),
              _vm._v(" "),
              _vm.type === "flexible"
                ? _c(
                    "div",
                    { staticClass: "duration-pips" },
                    [
                      _vm._l(_vm.daysFlexible, function (dur, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            class: { pip: true, active: dur === _vm.days },
                            on: {
                              click: function ($event) {
                                return _vm.changeDays(dur)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.daysDisplay(dur)) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pip",
                          on: {
                            click: function ($event) {
                              return _vm.changeWeeks(
                                _vm.daysFlexible[_vm.daysFlexible.length - 1]
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("swap.max")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    2
                  )
                : _c(
                    "div",
                    { staticClass: "duration-pips" },
                    [
                      _vm._l(_vm.daysFixed, function (dur, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            class: { pip: true, active: dur === _vm.days },
                            on: {
                              click: function ($event) {
                                return _vm.changeDays(dur)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.daysDisplay(dur, "w")) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pip",
                          on: {
                            click: function ($event) {
                              return _vm.changeDays(
                                _vm.daysFixed[_vm.daysFixed.length - 1]
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("swap.max")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
              _vm._v(" "),
              _vm.type === "fixed"
                ? _c("div", { staticClass: "duration-input" }, [
                    _c("div", { staticClass: "field-area" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.days,
                            expression: "days",
                          },
                        ],
                        attrs: { type: "number", step: "0.0000001", min: "0" },
                        domProps: { value: _vm.days },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.days = $event.target.value
                            },
                            _vm.changeDays,
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "text hint" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$num2str(
                                [
                                  _vm.$t("common.week"),
                                  _vm.$t("common.weeks"),
                                  _vm.$t("common.many_weeks"),
                                ],
                                _vm.days
                              )
                            ) +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "arrow-down" }, [_c("ArrowDown")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "result-area" }, [
                _c("p", { staticClass: "subheader" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("stake.current_roi")) +
                      ":\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "h4" }, [
                  _vm._v(
                    "\n              $" +
                      _vm._s(_vm.$priceFormat(_vm.roi, 2)) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "second" }, [
                  _vm._v(
                    "\n              ~" +
                      _vm._s(
                        _vm.$formatCryptoAmount(_vm.roiSecond, 15) +
                          " " +
                          _vm.token.symbol
                      ) +
                      " (" +
                      _vm._s(_vm.$priceFormat(_vm.roiPercent, 2)) +
                      "%)\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "spoiler-area" }, [
                _c(
                  "div",
                  {
                    class: { spoiler: true, active: _vm.spoilerActive },
                    on: { click: _vm.toggleSpoiler },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            !_vm.spoilerActive
                              ? _vm.$t("lottery.details")
                              : _vm.$t("lottery.hide")
                          ) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("ChevronDown"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "spoiler-content": true,
                      active: _vm.spoilerActive,
                    },
                  },
                  [
                    _c("div", { staticClass: "table-data" }, [
                      _c("div", { staticClass: "table-data-row" }, [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "\n                    APR\n                  "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$priceFormat(_vm.apr, 2)) +
                              "%\n                  "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "list" }, [
                      _c("li", { staticClass: "item" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("stake.current_rates")) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("stake.no_guarantee")) +
                            "\n                "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }