var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu-area mobile" }, [
    _c("div", { staticClass: "main-menu" }, [
      _c(
        "div",
        { staticClass: "menu-item" },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/swap") } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("common.swap")) + "\n      "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-item" },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/account") } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("common.invest")) + "\n      "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-item" },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/listing") } }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("common.listing")) + "\n      "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-item" },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/liquidity") } }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("common.liquidity")) + "\n      "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-item" },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/farm") } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("common.farm")) + "\n      "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-item" },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/stake") } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("common.stake")) + "\n      "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-item" },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/rewards") } }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("common.rewards")) + "\n      "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-item" },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/analytics") } }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("common.analytics")) + "\n      "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "accordion0", staticClass: "menu-item accordion mr-0" },
        [
          _c(
            "p",
            { staticClass: "accordion-header" },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("common.game")) + "\n        "
              ),
              _c("ChevronDown"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "accordion-area" }, [
            _c(
              "div",
              { staticClass: "accordion-list" },
              [
                _c("nuxt-link", { attrs: { to: _vm.localePath("/lottery") } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.lottery")) +
                      "\n          "
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "accordion1", staticClass: "menu-item accordion mr-0" },
        [
          _c(
            "p",
            { staticClass: "accordion-header" },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("common.about")) + "\n        "
              ),
              _c("ChevronDown"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "accordion-area" }, [
            _c(
              "div",
              { staticClass: "accordion-list" },
              [
                _c(
                  "nuxt-link",
                  { attrs: { to: _vm.localePath("/contacts") } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.contacts")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: _vm.localePath("/faq") } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.faq")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: _vm.localePath("/voting") } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.voting")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  { attrs: { to: _vm.localePath("/whitepaper") } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.whitepaper")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://medium.com/@info_19215/meeting-with-defipool-5ab219f3be2e",
                      target: "_blank",
                      rel: "nofollow noreferrer noopener",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.blog")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "accordion2", staticClass: "menu-item accordion mr-0" },
        [
          _c(
            "p",
            { staticClass: "accordion-header" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("common.developers")) +
                  "\n        "
              ),
              _c("ChevronDown"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "accordion-area" }, [
            _c(
              "div",
              { staticClass: "accordion-list" },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.contacts.github,
                      rel: "nofollow noreferrer noopener",
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n            Github\n          ")]
                ),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: _vm.localePath("/docs") } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.documentation")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: _vm.localePath("/bounty") } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.bug_bounty")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: _vm.localePath("/audits") } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.audits")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: _vm.localePath("/careers") } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.careers")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: _vm.localePath("/report") } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.report")) +
                      "\n          "
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "accordion3", staticClass: "menu-item accordion mr-0" },
        [
          _c(
            "p",
            { staticClass: "accordion-header" },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("common.language")) + "\n        "
              ),
              _c("ChevronDown"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "accordion-area" }, [
            _c(
              "div",
              { staticClass: "accordion-list" },
              _vm._l(_vm.availableLocales, function (locale) {
                return _c(
                  "nuxt-link",
                  {
                    key: locale.code,
                    attrs: {
                      to: _vm.switchLocalePath(locale.code),
                      rel: "nofollow noreferrer",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(locale.name) + "\n          "
                    ),
                  ]
                )
              }),
              1
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "setting-section" }, [
      _c("div", { staticClass: "divider-horizontal" }),
      _vm._v(" "),
      _c("div", { staticClass: "socials-area" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.$config.discord,
              rel: "nofollow noreferrer noopener",
              target: "_blank",
            },
          },
          [_c("DiscordLogo")],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.$config.twitter,
              rel: "nofollow noreferrer noopener",
              target: "_blank",
            },
          },
          [_c("TwitterLogo")],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.$config.discourse,
              rel: "nofollow noreferrer noopener",
              target: "_blank",
            },
          },
          [_c("DiscourseLogo")],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.$config.reddit,
              rel: "nofollow noreferrer noopener",
              target: "_blank",
            },
          },
          [_c("RedditLogo")],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: `switcher-area ${_vm.darkTheme ? "dark" : "light"}`,
        on: { click: _vm.changeTheme },
      },
      [
        _c("div", { staticClass: "switcher" }, [
          _c("div", { staticClass: "light" }, [_c("Sun")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "dark" }, [_c("Moon")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "circle" }, [_c("SwitchCircle")], 1),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }