<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0,512) scale(0.1,-0.1)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M1758 4991 c-71 -28 -112 -57 -165 -115 -63 -69 -113 -165 -178 -341
            -68 -185 -101 -246 -140 -257 -16 -5 -117 -13 -223 -18 -106 -6 -228 -17 -270
            -26 -200 -41 -321 -151 -358 -325 -24 -113 -11 -212 61 -459 18 -63 36 -141
            40 -173 7 -57 7 -59 -26 -94 -19 -19 -90 -79 -159 -133 -165 -128 -252 -221
            -299 -319 -34 -71 -36 -82 -36 -171 0 -89 2 -100 36 -171 47 -98 134 -191 299
            -319 69 -54 140 -114 159 -133 33 -35 33 -37 26 -94 -4 -32 -22 -110 -40 -173
            -72 -247 -85 -346 -61 -459 37 -173 159 -284 359 -325 43 -9 164 -20 270 -26
            106 -5 206 -13 222 -18 39 -11 72 -72 140 -257 66 -180 116 -274 183 -347 138
            -148 336 -169 547 -57 33 17 108 65 168 105 119 82 215 134 247 134 32 0 128
            -52 247 -134 207 -141 310 -181 448 -174 68 4 95 10 148 36 127 63 207 178
            302 437 68 185 101 246 140 257 17 5 117 13 223 18 106 6 228 17 270 26 200
            41 321 151 358 325 24 113 11 212 -61 459 -18 63 -36 141 -40 173 -7 57 -7 59
            26 94 19 19 90 79 159 133 165 128 252 221 299 319 34 71 36 82 36 171 0 89
            -2 100 -36 171 -47 98 -134 191 -299 319 -69 54 -140 114 -159 134 l-35 36 13
            80 c6 44 21 105 31 135 31 89 68 254 75 330 17 186 -70 349 -228 424 -89 42
            -190 60 -409 71 -106 5 -206 13 -223 18 -39 11 -72 72 -140 257 -96 260 -174
            374 -303 437 -55 28 -80 34 -149 37 -138 5 -241 -35 -446 -175 -119 -82 -215
            -134 -247 -134 -31 0 -130 53 -233 124 -134 93 -204 132 -290 161 -94 31 -207
            34 -279 6z m190 -430 c20 -11 95 -58 166 -106 213 -143 315 -185 446 -185 129
            0 230 41 436 178 65 43 141 92 168 107 48 28 49 28 63 9 21 -27 47 -89 100
            -234 80 -219 137 -313 235 -386 102 -75 205 -98 498 -113 102 -5 193 -12 203
            -16 24 -10 17 -55 -39 -253 -122 -429 -74 -568 284 -839 40 -30 99 -79 130
            -108 l57 -53 -30 -32 c-16 -18 -87 -77 -156 -131 -151 -118 -229 -193 -274
            -263 -93 -145 -95 -281 -11 -579 56 -197 63 -242 39 -251 -10 -4 -101 -12
            -203 -17 -290 -14 -381 -34 -487 -104 -99 -66 -165 -172 -247 -397 -49 -135
            -93 -235 -108 -244 -11 -7 -70 27 -219 127 -202 135 -310 179 -439 179 -131 0
            -233 -42 -446 -185 -142 -95 -200 -128 -213 -121 -14 9 -59 111 -107 244 -82
            225 -144 325 -245 395 -105 72 -192 91 -489 106 -102 5 -193 13 -203 17 -24 9
            -17 54 39 251 84 298 82 434 -11 579 -45 70 -123 145 -274 263 -69 54 -139
            113 -155 130 l-30 31 30 31 c16 17 86 76 155 130 151 118 229 193 274 263 93
            145 95 281 11 579 -56 197 -63 242 -39 251 10 4 97 11 193 16 237 13 283 18
            372 46 177 56 266 165 371 454 56 153 99 250 113 250 3 0 21 -9 42 -19z"
      />
      <path
        d="M3290 3297 c-27 -8 -150 -125 -515 -488 l-480 -479 -200 200 c-218
            219 -252 243 -340 242 -152 -1 -250 -155 -190 -298 19 -47 558 -593 620 -629
            53 -31 103 -38 162 -24 46 11 77 41 616 578 330 329 577 582 588 605 13 24 20
            58 20 91 0 147 -137 246 -281 202z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VerifiedIcon'
}
</script>
