export const state = () => ({
  investments: [],
  total: 0,
  investmentsLoading: false,
  investmentLoading: false
})

export const mutations = {
  setInvestments(state, investments) {
    state.investments = investments
  },
  setTotal(state, total) {
    state.total = total
  },
  addInvestment(state, investment) {
    state.investments.unshift(investment)
  },
  addInvestments(state, investments) {
    state.investments = state.investments.concat(investments)
  },
  setLoadingInvestments(state, status) {
    state.investmentsLoading = status
  },
  setLoadingInvestment(state, status) {
    state.investmentLoading = status
  }
}

export const actions = {
  async fetchInvestments({
    commit
  }, payload) {
    commit('setLoadingInvestments', true)
    try {
      const request = await this.$generateApiRequest()
      const transactions = await request.$get('investments', {
        params: {
          page: payload.page !== undefined ? payload.page : 1,
          step: payload.step !== undefined ? payload.step : 25,
          orderField: payload.orderField !== undefined ? payload.orderField : 'created_at_desc',
          method: payload.method !== undefined ? payload.method : '',
          search: payload.search !== undefined ? payload.search : ''
        }
      })
      commit('setInvestments', transactions.answer.investments)
      commit('setTotal', transactions.total)
      commit('setLoadingInvestments', false)
    } catch (err) {
      commit('setLoadingInvestments', false)
    }
  },
  async addInvestment({
    commit
  }, payload) {
    commit('setLoadingInvestment', true)
    const request = await this.$generateApiRequest()
    const investment = await request.$post('investments', payload)
    commit('addInvestment', investment.answer.investment)
    commit('setLoadingInvestment', false)
  }
}

export const getters = {
  investments: state => state.investments,
  total: state => state.total,
  investmentsLoading: state => state.investmentsLoading,
  investmentLoading: state => state.investmentLoading
}
