var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "translate(0,512) scale(0.1,-0.1)",
            fill: "currentColor",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M2345 4629 c-301 -32 -624 -143 -882 -302 -913 -567 -1245 -1751\n          -757 -2707 345 -676 1013 -1104 1774 -1137 817 -34 1587 426 1945 1162 134\n          275 199 531 212 835 27 650 -263 1288 -773 1699 -419 339 -983 506 -1519 450z\n          m303 -976 c14 -10 35 -32 46 -47 21 -27 21 -38 21 -566 0 -528 0 -539 -21\n          -566 -39 -53 -71 -69 -134 -69 -63 0 -95 16 -134 69 -20 27 -21 40 -24 539 -2\n          282 0 526 3 544 7 40 49 91 90 109 40 19 120 12 153 -13z m-14 -1665 c55 -16\n          138 -99 154 -154 28 -94 8 -169 -63 -239 -101 -102 -229 -102 -330 0 -102 101\n          -102 229 0 330 70 71 145 90 239 63z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }