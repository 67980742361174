var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "12",
        viewbox: "0 0 14 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.7762 5.28831L8.72033 0.223738C8.57601 0.0792385 8.38365 -0.000161537 8.17855 -0.000161528C7.97322 -0.000161519 7.78098 0.0793387 7.63665 0.223738L7.17762 0.683638C7.03341 0.828038 6.95396 1.02084 6.95396 1.22634C6.95396 1.43184 7.03341 1.63114 7.17762 1.77544L10.1272 4.73649L0.756335 4.73649C0.333834 4.73649 -2.41512e-07 5.06781 -2.41512e-07 5.49114L-2.41512e-07 6.14135C-2.41512e-07 6.56468 0.333834 6.9294 0.756335 6.9294L10.1606 6.9294L7.17773 9.90695C7.03352 10.0515 6.95407 10.2391 6.95407 10.4446C6.95407 10.65 7.03352 10.8403 7.17773 10.9847L7.63677 11.4432C7.78109 11.5877 7.97333 11.6665 8.17866 11.6665C8.38377 11.6665 8.57612 11.5867 8.72045 11.4421L13.7763 6.3777C13.921 6.23267 14.0006 6.03908 14 5.83329C14.0005 5.62681 13.921 5.4331 13.7762 5.28831Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }