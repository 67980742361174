<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7556 18.5775L14.0682 12.89C15.1698 11.5292 15.8331 9.8 15.8331 7.91668C15.8331 3.55168 12.2815 0 7.91655 0C3.5516 0 0 3.55164 0 7.91664C0 12.2816 3.55164 15.8333 7.91659 15.8333C9.79989 15.8333 11.5291 15.17 12.8899 14.0683L18.5773 19.7558C18.7398 19.9183 18.9531 20 19.1665 20C19.3798 20 19.5931 19.9183 19.7556 19.7558C20.0815 19.43 20.0815 18.9033 19.7556 18.5775ZM7.91659 14.1666C4.46995 14.1666 1.66666 11.3633 1.66666 7.91664C1.66666 4.46996 4.46995 1.66664 7.91659 1.66664C11.3632 1.66664 14.1665 4.46996 14.1665 7.91664C14.1665 11.3633 11.3632 14.1666 7.91659 14.1666Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon'
}
</script>
