var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 20 20",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.90715 2.91771C6.79983 -0.972569 13.1878 -0.972569 17.0805 2.91771C20.9732 6.80798 20.9732 13.192 17.0805 17.0823C13.1878 20.9726 6.89964 20.9726 3.00696 17.0823C-0.985529 13.192 -0.985529 6.80798 2.90715 2.91771ZM15.6831 4.31421C12.7886 1.42145 8.29701 1.22195 5.10302 3.61596L16.282 14.8878C18.7773 11.7955 18.4779 7.20698 15.6831 4.31421ZM14.8846 16.2843L3.70565 5.11222C1.31015 8.20449 1.50978 12.793 4.40433 15.6858C7.29889 18.5786 11.7904 18.7781 14.8846 16.2843Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }